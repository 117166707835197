"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var object_1 = require("webeak-native/util/object");
/**
 * Base class for errors in forms.
 */
var FormError = /** @class */ (function () {
    /**
     * @param {string|null} message  optional error message (useful for errors set by the server for example)
     * @param {object}      params   optional parameters that will can be used in the message (%varName%).
     * @param {string}      group    type of the error (example: server)
     * @param {boolean}     volatile if true, the error message will be removed as soon as the control value is changed (useful for server side errors)
     */
    function FormError(message, params, group, volatile) {
        if (params === void 0) { params = {}; }
        if (group === void 0) { group = 'default'; }
        if (volatile === void 0) { volatile = false; }
        this.message = message;
        this.params = params;
        this.group = group;
        this.volatile = volatile;
        this.originalMessage = message;
        if (message !== null) {
            this.message = object_1.replaceStringVariables(this.message, this.params);
        }
    }
    return FormError;
}());
exports.FormError = FormError;
