"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Basic HTTP methods.
 * The list is not meant to be exhaustive, it only contains what's being used.
 */
var HttpMethods;
(function (HttpMethods) {
    HttpMethods["GET"] = "GET";
    HttpMethods["POST"] = "POST";
    HttpMethods["PUT"] = "PUT";
    HttpMethods["DELETE"] = "DELETE";
    HttpMethods["OPTIONS"] = "OPTIONS";
})(HttpMethods = exports.HttpMethods || (exports.HttpMethods = {}));
/**
 * Different status of the response.
 */
var HttpResponseStatus;
(function (HttpResponseStatus) {
    HttpResponseStatus[HttpResponseStatus["Pending"] = 0] = "Pending";
    HttpResponseStatus[HttpResponseStatus["Success"] = 1] = "Success";
    HttpResponseStatus[HttpResponseStatus["Error"] = 2] = "Error";
    HttpResponseStatus[HttpResponseStatus["Canceled"] = 3] = "Canceled";
})(HttpResponseStatus = exports.HttpResponseStatus || (exports.HttpResponseStatus = {}));
