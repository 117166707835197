"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment_to_utc_string_1 = require("essentials/model/transformer/types/moment-to-utc-string");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var raw_1 = require("essentials/model/transformer/types/raw");
/**
 * Mapping between Php types of the Api and the Api transformers here on the client.
 */
exports.ApiServerTypeTransformersMap = {
    string: primitive_1.Primitive('string'),
    integer: primitive_1.Primitive('number'),
    float: primitive_1.Primitive('number'),
    resource: raw_1.Raw(),
    DateTime: moment_to_utc_string_1.MomentToUtcString()
};
