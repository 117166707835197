"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var list_link_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-link/list-link.component");
var error_1 = require("essentials/error");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("essentials/utils/utils");
var api_form_dialog_1 = require("essentials/dialog/alertify/dialog/api-form.dialog");
var DialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DialogComponent, _super);
    function DialogComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.initialized = false;
        _this.dialog = new api_form_dialog_1.ApiFormDialog();
        return _this;
    }
    Object.defineProperty(DialogComponent.prototype, "form", {
        // Computed
        get: function () {
            return this.dialog.getForm();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Get a form control by name.
     */
    DialogComponent.prototype.getFormControl = function (name) {
        return this.dialog.getFormControl(name);
    };
    /**
     * Show the dialog.
     */
    DialogComponent.prototype.openDialog = function () {
        var _this = this;
        if (this.dialogPromise) {
            return this.dialogPromise;
        }
        this.dialogPromise = new Promise(function (resolve) {
            var _resolve = function () {
                resolve();
                _this.dialogPromise = null;
            };
            _this.dialog.action(_this.apiAction)
                .entity(_this.entity)
                .title(_this.title)
                .initialize().then(function () {
                _this.initialize().then(function () {
                    _this.dialog.content($(_this.$refs.dialog).show().get(0));
                    _this.dialog.open().then(function (result) {
                        if (result === true) {
                            if (_this.list) {
                                _this.list.update(true, true);
                            }
                            else if (_this.refreshAfterSave) {
                                window.location.reload();
                            }
                        }
                    }).finally(_resolve);
                });
            }).catch(function (error) {
                console.error(error_1.AppError.create(error));
                _resolve();
            });
        });
        return this.dialogPromise;
    };
    /**
     * Initialize the dialog.
     */
    DialogComponent.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.initialized) {
                            return [2 /*return*/];
                        }
                        this.initialized = true;
                        return [4 /*yield*/, utils_1.onNextCycle()];
                    case 1:
                        _a.sent();
                        if (!this.$scopedSlots.dialog || !this.$refs.dialog) {
                            throw new error_1.AppError('You must define a <template #dialog>You content</template> ' +
                                'inside your <list-dialog-edit-button></list-dialog-edit-button> component.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, required: true }),
        tslib_1.__metadata("design:type", String)
    ], DialogComponent.prototype, "apiAction", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Object], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], DialogComponent.prototype, "entity", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'Édition rapide' }),
        tslib_1.__metadata("design:type", String)
    ], DialogComponent.prototype, "title", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Number], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], DialogComponent.prototype, "dialogWidth", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Number], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], DialogComponent.prototype, "dialogHeight", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], DialogComponent.prototype, "refreshAfterSave", void 0);
    DialogComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./dialog.component.html')
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], DialogComponent);
    return DialogComponent;
}(list_link_component_1.ListLinkComponent));
exports.DialogComponent = DialogComponent;
vue_app_1.VueApp.RegisterComponent('form-dialog', DialogComponent);
