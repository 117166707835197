"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var error_1 = require("essentials/error");
var inversify_1 = require("inversify");
var utils_1 = require("webeak-native/util/utils");
/**
 * A generic key/value pair storage you can use to store data in memory.
 */
var VarHolder = /** @class */ (function () {
    function VarHolder() {
        this.storage = {};
    }
    /**
     * Returns the parameters.
     *
     * @return array An array of parameters
     */
    VarHolder.prototype.all = function () {
        return this.storage;
    };
    /**
     * Returns the parameter keys.
     *
     * @return array An array of parameter keys
     */
    VarHolder.prototype.keys = function () {
        return Object.keys(this.storage);
    };
    /**
     * Get a value stored in the storage.
     * You can give an array of keys to fetch in depth.
     */
    VarHolder.prototype.get = function (key, defaultValue) {
        if (defaultValue === void 0) { defaultValue = null; }
        var keys = utils_1.ensureArray(key);
        var storage = this.storage;
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var k = keys_1[_i];
            if (!utils_1.isObject(storage[k]) || utils_1.isUndefined(storage[k])) {
                return defaultValue;
            }
            storage = storage[k];
        }
        return storage;
    };
    /**
     * Set a value in the storage.
     * You can give an array of keys to set a value deep in the object.
     */
    VarHolder.prototype.set = function (key, value) {
        var keys = utils_1.ensureArray(key);
        var storage = this.storage;
        for (var i = 0; i < keys.length - 1; ++i) {
            if (utils_1.isUndefined(storage[keys[i]])) {
                storage[keys[i]] = {};
            }
            if (!utils_1.isObject(storage[keys[i]])) {
                throw new error_1.AppError('The key "' + keys[i] + '" is already used by a non object value.');
            }
            storage = storage[keys[i]];
        }
        storage[keys[keys.length - 1]] = value;
    };
    /**
     * Replaces the current parameters by a new set.
     *
     * @param {object} parameters An array of parameters
     */
    VarHolder.prototype.replace = function (parameters) {
        this.storage = parameters;
    };
    /**
     * Adds parameters.
     *
     * @param {array} parameters An array of parameters
     */
    VarHolder.prototype.add = function (parameters) {
        for (var name_1 in parameters) {
            if (parameters.hasOwnProperty(name_1)) {
                this.storage[name_1] = parameters[name_1];
            }
        }
    };
    /**
     * Returns true if the parameter is defined.
     *
     * @param {string|string[]} key The key
     *
     * @return bool true if the parameter exists, false otherwise
     */
    VarHolder.prototype.has = function (key) {
        // We can't do:
        //   `this.get(key, undefined)`
        // because doing this will set the default value to `null` (the default value of the parameter).
        //
        // And I don't want to lose the ability to have the default value to null by default.
        // So the trick is to get the value once:
        var v = this.get(key);
        // If the value is null, it may not exist in the storage.
        if (v === null) {
            // But the value set by the user could be null, so do another get to be sure
            return this.get(key, '_') === null; // If the result is still null, the key was effectively set to null by the user.
        }
        // Otherwise the key exist.
        return true;
    };
    /**
     * Removes a parameter.
     *
     * @param {string} key The key
     */
    VarHolder.prototype.remove = function (key) {
        delete (this.storage[key]);
    };
    /**
     * Returns the number of parameters.
     *
     * @return {number}
     */
    VarHolder.prototype.count = function () {
        return Object.keys(this.storage).length;
    };
    VarHolder = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], VarHolder);
    return VarHolder;
}());
exports.VarHolder = VarHolder;
