"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var log_1 = require("essentials/log");
var container_1 = require("webeak-native/inversify/container");
var event_dispatcher_1 = require("essentials/event/event-dispatcher");
var EventDispatcherService = /** @class */ (function (_super) {
    tslib_1.__extends(EventDispatcherService, _super);
    function EventDispatcherService(logger) {
        var _this = _super.call(this) || this;
        _this.logger = logger;
        return _this;
    }
    /**
     * Trigger an event.
     */
    EventDispatcherService.prototype.dispatch = function (eventName, arg) {
        this.logger.debug('Dispatch "' + eventName + '" event.');
        _super.prototype.dispatch.call(this, eventName, arg);
    };
    /**
     * Trigger an event and return responses of callbacks.
     * This method will wait for promises to resolve.
     */
    EventDispatcherService.prototype.dispatchForResponse = function (eventName, arg) {
        this.logger.debug('Dispatch "' + eventName + '" event.');
        return _super.prototype.dispatchForResponse.call(this, eventName, arg);
    };
    EventDispatcherService = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(log_1.LoggerServiceSymbol)),
        tslib_1.__metadata("design:paramtypes", [log_1.LoggerService])
    ], EventDispatcherService);
    return EventDispatcherService;
}(event_dispatcher_1.EventDispatcher));
exports.EventDispatcherService = EventDispatcherService;
exports.EventDispatcherServiceSymbol = Symbol("EventDispatcherService");
container_1.Container.registerService(exports.EventDispatcherServiceSymbol, EventDispatcherService);
