"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
var container_1 = require("webeak-native/inversify/container");
/**
 * Base error class.
 * All errors of the application MUST inherit from this class.
 *
 * Do NOT use the default Error class because of:
 * https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
 *
 * And more particularly because of:
 *
 * "Unfortunately, these workarounds will not work on Internet Explorer 10 and prior.
 * One can manually copy methods from the prototype onto the instance itself (i.e. FooError.prototype onto this), but the prototype chain itself cannot be fixed."
 *
 * To keep IE 10 compatibility, the most reliable way is to have our own base class.
 */
var AppError = /** @class */ (function () {
    /**
     * Creates an AppError instance.
     */
    function AppError(message, previous, extra) {
        if (message === void 0) { message = ''; }
        this.message = message;
        this.previous = previous;
        this.extra = extra;
        if (!utils_1.isNullOrUndefined(previous) && !(previous instanceof AppError)) {
            this.previous = AppError.create(previous);
        }
        if (AppError.IsDev()) {
            if (utils_1.isObject(extra) && !utils_1.isNullOrUndefined(extra.stack)) {
                console.log(extra.stack);
            }
            else {
                console.trace(this);
            }
        }
    }
    /**
     * Offers and easy way to test if the current env is dev.
     */
    AppError.IsDev = function () {
        if (AppError._isDev === null) {
            // SharedConfiguration has the particularity to be also registered as a string constant so it can
            // be imported anywhere without circular dependency error.
            var conf = container_1.Container.getContainer().get('SharedConfiguration');
            AppError._isDev = conf.env === 'dev';
        }
        return AppError._isDev;
    };
    /**
     * Create a AppError instance from a mixed input.
     * Input can be:
     *   - a string
     *   - an Error object
     *   - a AppError object
     *   - a plain object containing a "message" key
     */
    AppError.create = function (input, defaultMessage) {
        if (defaultMessage === void 0) { defaultMessage = 'Unknown error'; }
        if (input instanceof AppError) {
            return input;
        }
        if (utils_1.isString(input)) {
            return new AppError(input);
        }
        if (input instanceof Error) {
            return new AppError(input.toString(), null, { originalError: input, stack: input.stack });
        }
        if (utils_1.isObject(input) && utils_1.isString(input.message)) {
            return new AppError(input.message, null, { originalError: input });
        }
        return new AppError(defaultMessage);
    };
    /**
     * Will try to find the first PublicAppError instance in the stack and will return its message if found.
     * If no public error is found the default message will be returned.
     */
    AppError.prototype.getPublicMessage = function (defaultMessage) {
        if (defaultMessage === void 0) { defaultMessage = 'Unknown error'; }
        var publicError = this.getPublicError();
        if (publicError) {
            return publicError.message;
        }
        return AppError.IsDev() ? this.getRealError().message : defaultMessage;
    };
    /**
     * Returns the first PublicAppError instance in the stack.
     * If none is found, returns null.
     */
    AppError.prototype.getPublicError = function () {
        if (this.previous && this.previous.isPublicError()) {
            return this.previous;
        }
        if (!utils_1.isNullOrUndefined(this.previous)) {
            return this.previous.getPublicError();
        }
        return null;
    };
    /**
     * Ensure the "real" error is returned.
     *
     * That's useful in case you don't know if the error you receive has been wrapped inside a PublicAppError.
     */
    AppError.prototype.getRealError = function () {
        return this;
    };
    /**
     * Get the first error of a certain type in the stack of errors.
     */
    AppError.prototype.getFirstErrorOfType = function (type) {
        if (type === this.constructor) {
            return this;
        }
        if (this.previous) {
            return this.previous.getFirstErrorOfType(type);
        }
        return null;
    };
    /**
     * Gets the string representation of the error.
     */
    AppError.prototype.toString = function () {
        var realError = this.getRealError();
        return realError ? realError.message : this.message;
    };
    /**
     * Returns if the current instance is a public error.
     * Public error must override this method to return "true" instead.
     */
    AppError.prototype.isPublicError = function () {
        return false;
    };
    AppError._isDev = null;
    return AppError;
}());
exports.AppError = AppError;
