"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UploadingFileStatus;
(function (UploadingFileStatus) {
    UploadingFileStatus[UploadingFileStatus["Uploading"] = 0] = "Uploading";
    UploadingFileStatus[UploadingFileStatus["Uploaded"] = 1] = "Uploaded";
    UploadingFileStatus[UploadingFileStatus["Paused"] = 2] = "Paused";
    UploadingFileStatus[UploadingFileStatus["Canceled"] = 3] = "Canceled";
    UploadingFileStatus[UploadingFileStatus["Error"] = 4] = "Error";
})(UploadingFileStatus = exports.UploadingFileStatus || (exports.UploadingFileStatus = {}));
/**
 * Represent a file being uploaded by the file upload queue.
 * The app should only manipulate this interface when dealing with file upload.
 */
var UploadingFile = /** @class */ (function () {
    function UploadingFile() {
    }
    /**
     * Set the upload status of the file.
     */
    UploadingFile.prototype.setStatus = function (status) {
        this.status = status;
        this.isUploading = this.status === UploadingFileStatus.Uploading;
        this.isUploaded = this.status === UploadingFileStatus.Uploaded;
        this.isPaused = this.status === UploadingFileStatus.Paused;
        this.isCanceled = this.status === UploadingFileStatus.Canceled;
        this.isOnError = this.status === UploadingFileStatus.Error;
    };
    /**
     * Set a client side error type.
     */
    UploadingFile.prototype.addClientError = function (error) {
        this.setStatus(UploadingFileStatus.Error);
        this.clientErrors.push(error);
    };
    return UploadingFile;
}());
exports.UploadingFile = UploadingFile;
