"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var form_array_1 = require("essentials/form/form-array");
var form_control_1 = require("essentials/form/form-control");
var utils_1 = require("webeak-native/util/utils");
var value_changed_form_event_1 = require("essentials/form/event/value-changed.form-event");
var CheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckboxComponent, _super);
    function CheckboxComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.checked = false;
        return _this;
    }
    // Watchers
    CheckboxComponent.prototype.onChecked = function (newValue, oldValue) {
        if (newValue) {
            this.addToSelection();
        }
        else {
            this.removeFromSelection();
        }
        if (this.ready) {
            this.$emit('change', new value_changed_form_event_1.ValueChangedFormEvent(this.control, oldValue, newValue));
        }
        this.setAsReady();
    };
    /**
     * Add the value of the current checkbox into the parent form array
     */
    CheckboxComponent.prototype.addToSelection = function () {
        if (this.control instanceof form_array_1.FormArray) {
            var container = this.control;
            for (var _i = 0, _a = container.controls; _i < _a.length; _i++) {
                var control = _a[_i];
                if (control.value === this.value) {
                    return;
                }
            }
            var newControl = new form_control_1.FormControl(this.value);
            container.add(newControl, this.ready, this.ready, !this.ready);
        }
        else {
            this.control.setValue(this.value, this.ready, this.ready, !this.ready);
        }
    };
    /**
     * Remove the value of the current checkbox from the parent form array
     */
    CheckboxComponent.prototype.removeFromSelection = function () {
        if (this.control instanceof form_array_1.FormArray) {
            var container = this.control;
            for (var i = 0; i < container.controls.length; ++i) {
                if (container.controls[i].value === this.value) {
                    container.remove(i, this.ready, this.ready, !this.ready);
                }
            }
        }
        else {
            this.control.setValue(utils_1.isBoolean(this.value) ? false : '', this.ready, this.ready, !this.ready);
        }
    };
    /**
     * Inverse the state of the selection.
     */
    CheckboxComponent.prototype.toggleSelection = function () {
        this.checked = !this.checked;
    };
    /**
     * @inheritDoc
     */
    CheckboxComponent.prototype.doInitialize = function () {
        var wasChecked = this.checked;
        this.checked = (this.control instanceof form_array_1.FormArray) ?
            utils_1.isArray(this.control.value) && this.control.value.indexOf(this.value) >= 0 :
            !!this.control.value;
        // We cannot prevent the "onChecked" watcher to trigger if the value is different.
        // But we don't want any event to be emitted while synchronizing the component with the form control.
        //
        // So "this.ready" will only be set to true here if the default value was already the right one.
        // Otherwise we will let "onChecked()" set it.
        return wasChecked === this.checked;
    };
    /**
     * @inheritDoc
     */
    CheckboxComponent.prototype.bind = function () {
        var _this = this;
        //
        // UGLY but necessary.
        // VueJS doesn't have parent methods in prototype so we have to access them like this.
        //
        // Note that we don't directly do:
        // this.constructor.super.options.methods.bind.call(this);
        //
        // Because it may give an infinite recursion if bind is called from a child class.
        // We have to get up the hierarchy until the bind method we find is different than the current one.
        //
        // @see https://github.com/vuejs/vue/issues/2977#issuecomment-222878609
        //
        var parent = this.constructor;
        do {
            parent = parent.super;
        } while (parent.options.methods.bind === this.constructor.options.methods.bind);
        parent.options.methods.bind.call(this);
        // End of hack
        this.control.onValueChange(function (event) {
            if (_this.control instanceof form_array_1.FormArray) {
                _this.checked = utils_1.isArray(event.newValue) && event.newValue.indexOf(_this.value) >= 0;
            }
            else {
                _this.checked = !!event.newValue;
            }
        });
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Boolean], default: true }),
        tslib_1.__metadata("design:type", Object)
    ], CheckboxComponent.prototype, "value", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('checked'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Boolean, Boolean]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CheckboxComponent.prototype, "onChecked", null);
    CheckboxComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./checkbox.component.html'),
        })
    ], CheckboxComponent);
    return CheckboxComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.CheckboxComponent = CheckboxComponent;
vue_1.default.component('form-checkbox', CheckboxComponent);
