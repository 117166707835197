"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
var Container = /** @class */ (function () {
    function Container() {
    }
    /**
     * Gets the inversify container and create it if necessary.
     *
     * @returns {ContainerType}
     */
    Container.getContainer = function () {
        if (Container.container === null) {
            Container.container = new inversify_1.Container();
        }
        return Container.container;
    };
    /**
     * Register an object as a module into the container.
     * Modules are transient, a new instance will be created each time they are imported as a dependency.
     *
     * @param {Symbol} symbol
     * @param {object} type
     */
    Container.registerModule = function (symbol, type) {
        Container.getContainer().bind(symbol).to(type).inTransientScope();
        Container.symbols.modules.push(symbol);
    };
    /**
     * Register an object as a service into the container.
     * Services are singleton, only one instance will be created and will be shared each time it is imported as a dependency.
     *
     * @param {Symbol} symbol
     * @param {object} type
     */
    Container.registerService = function (symbol, type) {
        Container.getContainer().bind(symbol).to(type).inSingletonScope();
        Container.symbols.services.push(symbol);
    };
    /**
     * Register a service factory.
     *
     * @param {Symbol}   symbol
     * @param {function} callback
     */
    Container.registerFactory = function (symbol, callback) {
        Container.getContainer().bind(symbol).toDynamicValue(callback);
    };
    /**
     * Gets the whole list of registered symbols for modules.
     *
     * @returns {symbol[]}
     */
    Container.getModulesSymbols = function () {
        return [].concat(Container.symbols.modules);
    };
    /**
     * Gets the whole list of registered symbols for services.
     *
     * @returns {symbol[]}
     */
    Container.getServicesSymbols = function () {
        return [].concat(Container.symbols.services);
    };
    /**
     * Inversify container instance.
     */
    Container.container = null;
    /**
     * Symbols of registered modules.
     */
    Container.symbols = {
        modules: [],
        services: [],
    };
    return Container;
}());
exports.Container = Container;
