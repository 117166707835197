"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var error_1 = require("essentials/error");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var list_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list.component");
var string_1 = require("webeak-native/util/string");
var ListColumnComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListColumnComponent, _super);
    function ListColumnComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.realName = null;
        _this.visible = true;
        // Logic vars
        _this.list = null;
        return _this;
    }
    /**
     * VueJS lifecycle method.
     */
    ListColumnComponent.prototype.mounted = function () {
        var _this = this;
        var tbody = null;
        var parentNode = this.$el;
        do {
            parentNode = parentNode.parentNode;
            if (parentNode && parentNode.tagName.toLowerCase() === 'tbody') {
                tbody = parentNode;
                break;
            }
        } while (parentNode);
        if (!parentNode) {
            throw new error_1.AppError('Invalid markup. No <tbody></tbody> found in upper nodes.');
        }
        var $parent = this;
        do {
            $parent = $parent.$parent;
            if ($parent instanceof list_component_1.ListComponent) {
                break;
            }
        } while ($parent);
        if (!($parent instanceof list_component_1.ListComponent)) {
            throw new error_1.AppError('A <list-column></list-column> must be placed inside a <list></list> component.');
        }
        this.list = $parent;
        this.realName = this.name !== null ? this.name : string_1.slugify(this.title);
        var firstLine = parentNode.children[0];
        if (firstLine === this.$el.parentNode) {
            this.list.registerColumn({
                name: this.realName,
                title: this.title,
                orderByName: this.ordering,
                orderByStatus: 'n/a',
                width: this.width
            });
        }
        this.visible = this.list.context.isColumnVisible(this.realName);
        this.contextChangeUnregisterCb = this.list.context.onChange(function (context) {
            _this.visible = context.isColumnVisible(_this.realName);
        });
    };
    ListColumnComponent.prototype.beforeDestroy = function () {
        if (this.contextChangeUnregisterCb) {
            this.contextChangeUnregisterCb();
        }
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListColumnComponent.prototype, "name", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: '(Sans titre)' }),
        tslib_1.__metadata("design:type", String)
    ], ListColumnComponent.prototype, "title", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListColumnComponent.prototype, "ordering", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListColumnComponent.prototype, "width", void 0);
    ListColumnComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-column.component.html')
        })
    ], ListColumnComponent);
    return ListColumnComponent;
}(vue_1.default));
exports.ListColumnComponent = ListColumnComponent;
vue_app_1.VueApp.RegisterComponent('list-column', ListColumnComponent);
