"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var form_event_1 = require("essentials/form/event/form-event");
var SelectChoiceFormEvent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectChoiceFormEvent, _super);
    function SelectChoiceFormEvent(control, choice, dropdownClosed) {
        var _this = _super.call(this, control) || this;
        _this.choice = choice;
        _this.dropdownClosed = dropdownClosed;
        return _this;
    }
    return SelectChoiceFormEvent;
}(form_event_1.FormEvent));
exports.SelectChoiceFormEvent = SelectChoiceFormEvent;
