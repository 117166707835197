"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var local_storage_driver_service_1 = require("./service/local-storage-driver.service");
var cookies_driver_service_1 = require("./service/cookies-driver.service");
var utils_1 = require("webeak-native/util/utils");
var container_1 = require("webeak-native/inversify/container");
var storage_service_1 = require("essentials/storage/service/storage.service");
/**
 * Factory creating the correct storage service instance depending on the platform.
 */
container_1.Container.registerFactory(storage_service_1.StorageServiceSymbol, function (context) {
    if (!utils_1.isUndefined(window.localStorage)) {
        return container_1.Container.getContainer().get(local_storage_driver_service_1.LocalStorageDriverServiceSymbol);
    }
    return container_1.Container.getContainer().get(cookies_driver_service_1.CookiesDriverServiceSymbol);
});
