"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var utils_1 = require("webeak-native/util/utils");
var value_changed_form_event_1 = require("essentials/form/event/value-changed.form-event");
var check_value_change_form_event_1 = require("essentials/form/event/check-value-change.form-event");
var maxId = 0;
var AbstractFormControl = /** @class */ (function () {
    function AbstractFormControl() {
        /**
         * Unique id of the control.
         */
        this.id = ++maxId;
        /**
         * Name of the control in his parent `FormGroup` or the index in his parent `FormArray`
         * The root `FormGroup` has no name.
         */
        this.name = null;
        /**
         * Parent form control.
         */
        this.parent = null;
        /**
         * A control is `valid` when no validator has returned an error.
         */
        this.valid = true;
        /**
         * A control is `validating` when the validation is running.
         */
        this.validating = false;
        /**
         * When a control is `disabled` it is no more part of validation or export.
         */
        this.disabled = false;
        /**
         * A control is `pristine` if the user has not yet changed
         * the value in the UI.
         *
         * @return {boolean}
         */
        this.pristine = true;
        /**
         * True if the control is marked as `touched`.
         *
         * A control is marked `touched` once the user has triggered
         * a `blur` event on it.
         */
        this.touched = false;
        /**
         * A control is `changed` when its value is different from the initial value.
         */
        this.changed = false;
        /**
         * A control is `focused` when its the current field on edition.
         */
        this.focused = false;
        /**
         * True if the control is marked as `virtual`.
         *
         * A virtual control will be ignored in validation and values updates,
         * so it will basically be invisible to the outside world.
         *
         * The goal is to be able to create a form where we don't know yet what
         * controls will actually be used.
         *
         * In such a case, set all fields as virtual by default and only mark as
         * concrete fields that have an actual html element bound to them.
         */
        this.virtual = false;
        /**
         * Object holding each error associated with the control, indexed by error type.
         */
        this.errors = {};
        /**
         * Extra data that are not used internally.
         * Can be used to store anything useful outside of the form.
         */
        this.extra = {};
        /**
         * Object holding callbacks of different events the form control can emit.
         */
        this.listeners = new WeakMap();
        /**
         * Holds the number of times a flag has been applied.
         * Especially useful for the validation flag.
         */
        this.flagsCounters = {};
    }
    Object.defineProperty(AbstractFormControl.prototype, "invalid", {
        /**
         * A control is `invalid` when one of more validators have failed their validation.
         *
         * @return {boolean}
         */
        get: function () { return !this.valid; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormControl.prototype, "enabled", {
        /**
         * A control is `enabled` when it is not `disabled` -_-.
         *
         * @return {boolean}
         */
        get: function () { return !this.disabled; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormControl.prototype, "dirty", {
        /**
         * A control is `dirty` if the user has changed the value
         * in the UI.
         *
         * @return {boolean}
         */
        get: function () { return !this.pristine; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormControl.prototype, "untouched", {
        /**
         * True if the control has not been marked as touched
         *
         * @return {boolean}
         */
        get: function () { return !this.touched; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormControl.prototype, "unchanged", {
        /**
         * True if the value of the control has changed since the form has been created.
         *
         * @return {boolean}
         */
        get: function () { return !this.changed; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormControl.prototype, "unfocused", {
        /**
         * Inverse of `focused`.
         *
         * @return {boolean}
         */
        get: function () { return !this.focused; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormControl.prototype, "concrete", {
        /**
         * True if the control has not been marked as virtual
         *
         * @return {boolean}
         */
        get: function () { return !this.virtual; },
        enumerable: true,
        configurable: true
    });
    /**
     * Set an extra value.
     */
    AbstractFormControl.prototype.addExtra = function (key, value) {
        this.extra[key] = value;
    };
    /**
     * Try to get an extra value.
     * If not found, the default value is returned.
     */
    AbstractFormControl.prototype.getExtra = function (key, defaultValue) {
        if (defaultValue === void 0) { defaultValue = null; }
        if (!utils_1.isUndefined(this.extra[key])) {
            return this.extra[key];
        }
        return defaultValue;
    };
    /**
     * Modify the name of the control.
     */
    AbstractFormControl.prototype.setName = function (name) {
        this.name = name;
    };
    /**
     * Sets the parent control.
     */
    AbstractFormControl.prototype.setParent = function (parent) {
        this.parent = parent;
    };
    /**
     * Make the form control focused.
     */
    AbstractFormControl.prototype.focus = function (bubbleUp) {
        if (bubbleUp === void 0) { bubbleUp = true; }
        this.markAs('focused', true, bubbleUp);
    };
    /**
     * Make the form control focused.
     */
    AbstractFormControl.prototype.blur = function (bubbleUp) {
        if (bubbleUp === void 0) { bubbleUp = true; }
        if (this.focused) {
            this.markAs('touched', true, bubbleUp);
            this.validate(false, false, false);
        }
        this.markAs('focused', false, bubbleUp);
    };
    /**
     * Disable the form control.
     */
    AbstractFormControl.prototype.disable = function (bubbleUp) {
        if (bubbleUp === void 0) { bubbleUp = true; }
        if (this.disabled) {
            return;
        }
        this.markAs('disabled', true, bubbleUp);
    };
    /**
     * Enable the form control.
     */
    AbstractFormControl.prototype.enable = function (bubbleUp) {
        if (bubbleUp === void 0) { bubbleUp = true; }
        if (this.enabled) {
            return;
        }
        this.markAs('disabled', false, bubbleUp);
    };
    /**
     * Modify the value of the control.
     */
    AbstractFormControl.prototype.setValue = function (value, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        if (this.name === 'products') {
            debugger;
        }
        var oldValue = this.value;
        // Ignore the "emitEvent" flag here because this event only check if the value has changed, makes no sense to prevent it.
        var valueChanged = this.notify(new check_value_change_form_event_1.CheckValueChangeFormEvent(this, oldValue, value), false, false);
        if (valueChanged === false || (utils_1.isUndefined(valueChanged) && value === oldValue)) {
            return;
        }
        this.value = value;
        this.updateValue();
        this.removeVolatileErrors();
        if (emitEvent !== false) {
            this.notify(new value_changed_form_event_1.ValueChangedFormEvent(this, oldValue, this.value));
        }
        if (!silent) {
            this.markAs('pristine', false, true, false);
        }
        if (validate) {
            this.validate(false, false, false);
        }
    };
    /**
     * Refresh the value of the control.
     */
    AbstractFormControl.prototype.updateValue = function () {
        if (this.parent !== null && this.parent.concrete) {
            this.parent.updateValue();
        }
    };
    /**
     * Test if the input value is the same as the current value.
     */
    AbstractFormControl.prototype.isSameValue = function (value) {
        return this.notify(new check_value_change_form_event_1.CheckValueChangeFormEvent(this, value, this.value)) === false;
    };
    /**
     * Register a callback that will be called when the value of the control changes.
     */
    AbstractFormControl.prototype.onValueChange = function (cb) {
        return this.subscribe(value_changed_form_event_1.ValueChangedFormEvent, cb);
    };
    /**
     * Register the callback that will be called when a new value has been set to check if the value is different.
     * The callback is expected to return if the two values are identical.
     */
    AbstractFormControl.prototype.onCheckValueChange = function (cb) {
        return this.subscribe(check_value_change_form_event_1.CheckValueChangeFormEvent, cb);
    };
    /**
     * Update the validation status the form control.
     */
    AbstractFormControl.prototype.validate = function (bubbleUp, bubbleDown, setAsTouched) {
        if (bubbleUp === void 0) { bubbleUp = true; }
        if (bubbleDown === void 0) { bubbleDown = true; }
        if (setAsTouched === void 0) { setAsTouched = true; }
        var validationMask = this.buildValidationMask({}, null, bubbleDown);
        if (setAsTouched) {
            this.markAs('touched', true, bubbleUp, bubbleDown);
        }
        this.removeVolatileErrors(true);
        return this.doValidate(validationMask);
    };
    /**
     * Set the validation schema to use to validate the form.
     */
    AbstractFormControl.prototype.setValidationSchema = function (schema) {
        if (this.parent) {
            this.parent.setValidationSchema(schema);
        }
    };
    /**
     * Test if a validation schema is associated with the control.
     */
    AbstractFormControl.prototype.hasValidationSchema = function () {
        if (this.parent) {
            this.parent.hasValidationSchema();
        }
        return false;
    };
    /**
     * Change a status flag of the control.
     */
    AbstractFormControl.prototype.markAs = function (flagName, value, bubbleUp, bubbleDown, weight) {
        if (bubbleUp === void 0) { bubbleUp = true; }
        if (bubbleDown === void 0) { bubbleDown = false; }
        if (weight === void 0) { weight = 0; }
        if (weight > 0) {
            if (utils_1.isUndefined(this.flagsCounters[flagName])) {
                this.flagsCounters[flagName] = 0;
            }
            this.flagsCounters[flagName] += weight * (value ? 1 : -1);
            if ((value && this.flagsCounters[flagName] >= 0) || (!value && this.flagsCounters[flagName] <= 0)) {
                this[flagName] = value;
            }
        }
        else {
            this[flagName] = value;
            this.flagsCounters[flagName] = 0;
        }
        if (this.parent && bubbleUp) {
            this.parent.markAs(flagName, value, true, false, weight);
        }
        if (bubbleDown) {
            this.forEachChild(function (control) { return void control.markAs(flagName, value, false, true, weight); });
        }
        if (flagName === 'virtual' || flagName === 'concrete') {
            this.updateValue();
        }
    };
    /**
     * Remove all errors in the current form control.
     */
    AbstractFormControl.prototype.clearErrors = function (bubbleUp, bubbleDown) {
        if (bubbleUp === void 0) { bubbleUp = false; }
        if (bubbleDown === void 0) { bubbleDown = false; }
        this.setErrors({});
        if (this.parent && bubbleUp) {
            this.parent.clearErrors(true, false);
        }
        if (bubbleDown) {
            this.forEachChild(function (control) { return void control.clearErrors(false, true); });
        }
    };
    /**
     * Sets the whole errors object.
     */
    AbstractFormControl.prototype.setErrors = function (errors) {
        var wasInvalid = Object.keys(this.errors).length > 0;
        var isInvalid = Object.keys(errors).length > 0;
        this.errors = Object.assign({}, errors);
        if (wasInvalid !== isInvalid) {
            this.markAs('valid', !isInvalid, true, false, 1);
        }
    };
    /**
     * Add a new error.
     */
    AbstractFormControl.prototype.addError = function (error) {
        var _a;
        var wasInvalid = Object.keys(this.errors).length > 0;
        this.errors = Object.assign({}, this.errors, (_a = {}, _a[error.group] = error, _a));
        if (!wasInvalid) {
            this.markAs('valid', false, true, false, 1);
        }
    };
    /**
     * Remove a single error by type.
     */
    AbstractFormControl.prototype.removeError = function (type) {
        if (!utils_1.isNullOrUndefined(this.errors[type])) {
            delete this.errors[type];
            this.errors = Object.assign({}, this.errors); // So VueJS can react to the change without any external help..
            if (!Object.keys(this.errors).length) {
                this.markAs('valid', true, true, false, 1);
            }
        }
    };
    /**
     * Remove all errors marked as volatile for the current form control.
     */
    AbstractFormControl.prototype.removeVolatileErrors = function (bubbleUp, bubbleDown) {
        if (bubbleUp === void 0) { bubbleUp = false; }
        if (bubbleDown === void 0) { bubbleDown = false; }
        var types = Object.keys(this.errors);
        for (var _i = 0, types_1 = types; _i < types_1.length; _i++) {
            var type = types_1[_i];
            if (this.errors[type].volatile) {
                this.removeError(type);
            }
        }
        if (bubbleDown) {
            this.forEachChild(function (control) {
                control.removeVolatileErrors(false, true);
            });
        }
        if (bubbleUp && this.parent) {
            this.parent.removeVolatileErrors(true, false);
        }
    };
    /**
     * Test if the form control contains errors.
     */
    AbstractFormControl.prototype.hasErrors = function () {
        return Object.keys(this.errors).length > 0;
    };
    /**
     * Reset the form control to its initial state.
     */
    AbstractFormControl.prototype.reset = function () {
        this.forEachChild(function (child) { return void child.reset(); });
        this.clearValue();
        this.id = ++maxId;
        this.validating = false;
        this.listeners = new WeakMap();
        this.setErrors({});
        this.markAs('valid', true, false, false);
        this.markAs('disabled', false, false, false);
        this.markAs('pristine', true, false, false);
        this.markAs('touched', false, false, false);
        this.markAs('changed', false, false, false);
        this.markAs('focused', false, false, false);
        this.markAs('validating', false, false, false);
        this.setParent(null);
    };
    /**
     * Reset the form control value to an empty value.
     */
    AbstractFormControl.prototype.clearValue = function () {
        this.setValue(null);
    };
    /**
     * Get the first form group in the parent hierarchy.
     */
    AbstractFormControl.prototype.getRoot = function () {
        if (this.parent) {
            return this.parent.getRoot();
        }
        return this;
    };
    /**
     * Build the object the validation service will use to determine which field must be validated.
     */
    AbstractFormControl.prototype.buildValidationMask = function (obj, bubbleUp, bubbleDown) {
        var _a;
        var _this = this;
        var output = (_a = {}, _a[this.name] = obj, _a);
        if (bubbleDown) {
            this.forEachChild(function (control) {
                // Bug fix, the old line is kept as a reminder.
                //
                // The original idea was to exclude disabled fields from the validation, but it's a bad idea.
                // When saving a form, all fields may be disabled by the app logic BEFORE the validation occurs.
                // In such a case, the validation mask will be empty and no validation will occur whatsoever.
                //
                // if (control.enabled) {
                output[_this.name] = Object.assign(output[_this.name], control.buildValidationMask({}, false, true));
                // }
            });
        }
        if (((!this.hasValidationSchema() && bubbleUp === null) || bubbleUp === true) && this.parent) {
            return this.parent.buildValidationMask(output, true, false);
        }
        if (this.name === null) {
            return output[this.name];
        }
        return output;
    };
    /**
     * Do the validation work.
     */
    AbstractFormControl.prototype.doValidate = function (validationMask) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.parent) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.parent.doValidate(validationMask)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/, this.getRoot().valid];
                }
            });
        });
    };
    /**
     * Dispatch an event.
     */
    AbstractFormControl.prototype.notify = function (event, bubbleUp, bubbleDown) {
        if (bubbleUp === void 0) { bubbleUp = true; }
        if (bubbleDown === void 0) { bubbleDown = false; }
        event.prependToStack(this);
        var result = undefined;
        var listeners = this.listeners.get(event.constructor);
        if (utils_1.isArray(listeners)) {
            for (var _i = 0, listeners_1 = listeners; _i < listeners_1.length; _i++) {
                var listener = listeners_1[_i];
                if (event.isPropagationStopped()) {
                    return result;
                }
                result = listener.apply(this, [event]);
            }
        }
        if (!event.isPropagationStopped() && (bubbleUp && this.parent)) {
            this.parent.notify(event, true, false);
        }
        if (bubbleDown) {
            this.forEachChild(function (child) {
                if (!event.isPropagationStopped()) {
                    child.notify(event, false, true);
                }
            });
        }
        return result;
    };
    /**
     * Generic subscription method for internal events.
     */
    AbstractFormControl.prototype.subscribe = function (type, cb) {
        var _this = this;
        var listeners = this.listeners.has(type) ? this.listeners.get(type) : [];
        listeners.push(cb);
        this.listeners.set(type, listeners);
        return function () {
            if (utils_1.isObject(_this.listeners) && utils_1.isArray(_this.listeners.has(type))) {
                var candidates = _this.listeners.get(type);
                for (var i = 0; i < candidates.length; ++i) {
                    if (candidates[i] === cb) {
                        candidates.splice(i, 1);
                    }
                }
                _this.listeners.set(type, candidates);
            }
        };
    };
    return AbstractFormControl;
}());
exports.AbstractFormControl = AbstractFormControl;
