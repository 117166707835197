"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var list_button_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-button/list-button.component");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var ListClearFiltersButtonComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListClearFiltersButtonComponent, _super);
    function ListClearFiltersButtonComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListClearFiltersButtonComponent.prototype.resetFilters = function () {
        this.list.clearFilters();
    };
    ListClearFiltersButtonComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-clear-filters-button.component.html')
        })
    ], ListClearFiltersButtonComponent);
    return ListClearFiltersButtonComponent;
}(list_button_component_1.ListButtonComponent));
exports.ListClearFiltersButtonComponent = ListClearFiltersButtonComponent;
vue_app_1.VueApp.RegisterComponent('list-clear-filters-button', ListClearFiltersButtonComponent);
