"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * NOTE: These parameters are READ ONLY.
 *
 * These parameters are set on the server and are only sent to the client to inform of what's going on
 * so the request builder can build the appropriate query.
 *
 * You cannot alter these parameters in any way from the client side.
 */
var PaginationMetadata = /** @class */ (function () {
    function PaginationMetadata() {
    }
    /**
     * Create a PaginationMetadata instance from the raw output from the server.
     */
    PaginationMetadata.createFromServerResponse = function (config) {
        var instance = new PaginationMetadata();
        instance.isEnabled = !!config[0];
        instance.isClientEnabled = !!config[1];
        instance.isClientItemsPerPage = !!config[2];
        instance.itemsPerPage = config[3];
        instance.maximumItemsPerPage = config[4];
        instance.pageParameterName = config[5];
        instance.enabledParameterName = config[6];
        instance.itemsPerPageParameterName = config[7];
        return instance;
    };
    return PaginationMetadata;
}());
exports.PaginationMetadata = PaginationMetadata;
