"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var abstract_form_control_1 = require("essentials/form/abstract-form-control");
var FormControl = /** @class */ (function (_super) {
    tslib_1.__extends(FormControl, _super);
    function FormControl(value, name) {
        if (value === void 0) { value = null; }
        if (name === void 0) { name = null; }
        var _this = _super.call(this) || this;
        if (name !== null) {
            _this.setName(name);
        }
        _this.setValue(value, false, false, true);
        return _this;
    }
    FormControl.prototype.forEachChild = function (cb) {
        // No children
    };
    return FormControl;
}(abstract_form_control_1.AbstractFormControl));
exports.FormControl = FormControl;
