"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var schemas_holder_1 = require("../../model/schemas-holder");
var form_transformation_model_schema_1 = require("../form-transformation-model.schema");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var utils_1 = require("webeak-native/util/utils");
var error_1 = require("essentials/error");
var form_control_1 = require("essentials/form/transformers/form-control");
var raw_1 = require("essentials/model/transformer/types/raw");
function FormComponent(transformer) {
    if (transformer === void 0) { transformer = null; }
    return function (target, prop) {
        var schema = schemas_holder_1.SchemasHolder.GetInstance().getOrCreate(form_transformation_model_schema_1.FormTransformationModelSchema, target.constructor);
        if (transformer === null) {
            // No transformer has been provided, try to deduct it from the @Transformable() decorator.
            var genericSchema = schemas_holder_1.SchemasHolder.GetInstance().getOrCreate(generic_transformation_model_schema_1.GenericTransformationModelSchema, target.constructor);
            if (!utils_1.isUndefined(genericSchema.properties[prop])) {
                transformer = genericSchema.properties[prop];
            }
        }
        if (transformer === null) {
            var ctorName = utils_1.isObject(target) && !utils_1.isNullOrUndefined(target.constructor) ? target.constructor.name : "Unknown";
            throw new error_1.AppError("No transformer has been defined for property \"" + prop + "\" (" + ctorName + ") and failed to get it from a @Transformable() decorator. " +
                "You must provide a transformer function as argument of the @FormComponent() decorator or " +
                "set a @Transformable() decorator AFTER @FormComponent().");
        }
        if (transformer.type === 'model') {
            transformer = raw_1.Raw();
        }
        if (['form-control', 'form-array', 'form-group'].indexOf(transformer.type) < 0) {
            transformer = form_control_1.FormControl(transformer);
        }
        schema.registerProperty(prop, transformer, raw_1.Raw());
    };
}
exports.FormComponent = FormComponent;
