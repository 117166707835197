"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var utils_1 = require("webeak-native/util/utils");
var form_array_1 = require("essentials/form/form-array");
var form_control_value_1 = require("essentials/form/transformers/form-control-value");
/**
 * Convert an array of values into a FormArray and vice versa.
 */
function FormArray(transformer) {
    if (transformer === void 0) { transformer = form_control_value_1.FormControlValue(); }
    return {
        type: 'form-array',
        transform: function (values, context) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var formArray, i, control;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formArray = new form_array_1.FormArray();
                            formArray.setName(context.currentPropertyName);
                            if (!utils_1.isArray(values)) return [3 /*break*/, 4];
                            i = 0;
                            _a.label = 1;
                        case 1:
                            if (!(i < values.length)) return [3 /*break*/, 4];
                            return [4 /*yield*/, transformer.transform.call(context.result, values[i], context)];
                        case 2:
                            control = _a.sent();
                            formArray.add(control);
                            _a.label = 3;
                        case 3:
                            ++i;
                            return [3 /*break*/, 1];
                        case 4: return [2 /*return*/, formArray];
                    }
                });
            });
        },
        transformInverse: function (formArray, context) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var output, _i, _a, control, converted;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            output = [];
                            _i = 0, _a = formArray.controls;
                            _b.label = 1;
                        case 1:
                            if (!(_i < _a.length)) return [3 /*break*/, 4];
                            control = _a[_i];
                            return [4 /*yield*/, transformer.transformInverse.call(context.result, control, context)];
                        case 2:
                            converted = _b.sent();
                            output.push(converted);
                            _b.label = 3;
                        case 3:
                            _i++;
                            return [3 /*break*/, 1];
                        case 4: return [2 /*return*/, output];
                    }
                });
            });
        },
        getChild: function () {
            return transformer;
        }
    };
}
exports.FormArray = FormArray;
