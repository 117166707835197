"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
require("./tags.component.scss");
var form_array_1 = require("essentials/form/form-array");
var error_1 = require("essentials/error");
var form_group_1 = require("essentials/form/form-group");
var form_control_1 = require("essentials/form/form-control");
var TagsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TagsComponent, _super);
    function TagsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.inputTextValue = '';
        return _this;
    }
    // Watchers
    TagsComponent.prototype.onValueChanged = function (newVal, oldVal) {
        // disabled
    };
    /**
     * Called when the user validates the current input to create a tag.
     */
    TagsComponent.prototype.onSubmit = function () {
        if (!this.allowCreation || !this.inputTextValue) {
            return;
        }
        if (this.addTag({
            id: null,
            label: this.inputTextValue,
            color: this.defaultColor
        })) {
            this.inputTextValue = '';
        }
    };
    TagsComponent.prototype.focusInput = function () {
        this.$refs.input.focus();
    };
    /**
     * Add a tag into the internal array of tags AND the associated form control.
     */
    TagsComponent.prototype.addTag = function (tag) {
        var _a;
        if (!this.allowDoubles) {
            for (var _i = 0, _b = this.tags; _i < _b.length; _i++) {
                var existingTag = _b[_i];
                if (existingTag.label === tag.label) {
                    return false;
                }
            }
        }
        if (!tag.color) {
            tag.color = this.defaultColor;
        }
        this.tags.push(tag);
        this.control.add(new form_group_1.FormGroup((_a = {},
            _a[this.labelFieldName] = new form_control_1.FormControl(tag.label),
            _a[this.colorFieldName] = new form_control_1.FormControl(tag.color),
            _a)));
        return true;
    };
    /**
     * Remove a tag from the internal array of tags AND the associated form control.
     */
    TagsComponent.prototype.removeTag = function (tag) {
        var fieldsAliasesMap = { id: this.idFieldName, label: this.labelFieldName, color: this.colorFieldName };
        var _loop_1 = function (i) {
            if (this_1.tags[i] === tag) {
                var toRemove_1 = null;
                this_1.tags.splice(i, 1);
                this_1.control.forEachChild(function (group) {
                    var refFieldName = tag.id ? 'id' : 'label';
                    if (!group.has(fieldsAliasesMap[refFieldName])) {
                        return;
                    }
                    var control = group.get(fieldsAliasesMap[refFieldName]);
                    if (control.value === tag[refFieldName]) {
                        toRemove_1 = group.name;
                    }
                });
                if (toRemove_1 !== null) {
                    this_1.control.remove(toRemove_1);
                }
                return { value: true };
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.tags.length; ++i) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return false;
    };
    /**
     * Called when the value of the control has changed.
     */
    TagsComponent.prototype.onFormValueChange = function (event) {
        var _this = this;
        var getInvalidTypeError = function () { return new error_1.AppError('Expecting a FormArray control. Please decorate your model\'s attribute like so: ' +
            '@FormComponent(FormArray(FormGroup(\'FIELD_NAME\', FIELD_TYPE)))".'); };
        var array = this.getControlFromFormEvent(event);
        if (!(array instanceof form_array_1.FormArray)) {
            throw getInvalidTypeError();
        }
        var fieldsAliasesMap = { id: this.idFieldName, label: this.labelFieldName, color: this.colorFieldName };
        this.tags.splice(0, this.tags.length);
        array.forEachChild(function (group) {
            if (!(group instanceof form_group_1.FormGroup)) {
                throw getInvalidTypeError();
            }
            var tag = {
                id: null,
                label: null,
                color: null
            };
            for (var field in fieldsAliasesMap) {
                if (fieldsAliasesMap.hasOwnProperty(field) && group.has(fieldsAliasesMap[field])) {
                    tag[field] = group.get(fieldsAliasesMap[field]).value;
                }
            }
            if (!tag.color) {
                tag.color = _this.defaultColor;
            }
            if (tag.label) {
                _this.tags.push(tag);
            }
        });
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: '' }),
        tslib_1.__metadata("design:type", String)
    ], TagsComponent.prototype, "url", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: '#a7a7a7' }),
        tslib_1.__metadata("design:type", String)
    ], TagsComponent.prototype, "defaultColor", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], TagsComponent.prototype, "allowCreation", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], TagsComponent.prototype, "allowDoubles", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'label' }),
        tslib_1.__metadata("design:type", String)
    ], TagsComponent.prototype, "labelFieldName", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'color' }),
        tslib_1.__metadata("design:type", String)
    ], TagsComponent.prototype, "colorFieldName", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'ref' }),
        tslib_1.__metadata("design:type", String)
    ], TagsComponent.prototype, "idFieldName", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Array, default: function () { return []; } }),
        tslib_1.__metadata("design:type", Array)
    ], TagsComponent.prototype, "tags", void 0);
    TagsComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./tags.component.html'),
        })
    ], TagsComponent);
    return TagsComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.TagsComponent = TagsComponent;
vue_1.default.component('form-tags', TagsComponent);
