"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_metadata_1 = require("essentials/api/metadata/api.metadata");
var resource_action_metadata_1 = require("essentials/api/metadata/resource-action.metadata");
var resource_inheritance_metadata_1 = require("essentials/api/metadata/resource-inheritance.metadata");
var resource_property_metadata_1 = require("essentials/api/metadata/resource-property.metadata");
var error_1 = require("essentials/error");
var resource_relation_metadata_1 = require("essentials/api/metadata/resource-relation.metadata");
var utils_1 = require("webeak-native/util/utils");
var ResourceMetadata = /** @class */ (function () {
    function ResourceMetadata() {
        /**
         * Array containing ALL the properties visible by the api (including properties declared on parent entities).
         * Use "ownProperties" to only get properties defined on the entity itself.
         */
        this.properties = [];
        /**
         * Array containing only properties declared in the entity itself (excluding parent properties).
         */
        this.ownProperties = [];
        /**
         * Array containing ALL the relations visible by the api (including relations declared on parent entities).
         * Use "ownrelations" to only get relations defined on the entity itself.
         */
        this.relations = [];
        /**
         * Array containing only relations declared in the entity itself (excluding parent relations).
         */
        this.ownRelations = [];
        /**
         * Array holding the actions' metadata.
         */
        this.actions = [];
        /**
         * Inheritance metadata.
         */
        this.inheritance = null;
        /**
         * Reference on the ResourceMetadata of the parent entity (if there is one).
         */
        this.parent = null;
    }
    /**
     * Create a ResourceMetadata instance from the raw output from the server.
     */
    ResourceMetadata.createFromServerResponse = function (config, entityType) {
        var instance = new ResourceMetadata();
        instance.entityType = entityType;
        instance.identifierProperty = config[0];
        for (var _i = 0, _a = config[1]; _i < _a.length; _i++) {
            var propertyConfig = _a[_i];
            var property = resource_property_metadata_1.ResourcePropertyMetadata.createFromServerResponse(propertyConfig);
            instance.properties.push(property);
            instance.ownProperties.push(property);
        }
        for (var _b = 0, _c = config[2]; _b < _c.length; _b++) {
            var relationConfig = _c[_b];
            var relation = resource_relation_metadata_1.ResourceRelationMetadata.createFromServerResponse(relationConfig);
            instance.relations.push(relation);
            instance.ownRelations.push(relation);
        }
        for (var _d = 0, _e = config[3]; _d < _e.length; _d++) {
            var actionConfig = _e[_d];
            instance.actions.push(resource_action_metadata_1.ResourceActionMetadata.createFromServerResponse(actionConfig));
        }
        instance.parentIdentifier = config[4];
        instance.inheritance = resource_inheritance_metadata_1.ResourceInheritanceMetadata.createFromServerResponse(config[5]);
        return instance;
    };
    /**
     * Gets the configuration of an action.
     */
    ResourceMetadata.prototype.getActionMetadata = function (actionName) {
        for (var _i = 0, _a = this.actions; _i < _a.length; _i++) {
            var action = _a[_i];
            if (action.name === actionName) {
                return action;
            }
        }
        var type = '';
        if (actionName.slice(-5) === '_item') {
            actionName = actionName.substring(0, actionName.length - 5);
            type = 'item ';
        }
        else if (actionName.slice(-11) === '_collection') {
            actionName = actionName.substring(0, actionName.length - 11);
            type = 'collection ';
        }
        throw new error_1.AppError("No " + type + "action \"" + actionName + "\" has been found for entity " + this.entityType.name + ".");
    };
    /**
     * Resolve the entity type considering the possible inheritance.
     */
    ResourceMetadata.prototype.getConcreteEntityType = function (entityData) {
        if (!this.inheritance || utils_1.isNullOrUndefined(entityData[this.inheritance.discriminator]) || !api_metadata_1.ApiMetadata.Instance) {
            return this.entityType;
        }
        var typeAlias = entityData[this.inheritance.discriminator];
        var concreteEntityId = this.inheritance.map[typeAlias];
        return api_metadata_1.ApiMetadata.Instance.getResourceMetadata(concreteEntityId).entityType;
    };
    /**
     * Try to get the name of the discriminator column used for single table inheritance.
     */
    ResourceMetadata.prototype.getDiscriminatorColumn = function () {
        if (this.inheritance) {
            return this.inheritance.discriminator;
        }
        if (this.parent) {
            return this.parent.getDiscriminatorColumn();
        }
        return null;
    };
    /**
     * Set the parent metadata.
     * The parent MUST have its parent resolved otherwise its properties will not be added.
     */
    ResourceMetadata.prototype.setParent = function (parentMetadata) {
        this.parent = parentMetadata;
        for (var _i = 0, _a = this.parent.properties; _i < _a.length; _i++) {
            var parentProperty = _a[_i];
            var i = 0;
            for (; i < this.properties.length; ++i) {
                if (parentProperty.name === this.properties[i].name) {
                    break;
                }
            }
            if (i >= this.properties.length) {
                this.properties.push(parentProperty);
            }
        }
        for (var _b = 0, _c = this.parent.relations; _b < _c.length; _b++) {
            var parentRelation = _c[_b];
            var i = 0;
            for (; i < this.relations.length; ++i) {
                if (parentRelation.name === this.relations[i].name) {
                    break;
                }
            }
            if (i >= this.relations.length) {
                this.relations.push(parentRelation);
            }
        }
    };
    return ResourceMetadata;
}());
exports.ResourceMetadata = ResourceMetadata;
