"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_request_1 = require("essentials/api/api-request");
var api_transformation_model_schema_1 = require("essentials/api/api-transformation-model.schema");
var api_metadata_factory_1 = require("essentials/api/metadata/api-metadata.factory");
var abstract_api_request_builder_1 = require("essentials/api/request-builder/abstract-api-request-builder");
var error_1 = require("essentials/error");
var abstract_form_control_collection_1 = require("essentials/form/abstract-form-control-collection");
var form_error_1 = require("essentials/form/error/form.error");
var form_array_1 = require("essentials/form/form-array");
var form_group_1 = require("essentials/form/form-group");
var form_model_binder_1 = require("essentials/form/form-model-binder");
var form_transformation_model_schema_1 = require("essentials/form/form-transformation-model.schema");
var model_1 = require("essentials/model");
var constants_1 = require("essentials/network/constants");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var object_1 = require("webeak-native/util/object");
var utils_1 = require("webeak-native/util/utils");
/**
 * Offers an easy way to create a request for saving one or multiple entities into the Api.
 */
var ApiPersistRequestBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(ApiPersistRequestBuilder, _super);
    function ApiPersistRequestBuilder(metadataFactory, transformer) {
        var _this = _super.call(this, metadataFactory) || this;
        _this.transformer = transformer;
        _this.payload = null;
        _this.isCollection = null;
        _this.actionMetadata = null;
        _this.boundForm = null;
        return _this;
    }
    /**
     * Sets the name of the action to execute when doing a POST request.
     */
    ApiPersistRequestBuilder.prototype.postAction = function (name) {
        this.postActionName = name;
        return this;
    };
    /**
     * Sets the name of the action to execute when doing a PUT request.
     */
    ApiPersistRequestBuilder.prototype.putAction = function (name) {
        this.putActionName = name;
        return this;
    };
    /**
     * Sets the data to persist.
     *
     * The builder will automatically choose between item/collection actions and PUT/POST depending
     * on your payload.
     *
     * Beware that you cannot mix creation and update in a single query. And exception will be thrown
     * if you attempt to do it.
     */
    ApiPersistRequestBuilder.prototype.data = function (payload) {
        var _this = this;
        this.addAsyncTask(function (metadata) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var exportMask, form, apiMetadata, concretEntityType, form, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        exportMask = null;
                        if (!(payload instanceof form_group_1.FormGroup)) return [3 /*break*/, 4];
                        form = payload;
                        return [4 /*yield*/, this.metadataFactory.create()];
                    case 1:
                        apiMetadata = _b.sent();
                        concretEntityType = apiMetadata.getConcreteEntityType(this.entityType, form.value);
                        this.bindErrorsWithForm(payload);
                        return [4 /*yield*/, this.validateForm(payload)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.transformer.transformInverse(payload, form_transformation_model_schema_1.FormTransformationModelSchema, concretEntityType)];
                    case 3:
                        payload = _b.sent();
                        exportMask = this.buildExportMaskFromForm(form);
                        return [3 /*break*/, 6];
                    case 4:
                        if (!(payload instanceof form_model_binder_1.FormModelBinder)) return [3 /*break*/, 6];
                        form = payload.getForm();
                        this.bindErrorsWithForm(form);
                        return [4 /*yield*/, this.validateForm(form)];
                    case 5:
                        _b.sent();
                        payload = payload.getModel();
                        exportMask = this.buildExportMaskFromForm(form);
                        _b.label = 6;
                    case 6:
                        if (!!utils_1.isPojo(payload)) return [3 /*break*/, 8];
                        _a = this;
                        return [4 /*yield*/, this.normalizeEntityPayload(payload, metadata, exportMask)];
                    case 7:
                        _a.payload = _b.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        this.payload = payload;
                        _b.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        }); });
        return this;
    };
    /**
     * Validate the form and mark all fields as touched so errors are visible.
     * If the form is invalid, an error is thrown and added to the form.
     */
    ApiPersistRequestBuilder.prototype.validateForm = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, form.validate()];
                    case 1:
                        _a.sent();
                        if (!form.valid) {
                            form.clearErrors();
                            form.addError(new form_error_1.FormError('Le formulaire contient des erreurs.', {}, 'api', true));
                            throw new error_1.PublicAppError('Le formulaire contient des erreurs.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Bind a form to the proxy so if an error occurs when the request is executed
     * it will be added to the form instead of throwing.
     *
     * Validation errors will automatically be bound to the corresponding form fields.
     */
    ApiPersistRequestBuilder.prototype.bindErrorsWithForm = function (form) {
        this.boundForm = form;
        return this;
    };
    /**
     * {@inheritdoc}
     */
    ApiPersistRequestBuilder.prototype.createRequest = function (metadata) {
        var isCollection = utils_1.isArray(this.payload);
        var firstEntity = isCollection ? this.payload[0] : this.payload;
        var queryParameters = this.getFilteringQueryParameters(metadata);
        var resourceMetadata = metadata.getResourceMetadata(this.entityType);
        var isNew = utils_1.isNullOrUndefined(firstEntity[resourceMetadata.identifierProperty]) || !firstEntity[resourceMetadata.identifierProperty];
        var actionName = this.getFullActionName(isNew, isCollection);
        var actionMetadata = resourceMetadata.getActionMetadata(actionName);
        if (!isCollection && !isNew) {
            this.addUriReplacement('id', firstEntity[resourceMetadata.identifierProperty]);
        }
        var request = new api_request_1.ApiRequest(resourceMetadata, actionMetadata, isNew ? constants_1.HttpMethods.POST : constants_1.HttpMethods.PUT, this.generateUri(metadata, this.entityType, actionName, false, this.uriReplacements, object_1.merge(this.queryParameters, queryParameters)), this.payload, {});
        request.boundForm = this.boundForm;
        return request;
    };
    /**
     * Convert an entity (or an array of entities) into a payload ready to be sent to the Api.
     */
    ApiPersistRequestBuilder.prototype.normalizeEntityPayload = function (entity, metadata, mask, action, depth) {
        if (mask === void 0) { mask = null; }
        if (action === void 0) { action = null; }
        if (depth === void 0) { depth = 1; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var output, isCollection, entities, resourceMetadata, isNew, _i, entities_1, entity_1, normalized, curIsNew, discriminator, _a, _b, property, isMasked, _c, _d, _e, _f, relation, isMasked, _g, _h;
            return tslib_1.__generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        output = [];
                        isCollection = utils_1.isArray(entity);
                        entities = utils_1.ensureArray(entity);
                        if (!entities.length) {
                            return [2 /*return*/, isCollection ? [] : null];
                        }
                        resourceMetadata = metadata.getResourceMetadata(entities[0].constructor);
                        isNew = utils_1.isNullOrUndefined(entities[0][resourceMetadata.identifierProperty]) || !entities[0][resourceMetadata.identifierProperty];
                        if (!action) {
                            action = resourceMetadata.getActionMetadata(this.getFullActionName(isNew, isCollection));
                        }
                        _i = 0, entities_1 = entities;
                        _j.label = 1;
                    case 1:
                        if (!(_i < entities_1.length)) return [3 /*break*/, 11];
                        entity_1 = entities_1[_i];
                        normalized = {};
                        curIsNew = utils_1.isNullOrUndefined(entity_1[resourceMetadata.identifierProperty]) || !entity_1[resourceMetadata.identifierProperty];
                        if (isNew !== curIsNew && depth === 1) {
                            throw new error_1.AppError('You cannot mix new entities with existing ones. Please do two different Api requests.');
                        }
                        discriminator = resourceMetadata.getDiscriminatorColumn();
                        if (discriminator) {
                            normalized[discriminator] = entity_1[discriminator];
                        }
                        _a = 0, _b = resourceMetadata.properties;
                        _j.label = 2;
                    case 2:
                        if (!(_a < _b.length)) return [3 /*break*/, 5];
                        property = _b[_a];
                        isMasked = mask !== null && utils_1.isUndefined(mask[property.name]);
                        if (((isMasked || utils_1.isUndefined(entity_1[property.name]) || !action.isWritable(property)) && !property.isAlwaysLoaded) || (curIsNew && (property.isApiIdentifier || property.isDatabaseIdentifier))) {
                            return [3 /*break*/, 4];
                        }
                        _c = normalized;
                        _d = property.name;
                        return [4 /*yield*/, this.transformer.transformProperty(property.name, api_transformation_model_schema_1.ApiTransformationModelSchema, entity_1)];
                    case 3:
                        _c[_d] = _j.sent();
                        _j.label = 4;
                    case 4:
                        _a++;
                        return [3 /*break*/, 2];
                    case 5:
                        _e = 0, _f = resourceMetadata.relations;
                        _j.label = 6;
                    case 6:
                        if (!(_e < _f.length)) return [3 /*break*/, 9];
                        relation = _f[_e];
                        isMasked = mask !== null && utils_1.isUndefined(mask[relation.name]);
                        if (isMasked || utils_1.isUndefined(entity_1[relation.name]) || !action.isWritable(relation)) {
                            return [3 /*break*/, 8];
                        }
                        _g = normalized;
                        _h = relation.name;
                        return [4 /*yield*/, this.normalizeEntityPayload(entity_1[relation.name], metadata, mask !== null && !utils_1.isUndefined(mask[relation.name]) ? mask[relation.name] : null, action, depth + 1)];
                    case 7:
                        _g[_h] = _j.sent();
                        _j.label = 8;
                    case 8:
                        _e++;
                        return [3 /*break*/, 6];
                    case 9:
                        output.push(normalized);
                        _j.label = 10;
                    case 10:
                        _i++;
                        return [3 /*break*/, 1];
                    case 11: return [2 /*return*/, isCollection ? output : output[0]];
                }
            });
        });
    };
    /**
     * Build a mask that will only contain non virtual controls.
     */
    ApiPersistRequestBuilder.prototype.buildExportMaskFromForm = function (form) {
        var _this = this;
        var output = {};
        if (form instanceof form_array_1.FormArray) {
            form.forEachChild(function (control) {
                var subOutput = control instanceof abstract_form_control_collection_1.AbstractFormControlCollection ? _this.buildExportMaskFromForm(control) : null;
                if (subOutput !== null) {
                    object_1.extend(output, subOutput, true);
                }
            });
        }
        else {
            form.forEachChild(function (control) {
                output[control.name] = (control instanceof abstract_form_control_collection_1.AbstractFormControlCollection) ? _this.buildExportMaskFromForm(control) : null;
            });
        }
        return output;
    };
    /**
     * Get the full action name ready to be sent to the api.
     */
    ApiPersistRequestBuilder.prototype.getFullActionName = function (isNew, isCollection) {
        var actionName = isNew ? this.postActionName : this.putActionName;
        if (!actionName) {
            throw new error_1.AppError("Invalid configuration, missing \"" + (isNew ? 'POST' : 'PUT') + "\" action name.");
        }
        return actionName + (isCollection || isNew ? '_collection' : '_item');
    };
    ApiPersistRequestBuilder = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(api_metadata_factory_1.ApiMetadataFactorySymbol)),
        tslib_1.__param(1, inversify_1.inject(model_1.TransformerServiceSymbol)),
        tslib_1.__metadata("design:paramtypes", [api_metadata_factory_1.ApiMetadataFactory,
            model_1.TransformerService])
    ], ApiPersistRequestBuilder);
    return ApiPersistRequestBuilder;
}(abstract_api_request_builder_1.AbstractApiRequestBuilder));
exports.ApiPersistRequestBuilder = ApiPersistRequestBuilder;
exports.ApiPersistRequestBuilderSymbol = Symbol("ApiPersistRequestBuilder");
container_1.Container.registerModule(exports.ApiPersistRequestBuilderSymbol, ApiPersistRequestBuilder);
