"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var validation_schema_1 = require("essentials/validation/validation.schema");
var validation_service_1 = require("essentials/validation/validation.service");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var validation_event_type_1 = require("essentials/validation/event/validation-event-type");
var schemas_holder_1 = require("essentials/model/schemas-holder");
var error_1 = require("essentials/error");
function Schema(validators) {
    var validationService = container_1.Container.getContainer().get(validation_service_1.ValidationServiceSymbol);
    var schema = utils_1.isObject(validators) ? new validation_schema_1.ValidationSchema(validators) : null;
    return {
        validate: function (value, context) {
            return new Promise(function (resolve) {
                if (!utils_1.isObject(value)) {
                    return void resolve();
                }
                if (schema === null) {
                    if (utils_1.isString(validators)) {
                        validators = schemas_holder_1.SchemasHolder.GetInstance().resolveModelTypeFromString(validators);
                    }
                    schema = schemas_holder_1.SchemasHolder.GetInstance().get(validation_schema_1.ValidationSchema, validators);
                    if (!schema) {
                        var ctorName = utils_1.isObject(validators) && utils_1.isObject(validators.constructor) ? validators.constructor.name : 'Unknown';
                        throw new error_1.AppError("Unable to resolve schema for " + ctorName + ".");
                    }
                }
                validationService.validateFromSchema(schema, value, context.mask, context).subscribe({
                    next: function (event) {
                        if (event.type === validation_event_type_1.ValidationEventType.ValidationComplete) {
                            resolve();
                        }
                        else {
                            context.observer.next(event);
                        }
                    }
                });
            });
        }
    };
}
exports.Schema = Schema;
