"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var trim = require("lodash/trim");
var lodashToString = require("lodash/toString");
var object_1 = require("webeak-native/util/object");
/**
 * Determines if a reference is null or undefined.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isNullOrUndefined(value) {
    return value === null || typeof value === "undefined";
}
exports.isNullOrUndefined = isNullOrUndefined;
/**
 * Determines if a reference is undefined.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isUndefined(value) {
    return typeof value === "undefined";
}
exports.isUndefined = isUndefined;
/**
 * Determines if a reference is defined.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isDefined(value) {
    return typeof value !== "undefined";
}
exports.isDefined = isDefined;
/**
 * Determines if a reference is an `Object`. Unlike `typeof` in JavaScript, `null`s are not
 * considered to be objects. Note that JavaScript arrays are objects.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isObject(value) {
    // http://jsperf.com/isobject4
    return value !== null && typeof value === "object";
}
exports.isObject = isObject;
/**
 * Determine if a value is an object with a null prototype
 *
 * @returns {boolean}
 */
function isBlankObject(value) {
    return value !== null && typeof value === "object" && !Object.getPrototypeOf(value);
}
exports.isBlankObject = isBlankObject;
/**
 * Determines if a reference is a string.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isString(value) {
    return typeof value === "string";
}
exports.isString = isString;
/**
 * Determines if a reference is a number.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isNumber(value) {
    return typeof value === "number";
}
exports.isNumber = isNumber;
/**
 * Test if a variable represent number or not.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isNumeric(value) {
    return !isObject(value) && !isNaN(parseFloat(value)) && isFinite(value);
}
exports.isNumeric = isNumeric;
/**
 * Determines if a value is a date.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isDate(value) {
    return Object.prototype.toString.call(value) === "[object Date]";
}
exports.isDate = isDate;
/**
 * Determines if the input is a function.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isFunction(value) {
    return typeof value === "function";
}
exports.isFunction = isFunction;
/**
 * Determines if a value is a regular expression object.
 * The input must be a RegExp object in order to return true, NOT a string.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isRegExp(value) {
    return Object.prototype.toString.call(value) === "[object RegExp]";
}
exports.isRegExp = isRegExp;
/**
 * Test if the input is a File object.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isFile(value) {
    return Object.prototype.toString.call(value) === "[object File]";
}
exports.isFile = isFile;
/**
 * Test if the input is a blob object.
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function isBlob(value) {
    return Object.prototype.toString.call(value) === "[object Blob]";
}
exports.isBlob = isBlob;
/**
 * Test if the input is a boolean.
 *
 * @param {any} input
 *
 * @returns {boolean}
 */
function isBoolean(input) {
    return typeof input === "boolean";
}
exports.isBoolean = isBoolean;
/**
 * Test if the input is a real array.
 * Objects will return false.
 *
 * @param input
 * @returns {boolean}
 */
function isArray(input) {
    return Object.prototype.toString.call(input) === "[object Array]";
}
exports.isArray = isArray;
/**
 * Test if the input is a number.
 * This function not only checks the type like the angular one, it aso check if it is an invalid number.
 *
 * @param input
 */
function isValidNumber(input) {
    return typeof input === "number" && !isNaN(input);
}
exports.isValidNumber = isValidNumber;
/**
 * Test if the input is an integer.
 * Notice: "1" will return false because it's a string.
 *
 * @param input
 */
function isInteger(input) {
    return isValidNumber(input) && input % 1 === 0;
}
exports.isInteger = isInteger;
/**
 * Test if the input looks like a promise.
 *
 * @param {any} input
 *
 * @returns {boolean}
 */
function isPromiseLike(input) {
    return input && isFunction(input.then);
}
exports.isPromiseLike = isPromiseLike;
/**
 * Test if the input is a scalar type.
 *
 * @param {any} input
 *
 * @return {boolean}
 */
function isScalar(input) {
    return isString(input) ||
        isNumber(input) ||
        isBoolean(input) ||
        isNullOrUndefined(input);
}
exports.isScalar = isScalar;
/**
 * Test if the input is a plain old javascript object.
 *
 * @param {any}     input
 * @param {boolean} deep  (optional, default: true) if true, the check will be done recursively on all properties of the object.
 *
 * @return {boolean}
 */
function isPojo(input, deep) {
    if (deep === void 0) { deep = true; }
    if (input === null || typeof input !== "object") {
        return false;
    }
    if (Object.getPrototypeOf(input) !== Object.prototype) {
        return false;
    }
    if (!deep) {
        return true;
    }
    var testObjValue = function (value) {
        if (isArray(value)) {
            for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
                var item = value_1[_i];
                if (!testObjValue(item)) {
                    return false;
                }
            }
            return true;
        }
        if (!isScalar(value) && !isPojo(value, true)) {
            return false;
        }
        return true;
    };
    for (var _i = 0, _a = Object.keys(input); _i < _a.length; _i++) {
        var key = _a[_i];
        if (!testObjValue(input[key])) {
            return false;
        }
    }
    return true;
}
exports.isPojo = isPojo;
/**
 * Extracted from AngularJS.
 * Determines if a reference is a DOM element (or wrapped jQuery element).
 *
 * @param {any} node value to check.
 *
 * @returns {boolean} True if the value is a DOM element (or wrapped jQuery element).
 */
function isElement(node) {
    return !!(node &&
        (node.nodeName // We are a direct element.
            || (node.prop && node.attr && node.find))); // We have an on and find method part of jQuery API.
}
exports.isElement = isElement;
/**
 * Test if the input is a valid momentjs date.
 */
function isValidMomentDate(value) {
    return isObject(value) &&
        (!isUndefined(value.isMoment) || !isUndefined(value._isAMomentObject)) &&
        ((isFunction(value.isValid) && value.isValid()) || value._isValid === true);
}
exports.isValidMomentDate = isValidMomentDate;
/**
 * Test if the input value is an object constructor.
 */
function isConstructor(value) {
    try {
        new new Proxy(value, { construct: function () { return {}; } });
        return true;
    }
    catch (err) {
        return false;
    }
}
exports.isConstructor = isConstructor;
/**
 * Ensure the input is always a valid array.
 *
 * @param {any} input
 *
 * @returns {any[]}
 */
function ensureArray(input) {
    if (isNullOrUndefined(input)) {
        return [];
    }
    if (!isArray(input)) {
        return [input];
    }
    return input;
}
exports.ensureArray = ensureArray;
/**
 * Ensure the input is converted to a boolean.
 *
 * @param {any} input
 *
 * @return {boolean}
 */
function ensureBoolean(input) {
    return input === true || input === "" || input === "true" || input === "on" || !!input;
}
exports.ensureBoolean = ensureBoolean;
/**
 * Ensure the input is converted to an object.
 *
 * @param {any} input
 * @param {any} defaultValue (optional, default: empty object) value to return if the input is not an object
 *
 * @return {object}
 */
function ensureObject(input, defaultValue) {
    if (defaultValue === void 0) { defaultValue = {}; }
    return isObject(input) ? input : defaultValue;
}
exports.ensureObject = ensureObject;
/**
 * Ensure the input is converted to a string.
 *
 * @param {any} input
 *
 * @return {string}
 */
function ensureString(input) {
    return !isNullOrUndefined(input) ? lodashToString(input) : "";
}
exports.ensureString = ensureString;
/**
 * Ensure the input is converted to a valid number.
 *
 * @param {any} input
 * @param {any} defaultValue (optional, default: 0) value to return if the input cannot be converted to a valid number
 *
 * @return {number}
 */
function ensureNumber(input, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    if (isString(input)) {
        if (input.indexOf(".") < 0) {
            input = parseInt(input, 10);
        }
        else {
            input = parseFloat(input);
        }
    }
    return isValidNumber(input) ? input : defaultValue;
}
exports.ensureNumber = ensureNumber;
/**
 * Ensure the input is converted to a valid number.
 */
function ensureInteger(input, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    if (isString(input)) {
        input = parseInt(input, 10);
    }
    return isValidNumber(input) ? input : defaultValue;
}
exports.ensureInteger = ensureInteger;
/**
 * Ensure the input is converted to a valid number.
 */
function ensureSameType(input, referenceValue) {
    var targetType = typeof (referenceValue);
    switch (targetType) {
        case 'string': return ensureString(input);
        case 'number':
        case 'bigint': return ensureNumber(input);
        case 'boolean': return ensureBoolean(input);
        case 'object': {
            if (isArray(referenceValue)) {
                return ensureArray(input);
            }
            return ensureObject(input);
        }
        case 'undefined': return undefined;
    }
    return input;
}
exports.ensureSameType = ensureSameType;
/**
 * Trim each element of a string array.
 *
 * @param input string[]
 *
 * @returns string[]
 */
function trimArray(input) {
    for (var i = 0; i < input.length; ++i) {
        input[i] = trim(input[i]);
    }
    return input;
}
exports.trimArray = trimArray;
/**
 * Test if an object contains a nested set of properties.
 *
 * @param {object}    obj
 * @param {...string} keys any number of keys the check.
 *                         Checks are nested, meaning the second property will have to be in an object
 *                         pointed by the first one, and so on.
 *
 * @returns {boolean}
 */
function hasPropertyNested(obj) {
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    if (!isObject(obj)) {
        return false;
    }
    for (var _a = 0, keys_1 = keys; _a < keys_1.length; _a++) {
        var key = keys_1[_a];
        if (!obj.hasOwnProperty(key)) {
            return false;
        }
        obj = obj[key];
    }
    return true;
}
exports.hasPropertyNested = hasPropertyNested;
/**
 * Generates a random integer between two values.
 *
 * @param {number} min
 * @param {number} max
 *
 * @returns {number}
 */
function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
exports.randomInt = randomInt;
/**
 * Return a random element from an array.
 *
 * @param {T[]} arr
 *
 * @returns {T}
 */
function randomInArray(arr) {
    return arr[randomInt(0, arr.length - 1)];
}
exports.randomInArray = randomInArray;
/**
 * Bind a function to a context, optionally partially applying any arguments.
 * Extracted from jQuery 3.2.1 with minor modifications.
 *
 * @param {function} fn
 * @param {any}      context
 *
 * @return {function|undefined}
 */
function proxy(fn, context) {
    var tmp;
    var args;
    if (typeof context === "string") {
        tmp = fn[context];
        context = fn;
        fn = tmp;
    }
    // Quick check to determine if target is callable, in the spec
    // this throws a TypeError, but we will just return undefined.
    if (!isFunction(fn)) {
        return undefined;
    }
    // Simulated bind
    args = Array.prototype.slice.call(arguments, 2);
    return function () {
        return fn.apply(context || this, args.concat(Array.prototype.slice.call(arguments)));
    };
}
exports.proxy = proxy;
/**
 * Ensure a function is only called after not being called for a certain amount of time.
 *
 * @param {function} func      function to call
 * @param {number}   wait      time with no call to wait before calling the function
 * @param {boolean}  immediate (optional, default: true) call the function immediatly after the first call or not?
 *
 * @returns {function}
 */
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}
exports.debounce = debounce;
/**
 * Throttle call to a function to ensure it is not called more frequently than a specified timing.
 *
 * @param {function} func      function to call
 * @param {number}   threshold minimum time between calls, in ms
 * @param {object}   scope     (optional, default: this)
 *
 * @returns {function}
 */
function throttle(func, threshold, scope) {
    if (scope === void 0) { scope = null; }
    var last;
    var deferTimer;
    return function () {
        var context = scope || this;
        var now = (new Date()).getTime();
        var args = arguments;
        if (last && now < last + threshold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                func.apply(context, args);
            }, threshold);
        }
        else {
            last = now;
            func.apply(context, args);
        }
    };
}
exports.throttle = throttle;
/**
 * Convert a size in bytes to a human friendly string.
 */
function humanFileSize(bytes, si) {
    if (si === void 0) { si = true; }
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}
exports.humanFileSize = humanFileSize;
/**
 * Try to get arguments names of a function at runtime.
 * @source https://stackoverflow.com/a/9924463/1110635
 */
function getFunctionArguments(func) {
    if (!isFunction(func)) {
        return [];
    }
    var STRIP_COMMENTS = /(\/\/.*$)|(\/\*[\s\S]*?\*\/)|(\s*=[^,\)]*(('(?:\\'|[^'\r\n])*')|("(?:\\"|[^"\r\n])*"))|(\s*=[^,\)]*))/mg;
    var ARGUMENT_NAMES = /([^\s,]+)/g;
    var str = func.toString().replace(STRIP_COMMENTS, '');
    var result = str.slice(str.indexOf('(') + 1, str.indexOf(')')).match(ARGUMENT_NAMES);
    return result !== null ? result : [];
}
exports.getFunctionArguments = getFunctionArguments;
/**
 * Gets the first defined value of the list or arg.
 * If all arguments are undefined, undefined is returned.
 */
function getFirstOf() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (args.length === 0) {
        return undefined;
    }
    for (var _a = 0, args_1 = args; _a < args_1.length; _a++) {
        var arg = args_1[_a];
        if (!isUndefined(arg)) {
            return arg;
        }
    }
    return args[args.length - 1];
}
exports.getFirstOf = getFirstOf;
/**
 * Test if two values are the same, no matter their type.
 *
 * @param {any} a
 * @param {any} b
 *
 * @returns {boolean}
 */
function areEqual(a, b) {
    var ta = a !== null ? typeof (a) : 'null';
    var tb = b !== null ? typeof (b) : 'null';
    if (ta !== tb) {
        return false;
    }
    switch (ta) {
        case "undefined": return true;
        case "object": return object_1.areSameObjects(a, b);
        case "symbol": return true;
        // function, boolean, number, string, ...
        default: {
            return a === b;
        }
    }
}
exports.areEqual = areEqual;
