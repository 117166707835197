"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var value_changed_form_event_1 = require("essentials/form/event/value-changed.form-event");
var CheckValueChangeFormEvent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckValueChangeFormEvent, _super);
    function CheckValueChangeFormEvent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CheckValueChangeFormEvent;
}(value_changed_form_event_1.ValueChangedFormEvent));
exports.CheckValueChangeFormEvent = CheckValueChangeFormEvent;
