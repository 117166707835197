"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_paginated_results_1 = require("essentials/api/api-paginated-results");
var api_response_1 = require("essentials/api/api-response");
var api_transformation_model_schema_1 = require("essentials/api/api-transformation-model.schema");
var abstract_api_request_builder_1 = require("essentials/api/request-builder/abstract-api-request-builder");
var api_get_request_builder_1 = require("essentials/api/request-builder/api-get-request-builder");
var api_persist_request_builder_1 = require("essentials/api/request-builder/api-persist-request-builder");
var error_1 = require("essentials/error");
var form_validation_error_1 = require("essentials/form/error/form-validation.error");
var form_error_1 = require("essentials/form/error/form.error");
var model_1 = require("essentials/model");
var network_1 = require("essentials/network");
var constants_1 = require("essentials/network/constants");
var cancel_error_1 = require("essentials/network/error/cancel.error");
var http_error_1 = require("essentials/network/error/http.error");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var ApiService = /** @class */ (function () {
    function ApiService(http, transformer) {
        this.http = http;
        this.transformer = transformer;
    }
    /**
     * Create a fake ApiResponse object holding the result given in parameter.
     */
    ApiService.prototype.createFakeResponse = function (result) {
        var response = new api_response_1.ApiResponse();
        response.result = result;
        response.setStatus(constants_1.HttpResponseStatus.Success);
        response.promise = Promise.resolve(result);
        return response;
    };
    /**
     * Prepare a GET request for an entity.
     *
     * \!/ This method returns a request builder, you must complete the build and call the execute()
     * method to execute the request.
     */
    ApiService.prototype.get = function (entity, actionName) {
        if (actionName === void 0) { actionName = 'get'; }
        return new api_get_request_builder_1.ApiGetRequestBuilder(entity, actionName);
    };
    /**
     * Prepare a POST or a PUT request (depending on the payload) for an entity.
     *
     * \!/ This method returns a request builder, you must complete the build and call the execute()
     * method to execute the request.
     *
     * @return PersistRequestBuilder
     */
    ApiService.prototype.persist = function (entity, postActionName, putActionName) {
        if (postActionName === void 0) { postActionName = 'post'; }
        if (putActionName === void 0) { putActionName = 'put'; }
        var builder = container_1.Container.getContainer().get(api_persist_request_builder_1.ApiPersistRequestBuilderSymbol);
        builder.entity(entity);
        builder.postAction(postActionName);
        builder.putAction(putActionName);
        return builder;
    };
    //
    // /**
    //  * Prepare a DELETE request for an entity.
    //  *
    //  * \!/ This method returns a request builder, you must complete the build and call the execute()
    //  * method to execute the request.
    //  *
    //  * @return DeleteRequestBuilder
    //  */
    // public delete($entity, $actionName = 'delete'): DeleteRequestBuilder
    // {
    //     $builder = $this->container->get(DeleteRequestBuilder::class);
    //     $builder->entity($entity);
    //     $builder->action($actionName);
    //     return $builder;
    // }
    /**
     * Execute an Api request.
     */
    ApiService.prototype.execute = function (request) {
        var _this = this;
        var apiResponse = new api_response_1.ApiResponse();
        apiResponse.promise = new Promise(function (resolve, reject) {
            _this.resolveRequest(request).then(function (realRequest) {
                var httpResponse = _this.http.request(realRequest);
                apiResponse.syncWith(httpResponse);
                httpResponse.promise.then(function (requestRawResult) {
                    apiResponse.syncWith(httpResponse);
                    // The httpResponse is done but not the apiResponse because we still need to process the results.
                    apiResponse.setStatus(constants_1.HttpResponseStatus.Pending);
                    _this.processRequestResult(realRequest, requestRawResult).then(function (finalResult) {
                        apiResponse.result = finalResult;
                        apiResponse.setStatus(constants_1.HttpResponseStatus.Success);
                        resolve(finalResult);
                    }).catch(reject);
                }).catch(reject);
            }).catch(reject);
        });
        apiResponse.promise.catch(function (error) {
            if (error instanceof cancel_error_1.CancelError) {
                return;
            }
            _this.handleRequestError(request, apiResponse, error_1.AppError.create(error));
        });
        return apiResponse;
    };
    /**
     * Do the Api call.
     */
    ApiService.prototype.resolveRequest = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(request instanceof abstract_api_request_builder_1.AbstractApiRequestBuilder)) return [3 /*break*/, 2];
                        return [4 /*yield*/, request.getRequest()];
                    case 1:
                        request = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, request];
                }
            });
        });
    };
    ApiService.prototype.processRequestResult = function (request, rawResult) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!request.isSingleEntityResponse(rawResult)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.processSingleResult(request, rawResult)];
                    case 1:
                        result = _a.sent();
                        return [3 /*break*/, 6];
                    case 2:
                        if (!request.isSimpleEntitiesCollectionResponse()) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.processSimpleCollectionResults(request, rawResult)];
                    case 3:
                        result = _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        if (!request.isPaginatedEntitiesCollectionResponse()) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.processPaginatedCollectionResult(request, rawResult)];
                    case 5:
                        result = _a.sent();
                        _a.label = 6;
                    case 6:
                        if (request.boundForm) {
                            request.boundForm.clearErrors(true, true);
                        }
                        return [2 /*return*/, result];
                }
            });
        });
    };
    /**
     * Process a single entity response from the server.
     */
    ApiService.prototype.processSingleResult = function (request, result) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transformer.transformInverse(result, api_transformation_model_schema_1.ApiTransformationModelSchema, request.resourceMetadata.getConcreteEntityType(result))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Process the result of a simple collection of entities from the server.
     */
    ApiService.prototype.processSimpleCollectionResults = function (request, results) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var output, _i, results_1, entity, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!utils_1.isArray(results)) {
                            throw new error_1.AppError('Invalid Api response, expecting an array of entities.');
                        }
                        output = [];
                        _i = 0, results_1 = results;
                        _c.label = 1;
                    case 1:
                        if (!(_i < results_1.length)) return [3 /*break*/, 4];
                        entity = results_1[_i];
                        _b = (_a = output).push;
                        return [4 /*yield*/, this.processSingleResult(request, entity)];
                    case 2:
                        _b.apply(_a, [_c.sent()]);
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, output];
                }
            });
        });
    };
    /**
     * Process the result of a simple collection of entities from the server.
     */
    ApiService.prototype.processPaginatedCollectionResult = function (request, result) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var output, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        output = api_paginated_results_1.ApiPaginatedResults.CreateFromServerResponse(result);
                        _a = output;
                        return [4 /*yield*/, this.processSimpleCollectionResults(request, result.items)];
                    case 1:
                        _a.items = _b.sent();
                        return [2 /*return*/, output];
                }
            });
        });
    };
    /**
     * Apply an error into an ApiResponse.
     */
    ApiService.prototype.handleRequestError = function (request, response, error) {
        var httpError = error.getFirstErrorOfType(http_error_1.HttpError);
        response.setStatus(constants_1.HttpResponseStatus.Error);
        if (httpError && utils_1.isObject(httpError.payload)) {
            if (httpError.payload.type === "ValidationException") {
                if (request.boundForm) {
                    request.boundForm.clearErrors(false, true);
                    this.applyApiErrorsIntoForm(request.boundForm, httpError.payload.violations);
                }
                else {
                    response.error = new error_1.PublicAppError('Les données envoyées contiennent des éléments invalides.');
                }
            }
            else if (httpError.payload.type === "PublicException" && request.boundForm) {
                request.boundForm.addError(new form_error_1.FormError(httpError.payload.message));
            }
        }
        else {
            response.error = error;
        }
    };
    /**
     * Apply validation errors from the Api into a FormGroup.
     */
    ApiService.prototype.applyApiErrorsIntoForm = function (form, violations) {
        for (var _i = 0, violations_1 = violations; _i < violations_1.length; _i++) {
            var violation = violations_1[_i];
            var control = form.getForPath(violation.propertyPath);
            if (control) {
                control.addError(new form_validation_error_1.FormValidationError(violation.message, {}, 'api', true));
            }
        }
    };
    ApiService = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(network_1.HttpServiceSymbol)),
        tslib_1.__param(1, inversify_1.inject(model_1.TransformerServiceSymbol)),
        tslib_1.__metadata("design:paramtypes", [network_1.HttpService,
            model_1.TransformerService])
    ], ApiService);
    return ApiService;
}());
exports.ApiService = ApiService;
exports.ApiServiceSymbol = Symbol("ApiService");
container_1.Container.registerService(exports.ApiServiceSymbol, ApiService);
