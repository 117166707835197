"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var alertify = require("alertifyjs");
var utils_1 = require("essentials/network/utils");
var utils_2 = require("essentials/utils/utils");
var inversify_1 = require("inversify");
var util_1 = require("vue-class-component/lib/util");
var container_1 = require("webeak-native/inversify/container");
var modules_manager_1 = require("essentials/modules-manager");
var object_1 = require("webeak-native/util/object");
var utils_3 = require("webeak-native/util/utils");
var AlertifyService = /** @class */ (function () {
    function AlertifyService() {
        this.modulesManager = container_1.Container.getContainer().get(modules_manager_1.ModulesManagerSymbol);
    }
    AlertifyService_1 = AlertifyService;
    /**
     * Show a generic dialog from an HTML source code or a dom element (supports jquery).
     */
    AlertifyService.prototype.dialog = function (options) {
        var that = this;
        var id = this.generateUniqueId();
        var el = this.resolveDialogSource(id, options.source);
        var wrapCallback = function (callback, previous) {
            if (!utils_3.isFunction(previous)) {
                return callback;
            }
            return function () {
                callback.apply(this, arguments);
                previous.apply(this, arguments);
            };
        };
        if (utils_3.isNullOrUndefined(options.buttons)) {
            options.buttons = [
                {
                    text: 'Ok',
                    key: 27,
                    invokeOnClose: true,
                    className: alertify.defaults.theme.ok,
                    attrs: { attribute: 'value' },
                    scope: 'auxiliary',
                }
            ];
        }
        $('body').append(el);
        alertify.dialog(id, function () {
            var dialogConfig = {};
            var hooksConfig = {
                onshow: wrapCallback(function () {
                    window.setTimeout(function () {
                        that.modulesManager.scan();
                    });
                }, object_1.getObjectValue(options, ['hooks', 'onshow'], util_1.noop))
            };
            object_1.addToObjectIfDefined(hooksConfig, 'onclose', object_1.getObjectValue(options, ['hooks', 'onclose'], undefined));
            object_1.addToObjectIfDefined(hooksConfig, 'onupdate', object_1.getObjectValue(options, ['hooks', 'onupdate'], undefined));
            object_1.addToObjectIfDefined(dialogConfig, 'callback', options.onCallback);
            object_1.addToObjectIfDefined(dialogConfig, 'settings', options.settings);
            dialogConfig.hooks = hooksConfig;
            dialogConfig.setup = function () {
                var setupConfig = { options: object_1.extend({
                        modal: true,
                        basic: false,
                        maximizable: true,
                        resizable: true,
                        padding: true
                    }, options.options) };
                object_1.addToObjectIfDefined(setupConfig, 'buttons', options.buttons);
                object_1.addToObjectIfDefined(setupConfig, 'focus', options.focus);
                return setupConfig;
            };
            dialogConfig.main = wrapCallback(function (content) {
                this.setContent(content);
            }, options.onMain);
            dialogConfig.build = wrapCallback(util_1.noop, options.onBuild);
            dialogConfig.prepare = wrapCallback(util_1.noop, options.onPrepare);
            dialogConfig.settingUpdated = wrapCallback(util_1.noop, options.onSettingUpdated);
            return dialogConfig;
        });
        return alertify[id](el);
    };
    /**
     * Show a flash notification of type "info".
     */
    AlertifyService.prototype.notifyInfo = function (message, durationInSeconds) {
        alertify.notify(message, 'info', durationInSeconds);
    };
    /**
     * Show a flash notification of type "success".
     */
    AlertifyService.prototype.notifySuccess = function (message, durationInSeconds) {
        alertify.notify(message, 'success', durationInSeconds);
    };
    /**
     * Show a flash notification of type "warning".
     */
    AlertifyService.prototype.notifyWarning = function (message, durationInSeconds) {
        if (durationInSeconds === void 0) { durationInSeconds = 10; }
        alertify.notify(message, 'warning', durationInSeconds);
    };
    /**
     * Show a flash notification of type "error".
     */
    AlertifyService.prototype.notifyError = function (message, durationInSeconds) {
        if (durationInSeconds === void 0) { durationInSeconds = 10; }
        alertify.notify(message, 'error', durationInSeconds);
    };
    /**
     * Show a flash notification after a redirection to another url (of the same project..).
     */
    AlertifyService.prototype.notifyAfterRedirect = function (type, message, url) {
        document.location.href = '/_wb_essential/misc/redirect-and-notify' + utils_1.buildQueryParameters({
            p: utils_2.base64encodeUrlSafe(JSON.stringify({
                type: type,
                message: message,
                url: url
            }))
        });
    };
    /**
     * Generates an id guaranteed to be unique for the current DOM.
     */
    AlertifyService.prototype.generateUniqueId = function () {
        return 'al_gd_' + (++AlertifyService_1.MAX_ID);
    };
    /**
     * Convert a variable input into an HTMLElement instance ready to be used by the dialog system.
     */
    AlertifyService.prototype.resolveDialogSource = function (id, source) {
        if (utils_3.isObject(source)) {
            if (source instanceof HTMLElement) {
                return source;
            }
            if (!utils_3.isUndefined(source.jquery) || source instanceof $) {
                return source.get(0);
            }
        }
        if (utils_3.isString(source) && source[0] !== '<') {
            source = '<div>' + source + '</div>';
        }
        return $(source).prop('id', id).get(0);
    };
    var AlertifyService_1;
    AlertifyService.MAX_ID = 0;
    AlertifyService = AlertifyService_1 = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], AlertifyService);
    return AlertifyService;
}());
exports.AlertifyService = AlertifyService;
exports.AlertifyServiceSymbol = Symbol("AlertifyService");
container_1.Container.registerService(exports.AlertifyServiceSymbol, AlertifyService);
