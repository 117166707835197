"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("essentials/network/constants");
var HttpResponse = /** @class */ (function () {
    function HttpResponse(status) {
        var _this = this;
        if (status === void 0) { status = constants_1.HttpResponseStatus.Pending; }
        this.id = ++HttpResponse.IdIncrement;
        this.httpStatusCode = 0;
        this.httpStatusText = '';
        this.error = null;
        this.result = null;
        this.rawResult = null;
        this.rawResultType = 'text';
        this.promise = null;
        this.setStatus(status);
        this.cancel = function () {
            // Simply mark the request as canceled is enough here because if the "cancel()" method
            // has not yet been overridden by the HttpService it means that the request has not started yet.
            // The HttpService will check the flag before doing the request.
            console.warn('CANCEL via original callback', _this.id);
            _this.setStatus(constants_1.HttpResponseStatus.Canceled);
        };
    }
    /**
     * Update the response's status.
     */
    HttpResponse.prototype.setStatus = function (status) {
        this.status = status;
        this.isPending = this.status === constants_1.HttpResponseStatus.Pending;
        this.isSuccess = this.status === constants_1.HttpResponseStatus.Success;
        this.isError = this.status === constants_1.HttpResponseStatus.Error;
        this.isCanceled = this.status === constants_1.HttpResponseStatus.Canceled;
    };
    /**
     * Copy the state of another response into this one, except the promise.
     */
    HttpResponse.prototype.syncWith = function (other) {
        this.setStatus(other.status);
        this.error = other.error;
        this.result = other.result;
        this.rawResult = other.rawResult;
        this.rawResultType = other.rawResultType;
        this.httpStatusText = other.httpStatusText;
        this.httpStatusCode = other.httpStatusCode;
        this.cancel = function () {
            other.cancel();
        };
    };
    /**
     * Wrap another response into this one, except the promise.
     */
    HttpResponse.prototype.decorate = function (decorated) {
        var _loop_1 = function (key) {
            Object.defineProperty(this_1, key, {
                get: function () {
                    return decorated[key];
                },
                set: function (value) {
                    decorated[key] = value;
                }
            });
        };
        var this_1 = this;
        for (var _i = 0, _a = Object.keys(this); _i < _a.length; _i++) {
            var key = _a[_i];
            _loop_1(key);
        }
    };
    HttpResponse.IdIncrement = 0;
    return HttpResponse;
}());
exports.HttpResponse = HttpResponse;
