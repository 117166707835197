"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * NOTE: These parameters are READ ONLY.
 *
 * These parameters are set on the server and are only sent to the client to inform of what's going on
 * so the request builder can build the appropriate query.
 *
 * You cannot alter these parameters in any way from the client side.
 */
var FilteringMetadata = /** @class */ (function () {
    function FilteringMetadata() {
    }
    /**
     * Create a FilteringMetadata instance from the raw output from the server.
     */
    FilteringMetadata.createFromServerResponse = function (config) {
        var instance = new FilteringMetadata();
        instance.isEnabled = !!config[0];
        instance.wrapperParameterName = config[1];
        return instance;
    };
    return FilteringMetadata;
}());
exports.FilteringMetadata = FilteringMetadata;
