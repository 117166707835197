"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var abstract_module_1 = require("essentials/modules/abstract-module");
var Href = /** @class */ (function (_super) {
    tslib_1.__extends(Href, _super);
    function Href() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @inheritDoc
     */
    Href.prototype.getDefaultOptionName = function () {
        return 'href';
    };
    /**
     * @inheritDoc
     */
    Href.prototype.getDefaultOptions = function () {
        return {
            href: null,
        };
    };
    /**
     * @inheritDoc
     */
    Href.prototype.bind = function () {
        var _this = this;
        this.$element.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            document.location.href = _this.getOption('href');
        });
    };
    Href = tslib_1.__decorate([
        inversify_1.injectable()
    ], Href);
    return Href;
}(abstract_module_1.AbstractModule));
exports.HrefSymbol = Symbol("Href");
container_1.Container.registerModule(exports.HrefSymbol, Href);
