"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var network_1 = require("essentials/network");
var router_1 = require("essentials/symfony/router");
var api_metadata_1 = require("essentials/api/metadata/api.metadata");
var utils_1 = require("webeak-native/util/utils");
var error_1 = require("essentials/error");
var ApiMetadataFactory = /** @class */ (function () {
    function ApiMetadataFactory(http, router) {
        this.http = http;
        this.router = router;
        /**
         * The metadata object will be created on demand, only once.
         */
        this.metadata = null;
        /**
         * Map between entities' public identifiers and their respective model class.
         */
        this.modelsIdentifiersMap = {};
        /**
         * List of callbacks to call when the ApiMetadata object gets created.
         */
        this.onCreatedCallbacks = [];
        this.remoteConfigPromise = null;
    }
    /**
     * Register the link between an entity's class and its string identifier
     * so the metadata coming from the server can be associated with it.
     */
    ApiMetadataFactory.prototype.registerEntity = function (identifier, type) {
        if (!identifier) {
            throw new error_1.AppError("Invalid identifier " + identifier + ". It must be an non empty string.");
        }
        if (!utils_1.isUndefined(this.modelsIdentifiersMap[identifier])) {
            var ctorName = utils_1.isObject(type) && utils_1.isObject(type.constructor) && type.constructor.name ? type.constructor.name : 'Unknown';
            throw new error_1.AppError("The identifier " + identifier + " is already in use by " + ctorName + ". The identifier must be unique to an entity.");
        }
        this.modelsIdentifiersMap[identifier] = type;
    };
    /**
     * Load the Api configuration.
     */
    ApiMetadataFactory.prototype.create = function () {
        var _this = this;
        if (this.remoteConfigPromise !== null) {
            return this.remoteConfigPromise;
        }
        this.remoteConfigPromise = new Promise(function (resolve, reject) {
            if (_this.metadata !== null) {
                return void resolve(_this.metadata);
            }
            _this.http.get(_this.router.generateUrl('wb_api_js_config')).promise.then(function (result) {
                _this.metadata = api_metadata_1.ApiMetadata.createFromServerResponse(result, _this.modelsIdentifiersMap);
                _this.notifyCreation();
                resolve(_this.metadata);
            }).catch(reject).finally(function () {
                _this.remoteConfigPromise = null;
            });
        });
        return this.remoteConfigPromise;
    };
    /**
     * Register a callback that will be called when the ApiMetadata object is available.
     */
    ApiMetadataFactory.prototype.onCreated = function (cb) {
        var _this = this;
        this.onCreatedCallbacks.push(cb);
        if (this.metadata !== null) {
            this.notifyCreation();
        }
        return function () {
            for (var i = 0; i < _this.onCreatedCallbacks.length; ++i) {
                if (_this.onCreatedCallbacks[i] === cb) {
                    _this.onCreatedCallbacks.splice(i, 1);
                    return;
                }
            }
        };
    };
    /**
     * Notify registered callbacks that the ApiMetadata object is available.
     */
    ApiMetadataFactory.prototype.notifyCreation = function () {
        for (var _i = 0, _a = this.onCreatedCallbacks; _i < _a.length; _i++) {
            var callback = _a[_i];
            callback(this.metadata);
        }
        this.onCreatedCallbacks = [];
    };
    ApiMetadataFactory = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(network_1.HttpServiceSymbol)),
        tslib_1.__param(1, inversify_1.inject(router_1.RouterSymbol)),
        tslib_1.__metadata("design:paramtypes", [network_1.HttpService,
            router_1.Router])
    ], ApiMetadataFactory);
    return ApiMetadataFactory;
}());
exports.ApiMetadataFactory = ApiMetadataFactory;
exports.ApiMetadataFactorySymbol = Symbol("ApiMetadataFactory");
container_1.Container.registerService(exports.ApiMetadataFactorySymbol, ApiMetadataFactory);
