"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var abstract_model_transformer_1 = require("../model/transformer/abstract-model-transformer");
var form_transformation_model_schema_1 = require("./form-transformation-model.schema");
var transform_context_1 = require("essentials/model/transformer/transform-context");
var form_group_1 = require("essentials/form/form-group");
var form_array_1 = require("essentials/form/form-array");
var abstract_form_control_1 = require("essentials/form/abstract-form-control");
var FormModelTransformer = /** @class */ (function (_super) {
    tslib_1.__extends(FormModelTransformer, _super);
    function FormModelTransformer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Starts the transform operation for a model.
     */
    FormModelTransformer.prototype.transform = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var output, context;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        output = new form_group_1.FormGroup();
                        context = new transform_context_1.TransformContext(data, output, form_transformation_model_schema_1.FormTransformationModelSchema);
                        // Pick all properties, even if "undefined" to be able to use the resulting form control in the vue (even if null).
                        // VueJS will not react to values changing from "undefined" to something else, so we must define all form controls that can be use.
                        return [4 /*yield*/, this.transformObjectProperties(data, this.schema.properties, context, null, function (source, prop, context) {
                                if (context.result instanceof form_group_1.FormGroup && source instanceof abstract_form_control_1.AbstractFormControl) {
                                    context.result.set(source);
                                }
                            })];
                    case 1:
                        // Pick all properties, even if "undefined" to be able to use the resulting form control in the vue (even if null).
                        // VueJS will not react to values changing from "undefined" to something else, so we must define all form controls that can be use.
                        _a.sent();
                        return [2 /*return*/, context.result];
                }
            });
        });
    };
    /**
     * Starts the inverse transform that will create a model from the input data.
     */
    FormModelTransformer.prototype.transformInverse = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, _super.prototype.transformInverse.call(this, data instanceof form_group_1.FormGroup || data instanceof form_array_1.FormArray ? data.controls : data)];
            });
        });
    };
    /**
     * Create the TransformContext object that will hold context data of the current transformation.
     */
    FormModelTransformer.prototype.createContext = function (source, result) {
        return new transform_context_1.TransformContext(source, result, form_transformation_model_schema_1.FormTransformationModelSchema);
    };
    return FormModelTransformer;
}(abstract_model_transformer_1.AbstractModelTransformer));
exports.FormModelTransformer = FormModelTransformer;
