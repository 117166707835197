"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var js_base64_1 = require("js-base64");
var object_1 = require("webeak-native/util/object");
/**
 * Return a promise that is resolved on the next the next render cycle.
 */
function onNextCycle() {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, waitForDelay(0)];
        });
    });
}
exports.onNextCycle = onNextCycle;
/**
 * Return a promise that is resolved after a certain delay (in ms).
 */
function waitForDelay(duration) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    window.setTimeout(resolve, duration);
                })];
        });
    });
}
exports.waitForDelay = waitForDelay;
/**
 * Convert a string into a base 64.
 */
function base64encode(str) {
    return js_base64_1.Base64.encode(str);
}
exports.base64encode = base64encode;
/**
 * Decode a base 64 string.
 */
function base64decode(str) {
    return js_base64_1.Base64.decode(str);
}
exports.base64decode = base64decode;
/**
 * Encode a string into base 64 and replace url unsafe characters.
 */
function base64encodeUrlSafe(str) {
    return js_base64_1.Base64.encodeURI(str);
}
exports.base64encodeUrlSafe = base64encodeUrlSafe;
/**
 * Decode a string encoded with base64encodeUrlSafe.
 */
function base64decodeUrlSafe(str) {
    return js_base64_1.Base64.decode(str);
}
exports.base64decodeUrlSafe = base64decodeUrlSafe;
/**
 * Convert the url fragment into a key/value pair object.
 */
function parseUrlFragment() {
    var hash = window.location.hash.substr(1);
    return hash.split('&').reduce(function (result, item) {
        var parts = item.split('=');
        if (parts.length === 2) {
            result[parts[0]] = parts[1];
        }
        return result;
    }, {});
}
exports.parseUrlFragment = parseUrlFragment;
/**
 * Update the fragment of the url with a key/value pair object.
 * Existing elements are overridden.
 */
function updateUrlFragment(obj) {
    var fragment = [];
    obj = object_1.merge({}, parseUrlFragment(), obj);
    for (var _i = 0, _a = Object.keys(obj); _i < _a.length; _i++) {
        var key = _a[_i];
        fragment.push(key + '=' + obj[key]);
    }
    window.location.hash = fragment.join('&');
}
exports.updateUrlFragment = updateUrlFragment;
