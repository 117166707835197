"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var error_1 = require("essentials/error");
var model_1 = require("essentials/model");
var pickBy = require("lodash/pickBy");
var utils_1 = require("webeak-native/util/utils");
var AbstractModelTransformer = /** @class */ (function () {
    function AbstractModelTransformer(schema) {
        this.schema = schema;
    }
    /**
     * Starts the transform operation for a model.
     */
    AbstractModelTransformer.prototype.transform = function (model) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var context, properties;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        context = this.createContext(model, {});
                        properties = pickBy(this.schema.properties, function (v, k) { return !utils_1.isUndefined(model[k]); });
                        return [4 /*yield*/, this.transformObjectProperties(model, properties, context)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, context.result];
                }
            });
        });
    };
    /**
     * Transform a single property of a model.
     */
    AbstractModelTransformer.prototype.transformProperty = function (model, propertyName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var context;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        context = this.createContext(model, {});
                        context.currentPropertyName = propertyName;
                        return [4 /*yield*/, this.transformObjectProperty(model, propertyName, context)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Transform a generic value into a single property of a model.
     */
    AbstractModelTransformer.prototype.transformPropertyInverse = function (value, propertyName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var context;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        context = this.createContext(null, {});
                        context.currentPropertyName = propertyName;
                        return [4 /*yield*/, this.schema.properties[propertyName].transformInverse(value, context)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Starts the inverse transform that will create a model from the input data.
     */
    AbstractModelTransformer.prototype.transformInverse = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var genericSchema, output, context, properties;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        genericSchema = (this.schema instanceof model_1.GenericTransformationModelSchema) ? this.schema :
                            model_1.SchemasHolder.GetInstance().get(model_1.GenericTransformationModelSchema, this.schema.target);
                        output = genericSchema.factory.call(null);
                        context = this.createContext(data, output);
                        properties = pickBy(this.schema.properties, function (v, k) { return !utils_1.isUndefined(data[k]); });
                        return [4 /*yield*/, this.transformInverseObjectProperties(data, properties, context)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, context.result];
                }
            });
        });
    };
    /**
     * Transform a single model property and return the new value.
     */
    AbstractModelTransformer.prototype.transformObjectProperty = function (model, propertyName, context) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var transformer, properties;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        transformer = this.schema.getPropertyTransformer(propertyName);
                        if (transformer === null) {
                            throw new error_1.AppError("Property " + propertyName + " doesn't exist on the transformation schema.");
                        }
                        properties = {};
                        properties[propertyName] = transformer;
                        return [4 /*yield*/, this.transformObjectProperties(model, properties, context)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, context.result[propertyName]];
                }
            });
        });
    };
    /**
     * Apply the "transform" method to each properties in the input map and set the result in the resulting object.
     */
    AbstractModelTransformer.prototype.transformObjectProperties = function (source, properties, context, fetchFn, assignFn) {
        if (fetchFn === void 0) { fetchFn = null; }
        if (assignFn === void 0) { assignFn = null; }
        return this.doTransformObjectProperties('transform', source, properties, context, fetchFn, assignFn);
    };
    /**
     * Apply the "transformInverse" method to each properties in the input map and set the result in the resulting object.
     */
    AbstractModelTransformer.prototype.transformInverseObjectProperties = function (source, properties, context, fetchFn, assignFn) {
        if (fetchFn === void 0) { fetchFn = null; }
        if (assignFn === void 0) { assignFn = null; }
        return this.doTransformObjectProperties('transformInverse', source, properties, context, fetchFn, assignFn);
    };
    /**
     * Apply the transformFn method to each properties in the input map and set the result in the resulting object.
     */
    AbstractModelTransformer.prototype.doTransformObjectProperties = function (transformFn, source, properties, context, fetchFn, assignFn) {
        if (fetchFn === void 0) { fetchFn = null; }
        if (assignFn === void 0) { assignFn = null; }
        if (fetchFn === null) {
            fetchFn = function (s, p) { return s[p]; };
        }
        if (assignFn === null) {
            assignFn = function (v, p, c) { return c.result[p] = v; };
        }
        return new Promise(function (resolve, reject) {
            var pipeline = Promise.resolve();
            for (var property in properties) {
                if (!properties.hasOwnProperty(property)) {
                    continue;
                }
                pipeline = pipeline.then((function (currentProperty) {
                    return function () {
                        context.currentPropertyName = currentProperty;
                        return Promise.all([properties[currentProperty][transformFn].call(null, fetchFn(source, currentProperty), context)]).then(function (results) {
                            assignFn(results[0], currentProperty, context);
                        });
                    };
                })(property));
            }
            pipeline.then(function () { return void resolve(); }).catch(reject);
        });
    };
    return AbstractModelTransformer;
}());
exports.AbstractModelTransformer = AbstractModelTransformer;
