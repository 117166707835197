"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * NOTE: These parameters are READ ONLY.
 *
 * These parameters are set on the server and are only sent to the client to inform of what's going on
 * so the request builder can build the appropriate query.
 *
 * You cannot alter these parameters in any way from the client side.
 */
var OrderingMetadata = /** @class */ (function () {
    function OrderingMetadata() {
    }
    /**
     * Create a OrderingMetadata instance from the raw output from the server.
     */
    OrderingMetadata.createFromServerResponse = function (config) {
        var instance = new OrderingMetadata();
        instance.isEnabled = !!config[0];
        instance.parameterName = config[1];
        instance.direction = config[2];
        return instance;
    };
    return OrderingMetadata;
}());
exports.OrderingMetadata = OrderingMetadata;
