"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var model_1 = require("essentials/model");
var schemas_holder_1 = require("../schemas-holder");
/**
 * Register a custom factory function that will be used to create the target class instance.
 */
function Factory(callback) {
    return function (ctor) {
        var schema = schemas_holder_1.SchemasHolder.GetInstance().getOrCreate(model_1.GenericTransformationModelSchema, ctor);
        schema.factory = callback;
    };
}
exports.Factory = Factory;
