"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var abstract_form_control_1 = require("essentials/form/abstract-form-control");
var abstract_form_control_collection_1 = require("essentials/form/abstract-form-control-collection");
var validation_service_1 = require("essentials/validation/validation.service");
var container_1 = require("webeak-native/inversify/container");
var validation_complete_event_1 = require("essentials/validation/event/validation-complete.event");
var validation_event_type_1 = require("essentials/validation/event/validation-event-type");
var utils_1 = require("webeak-native/util/utils");
var form_control_1 = require("essentials/form/form-control");
var object_1 = require("webeak-native/util/object");
var value_changed_form_event_1 = require("essentials/form/event/value-changed.form-event");
var form_validation_error_1 = require("essentials/form/error/form-validation.error");
var FormGroup = /** @class */ (function (_super) {
    tslib_1.__extends(FormGroup, _super);
    function FormGroup(controls, validationSchema, name) {
        if (controls === void 0) { controls = {}; }
        if (validationSchema === void 0) { validationSchema = null; }
        if (name === void 0) { name = null; }
        var _this = _super.call(this) || this;
        _this.runningValidationMask = { validating: 0, children: {} };
        _this.waitingValidationMask = {};
        _this.validationStatusTimers = [{}, {}];
        _this.validatedValues = new WeakMap();
        _this.sameValidationMaskStack = [];
        _this.validationMaxId = 0;
        _this.validationPromiseResolve = null;
        _this.isSettingValue = false;
        _this.controls = {};
        _this.validationSchema = validationSchema;
        _this.validationPromise = null;
        _this.validationService = container_1.Container.getContainer().get(validation_service_1.ValidationServiceSymbol);
        for (var controlName in controls) {
            if (controls.hasOwnProperty(controlName)) {
                controls[controlName].setName(controlName);
                _this.add(controls[controlName]);
            }
        }
        if (name !== null) {
            _this.setName(name);
        }
        _this.updateValue();
        return _this;
    }
    /**
     * @inheritDoc
     */
    FormGroup.prototype.setValidationSchema = function (schema) {
        this.validationSchema = schema;
    };
    /**
     * @inheritDoc
     */
    FormGroup.prototype.hasValidationSchema = function () {
        return !utils_1.isNullOrUndefined(this.validationSchema);
    };
    /**
     * Add a control to this group.
     * If the control already exist, it is NOT replaced.
     *
     * Use the `setControl` method to replace an existing control.
     */
    FormGroup.prototype.add = function (control, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        var oldValue = Object.assign({}, this.value);
        if (this.has(control.name)) {
            return;
        }
        control.setParent(this);
        this.controls[control.name] = control;
        if (validate) {
            this.validate(false, false, false);
        }
        this.empty = false;
        this.updateValue();
        if (emitEvent !== false) {
            this.notify(new value_changed_form_event_1.ValueChangedFormEvent(this, oldValue, this.value));
        }
        if (!silent) {
            this.markAs('pristine', false, true, false);
        }
    };
    /**
     * Remove a control from this group.
     */
    FormGroup.prototype.remove = function (name, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        if (!this.has(name)) {
            return;
        }
        var oldValue = Object.assign({}, this.value);
        this.controls[name].reset();
        delete (this.controls[name]);
        if (validate) {
            this.validate(false, false, false);
        }
        if (!Object.keys(this.controls).length) {
            this.empty = true;
        }
        this.updateValue();
        if (emitEvent !== false) {
            this.notify(new value_changed_form_event_1.ValueChangedFormEvent(this, oldValue, this.value));
        }
        if (!silent) {
            this.markAs('pristine', false, true, false);
        }
    };
    /**
     * Get a control by name.
     */
    FormGroup.prototype.get = function (name) {
        return this.controls[name];
    };
    /**
     * Replace an existing control.
     */
    FormGroup.prototype.set = function (control, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        if (this.has(control.name)) {
            // No need to validate twice.
            this.remove(control.name, emitEvent, false, silent);
        }
        this.add(control, emitEvent, validate, silent);
    };
    /**
     * Check whether there is a control with the given name in the group.
     */
    FormGroup.prototype.has = function (name) {
        return this.controls.hasOwnProperty(name);
    };
    /**
     * Sets the value of the `FormGroup`. It accepts an object that matches
     * the structure of the group, with control names as keys.
     *
     * If a control present in the form group is missing from the input value, the value
     * will be kept to its original value.
     *
     * If a value of the input doesn't match any control, it will be ignored.
     *
     * @usageNotes
     * ```
     * const form = new FormGroup({
     *   first: new FormControl(),
     *   last: new FormControl()
     * });
     *
     * // // console.log(form.value);   // {first: null, last: null}
     *
     * form.setValue({first: 'Nancy', last: 'Drew'});
     * // // console.log(form.value);   // {first: 'Nancy', last: 'Drew'}
     * ```
     */
    FormGroup.prototype.setValue = function (value, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        this.isSettingValue = true;
        for (var _i = 0, _a = Object.keys(value); _i < _a.length; _i++) {
            var name_1 = _a[_i];
            if (this.has(name_1)) {
                this.controls[name_1].setValue(value[name_1], emitEvent, validate, silent);
                this.value[name_1] = this.controls[name_1].value;
            }
        }
        this.isSettingValue = false;
    };
    /**
     * Refresh the value from the children.
     */
    FormGroup.prototype.updateValue = function () {
        if (this.isSettingValue) {
            return;
        }
        this.value = {};
        for (var _i = 0, _a = Object.keys(this.controls); _i < _a.length; _i++) {
            var name_2 = _a[_i];
            this.value[name_2] = this.controls[name_2].value;
        }
        _super.prototype.updateValue.call(this);
    };
    /**
     * Call a function for each child.
     */
    FormGroup.prototype.forEachChild = function (cb) {
        for (var name_3 in this.controls) {
            if (this.controls[name_3].concrete) {
                cb(this.controls[name_3]);
            }
        }
    };
    /**
     * Reset the form control value to an empty value.
     */
    FormGroup.prototype.clearValue = function () {
        for (var _i = 0, _a = Object.keys(this.controls); _i < _a.length; _i++) {
            var name_4 = _a[_i];
            this.controls[name_4].clearValue();
        }
        this.updateValue();
    };
    /**
     * Build the object the validation service will use to determine which field must be validated.
     */
    FormGroup.prototype.buildValidationMask = function (obj, bubbleUp, bubbleDown) {
        if (this.validationSchema && !bubbleDown) {
            return obj;
        }
        return _super.prototype.buildValidationMask.call(this, obj, bubbleUp, bubbleDown);
    };
    /**
     * Do the validation work.
     */
    FormGroup.prototype.doValidate = function (validationMask) {
        var _this = this;
        if (this.parent && !this.validationSchema) {
            return _super.prototype.doValidate.call(this, validationMask);
        }
        if (!this.validationSchema) {
            return Promise.resolve(this.valid);
        }
        var resolve = function () {
            if (_this.validationPromise) {
                _this.validationPromiseResolve(_this.valid);
                _this.validationPromiseResolve = null;
                _this.validationPromise = null;
            }
        };
        var process = function () {
            var id = ++_this.validationMaxId;
            validationMask = _this.processValidationMask(id, validationMask, _this.runningValidationMask, _this.waitingValidationMask, _this);
            if (!Object.keys(validationMask).length) {
                resolve();
                return;
            }
            _this.validationService.validateFromSchema(_this.validationSchema, _this.value, validationMask).subscribe({
                next: function (event) {
                    if (event instanceof validation_complete_event_1.ValidationCompleteEvent) {
                        _this.applyValidationResult(_this, event.result.result);
                        _this.clearRunningValidationFlagsById(id, _this.runningValidationMask);
                        if (Object.keys(_this.waitingValidationMask).length > 0) {
                            var newValidationMask = object_1.extend({}, _this.waitingValidationMask, true);
                            if (_this.sameValidationMaskStack.length && object_1.areSameObjects(newValidationMask, _this.sameValidationMaskStack[0])) {
                                _this.sameValidationMaskStack.push(newValidationMask);
                                if (_this.sameValidationMaskStack.length > 10) {
                                    _this.resetValidation();
                                    resolve();
                                }
                            }
                            else {
                                _this.sameValidationMaskStack = [newValidationMask];
                            }
                            _this.doValidate(_this.waitingValidationMask);
                        }
                        else {
                            _this.sameValidationMaskStack = [];
                            resolve();
                        }
                    }
                    else {
                        var formControl = _this.getFormControlForValidationContext(event.context);
                        if (!formControl) {
                            return;
                        }
                        if (event.type === validation_event_type_1.ValidationEventType.PropertyValidationStart) {
                            _this.maybeAddValidatingStatus(formControl, event.context);
                        }
                        else if (event.type === validation_event_type_1.ValidationEventType.PropertyValidationEnd) {
                            _this.freeControlValidationLock(event.context);
                            _this.maybeRemoveValidatingStatus(formControl, event.context);
                            _this.validatedValues.set(formControl, event.context.source);
                        }
                    }
                }
            });
        };
        if (this.validationPromise === null) {
            this.validationPromise = new Promise(function (resolve) {
                _this.validationPromiseResolve = resolve;
                // So process() is not called immediately.
                // Without the timeout "process" will be called before "this.validationPromise" have been set
                // and if it try to resolve immediately it will fail.
                window.setTimeout(utils_1.proxy(process, _this));
            });
        }
        else {
            // So process() is not called immediately to give time to the validationPromiseResolve property to be set.
            window.setTimeout(utils_1.proxy(process, this));
        }
        return this.validationPromise;
    };
    /**
     * Set all "validating" flags to 0 in the runningValidationMask to ensure there is no infinite loop
     * even if the validation service didn't trigger "PropertyValidationStart" events
     * for all properties present in the validation mask.
     */
    FormGroup.prototype.clearRunningValidationFlagsById = function (id, mask) {
        if (mask.validating === id) {
            mask.validating = 0;
        }
        for (var key in mask.children) {
            if (mask.children.hasOwnProperty(key)) {
                this.clearRunningValidationFlagsById(id, mask.children[key]);
            }
        }
    };
    /**
     * Remove the validation lock on a form control.
     */
    FormGroup.prototype.freeControlValidationLock = function (context) {
        var propertiesStack = context.getPropertiesStack();
        var lastProp = propertiesStack[propertiesStack.length - 1];
        var container = this.runningValidationMask;
        do {
            if (utils_1.isUndefined(container.children[propertiesStack[0]])) {
                container = null;
                break;
            }
            container = container.children[propertiesStack[0]];
            propertiesStack.shift();
        } while (propertiesStack.length);
        if (container) {
            container.validating = 0;
        }
    };
    /**
     * Set the 'validating' status to a form control after a certain amount of time and
     * ensures it is never set twice.
     */
    FormGroup.prototype.maybeAddValidatingStatus = function (control, context) {
        var _this = this;
        var key = this.getUniqueKeyForValidationContext(context);
        if (!utils_1.isNullOrUndefined(this.validationStatusTimers[1][key])) {
            window.clearTimeout(this.validationStatusTimers[1][key]);
            this.validationStatusTimers[1][key] = null;
        }
        if (control.validating || !utils_1.isNullOrUndefined(this.validationStatusTimers[0][key])) {
            return;
        }
        this.validationStatusTimers[0][key] = window.setTimeout(function () {
            _this.validationStatusTimers[0][key] = null;
            control.markAs('validating', true, true, false, 1);
        }, 50);
    };
    /**
     * Check if a control is part of the validation waiting queue and removes its "validating" status if not.
     */
    FormGroup.prototype.maybeRemoveValidatingStatus = function (control, context) {
        var _this = this;
        var key = this.getUniqueKeyForValidationContext(context);
        if (!utils_1.isNullOrUndefined(this.validationStatusTimers[0][key])) {
            window.clearTimeout(this.validationStatusTimers[0][key]);
            this.validationStatusTimers[0][key] = null;
        }
        if (!control.validating || !utils_1.isNullOrUndefined(this.validationStatusTimers[1][key])) {
            return;
        }
        this.validationStatusTimers[1][key] = window.setTimeout(function () {
            _this.validationStatusTimers[1][key] = null;
            control.markAs('validating', false, true, false, 1);
        }, 10);
    };
    /**
     * Test if a control is in the validation waiting list.
     */
    FormGroup.prototype.isWaitingForValidation = function (context) {
        var propertiesStack = context.getPropertiesStack();
        var container = this.waitingValidationMask;
        do {
            if (utils_1.isUndefined(container[propertiesStack[0]])) {
                return false;
            }
            container = container[propertiesStack[0]];
            propertiesStack.shift();
        } while (propertiesStack.length);
        return true;
    };
    /**
     * Gets a unique string key corresponding to a ValidationContext.
     */
    FormGroup.prototype.getUniqueKeyForValidationContext = function (context) {
        return context.getPropertiesStack().join('#');
    };
    /**
     * Take a "candidate" validation mask (what you want to validate) and return a new validation mask
     * only containing controls that are not currently validating.
     *
     * The remaining controls are added to a waiting mask that will be used as a candidate mask automatically
     * when the current validation ends.
     */
    FormGroup.prototype.processValidationMask = function (id, candidateMask, runningMask, waitingMask, control) {
        var _this = this;
        var activeMask = {};
        var isSameValue = function (c) {
            if (!_this.validatedValues.has(c)) {
                return false;
            }
            var oldValue = _this.validatedValues.get(c);
            return c.isSameValue(oldValue);
        };
        for (var candidateKey in candidateMask) {
            if (!candidateMask.hasOwnProperty(candidateKey)) {
                continue;
            }
            var subControl = control instanceof abstract_form_control_collection_1.AbstractFormControlCollection ? control.get(candidateKey) : null;
            if (utils_1.isUndefined(subControl)) {
                console.warn('[BUG TO FIX] subControl should NOT be undefined. The mask doesn\'t correspond to the form control.');
            }
            var isFormControl = subControl instanceof form_control_1.FormControl;
            var isIgnored = !(subControl instanceof abstract_form_control_1.AbstractFormControl) || (isFormControl && isSameValue(subControl)) || subControl.virtual;
            if (isIgnored) {
                continue;
            }
            if (runningMask !== null && !runningMask.children.hasOwnProperty(candidateKey)) {
                runningMask.children[candidateKey] = { validating: id, children: {} };
                activeMask[candidateKey] = this.processValidationMask(id, candidateMask[candidateKey], runningMask.children[candidateKey], null, subControl);
            }
            else {
                if (runningMask !== null && !runningMask.children[candidateKey].validating) {
                    if (utils_1.isUndefined(waitingMask[candidateKey])) {
                        waitingMask[candidateKey] = {};
                    }
                    activeMask[candidateKey] = this.processValidationMask(id, candidateMask[candidateKey], runningMask.children[candidateKey], waitingMask[candidateKey], subControl);
                    runningMask.children[candidateKey].validating = id;
                    if (!Object.keys(waitingMask[candidateKey]).length) {
                        delete waitingMask[candidateKey];
                    }
                }
                else {
                    if (utils_1.isUndefined(waitingMask[candidateKey])) {
                        waitingMask[candidateKey] = {};
                    }
                    this.processValidationMask(id, candidateMask[candidateKey], null, waitingMask[candidateKey], subControl);
                }
            }
        }
        return activeMask;
    };
    /**
     * Try to resolve what form control corresponds to a validation context.
     * This may return null if the control cannot be found.
     */
    FormGroup.prototype.getFormControlForValidationContext = function (context) {
        var stack = context.getPropertiesStack();
        var container = this;
        for (var i = 0; i < stack.length; ++i) {
            var propertyName = stack[i];
            if (container.has(propertyName)) {
                var sub = container.get(propertyName);
                if (sub instanceof abstract_form_control_collection_1.AbstractFormControlCollection) {
                    container = sub;
                }
                else {
                    if (i < stack.length - 1) {
                        return null;
                    }
                    return sub;
                }
            }
        }
        return container;
    };
    /**
     * Apply the validation errors to the concerned controls.
     */
    FormGroup.prototype.applyValidationResult = function (control, result) {
        control.clearErrors(false, false);
        if (result.errors !== null) {
            for (var _i = 0, _a = Object.keys(result.errors); _i < _a.length; _i++) {
                var type = _a[_i];
                control.addError(form_validation_error_1.FormValidationError.CreateFromValidationError(result.errors[type]));
            }
        }
        for (var _b = 0, _c = Object.keys(result.externalNewErrors); _b < _c.length; _b++) {
            var type = _c[_b];
            control.addError(form_validation_error_1.FormValidationError.CreateFromValidationError(result.externalNewErrors[type]));
        }
        // for (const type of result.externalRemovedErrors) {
        //     control.removeError(type);
        // }
        for (var key in result.children) {
            if (!result.children.hasOwnProperty(key) || !(control instanceof abstract_form_control_collection_1.AbstractFormControlCollection) || !control.has(key)) {
                continue;
            }
            var subControl = control.get(key);
            this.applyValidationResult(subControl, result.children[key]);
        }
    };
    FormGroup.prototype.resetValidation = function () {
        for (var _i = 0, _a = this.validationStatusTimers; _i < _a.length; _i++) {
            var timers = _a[_i];
            for (var key in timers) {
                if (timers.hasOwnProperty(key)) {
                    window.clearTimeout(timers[key]);
                }
            }
        }
        this.sameValidationMaskStack = [];
        this.validationStatusTimers = [{}, {}];
        this.waitingValidationMask = {};
        this.runningValidationMask = { validating: 0, children: {} };
        this.markAs('validating', false, true, true, 0);
    };
    return FormGroup;
}(abstract_form_control_collection_1.AbstractFormControlCollection));
exports.FormGroup = FormGroup;
