"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HttpRequest = /** @class */ (function () {
    /**
     * Create a Request object.
     *
     * @param method        HTTP method.
     * @param url           Ready to use url.
     * @param payload       Body of the request (optional).
     * @param headers       Additional headers to send with the request.
     * @param maxRetryCount Maximum number of time the request can fail because of a network error or some other non definitive error
     */
    function HttpRequest(method, url, payload, headers, maxRetryCount) {
        if (payload === void 0) { payload = null; }
        if (headers === void 0) { headers = {}; }
        if (maxRetryCount === void 0) { maxRetryCount = 3; }
        this.method = method;
        this.url = url;
        this.payload = payload;
        this.headers = headers;
        this.maxRetryCount = maxRetryCount;
    }
    return HttpRequest;
}());
exports.HttpRequest = HttpRequest;
