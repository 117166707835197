"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var form_group_1 = require("essentials/form/form-group");
var form_control_1 = require("essentials/form/form-control");
var abstract_form_control_collection_1 = require("essentials/form/abstract-form-control-collection");
var error_1 = require("essentials/error");
var form_array_1 = require("essentials/form/form-array");
var utils_1 = require("webeak-native/util/utils");
/**
 * Create a new form control for a given path.
 * The control can be several levels deep, intermediate levels will be created too.
 *
 * For each level, the rules are the following:
 *   - if the name of the current component is a string, a FormGroup is created
 *   - if the name of the current component is a number, a FormArray is created. If the index is > 0, all the previous controls will be created too.
 *   - if the current component is the last, a FormControl is created.
 *
 * So for example:
 *
 * "categories.2.name" will create:
 *    - a FormArray named "categories"
 *    - the FormArray will have 3 FormGroup as children, named "0", "1", and "2".
 *    - the FormGroup named "2" will contain a FormControl named "name".
 */
function getOrCreateChildComponentByPath(container, path) {
    var parts = path.split('.');
    var currentControl = container;
    for (var i = 0; i < parts.length; ++i) {
        var part = parts[i];
        if (!(currentControl instanceof abstract_form_control_collection_1.AbstractFormControlCollection)) {
            throw new error_1.AppError("Unsolvable form control path, the component \"" + parts.slice(0, i).join('.') + "\" is not a collection.");
        }
        if (i === parts.length - 1) {
            var control = new form_control_1.FormControl(null, part);
            currentControl.add(control, false);
            return control;
        }
        if (currentControl.has(part)) {
            currentControl = currentControl.get(part);
            continue;
        }
        var subControl = void 0;
        var nextControlCtor = (utils_1.isNumeric(parts[i + 1])) ? form_array_1.FormArray : form_group_1.FormGroup;
        if (currentControl instanceof form_array_1.FormArray) {
            var index = parseInt(parts[i], 10);
            for (var j = 0; j <= index; ++j) {
                if (!currentControl.has(j)) {
                    subControl = new nextControlCtor();
                    subControl.setName(j);
                    currentControl.add(subControl);
                }
                else {
                    subControl = currentControl.get(j);
                }
            }
        }
        else {
            subControl = new nextControlCtor();
            subControl.setName(part);
            currentControl.add(subControl, false);
        }
        currentControl = subControl;
    }
}
exports.getOrCreateChildComponentByPath = getOrCreateChildComponentByPath;
/**
 * Get a form control any depth into a form only using a string.
 */
function getFormControlByPath(container, path, ensureFormControl) {
    if (ensureFormControl === void 0) { ensureFormControl = false; }
    var parts = path.split('.');
    var currentControl = container;
    for (var _i = 0, parts_1 = parts; _i < parts_1.length; _i++) {
        var part = parts_1[_i];
        if (!(currentControl instanceof abstract_form_control_collection_1.AbstractFormControlCollection) || !currentControl.has(part)) {
            throw new error_1.AppError("No form control named " + path + " has been found. Failed on part \"" + part + "\".");
        }
        currentControl = currentControl.get(part);
    }
    if (ensureFormControl && !(currentControl instanceof form_control_1.FormControl)) {
        throw new error_1.AppError("The form component " + path + " is not a FormControl. Please verify that the path is correct.");
    }
    return currentControl;
}
exports.getFormControlByPath = getFormControlByPath;
