"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
/**
 * The role of this transformer is only to "ensure" a scalar type (in both ways).
 */
function Primitive(type) {
    if (type === void 0) { type = null; }
    var ensureType = function (value) {
        switch (type) {
            case 'integer': return utils_1.ensureInteger(value);
            case 'number': return utils_1.ensureNumber(value);
            case 'string': return utils_1.ensureString(value);
            case 'boolean': return utils_1.ensureBoolean(value);
        }
        return value;
    };
    return {
        type: 'primitive',
        transform: function (value) {
            return ensureType(value);
        },
        transformInverse: function (value) {
            return ensureType(value);
        },
        getChild: function () {
            return null;
        }
    };
}
exports.Primitive = Primitive;
