"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This file includes everything that should be globally available in the app.
 */
require("reflect-metadata");
require("webeak-native/util/extensions/all");
require("essentials/jquery/strip-xssi");
/**
 * This section defines all modules used by the app.
 *
 * It's particularly important to import modules here because there may be no other reference
 * in the typescript code base if they are only used using html data attributes.
 *
 * Each module MUST declare itself to the container when imported, so you have nothing else to do
 * than import the module here.
 *
 * Import example:
 *
 *   import "webeak-native/services/example-service";
 */
// Initializers
require("./config/shared-configuration");
require("./initializer/moment.initializer");
// Modules
require("essentials/modules/class-toggle");
require("essentials/modules/perfect-scrollbar");
require("essentials/modules/href");
require("essentials/modules/dropdown");
require("essentials/modules/tooltip");
// Services
require("essentials/storage/storage.factory");
// Entities
require("./entity");
// VueJS
require("essentials/vuejs/directives/tooltip/tooltip");
require("./vuejs/vue-app");
require("./vuejs/components/application/form/form.component");
require("./vuejs/components/application/access-rights-manager/access-rights-manager.component");
require("./vuejs/components/application/login-page-form/login-page-form.component");
require("./vuejs/components/application/password-forgotten-page-form/password-forgotten-page-form.component");
require("./vuejs/components/application/emails-form/emails-form.component");
require("./vuejs/components/user/form/form.component");
require("./vuejs/components/user/access-rights-manager/access-rights-manager.component");
require("./vuejs/components/online-users-counter/online-users-counter.component");
