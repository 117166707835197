"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var storage_service_1 = require("./storage.service");
var container_1 = require("webeak-native/inversify/container");
var CookiesDriverService = /** @class */ (function (_super) {
    tslib_1.__extends(CookiesDriverService, _super);
    function CookiesDriverService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CookiesDriverService_1 = CookiesDriverService;
    /**
     * Get the value associated with the given key.
     */
    CookiesDriverService.prototype.get = function (key) {
        return new Promise(function (resolve) {
            var value = '; ' + document.cookie;
            var parts = value.split('; ' + CookiesDriverService_1.PREFIX + key + '=');
            if (parts.length === 2) {
                resolve(parts.pop().split(';').shift());
            }
            else {
                resolve(null);
            }
        });
    };
    /**
     * Set the value for the given key.
     */
    CookiesDriverService.prototype.set = function (key, value) {
        return new Promise(function (resolve) {
            var date = new Date();
            date.setTime(date.getTime() + (4 * 365 * 24 * 60 * 60 * 1000));
            var expires = '; expires=' + date.toUTCString();
            document.cookie = CookiesDriverService_1.PREFIX + key + '=' + (value || '') + expires + '; path=/';
            resolve();
        });
    };
    /**
     * Remove any value associated with this key.
     */
    CookiesDriverService.prototype.remove = function (key) {
        return new Promise(function (resolve) {
            document.cookie = CookiesDriverService_1.PREFIX + key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            resolve();
        });
    };
    /**
     * Clear the entire key value store.
     */
    CookiesDriverService.prototype.clear = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.keys().then(function (keys) {
                for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                    var key = keys_1[_i];
                    _this.remove(key);
                }
                resolve();
            });
        });
    };
    /**
     * Gets how many keys are stored in the storage.
     */
    CookiesDriverService.prototype.length = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.keys().then(function (keys) {
                resolve(keys.length);
            });
        });
    };
    /**
     * Gets the list of all keys stored in the storage.
     */
    CookiesDriverService.prototype.keys = function () {
        return new Promise(function (resolve) {
            var keys = [];
            var cookies = document.cookie.split(';');
            // tslint:disable-next-line:prefer-for-of
            for (var i = 0; i < cookies.length; i++) {
                keys.push(cookies[i].split('=')[0]);
            }
            resolve(keys);
        });
    };
    /**
     * Gets the type of storage used.
     */
    CookiesDriverService.prototype.getDriverName = function () {
        return 'Browser (cookie storage)';
    };
    var CookiesDriverService_1;
    /**
     * Prefix to be able to differentiate between cookies managed by the storage and cookies who don't.
     */
    CookiesDriverService.PREFIX = '__scd_';
    CookiesDriverService = CookiesDriverService_1 = tslib_1.__decorate([
        inversify_1.injectable()
    ], CookiesDriverService);
    return CookiesDriverService;
}(storage_service_1.StorageService));
exports.CookiesDriverService = CookiesDriverService;
exports.CookiesDriverServiceSymbol = Symbol("CookiesDriverService");
container_1.Container.registerService(exports.CookiesDriverServiceSymbol, CookiesDriverService);
