"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
var ResourcePropertyMetadata = /** @class */ (function () {
    function ResourcePropertyMetadata() {
    }
    /**
     * Create a ResourcePropertyMetadata instance from the raw output from the server.
     */
    ResourcePropertyMetadata.createFromServerResponse = function (config) {
        var instance = new ResourcePropertyMetadata();
        instance.importServerData(config);
        return instance;
    };
    /**
     * Import raw output from the server into the current instance.
     */
    ResourcePropertyMetadata.prototype.importServerData = function (config) {
        this.name = config[0];
        this.type = config[1];
        this.isApiIdentifier = !!config[2];
        this.isDatabaseIdentifier = !!config[3];
        this.isNullable = !!config[4];
        this.isUnique = !!config[5];
        this.isAlwaysLoaded = !!config[6];
        this.normalizationGroups = utils_1.ensureArray(config[7]);
        this.denormalizationGroups = utils_1.ensureArray(config[8]);
    };
    return ResourcePropertyMetadata;
}());
exports.ResourcePropertyMetadata = ResourcePropertyMetadata;
