"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var value_changed_form_event_1 = require("essentials/form/event/value-changed.form-event");
var utils_1 = require("webeak-native/util/utils");
var abstract_form_control_1 = require("essentials/form/abstract-form-control");
var error_1 = require("essentials/error");
var AbstractFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractFormComponent, _super);
    function AbstractFormComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.id = null;
        _this.controlValue = AbstractFormComponent_1.DefaultValue;
        _this.ready = false;
        _this.vm = _this;
        // Logic vars
        _this.listenersUnsubscribeFn = [];
        _this.initializationDeferred = false;
        _this.readyNotified = false;
        return _this;
    }
    AbstractFormComponent_1 = AbstractFormComponent;
    // Watchers
    AbstractFormComponent.prototype.onControlChanged = function (newValue) {
        if (!this.ready) {
            this.initialize();
        }
    };
    AbstractFormComponent.prototype.onDisableChange = function (newValue, oldValue) {
        if (newValue) {
            this.control.disable();
        }
        else {
            this.control.enable();
        }
    };
    AbstractFormComponent.prototype.onValueChanged = function (newVal, oldVal) {
        var isFirstChange = this.isDefaultValue(oldVal);
        this.control.setValue(newVal, !isFirstChange, !isFirstChange, isFirstChange);
        if (!isFirstChange) {
            this.$emit('change', new value_changed_form_event_1.ValueChangedFormEvent(this.control, oldVal, newVal));
        }
    };
    Object.defineProperty(AbstractFormComponent.prototype, "hasLabelInDefaultSlot", {
        // Computed
        get: function () {
            var hasDefaultSlotLabel = this.hasSlot('default');
            if (hasDefaultSlotLabel && this.hasLabel) {
                throw new error_1.AppError("You cannot define the label twice. You must either use the default slot OR the label slot.");
            }
            return hasDefaultSlotLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormComponent.prototype, "hasLabel", {
        get: function () {
            return this.hasSlot('label');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormComponent.prototype, "hasHelp", {
        get: function () {
            return this.hasSlot('help');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormComponent.prototype, "validationVisible", {
        get: function () {
            return /*this.control.valid && */ (this.control.touched || this.control.dirty);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormComponent.prototype, "controlValidationClasses", {
        get: function () {
            if (!this.ready || !this.showValidation || this.disabled) {
                return { 'no-validation': true };
            }
            var obj = {};
            if (this.control.invalid && (this.control.touched || this.control.dirty)) {
                obj[this.invalidClass] = true;
            }
            if (this.control.valid && (this.control.touched || this.control.dirty)) {
                obj[this.validClass] = true;
            }
            return obj;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractFormComponent.prototype, "containerValidationClasses", {
        get: function () {
            if (!this.ready || !this.showValidation || this.disabled) {
                return {};
            }
            var obj = Object.assign({}, this.controlValidationClasses);
            obj['was-validated'] = this.validationVisible;
            return obj;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle callback.
     */
    AbstractFormComponent.prototype.mounted = function () {
        this.initialize();
    };
    /**
     * VueJS lifecycle callback.
     */
    AbstractFormComponent.prototype.updated = function () {
        if (this.ready && !this.readyNotified) {
            this.readyNotified = true;
            this.onReady();
        }
    };
    /**
     * VueJS lifecycle callback.
     */
    AbstractFormComponent.prototype.beforeDestroy = function () {
        for (var _i = 0, _a = this.listenersUnsubscribeFn; _i < _a.length; _i++) {
            var unsubscribeFn = _a[_i];
            unsubscribeFn();
        }
    };
    /**
     * Blur the form control.
     */
    AbstractFormComponent.prototype.onBlur = function () {
        this.control.blur();
        this.$emit('blur');
    };
    /**
     * Focus the form control.
     */
    AbstractFormComponent.prototype.onFocus = function () {
        this.control.focus();
        this.$emit('focus');
    };
    AbstractFormComponent.prototype.getSlotNameForError = function (error) {
        return error.group.replace(/([A-Z])/g, '-$1').replace(/\./g, '-').toLowerCase();
    };
    AbstractFormComponent.prototype.hasErrorSlot = function (error) {
        return this.hasSlot(this.getSlotNameForError(error));
    };
    /**
     * This method is the one components should override to execute their initialization logic.
     * It must return a boolean indicating if the initialization succeeded or not.
     *
     * If asynchronous processing is required, you can return a promise.
     *
     * The default implementation does nothing, so no need to call super.doInitialize().
     */
    AbstractFormComponent.prototype.doInitialize = function () {
        return true;
    };
    /**
     * Bind events.
     */
    AbstractFormComponent.prototype.bind = function () {
        this.listenersUnsubscribeFn.push(this.control.onCheckValueChange(utils_1.proxy(this.onCheckIfValueChanged, this)));
        this.listenersUnsubscribeFn.push(this.control.onValueChange(utils_1.proxy(this.onFormValueChange, this)));
    };
    /**
     * Mark the component as ready.
     */
    AbstractFormComponent.prototype.setAsReady = function () {
        if (this.ready) {
            return;
        }
        this.ready = true;
    };
    /**
     * Called when the component is ready.
     */
    AbstractFormComponent.prototype.onReady = function () {
    };
    /**
     * Called then the form needs to check if a new value is the same as the previous one.
     */
    AbstractFormComponent.prototype.onCheckIfValueChanged = function (event) {
        return event.newValue !== event.oldValue;
    };
    /**
     * Called when the value of the control has changed.
     */
    AbstractFormComponent.prototype.onFormValueChange = function (event) {
        var control = this.getControlFromFormEvent(event);
        if (control) {
            this.controlValue = event.newValue;
        }
    };
    /**
     * Test if a slot is defined and contains something.
     *
     * DO NOT use this directly in your template, PLEASE create a computed property calling this method instead.
     */
    AbstractFormComponent.prototype.hasSlot = function (name) {
        return (utils_1.isObject(this.$slots) && utils_1.isArray(this.$slots[name])) ||
            (utils_1.isObject(this.$scopedSlots) && utils_1.isArray(this.$scopedSlots[name]));
    };
    /**
     * Try to get the form control associated with the component from a FormEvent object.
     */
    AbstractFormComponent.prototype.getControlFromFormEvent = function (event) {
        for (var _i = 0, _a = event.stack; _i < _a.length; _i++) {
            var candidate = _a[_i];
            if (candidate === this.control) {
                return candidate;
            }
        }
        return null;
    };
    /**
     * Test if a value is the default value of all form components.
     */
    AbstractFormComponent.prototype.isDefaultValue = function (val) {
        return val === AbstractFormComponent_1.DefaultValue;
    };
    /**
     * Defer the call to initialize() and return a callback to call to initiate the call.
     */
    AbstractFormComponent.prototype.deferInitialization = function () {
        var _this = this;
        this.initializationDeferred = true;
        return function () {
            _this.initializationDeferred = false;
            _this.initialize();
        };
    };
    /**
     * Initialize the form component.
     */
    AbstractFormComponent.prototype.initialize = function () {
        var _this = this;
        if (this.initializationDeferred || !(this.control instanceof abstract_form_control_1.AbstractFormControl) || this.ready) {
            return;
        }
        this.id = 'fc_' + this.control.id + '_' + (++AbstractFormComponent_1.MaxId);
        this.controlValue = this.control.value;
        this.vm = this;
        this.bind();
        if (this.name !== null) {
            this.control.setName(this.name);
        }
        this.control.markAs('virtual', false, true, false);
        Promise.all([this.doInitialize()]).then(function (results) {
            if (results[0] === true) {
                _this.setAsReady();
            }
        }).catch(function (error) {
            var name = _this.name ? _this.name : (_this.control ? _this.control.name : 'Unknown');
            console.error("Initialization failed for form component \"" + name + "\". Original error: " + error_1.AppError.create(error).message);
        });
    };
    var AbstractFormComponent_1;
    AbstractFormComponent.DefaultValue = {};
    AbstractFormComponent.MaxId = 0;
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractFormComponent.prototype, "name", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], AbstractFormComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, required: true }),
        tslib_1.__metadata("design:type", abstract_form_control_1.AbstractFormControl)
    ], AbstractFormComponent.prototype, "control", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'is-invalid' }),
        tslib_1.__metadata("design:type", String)
    ], AbstractFormComponent.prototype, "invalidClass", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'is-valid' }),
        tslib_1.__metadata("design:type", String)
    ], AbstractFormComponent.prototype, "validClass", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], AbstractFormComponent.prototype, "showValidation", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('control'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [abstract_form_control_1.AbstractFormControl]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AbstractFormComponent.prototype, "onControlChanged", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('disabled'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Boolean, Boolean]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AbstractFormComponent.prototype, "onDisableChange", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('controlValue'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AbstractFormComponent.prototype, "onValueChanged", null);
    AbstractFormComponent = AbstractFormComponent_1 = tslib_1.__decorate([
        vue_class_component_1.default({})
    ], AbstractFormComponent);
    return AbstractFormComponent;
}(vue_1.default));
exports.AbstractFormComponent = AbstractFormComponent;
