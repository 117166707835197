"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var model_1 = require("essentials/model");
var moment = require("moment");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var form_component_decorator_1 = require("essentials/form/decorators/form-component.decorator");
var AbstractEntity = /** @class */ (function () {
    function AbstractEntity() {
        this.ref = null;
        this.updateDate = null;
        this.creationDate = null;
        // Untracked
        this.flags = {};
        this.updateDate = moment();
        this.creationDate = moment();
    }
    /**
     * Generic utility to mark the entity with a flag.
     *
     * @param {string}       flag
     * @param {boolean|null} value the value of the flag. If null the flag status will be inverted.
     */
    AbstractEntity.prototype.markAs = function (flag, value) {
        if (value === void 0) { value = null; }
        this.flags[flag] = value === null ? !this.flags[flag] : !!value;
        this.flags = Object.assign({}, this.flags); // Done to help VueJS to view the change.
    };
    AbstractEntity.prototype.isMarkedAs = function (flag) {
        return !!this.flags[flag];
    };
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], AbstractEntity.prototype, "ref", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.MomentToUtcString()),
        tslib_1.__metadata("design:type", Object)
    ], AbstractEntity.prototype, "updateDate", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.MomentToUtcString()),
        tslib_1.__metadata("design:type", Object)
    ], AbstractEntity.prototype, "creationDate", void 0);
    return AbstractEntity;
}());
exports.AbstractEntity = AbstractEntity;
