"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var v_calendar_1 = require("v-calendar");
var vue_property_decorator_1 = require("vue-property-decorator");
require("v-calendar/lib/v-calendar.min.css");
var container_1 = require("webeak-native/inversify/container");
var app_1 = require("essentials/app");
var shared_configuration_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/config/shared-configuration");
var input_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/form/input/input.component");
var DateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DateComponent, _super);
    function DateComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DateComponent.prototype, "props", {
        // Computed
        get: function () {
            var obj = { class: "form-control" };
            if (this.placeholder !== null) {
                obj['placeholder'] = this.placeholder;
            }
            if (this.disabled === true) {
                obj['disabled'] = 'disabled';
            }
            return obj;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], DateComponent.prototype, "placeholder", void 0);
    DateComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./date.component.html'),
        })
    ], DateComponent);
    return DateComponent;
}(input_component_1.InputComponent));
exports.DateComponent = DateComponent;
vue_1.default.component('form-date', DateComponent);
container_1.Container.getContainer().get(app_1.AppSymbol).registerInitializer(function () {
    var config = container_1.Container.getContainer().get(shared_configuration_1.SharedConfigurationSymbol);
    vue_1.default.use(v_calendar_1.default, config.get(['vuejs', 'forms', 'datePicker'], {}));
});
