"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tippy_js_1 = require("tippy.js");
require("tippy.js/dist/backdrop.css");
require("tippy.js/animations/shift-away.css");
var vue_1 = require("vue");
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var tippy_default_options_1 = require("essentials/vuejs/directives/tooltip/tippy-default-options");
var currentTippyOptions = {};
function buildOptions(defaultOptions, el, binding) {
    var options = Object.assign({}, defaultOptions);
    if (utils_1.isString(binding.value)) {
        options.content = binding.value;
    }
    else if (utils_1.isObject(binding.value)) {
        options = object_1.extend(options, binding.value, true);
    }
    if (utils_1.isUndefined(options.content)) {
        if (el.getAttribute('title')) {
            options.content = el.getAttribute('title');
        }
        if (el.getAttribute('content')) {
            options.content = el.getAttribute('content');
        }
    }
    return options;
}
function filterOptions(options) {
    options = Object.assign({}, options);
    if (!utils_1.isUndefined(options.visible)) {
        delete options.visible;
    }
    return options;
}
/**
 * Create a tippy instance.
 */
function createTippy(el, binding, vnode) {
    var handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners);
    var opts = tippy_default_options_1.TIPPY_DEFAULT_OPTIONS;
    if (handlers && handlers['show']) {
        opts.onShow = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = handlers['show']).fns.apply(_a, args);
        };
    }
    if (handlers && handlers['shown']) {
        opts.onShown = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            (_a = handlers['shown']).fns.apply(_a, args);
        };
    }
    if (handlers && handlers['hidden']) {
        opts.onHidden = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            (_a = handlers['hidden']).fns.apply(_a, args);
        };
    }
    if (handlers && handlers['hide']) {
        opts.onHide = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return (_a = handlers['hide']).fns.apply(_a, args);
        };
    }
    if (handlers && handlers['mount']) {
        opts.onMount = function () {
            var _a;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            (_a = handlers['mount']).fns.apply(_a, args);
        };
    }
    currentTippyOptions = buildOptions(opts, el, binding);
    tippy_js_1.default(el, object_1.extend(filterOptions(currentTippyOptions), { plugins: [tippy_js_1.followCursor, tippy_js_1.animateFill] }));
    if (opts.showOnLoad) {
        el._tippy.show();
    }
    vue_1.default.nextTick(function () {
        if (handlers && handlers['init']) {
            handlers['init'].fns(el._tippy, el);
        }
        handleManualVisibility(el);
    });
}
function handleManualVisibility(el) {
    if (currentTippyOptions.trigger === 'manual') {
        if (currentTippyOptions.visible === true) {
            el._tippy.show();
        }
        else {
            el._tippy.hide();
        }
    }
}
exports.Tooltip = {
    inserted: function (el, binding, vnode) {
        vue_1.default.nextTick(function () {
            createTippy(el, binding, vnode);
        });
    },
    componentUpdated: function (el, binding, vnode) {
        if (!utils_1.isUndefined(el._tippy)) {
            currentTippyOptions = buildOptions(currentTippyOptions, el, binding);
            el._tippy.setProps(filterOptions(currentTippyOptions));
            vue_1.default.nextTick(function () {
                handleManualVisibility(el);
            });
        }
    },
    unbind: function (el) {
        if (utils_1.isObject(el._tippy)) {
            el._tippy.destroy();
        }
    }
};
vue_1.default.directive('tooltip', exports.Tooltip);
