"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIPPY_DEFAULT_OPTIONS = {
    /**
     * Determines if content strings are parsed as HTML instead of text.
     * Make sure you are sanitizing any user data if rendering HTML to prevent XSS attacks.
     */
    allowHTML: false,
    /**
     * Determines if the tippy has an arrow.
     */
    arrow: true,
    /**
     * Delay in ms once a trigger event is fired before a tippy shows or hides.
     */
    delay: 0,
    /**
     * How far in pixels the tippy element is from the reference element.
     */
    distance: 4,
    /**
     * Determines if the tippy should hide if a mousedown event was fired outside of it
     * (i.e. clicking on the reference element or the body of the page).
     */
    hideOnClick: true,
    /**
     * Determines if the tippy is interactive, i.e. it can be hovered over or clicked without hiding.
     */
    interactive: true,
    /**
     * Determines the maximum width of the tippy.
     */
    maxWidth: 350,
    /**
     * Determines the offset of the tippy element.
     *
     * Unlike distance, it can work on both axes by using a string in the form "x, y", such as "50, 20".
     *
     * Avoid using an offset along the same axis as the placement prop if using interactive: true.
     * If using a number, there won't be any problems.
     */
    offset: 0,
    /**
     * Positions the tippy relative to its reference element.
     *
     * Use the suffix -start or -end to shift the tippy to the start or end of the reference element,
     * instead of centering it. For example, "top-start" or "left-end".
     */
    placement: "top",
    /**
     * The events (each separated by a space) which cause a tippy to show.
     *
     * Possible values: "mouseenter", "focus", "click", "manual".
     *
     * Use "manual" to only trigger the tippy programmatically.
     */
    trigger: "mouseenter focus",
    /**
     * The type of transition animation.
     *
     * Available animations are :
     *  - shift-away
     *  - shift-toward
     *  - scale
     *  - perspective
     */
    animation: 'shift-away',
    /**
     * Determines if the background fill color of the tippy should be animated.
     *
     * You must import the dist/backdrop.css & animations/shift-away.css stylesheets for styling to work.
     * You must also import the "animateFill" plugin.
     */
    animateFill: true,
    /**
     * Which element(s) the trigger event listeners are applied to instead of the reference element.
     *
     * Possible values: null, Element, or Element[].
     */
    triggerTarget: null,
    /**
     * Determines the z-index of the tippy.
     */
    zIndex: 9999,
    /**
     * Silences keyboard accessibility warning when used on an inline element.
     *
     * @see https://atomiks.github.io/tippyjs/accessibility/#interactivity
     */
    appendTo: document.body,
    /**
     * If true, force the tooltip to stay visible.
     * If false, force the tooltip to remain hidden.
     *
     * Only used if "trigger" is set to "manual".
     */
    visible: false
};
