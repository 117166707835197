/**
 * Extensions of the Object interface.
 * Here are regrouped all utilities directly integrated to the Object prototype.
 *
 * Do NOT put export function here.
 */
if (!Object.keys) {
    Object.keys = function (obj) {
        var keys = [];
        for (var k in obj) {
            if (obj.hasOwnProperty(k)) {
                keys.push(k);
            }
        }
        return keys;
    };
}
