"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var list_link_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-link/list-link.component");
var vue_property_decorator_1 = require("vue-property-decorator");
var ListButtonComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListButtonComponent, _super);
    function ListButtonComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ListButtonComponent.prototype, "classes", {
        // Computed
        get: function () {
            if (this.type) {
                return 'btn-' + this.type;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'primary' }),
        tslib_1.__metadata("design:type", String)
    ], ListButtonComponent.prototype, "type", void 0);
    ListButtonComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-button.component.html')
        })
    ], ListButtonComponent);
    return ListButtonComponent;
}(list_link_component_1.ListLinkComponent));
exports.ListButtonComponent = ListButtonComponent;
vue_app_1.VueApp.RegisterComponent('list-button', ListButtonComponent);
