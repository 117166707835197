"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_response_1 = require("essentials/network/http-response");
var network_1 = require("essentials/network");
var container_1 = require("webeak-native/inversify/container");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var constants_1 = require("essentials/network/constants");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var HttpFetcher = /** @class */ (function () {
    function HttpFetcher(url, entity) {
        this.url = url;
        this.entity = entity;
        this.http = container_1.Container.getContainer().get(network_1.HttpServiceSymbol);
        this.transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
    }
    /**
     * @inheritDoc
     */
    HttpFetcher.prototype.fetch = function () {
        var _this = this;
        var response = new http_response_1.HttpResponse(constants_1.HttpResponseStatus.Pending);
        response.promise = new Promise(function (resolve, reject) {
            var realResponse = _this.http.get(_this.url);
            realResponse.promise.finally(function () {
                response.syncWith(realResponse);
                if (response.isSuccess) {
                    _this.processResponse(response).then(resolve).catch(reject);
                }
                else {
                    reject(response.error);
                }
            });
        });
        return response;
    };
    /**
     * Make the necessary transformations to the response so the form can handle it.
     */
    HttpFetcher.prototype.processResponse = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.entity) return [3 /*break*/, 2];
                        _a = response;
                        return [4 /*yield*/, this.transformer.transformInverse(response.result, generic_transformation_model_schema_1.GenericTransformationModelSchema, this.entity)];
                    case 1:
                        _a.result = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, response];
                }
            });
        });
    };
    return HttpFetcher;
}());
exports.HttpFetcher = HttpFetcher;
