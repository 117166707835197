"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var checkbox_component_1 = require("essentials/vuejs/components/form/checkbox/checkbox.component");
var CheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckboxComponent, _super);
    function CheckboxComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckboxComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./checkbox.component.html'),
            name: 'form-checkbox'
        })
    ], CheckboxComponent);
    return CheckboxComponent;
}(checkbox_component_1.CheckboxComponent));
exports.CheckboxComponent = CheckboxComponent;
vue_1.default.component('form-checkbox', CheckboxComponent);
