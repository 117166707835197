"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var md5_1 = require("./md5");
var string_1 = require("./string");
var utils_1 = require("./utils");
var utils_2 = require("./utils");
/**
 * Extract a value from an object and ensure it is a string.
 * If the key is not found, the default value is returned.
 *
 * @param {any}    data
 * @param {string} key
 * @param {string} defaultValue
 *
 * @returns {any}
 */
function getObjectValueAsString(data, key, defaultValue) {
    return utils_2.ensureString(getObjectValue(data, key, defaultValue));
}
exports.getObjectValueAsString = getObjectValueAsString;
/**
 * Extract a value from an object and ensure it is a valid number.
 * If the key is not found, the default value is returned.
 *
 * @param {any}    data
 * @param {string} key
 * @param {number} defaultValue
 *
 * @returns {any}
 */
function getObjectValueAsNumber(data, key, defaultValue) {
    return utils_2.ensureNumber(getObjectValue(data, key, defaultValue));
}
exports.getObjectValueAsNumber = getObjectValueAsNumber;
/**
 * Extract a value from an object and ensure it is a boolean.
 * If the key is not found, the default value is returned.
 *
 * @param {any}     data
 * @param {string}  key
 * @param {boolean} defaultValue (optional, default: false)
 *
 * @returns {any}
 */
function getObjectValueAsBoolean(data, key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = false; }
    return utils_2.ensureBoolean(getObjectValue(data, key, defaultValue));
}
exports.getObjectValueAsBoolean = getObjectValueAsBoolean;
/**
 * Extract a value from an object and ensure it is an array.
 * If the key is not found, the default value is returned.
 *
 * @param {any}    data
 * @param {string} key
 * @param {any[]}  defaultValue (optional, default: empty array)
 *
 * @returns {any}
 */
function getObjectValueAsArray(data, key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = []; }
    return utils_2.ensureArray(getObjectValue(data, key, defaultValue));
}
exports.getObjectValueAsArray = getObjectValueAsArray;
/**
 * Extract a value from an object and ensure it is an object.
 * If the key is not found, the default value is returned.
 *
 * @param {any}    data
 * @param {string} key
 * @param {any}    defaultValue (optional, default: empty object)
 *
 * @returns {any}
 */
function getObjectValueAsObject(data, key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = null; }
    return utils_2.ensureObject(getObjectValue(data, key, defaultValue));
}
exports.getObjectValueAsObject = getObjectValueAsObject;
/**
 * Try to get a value from an object and returns a default value if not found.
 * The "key" parameter can be an array for multi-dimensional search.
 * You can also write it as a string separated with "->".
 *
 * @param {any}             data
 * @param {string|string[]} key
 * @param {any}             defaultValue (optional, default: null)
 *
 * @returns {any}
 */
function getObjectValue(data, key, defaultValue) {
    if (defaultValue === void 0) { defaultValue = null; }
    if (!utils_2.isArray(key)) {
        key = key.split("->");
    }
    var container = data;
    for (var _i = 0, key_1 = key; _i < key_1.length; _i++) {
        var item = key_1[_i];
        if (!utils_1.isObject(container) || utils_1.isUndefined(container[item])) {
            return defaultValue;
        }
        container = container[item];
    }
    return container;
}
exports.getObjectValue = getObjectValue;
/**
 * Gets the description string of a symbol.
 *
 * @param {symbol} symbol
 *
 * @returns {string}
 */
function getSymbolDescription(symbol) {
    var regExp = /\(([^)]+)\)/;
    var names = regExp.exec(symbol.toString()) || [];
    return names[1];
}
exports.getSymbolDescription = getSymbolDescription;
/**
 * Compares two objects and returns the difference between them.
 *
 * @param {object}  a
 * @param {object}  b
 * @param {boolean} keepBothValues (optional, default: false) if true, for each difference the old
 *                                 value is stored as a "before" key and the new value as a "after" key.
 *                                 if false, only the new value is returned with no additional object.
 *
 * @return {GenericRepresentationInterface}
 */
function compareObjects(a, b, keepBothValues) {
    if (keepBothValues === void 0) { keepBothValues = false; }
    var output = {};
    if (!utils_1.isObject(a) || !utils_1.isObject(b)) {
        return output;
    }
    for (var key in a) {
        if (!a.hasOwnProperty(key)) {
            continue;
        }
        var value = a[key];
        if (!utils_1.isUndefined(b[key])) {
            if (utils_1.isObject(value) && utils_1.isObject(b[key])) {
                var subOutput = compareObjects(value, b[key], keepBothValues);
                if (Object.keys(subOutput).length > 0) {
                    output[key] = subOutput;
                }
            }
            else if (value !== b[key]) {
                if (keepBothValues) {
                    output[key] = {
                        a: value,
                        b: b[key],
                    };
                }
                else {
                    output[key] = b[key];
                }
            }
        }
        else if (utils_1.isUndefined(value)) {
            // In this case both a and b are "undefined", so there is no difference after all.
            continue;
        }
        else if (keepBothValues) {
            output[key] = {
                a: value,
                b: undefined,
            };
        }
        else {
            output[key] = undefined;
        }
    }
    for (var key in b) {
        if (!b.hasOwnProperty(key) || !utils_1.isUndefined(a[key]) || utils_1.isUndefined(b[key])) {
            continue;
        }
        if (keepBothValues) {
            output[key] = {
                a: undefined,
                b: b[key],
            };
        }
        else {
            output[key] = b[key];
        }
    }
    return output;
}
exports.compareObjects = compareObjects;
/**
 * Make a deep comparison between two objects to see if they are the same.
 *
 * @param {object} a
 * @param {object} b
 *
 * @return {boolean}
 */
function areSameObjects(a, b) {
    return Object.keys(compareObjects(a, b)).length === 0;
}
exports.areSameObjects = areSameObjects;
/**
 * Compare two variables for equality.
 *
 * @param {any} a
 * @param {any} b
 *
 * @return {boolean}
 */
function areSame(a, b) {
    if (typeof (a) !== typeof (b)) {
        return false;
    }
    if (utils_1.isObject(a)) {
        return areSameObjects(a, b);
    }
    return a === b;
}
exports.areSame = areSame;
/**
 * Promise safe extend.
 * Its a copy/paste from AngularJS source `baseExtend` function but it includes a test
 * to avoid extending promises and corrupt them.
 */
function extend(dst, objs, deep) {
    if (deep === void 0) { deep = true; }
    var h = dst.$$hashKey;
    if (!utils_2.isArray(objs)) {
        objs = [objs];
    }
    for (var i = 0, ii = objs.length; i < ii; ++i) {
        var obj = objs[i];
        if (!utils_1.isObject(obj) && !utils_1.isFunction(obj)) {
            continue;
        }
        var keys = Object.keys(obj);
        for (var j = 0, jj = keys.length; j < jj; j++) {
            var key = keys[j];
            var src = obj[key];
            if (deep && utils_1.isObject(src)) {
                if (utils_1.isDate(src)) {
                    dst[key] = new Date(src.valueOf());
                }
                else if (utils_1.isRegExp(src)) {
                    dst[key] = new RegExp(src);
                }
                else if (src.nodeName) {
                    dst[key] = src.cloneNode(true);
                }
                else if (utils_2.isElement(src)) {
                    dst[key] = src.clone();
                }
                else if (utils_2.isPromiseLike(src)) {
                    // Do nothing in case it's a promise
                    dst[key] = src;
                }
                else {
                    var isar = utils_2.isArray(src);
                    if (!utils_1.isObject(dst[key])) {
                        dst[key] = isar ? [] : {};
                    }
                    if (isar) {
                        dst[key] = dst[key].concat(src);
                    }
                    else {
                        extend(dst[key], [src], true);
                    }
                }
            }
            else {
                dst[key] = src;
            }
        }
    }
    if (h) {
        dst.$$hashKey = h;
    }
    else {
        delete dst.$$hashKey;
    }
    return dst;
}
exports.extend = extend;
/**
 * Same as extend, a copy paste from angular but calls `extend` instead of `baseExtend` (implementation internal to angular).
 */
function merge(dst) {
    var src = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        src[_i - 1] = arguments[_i];
    }
    return extend(dst, src, true);
}
exports.merge = merge;
/**
 * Generates a unique hash for an object.
 */
function generateObjectHash(data) {
    return md5_1.md5(JSON.stringify(generateHashData(data)));
}
exports.generateObjectHash = generateObjectHash;
/**
 * Generates a copy of an object that will always product the same JSON when encoded.
 */
function generateHashData(data) {
    if (utils_2.isArray(data)) {
        var output = [];
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var item = data_1[_i];
            output.push(generateHashData(item));
        }
        return output;
    }
    else if (utils_1.isObject(data)) {
        var output = [];
        var keys = Object.keys(data);
        keys.sort();
        for (var _a = 0, keys_1 = keys; _a < keys_1.length; _a++) {
            var key = keys_1[_a];
            var obj = {};
            obj[key] = generateHashData(data[key]);
            output.push(obj);
        }
        return output;
    }
    if (utils_2.isString(data)) {
        return string_1.slugify(data);
    }
    return data;
}
/**
 * Try to find a value in an object.
 */
function getValueInObject(data, search, defaultValue) {
    if (defaultValue === void 0) { defaultValue = null; }
    if (!utils_1.isObject(data) || utils_1.isUndefined(data[search[0]])) {
        return defaultValue;
    }
    if (search.length > 1) {
        return getValueInObject(data[search[0]], search.slice(1));
    }
    return data[search[0]];
}
exports.getValueInObject = getValueInObject;
/**
 * Add a value to an object if it's not undefined.
 */
function addToObjectIfDefined(obj, key, value) {
    if (!utils_1.isUndefined(value)) {
        obj[key] = value;
    }
}
exports.addToObjectIfDefined = addToObjectIfDefined;
/**
 * Replace all variables corresponding to the syntax "%variableName% in the input with the corresponding
 * value in the replacements parameter.
 *
 * This works on any number of levels.
 *
 * For example the string:
 *   "Hello %config.who%!"
 *   with a replacement object of: {config: {who: "World"}}
 *   will output: "Hello World!".
 *
 * Variables names are limited to the following range of characters:
 *   [a-zA-Z0-9*_-]
 * (with the addition of the "." (dot) to separate levels in the hierarchy)
 *
 * You can also chose the starting and ending characters.
 */
function replaceStringVariables(input, replacements, startChar, endChar) {
    if (startChar === void 0) { startChar = '%'; }
    if (endChar === void 0) { endChar = '%'; }
    if (utils_2.isString(input)) {
        var reg = new RegExp(startChar + '([a-z0-9*._-]+)' + endChar, 'gi');
        var replacementsResults = {};
        var matches = void 0;
        /* tslint:disable:no-conditional-assignment */
        while ((matches = reg.exec(input)) !== null) {
            if (utils_2.isArray(matches) && matches.length > 1) {
                var parts = matches[1].split('.');
                var value = getValueInObject(replacements, parts);
                if (value !== null) {
                    // Do not replace the value immediately because if you do so and the
                    // string auto reference itself you have an infinite loop.
                    replacementsResults[matches[0]] = value;
                }
            }
        }
        // Now we can safely replace the results.
        for (var toReplace in replacementsResults) {
            if (replacementsResults.hasOwnProperty(toReplace)) {
                input = input.replace(new RegExp(toReplace, 'g'), replacementsResults[toReplace]);
            }
        }
    }
    if (utils_2.isArray(input)) {
        for (var i = 0; i < input.length; ++i) {
            input[i] = replaceStringVariables(input[i], replacements);
        }
    }
    if (utils_1.isObject(input)) {
        for (var k in input) {
            if (input.hasOwnProperty(k)) {
                input[k] = replaceStringVariables(input[k], replacements);
            }
        }
    }
    return input;
}
exports.replaceStringVariables = replaceStringVariables;
/**
 * Take any input and prepare it so it can safely be encoded into a string so it can be transferred or dumped.
 *
 * This is a lossy operation, the resulting object is not intended to be used as the original one.
 *
 * The original object is not affected, a clone is made.
 */
function prepareObjectForDump(input, maxDepth, 
// tslint:disable-next-line:align
/* internal */ objectsStack, 
// tslint:disable-next-line:align
/* internal */ depth) {
    if (maxDepth === void 0) { maxDepth = 5; }
    if (objectsStack === void 0) { objectsStack = []; }
    if (depth === void 0) { depth = 0; }
    if (utils_1.isUndefined(input)) {
        return '[undefined]';
    }
    if (input === null) {
        return '[null]';
    }
    if (utils_2.isString(input)) {
        return input.substring(0, 512);
    }
    if (utils_2.isPromiseLike(input)) {
        return '[promise]';
    }
    if (utils_2.isArray(input)) {
        var clone = [];
        if (maxDepth <= 0 || depth < maxDepth) {
            var maxNumberOfItems = 50;
            var itemIndex = 0;
            for (var _i = 0, input_1 = input; _i < input_1.length; _i++) {
                var item = input_1[_i];
                clone.push(prepareObjectForDump(item, maxDepth, objectsStack, depth + 1));
                ++itemIndex;
                if (itemIndex >= maxNumberOfItems) {
                    break;
                }
            }
            return clone;
        }
        return '[array of ' + input.length + ' element' + (input.length > 1 ? 's' : '') + ']';
    }
    if (utils_1.isObject(input)) {
        for (var _a = 0, objectsStack_1 = objectsStack; _a < objectsStack_1.length; _a++) {
            var candidate = objectsStack_1[_a];
            if (candidate === input) {
                return '[recursive object reference]';
            }
        }
        var clone = {};
        var keysCount = Object.keys(input).length;
        var maxNumberOfKeys = 30;
        if (maxDepth <= 0 || depth < maxDepth) {
            var keyIndex = 0;
            objectsStack.push(input);
            // tslint:disable-next-line:forin
            for (var key in input) {
                if (!utils_1.isUndefined(input.hasOwnProperty) && input.hasOwnProperty(key) && !utils_1.isFunction(input[key])) {
                    clone[key] = prepareObjectForDump(input[key], maxDepth, objectsStack, depth + 1);
                }
                ++keyIndex;
                if (keyIndex >= maxNumberOfKeys) {
                    break;
                }
            }
            objectsStack.pop();
        }
        else {
            return '[object of ' + keysCount + ' key' + (keysCount > 1 ? 's' : '') + ']';
        }
        return clone;
    }
    return input;
}
exports.prepareObjectForDump = prepareObjectForDump;
/**
 * Flatten a N dimension object into a single dimension one.
 */
function flatten(obj, concatenator) {
    if (concatenator === void 0) { concatenator = '.'; }
    return Object.keys(obj).reduce(function (acc, key) {
        var _a;
        if (typeof obj[key] !== 'object') {
            return tslib_1.__assign(tslib_1.__assign({}, acc), (_a = {}, _a[key] = obj[key], _a));
        }
        var flattenedChild = flatten(obj[key], concatenator);
        return tslib_1.__assign(tslib_1.__assign({}, acc), Object.keys(flattenedChild).reduce(function (childAcc, childKey) {
            var _a;
            return (tslib_1.__assign(tslib_1.__assign({}, childAcc), (_a = {}, _a["" + key + concatenator + childKey] = flattenedChild[childKey], _a)));
        }, {}));
    }, {});
}
exports.flatten = flatten;
