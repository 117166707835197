"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var intersection = require("lodash/intersection");
var utils_1 = require("webeak-native/util/utils");
var ResourceActionMetadata = /** @class */ (function () {
    function ResourceActionMetadata() {
    }
    /**
     * Create a ResourceActionMetadata instance from the raw output from the server.
     */
    ResourceActionMetadata.createFromServerResponse = function (config) {
        var instance = new ResourceActionMetadata();
        instance.name = config[0];
        instance.method = config[1];
        instance.path = config[2];
        instance.route = config[3];
        instance.isCollection = config[0].slice(-11) === '_collection';
        instance.normalizationGroups = utils_1.ensureArray(config[4]);
        instance.denormalizationGroups = utils_1.ensureArray(config[5]);
        instance.options = config[6] || {};
        return instance;
    };
    /**
     * Try to get the value of an option and return a default value if not found.
     */
    ResourceActionMetadata.prototype.getOption = function (name, defaultValue) {
        if (defaultValue === void 0) { defaultValue = null; }
        if (!utils_1.isUndefined(this.options[name])) {
            return this.options[name];
        }
        return defaultValue;
    };
    /**
     * Test if a property is writable for the current action.
     */
    ResourceActionMetadata.prototype.isReadable = function (property) {
        return intersection(this.normalizationGroups, property.normalizationGroups).length > 0;
    };
    /**
     * Test if a property is writable for the current action.
     */
    ResourceActionMetadata.prototype.isWritable = function (property) {
        return intersection(this.denormalizationGroups, property.denormalizationGroups).length > 0;
    };
    return ResourceActionMetadata;
}());
exports.ResourceActionMetadata = ResourceActionMetadata;
