"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var shared_configuration_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/config/shared-configuration");
var shared_configuration_2 = require("essentials/config/shared-configuration");
var SharedConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(SharedConfiguration, _super);
    function SharedConfiguration() {
        var _this = _super.call(this) || this;
        _this.env = "dev";
        _this.version = "0.0.2";
        return _this;
    }
    SharedConfiguration = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], SharedConfiguration);
    return SharedConfiguration;
}(shared_configuration_1.SharedConfiguration));
exports.SharedConfiguration = SharedConfiguration;
shared_configuration_2.SharedConfiguration.Register(SharedConfiguration);
