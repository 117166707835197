"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ValidationEventType;
(function (ValidationEventType) {
    /**
     * Triggered when a property starts its validation.
     */
    ValidationEventType["PropertyValidationStart"] = "property-validation-start";
    /**
     * Triggered when a property finished its validation
     */
    ValidationEventType["PropertyValidationEnd"] = "property-validation-end";
    /**
     * Triggered when the whole validation has come to an end.
     */
    ValidationEventType["ValidationComplete"] = "validation-complete";
})(ValidationEventType = exports.ValidationEventType || (exports.ValidationEventType = {}));
