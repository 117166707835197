"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var ModelTransformerFactory = /** @class */ (function () {
    function ModelTransformerFactory() {
        this.transformersMap = new WeakMap();
    }
    /**
     * Register a type of model transformer for a type schema.
     */
    ModelTransformerFactory.prototype.register = function (schemaType, transformerType) {
        this.transformersMap.set(schemaType, transformerType);
    };
    /**
     * Create the model transformer associated with a schema if available.
     */
    ModelTransformerFactory.prototype.create = function (schema) {
        var schemaType = schema.constructor;
        if (this.transformersMap.has(schemaType)) {
            var ctor = this.transformersMap.get(schemaType);
            return new ctor(schema);
        }
        return null;
    };
    ModelTransformerFactory = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], ModelTransformerFactory);
    return ModelTransformerFactory;
}());
exports.ModelTransformerFactory = ModelTransformerFactory;
exports.ModelTransformerFactorySymbol = Symbol("ModelTransformerFactory");
container_1.Container.registerService(exports.ModelTransformerFactorySymbol, ModelTransformerFactory);
