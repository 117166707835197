"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var model_1 = require("essentials/model");
var generic_transformation_model_schema_1 = require("../generic-transformation-model.schema");
var schemas_holder_1 = require("../schemas-holder");
function Transformable(transformer) {
    if (transformer === void 0) { transformer = model_1.Raw(); }
    return function (target, prop) {
        var schema = schemas_holder_1.SchemasHolder.GetInstance().getOrCreate(generic_transformation_model_schema_1.GenericTransformationModelSchema, target.constructor);
        if (schema.factory === null) {
            schema.factory = function () { return new target.constructor(); };
        }
        schema.registerProperty(prop, transformer, model_1.Raw());
    };
}
exports.Transformable = Transformable;
