"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var var_holder_1 = require("../storage/var-holder");
var utils_1 = require("webeak-native/util/utils");
var validation_event_1 = require("essentials/validation/event/validation-event");
var validation_event_type_1 = require("essentials/validation/event/validation-event-type");
var ValidationContext = /** @class */ (function (_super) {
    tslib_1.__extends(ValidationContext, _super);
    /**
     * Create a ValidationContext object.
     *
     * @param propertyName Name of the property the context is associated to
     * @param source       The object we do the validation for.
     * @param schema       The type of schema currently in use for the validation.
     * @param observer     The observer waiting for validation events
     * @param parent       (optional) The parent context
     * @param mask         (optional) An object defining what properties should be validated (it's like a whitelist for the validation tree)
     */
    function ValidationContext(propertyName, source, schema, observer, parent, mask) {
        if (parent === void 0) { parent = null; }
        if (mask === void 0) { mask = null; }
        var _this = _super.call(this) || this;
        _this.propertyName = propertyName;
        _this.source = source;
        _this.schema = schema;
        _this.observer = observer;
        _this.parent = parent;
        _this.mask = mask;
        _this.errors = null;
        _this.externalNewErrors = {};
        _this.externalRemovedErrors = [];
        _this.children = {};
        if (parent) {
            parent.addChild(propertyName, _this);
        }
        return _this;
    }
    /**
     * Register a context as a child.
     */
    ValidationContext.prototype.addChild = function (propertyName, context) {
        this.children[propertyName] = context;
        context.parent = this;
    };
    /**
     * Test if a given property should be validated or not.
     * Calling `ValidationContext::CreateSubContext(...)` on a property that should not be validated will result in an exception.
     */
    ValidationContext.prototype.shouldBeValidated = function (propertyName) {
        return this.mask === null || this.mask.hasOwnProperty(propertyName);
    };
    /**
     * Mark the context as validating a property.
     */
    ValidationContext.prototype.enter = function () {
        // if (this.mask !== null && !this.mask.hasOwnProperty(this.propertyName)) {
        //     // Should never happen or a validator doesn't do its job and doesn't check properly if the property
        //     // should be validated before cloning the context.
        //     throw new AppError(`Property ${this.propertyName} should not be validated this time.`);
        // }
        this.errors = {};
        this.observer.next(new validation_event_1.ValidationEvent(validation_event_type_1.ValidationEventType.PropertyValidationStart, this));
    };
    /**
     * Unmark as validating the last entered property.
     */
    ValidationContext.prototype.leave = function () {
        this.observer.next(new validation_event_1.ValidationEvent(validation_event_type_1.ValidationEventType.PropertyValidationEnd, this));
    };
    /**
     * Gets an array listing all properties's names from the root node to this context.
     */
    ValidationContext.prototype.getPropertiesStack = function () {
        var stack = [];
        var currentContext = this;
        do {
            if (currentContext.propertyName) {
                stack.unshift(currentContext.propertyName);
            }
            currentContext = currentContext.parent;
        } while (currentContext);
        return stack;
    };
    /**
     * Add an error to the current property on validation.
     */
    ValidationContext.prototype.addError = function (error) {
        var errors = utils_1.ensureArray(error);
        if (this.errors === null) {
            this.errors = {};
        }
        for (var _i = 0, errors_1 = errors; _i < errors_1.length; _i++) {
            var currentError = errors_1[_i];
            this.errors[currentError.type] = currentError;
        }
    };
    /**
     * Remove an error using its type name.
     */
    ValidationContext.prototype.removeError = function (errorType) {
        if (this.errors === null) {
            this.errors = {};
        }
        if (!utils_1.isNullOrUndefined(this.errors[errorType])) {
            delete this.errors[errorType];
        }
    };
    /**
     * Add an error to another property.
     */
    ValidationContext.prototype.addOtherPropertyError = function (propertyName, error) {
        var errors = utils_1.ensureArray(error);
        var context = this.getOtherPropertyContext(propertyName);
        if (context) {
            for (var _i = 0, errors_2 = errors; _i < errors_2.length; _i++) {
                var currentError = errors_2[_i];
                context.externalNewErrors[currentError.type] = currentError;
            }
        }
    };
    /**
     * Remove an error to another property.
     */
    ValidationContext.prototype.removeOtherPropertyError = function (propertyName, errorType) {
        var errorsTypes = utils_1.ensureArray(errorType);
        var context = this.getOtherPropertyContext(propertyName);
        if (context) {
            for (var _i = 0, errorsTypes_1 = errorsTypes; _i < errorsTypes_1.length; _i++) {
                var type = errorsTypes_1[_i];
                if (this.externalRemovedErrors.indexOf(type) < 0) {
                    context.externalRemovedErrors.push(type);
                }
            }
        }
    };
    /**
     * Get the value of another property in the parent context.
     */
    ValidationContext.prototype.getOtherPropertyValue = function (propertyName) {
        var context = this.getOtherPropertyContext(propertyName);
        if (context) {
            return context.source;
        }
        return void 0;
    };
    /**
     * Get the context of another property member of the parent context.
     */
    ValidationContext.prototype.getOtherPropertyContext = function (propertyName) {
        var path = utils_1.ensureArray(propertyName);
        var container = this.parent;
        if (!path.length || !container) {
            return null;
        }
        for (var i = path.length - 1; i >= 0; --i) {
            if (!utils_1.isNullOrUndefined(container.children[path[i]])) {
                container = container.children[path[i]];
            }
            else {
                return null;
            }
        }
        return container;
    };
    /**
     * Get the root validation context.
     */
    ValidationContext.prototype.getRootContext = function () {
        if (this.parent === null) {
            return this;
        }
        return this.parent.getRootContext();
    };
    /**
     * Create a clone of the context for one of its properties.
     */
    ValidationContext.CreateSubContext = function (context, propertyName) {
        return new ValidationContext(propertyName, utils_1.isObject(context.source) ? context.source[propertyName] : null, context.schema, context.observer, context, //context.propertyName !== null ? context : context.parent,
        context.mask !== null ? context.mask[propertyName] : null);
    };
    return ValidationContext;
}(var_holder_1.VarHolder));
exports.ValidationContext = ValidationContext;
