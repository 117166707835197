"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var abstract_form_control_collection_1 = require("essentials/form/abstract-form-control-collection");
var value_changed_form_event_1 = require("essentials/form/event/value-changed.form-event");
var FormArray = /** @class */ (function (_super) {
    tslib_1.__extends(FormArray, _super);
    function FormArray(controls, name) {
        if (controls === void 0) { controls = []; }
        if (name === void 0) { name = null; }
        var _this = _super.call(this) || this;
        _this.isSettingValue = false;
        if (name !== null) {
            _this.setName(name);
        }
        _this.controls = [];
        for (var i = 0; i < controls.length; ++i) {
            controls[i].setName(i);
            _this.add(controls[i]);
        }
        _this.updateValue();
        return _this;
    }
    /**
     * Add a control to the collection.
     * If the control already exist, it is NOT replaced.
     *
     * Use the `set` method to replace an existing control.
     */
    FormArray.prototype.add = function (control, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        var oldValue = [].concat(this.value);
        this.controls.push(control);
        control.setParent(this);
        this.updateCollection(oldValue, emitEvent, validate, silent);
    };
    /**
     * Remove a control from the collection.
     */
    FormArray.prototype.remove = function (idx, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        var oldValue = [].concat(this.value);
        this.controls[idx].reset();
        this.controls.splice(idx, 1);
        this.updateCollection(oldValue, emitEvent, validate, silent);
    };
    /**
     * Get a control by name.
     */
    FormArray.prototype.get = function (name) {
        return this.controls[name];
    };
    /**
     * Replace an existing control.
     */
    FormArray.prototype.set = function (control, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        if (this.has(control.name)) {
            this.remove(control.name, emitEvent, false, silent);
        }
        this.add(control, emitEvent, validate, silent);
    };
    /**
     * Check whether there is a control with the given name in the collection.
     */
    FormArray.prototype.has = function (name) {
        return name >= 0 && name < this.controls.length;
    };
    /**
     * Sets the value of the `FormArray`. It accepts an array that matches the internal array of controls.
     *
     * If a control present in the form array is missing from the input value, the value
     * will be kept to its original value.
     *
     * If a value of the input doesn't match any control, it will be ignored.
     */
    FormArray.prototype.setValue = function (values, emitEvent, validate, silent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (validate === void 0) { validate = true; }
        if (silent === void 0) { silent = false; }
        this.isSettingValue = true;
        var oldValue = [].concat(this.value);
        for (var i = 0, c = Math.min(this.controls.length, values.length); i < c; ++i) {
            // TODO: \!/ POSSIBLE BUG CREATION HERE
            // Working line was :
            // this.controls[i].setValue(values[i], false, false);
            this.controls[i].setValue(values[i], emitEvent, validate, silent);
        }
        if (emitEvent !== false) {
            this.notify(new value_changed_form_event_1.ValueChangedFormEvent(this, oldValue, this.value));
        }
        this.isSettingValue = false;
    };
    /**
     * Refresh the value from the children.
     */
    FormArray.prototype.updateValue = function () {
        if (this.isSettingValue) {
            return;
        }
        this.value = [];
        for (var _i = 0, _a = this.controls; _i < _a.length; _i++) {
            var control = _a[_i];
            this.value.push(control.value);
        }
        _super.prototype.updateValue.call(this);
    };
    /**
     * Call a function for each child.
     */
    FormArray.prototype.forEachChild = function (cb) {
        for (var _i = 0, _a = this.controls; _i < _a.length; _i++) {
            var control = _a[_i];
            if (control.concrete) {
                cb(control);
            }
        }
    };
    /**
     * Reset the form control value to an empty value.
     */
    FormArray.prototype.clearValue = function () {
        for (var _i = 0, _a = this.controls; _i < _a.length; _i++) {
            var control = _a[_i];
            control.clearValue();
        }
        this.updateValue();
    };
    /**
     * Reset sub controls names to ensure continuity.
     */
    FormArray.prototype.updateCollection = function (oldValue, emitEvent, validate, silent) {
        for (var i = 0; i < this.controls.length; ++i) {
            this.controls[i].setName(i);
        }
        if (validate) {
            this.validate(false, false, false);
        }
        this.updateValue();
        if (emitEvent !== false) {
            this.notify(new value_changed_form_event_1.ValueChangedFormEvent(this, oldValue, this.value));
        }
        if (!silent) {
            this.markAs('pristine', false, true, false);
        }
    };
    return FormArray;
}(abstract_form_control_collection_1.AbstractFormControlCollection));
exports.FormArray = FormArray;
