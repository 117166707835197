"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var validation_error_1 = require("essentials/validation/validation.error");
var validator_container_1 = require("essentials/validation/validator-container");
function Choice() {
    var choices = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        choices[_i] = arguments[_i];
    }
    return {
        validate: function (value, context) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    if (choices.indexOf(value) < 0) {
                        context.addError(new validation_error_1.ValidationError('choice', 'Votre sélection ne fait pas partie des choix proposés.'));
                    }
                    return [2 /*return*/];
                });
            });
        }
    };
}
exports.Choice = Choice;
validator_container_1.ValidatorContainer.register('Choice', Choice);
