"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var generic_transformation_model_schema_1 = require("./generic-transformation-model.schema");
var abstract_model_transformer_1 = require("./transformer/abstract-model-transformer");
var transform_context_1 = require("./transformer/transform-context");
var GenericModelTransformer = /** @class */ (function (_super) {
    tslib_1.__extends(GenericModelTransformer, _super);
    function GenericModelTransformer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create the TransformContext object that will hold context data of the current transformation.
     */
    GenericModelTransformer.prototype.createContext = function (source, result) {
        return new transform_context_1.TransformContext(source, result, generic_transformation_model_schema_1.GenericTransformationModelSchema);
    };
    return GenericModelTransformer;
}(abstract_model_transformer_1.AbstractModelTransformer));
exports.GenericModelTransformer = GenericModelTransformer;
