"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var abstract_context_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/abstract-context");
var utils_1 = require("webeak-native/util/utils");
/**
 * The context contains all the information describing the current state of the pagination.
 */
var PaginationContext = /** @class */ (function (_super) {
    tslib_1.__extends(PaginationContext, _super);
    function PaginationContext(obj) {
        if (obj === void 0) { obj = {}; }
        var _this = _super.call(this) || this;
        /**
         * The current page to show.
         */
        _this.page = 1;
        /**
         * The current maximum number of items to show in a page.
         */
        _this.itemsPerPage = 30;
        obj.page = Math.max(1, utils_1.ensureInteger(obj.page));
        obj.itemsPerPage = Math.max(1, utils_1.ensureInteger(obj.itemsPerPage));
        Object.assign(_this, obj);
        return _this;
    }
    /**
     * Set the current page number.
     */
    PaginationContext.prototype.setPage = function (page) {
        this.set('page', page);
    };
    /**
     * Increment the current page number by one.
     */
    PaginationContext.prototype.incrementPage = function () {
        this.set('page', this.page + 1);
    };
    /**
     * Decrement the current page number by one.
     */
    PaginationContext.prototype.decrementPage = function () {
        this.set('page', this.page - 1);
    };
    /**
     * Set the maximum number of items a page can show.
     */
    PaginationContext.prototype.setItemsPerPage = function (count) {
        this.set('itemsPerPage', count);
    };
    /**
     * Try to create a ListContext from an array created by "ListContext::exportAsArray()".
     * Will return null if the format is invalid.
     */
    PaginationContext.prototype.importFromArray = function (context) {
        if (_super.prototype.importFromArray.call(this, context)) {
            this.setPage(Math.max(1, utils_1.ensureInteger(this.page)));
            this.setItemsPerPage(Math.max(1, utils_1.ensureInteger(this.itemsPerPage)));
            return true;
        }
        return false;
    };
    /**
     * @inheritDoc
     */
    PaginationContext.prototype.getExportFields = function (remoteOnly) {
        return ['page', 'itemsPerPage'];
    };
    return PaginationContext;
}(abstract_context_1.AbstractContext));
exports.PaginationContext = PaginationContext;
