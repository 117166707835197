"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var list_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list.component");
var vue_property_decorator_1 = require("vue-property-decorator");
var form_control_1 = require("essentials/form/form-control");
var utils_1 = require("webeak-native/util/utils");
var normalize_value_event_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-column-filter/event/normalize-value.event");
var error_1 = require("essentials/error");
var ListColumnFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListColumnFilterComponent, _super);
    function ListColumnFilterComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Templates vars
        _this.controlVisible = false;
        _this.visible = true;
        _this.controls = [];
        _this.restored = false;
        _this.column = null;
        return _this;
    }
    ListColumnFilterComponent_1 = ListColumnFilterComponent;
    Object.defineProperty(ListColumnFilterComponent.prototype, "hasContent", {
        // Computed
        get: function () {
            var name = 'default';
            return (utils_1.isObject(this.$slots) && !utils_1.isUndefined(this.$slots[name])) ||
                (utils_1.isObject(this.$scopedSlots) && !utils_1.isUndefined(this.$scopedSlots[name]));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListColumnFilterComponent.prototype, "control", {
        get: function () {
            return this.getFormControl(0);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle method.
     */
    ListColumnFilterComponent.prototype.mounted = function () {
        var _this = this;
        if (this.name === null) {
            if (this.hasContent) {
                console.error('You must provide the name of the column you want to filter in a non empty <list-column-filter></list-column-filter>.');
            }
            return;
        }
        this.initializeParent();
        if (!(this.list instanceof list_component_1.ListComponent)) {
            return void console.error('A <list-column-filter></list-column-filter> must be placed inside a <list></list> component.');
        }
        this.name = 'lcf_' + (++ListColumnFilterComponent_1.MaxId);
        this.filter = {
            name: this.name,
            properties: utils_1.ensureArray(this.property)
        };
        this.list.$on('initialized', utils_1.proxy(this.restoreFiltersValues, this));
        this.list.registerFilter(this);
        this.list.$on('columns-locked', function () {
            var index = Array.from(_this.$el.parentNode.children).indexOf(_this.$el);
            _this.column = _this.list.columns[index];
        });
        this.list.context.onChange(function (context) {
            if (_this.column) {
                _this.visible = _this.list.context.isColumnVisible(_this.column.name);
            }
        });
    };
    /**
     * Remove the filter's value.
     */
    ListColumnFilterComponent.prototype.clear = function () {
        for (var _i = 0, _a = this.controls; _i < _a.length; _i++) {
            var control = _a[_i];
            control.setValue(null);
        }
    };
    ListColumnFilterComponent.prototype.toggleFilter = function () {
        this.controlVisible = !this.controlVisible;
    };
    /**
     * Get (and create if necessary) a form control.
     *
     * @param {number} index
     */
    ListColumnFilterComponent.prototype.getFormControl = function (index) {
        if (!utils_1.isUndefined(this.controls[index])) {
            return this.controls[index];
        }
        for (var i = this.controls.length; i <= index; ++i) {
            this.controls[i] = null;
        }
        if (!this.controls[index]) {
            this.controls[index] = new form_control_1.FormControl();
        }
        var control = this.controls[index];
        control.onValueChange(utils_1.proxy(this.onControlValueChanged, this));
        return control;
    };
    ListColumnFilterComponent.prototype.onControlValueChanged = function (event) {
        var _this = this;
        if (!this.restored) {
            return;
        }
        var value = event.newValue;
        if (value !== null) {
            var normalizeEvent = new normalize_value_event_1.NormalizeValueEvent(value);
            this.$emit('normalize', normalizeEvent);
            value = normalizeEvent.value;
        }
        if (utils_1.isObject(value)) {
            if (!this.normalizeProperty) {
                console.error('You must define the property or method to use to get the filter value if your form control gets an object as value. ' +
                    'Please add a "value-property" attribute to your <list-column-filter></list-column-filter> component.');
                return;
            }
            value = value[this.normalizeProperty];
            if (utils_1.isFunction(value)) {
                value = value.apply(null);
            }
        }
        var applyFilter = function (property) {
            if (value === null && !_this.allowNull) {
                _this.list.context.removeFilter(property);
            }
            else {
                _this.list.context.setFilter(property, value);
            }
        };
        if (this.filter.properties.length > 0) {
            for (var _i = 0, _a = this.filter.properties; _i < _a.length; _i++) {
                var property = _a[_i];
                applyFilter(property);
            }
        }
        else if (event.control.name) {
            applyFilter(event.control.name);
        }
        else {
            console.error('No name has been defined for the filter. ' +
                'You must either set the "property" attribute on the <list-column-filter></list-column-filter> ' +
                'or set the "name" property of your form control.');
            return;
        }
        this.list.update();
    };
    /**
     * For each control associated with the column, check if a value exists in the context and
     * apply it to the form control if so.
     */
    ListColumnFilterComponent.prototype.restoreFiltersValues = function () {
        for (var _i = 0, _a = this.controls; _i < _a.length; _i++) {
            var control = _a[_i];
            var filtersNames = control.name ? [control.name + ""] : this.filter.properties;
            for (var _b = 0, filtersNames_1 = filtersNames; _b < filtersNames_1.length; _b++) {
                var filterName = filtersNames_1[_b];
                var filterValue = this.list.context.filters[filterName];
                if (utils_1.isString(filterValue) && filterValue && (!control.name || control.name === filterName)) {
                    var normalizeEvent = new normalize_value_event_1.NormalizeValueEvent(filterValue);
                    this.$emit('denormalize', normalizeEvent);
                    filterValue = normalizeEvent.value;
                    try {
                        control.setValue(filterValue);
                    }
                    catch (e) {
                        console.error("Failed to set value of " + filterName + ", please add a @denormalize event to convert the filter value to a form value. \n                            Original error: \"" + error_1.AppError.create(e).message + "\".");
                    }
                    break;
                }
            }
        }
        this.restored = true;
    };
    /**
     * Try to find the parent ListComponent.
     */
    ListColumnFilterComponent.prototype.initializeParent = function () {
        var parent = this;
        do {
            parent = parent.$parent;
            if (parent instanceof list_component_1.ListComponent) {
                this.list = parent;
                return;
            }
        } while (parent);
    };
    var ListColumnFilterComponent_1;
    ListColumnFilterComponent.MaxId = 0;
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Array], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], ListColumnFilterComponent.prototype, "property", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListColumnFilterComponent.prototype, "normalizeProperty", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListColumnFilterComponent.prototype, "allowNull", void 0);
    ListColumnFilterComponent = ListColumnFilterComponent_1 = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-column-filter.component.html')
        })
    ], ListColumnFilterComponent);
    return ListColumnFilterComponent;
}(vue_1.default));
exports.ListColumnFilterComponent = ListColumnFilterComponent;
vue_app_1.VueApp.RegisterComponent('list-column-filter', ListColumnFilterComponent);
