"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var api_resource_decorator_1 = require("essentials/api/decorators/api-resource.decorator");
var abstract_entity_1 = require("essentials/entity/abstract.entity");
var model_1 = require("essentials/model/transformer/types/model");
var user_entity_1 = require("backend/entity/user.entity");
var application_entity_1 = require("backend/entity/application.entity");
var raw_1 = require("essentials/model/transformer/types/raw");
var LogEntity = /** @class */ (function (_super) {
    tslib_1.__extends(LogEntity, _super);
    function LogEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.level = '';
        _this.message = '';
        _this.extra = null;
        _this.application = null;
        _this.user = null;
        return _this;
    }
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], LogEntity.prototype, "level", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], LogEntity.prototype, "message", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(raw_1.Raw()),
        tslib_1.__metadata("design:type", Object)
    ], LogEntity.prototype, "extra", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.Model('ApplicationEntity')),
        tslib_1.__metadata("design:type", application_entity_1.ApplicationEntity)
    ], LogEntity.prototype, "application", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.Model('UserEntity')),
        tslib_1.__metadata("design:type", user_entity_1.UserEntity)
    ], LogEntity.prototype, "user", void 0);
    LogEntity = tslib_1.__decorate([
        api_resource_decorator_1.ApiResource('Y1W989')
    ], LogEntity);
    return LogEntity;
}(abstract_entity_1.AbstractEntity));
exports.LogEntity = LogEntity;
