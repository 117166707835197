"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var utils_1 = require("webeak-native/util/utils");
var container_1 = require("webeak-native/inversify/container");
var abstract_module_1 = require("essentials/modules/abstract-module");
var constants_1 = require("essentials/constants");
var VueApp = /** @class */ (function (_super) {
    tslib_1.__extends(VueApp, _super);
    function VueApp() {
        var _this = _super.call(this) || this;
        _this.vue = null;
        return _this;
    }
    /**
     * Gets the object holding global components.
     */
    VueApp.GetGlobalComponents = function () {
        return VueApp.COMPONENTS._global;
    };
    /**
     * Register a VueJS component.
     */
    VueApp.RegisterComponent = function (name, component, group) {
        if (group === void 0) { group = VueApp.DEFAULT_GROUP; }
        if (utils_1.isUndefined(VueApp.COMPONENTS[group])) {
            VueApp.COMPONENTS[group] = {};
        }
        VueApp.COMPONENTS[group][name] = component;
    };
    /**
     * Register a VueJS component that must be available in all apps.
     */
    VueApp.RegisterGlobalComponent = function (name, component) {
        VueApp.COMPONENTS._global[name] = component;
    };
    /**
     * Register a global VueJS option that will be passed to every Vue instance.
     */
    VueApp.SetVueOption = function (name, value) {
        VueApp.OPTIONS[name] = value;
    };
    /**
     * @inheritDoc
     */
    VueApp.prototype.getDefaultOptionName = function () {
        return 'group';
    };
    /**
     * @inheritDoc
     */
    VueApp.prototype.getDefaultOptions = function () {
        return {
            // Name of the group of components to include.
            group: VueApp.DEFAULT_GROUP,
        };
    };
    /**
     * @inheritDoc
     */
    VueApp.prototype.doInit = function () {
        if (this.vue !== null) {
            throw new Error('You cannot call setElement() twice on a vue module.');
        }
        var options = this.mergeVueOptions({
            beforeCreate: utils_1.proxy(this.beforeCreate, this),
            created: utils_1.proxy(this.created, this),
        }, this.getVueOptions(), {
            el: this.$element.get(0),
            delimiters: constants_1.Constants.Get('DELIMITERS')
        });
        this.vue = new vue_1.default(options);
    };
    /**
     * **VueJS lifecycle event**
     *
     * Called synchronously immediately after the instance has been initialized,
     * before data observation and event/watcher setup.
     */
    VueApp.prototype.beforeCreate = function () {
    };
    /**
     * **VueJS lifecycle event**
     *
     * Called synchronously after the instance is created.
     * At this stage, the instance has finished processing the options which means the following have been set up:
     * data observation, computed properties, methods, watch/event callbacks.
     *
     * However, the mounting phase has not been started, and the $el property will not be available yet.
     */
    VueApp.prototype.created = function () {
    };
    /**
     * Build an object containing user defined options.
     */
    VueApp.prototype.getVueOptions = function () {
        var options = {};
        var group = this.getOption('group');
        if (utils_1.isUndefined(VueApp.COMPONENTS[group])) {
            throw new Error("No group named " + group + " has been defined.");
        }
        options.components = Object.assign(VueApp.COMPONENTS._global, VueApp.COMPONENTS[group]);
        options = Object.assign(options, VueApp.OPTIONS);
        return options;
    };
    /**
     * Merge multiple arrays of vue options together.
     *
     * @param args
     */
    VueApp.prototype.mergeVueOptions = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var output = {};
        for (var _a = 0, args_1 = args; _a < args_1.length; _a++) {
            var arg = args_1[_a];
            for (var key in arg) {
                if (!arg.hasOwnProperty(key)) {
                    continue;
                }
                if (key === 'components') {
                    if (!utils_1.isObject(output.components)) {
                        output.components = {};
                    }
                    for (var componentName in arg[key]) {
                        if (arg[key].hasOwnProperty(componentName)) {
                            output.components[componentName] = arg[key][componentName];
                        }
                    }
                }
                else {
                    output[key] = arg[key];
                }
            }
        }
        return output;
    };
    // Static vars
    VueApp.DEFAULT_GROUP = 'default';
    VueApp.COMPONENTS = { _global: {}, default: {} };
    VueApp.OPTIONS = {};
    return VueApp;
}(abstract_module_1.AbstractModule));
exports.VueApp = VueApp;
exports.VueAppSymbol = Symbol("VueApp");
container_1.Container.registerModule(exports.VueAppSymbol, VueApp);
