"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var container_1 = require("webeak-native/inversify/container");
var modules_manager_1 = require("essentials/modules-manager");
var inversify_1 = require("inversify");
var event_1 = require("essentials/event");
var App = /** @class */ (function () {
    /**
     * @inject() not used to facilitate overriding.
     */
    function App() {
        this.modulesManager = container_1.Container.getContainer().get(modules_manager_1.ModulesManagerSymbol);
        this.eventDispatcher = container_1.Container.getContainer().get(event_1.EventDispatcherServiceSymbol);
        this.initializers = [];
        this.isStarted = false;
    }
    App.prototype.start = function () {
        for (var _i = 0, _a = this.initializers; _i < _a.length; _i++) {
            var initializer = _a[_i];
            initializer.apply(null);
        }
        this.initializers = [];
        this.modulesManager.scan();
        this.isStarted = true;
    };
    /**
     * Register a function that will be called when the app starts.
     */
    App.prototype.registerInitializer = function (callback) {
        if (!this.isStarted) {
            this.initializers.push(callback);
        }
        else {
            window.setTimeout(function () {
                callback.apply(null);
            });
        }
    };
    /**
     * Dispatch a global event into the app.
     */
    App.prototype.trigger = function (name, arg) {
        this.eventDispatcher.dispatch(name, arg);
    };
    App.Instance = null;
    App = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], App);
    return App;
}());
exports.App = App;
exports.AppSymbol = Symbol("App");
container_1.Container.getContainer().bind(exports.AppSymbol).to(App).inSingletonScope();
