"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var tags_component_1 = require("essentials/vuejs/components/form/tags/tags.component");
var TagsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TagsComponent, _super);
    function TagsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TagsComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./tags.component.html'),
        })
    ], TagsComponent);
    return TagsComponent;
}(tags_component_1.TagsComponent));
exports.TagsComponent = TagsComponent;
vue_1.default.component('form-tags', TagsComponent);
