"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var validation_error_1 = require("../validation.error");
var utils_1 = require("webeak-native/util/utils");
var validator_container_1 = require("essentials/validation/validator-container");
function NotBlank(message) {
    if (message === void 0) { message = 'Doit être renseigné.'; }
    return {
        validate: function (value, context) {
            if (utils_1.isNullOrUndefined(value) || value === '') {
                context.addError(new validation_error_1.ValidationError('notBlank', message));
            }
        }
    };
}
exports.NotBlank = NotBlank;
validator_container_1.ValidatorContainer.register('NotBlank', NotBlank);
