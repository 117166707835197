"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ValidationResult = /** @class */ (function () {
    function ValidationResult(context) {
        this.context = context;
        this.result = this.buildResultForContext(context);
    }
    ValidationResult.prototype.buildResultForContext = function (context, parent) {
        if (parent === void 0) { parent = null; }
        var children = {};
        for (var propertyName in context.children) {
            var subResult = this.buildResultForContext(context.children[propertyName], children);
            if (subResult.errors !== null ||
                subResult.externalRemovedErrors.length > 0 ||
                Object.keys(subResult.externalNewErrors).length > 0 ||
                Object.keys(subResult.children).length > 0) {
                children[propertyName] = subResult;
            }
        }
        return {
            errors: context.errors,
            externalNewErrors: context.externalNewErrors,
            externalRemovedErrors: context.externalRemovedErrors,
            children: children,
            parent: parent
        };
    };
    return ValidationResult;
}());
exports.ValidationResult = ValidationResult;
