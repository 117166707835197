"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_error_1 = require("./http.error");
/**
 * Error indicating the request cannot be performed by the current user,
 * either because he's not authenticated at all or because he has insufficient access rights.
 */
var AuthenticationError = /** @class */ (function (_super) {
    tslib_1.__extends(AuthenticationError, _super);
    function AuthenticationError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AuthenticationError;
}(http_error_1.HttpError));
exports.AuthenticationError = AuthenticationError;
