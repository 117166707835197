"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var form_group_1 = require("essentials/form/form-group");
function FormControlValue() {
    return {
        type: 'form-control-value',
        transform: function (value) {
            return value;
        },
        transformInverse: function (control) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    if (control instanceof form_group_1.FormGroup) {
                        console.error('control is an instance of FormGroupObj. Please check it out!');
                        //     const formGroupTransformer: PropertyTransformerInterface = FormGroupTransformer();
                    }
                    return [2 /*return*/, control.value];
                });
            });
        },
        getChild: function () {
            return null;
        }
    };
}
exports.FormControlValue = FormControlValue;
