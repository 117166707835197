"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var input_component_1 = require("essentials/vuejs/components/form/input/input.component");
var vue_property_decorator_1 = require("vue-property-decorator");
var InputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputComponent, _super);
    function InputComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InputComponent.prototype, "hasBeforeAddon", {
        // Computed
        get: function () {
            return this.hasSlot('before-addon');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "hasAfterAddon", {
        get: function () {
            return this.hasSlot('after-addon');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "hasBeforeAddonText", {
        get: function () {
            return this.hasSlot('before-addon-text');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "hasAfterAddonText", {
        get: function () {
            return this.hasSlot('after-addon-text');
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: '' }),
        tslib_1.__metadata("design:type", String)
    ], InputComponent.prototype, "placeholder", void 0);
    InputComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./input.component.html'),
        })
    ], InputComponent);
    return InputComponent;
}(input_component_1.InputComponent));
exports.InputComponent = InputComponent;
vue_1.default.component('form-input', InputComponent);
