"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var app_error_1 = require("./app.error");
var utils_1 = require("webeak-native/util/utils");
/**
 * Error that can be displayed to the user.
 *
 * Creating an error of this type doesn't mean it will be shown, but if a part of the application wants to display an error the user
 * it will search for the first PublicAppError instance in the error stack (starting at the bottom of the stack).
 *
 * It's important that for every critical error at least one part of the app creates an PublicAppError to ensure
 * a clear message can be delivered to the user.
 */
var PublicAppError = /** @class */ (function (_super) {
    tslib_1.__extends(PublicAppError, _super);
    /**
     * Creates a PublicAppError instance.
     */
    function PublicAppError(message, realError, previous) {
        var _this = _super.call(this, message, previous) || this;
        _this.message = message;
        _this.realError = realError;
        _this.previous = previous;
        if (!utils_1.isNullOrUndefined(realError) && !(realError instanceof app_error_1.AppError)) {
            _this.realError = app_error_1.AppError.create(realError);
        }
        if (!_this.previous && _this.realError) {
            _this.previous = _this.realError;
        }
        return _this;
    }
    /**
     * Returns the highest PublicAppError instance in the stack.
     * If none is found, returns null.
     */
    PublicAppError.prototype.getPublicError = function () {
        var parent = this.previous ? this.previous.getPublicError() : null;
        return parent ? parent : this;
    };
    /**
     * Get the first error of a certain type in the stack of errors.
     */
    PublicAppError.prototype.getFirstErrorOfType = function (type) {
        if (this.realError && type === this.realError.constructor) {
            return this.realError;
        }
        return _super.prototype.getFirstErrorOfType.call(this, type);
    };
    /**
     * Returns the real error behind the public error wrapper.
     */
    PublicAppError.prototype.getRealError = function () {
        return this.realError;
    };
    /**
     * Returns if the current instance is a public error.
     */
    PublicAppError.prototype.isPublicError = function () {
        return true;
    };
    return PublicAppError;
}(app_error_1.AppError));
exports.PublicAppError = PublicAppError;
