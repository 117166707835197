"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var form_control_1 = require("essentials/form/form-control");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
var pagination_context_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-pagination/pagination-context");
var http_response_1 = require("essentials/network/http-response");
var paginated_results_1 = require("essentials/network/paginated-results");
var error_1 = require("essentials/error");
var ListPaginationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListPaginationComponent, _super);
    function ListPaginationComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.lastPage = 1;
        _this.isFirstPage = true;
        _this.isLastPage = true;
        _this.pages = [];
        _this.userItemsPerPage = new form_control_1.FormControl(0);
        _this.userPage = '';
        return _this;
    }
    // Watchers
    ListPaginationComponent.prototype.onResponseChange = function (newVal) {
        if (newVal === null || !this.response.isSuccess) {
            return;
        }
        if (!(newVal instanceof paginated_results_1.PaginatedResults)) {
            throw new error_1.AppError('Invalid response\'s result. Instance of PaginatedResults expected.');
        }
        var paginatedResult = newVal;
        var exceedDisplayablePages = paginatedResult.totalPagesCount > (this.delta * 2) + 1;
        this.isFirstPage = paginatedResult.isFirstPage;
        this.isLastPage = paginatedResult.isLastPage;
        this.lastPage = paginatedResult.totalPagesCount;
        this.context.setPage(paginatedResult.page);
        this.pages = [];
        var start = Math.max(1, this.context.page - this.delta);
        var end = Math.min(this.lastPage, this.context.page + this.delta);
        for (var i = start; i <= end && i <= paginatedResult.totalPagesCount; ++i) {
            var item = {
                page: i,
                text: i,
                type: (i === this.context.page && this.allowInput && exceedDisplayablePages) ? 'input' : 'link'
            };
            if (item.type === 'input') {
                this.userPage = item.text + '';
            }
            this.pages.push(item);
        }
    };
    ListPaginationComponent.prototype.onPageChange = function (newVal) {
        this.context.setPage(newVal);
    };
    ListPaginationComponent.prototype.onItemsPerPageChange = function (newVal) {
        if (this.allowedItemsPerPage.length > 0 && this.allowedItemsPerPage.indexOf(newVal) < 0) {
            newVal = this.allowedItemsPerPage[Math.floor(this.allowedItemsPerPage.length / 2)];
        }
        this.context.setItemsPerPage(newVal);
    };
    ListPaginationComponent.prototype.onUserItemsPerPageChanges = function (event) {
        this.onItemsPerPageChange(parseInt(event.newValue));
    };
    /**
     * VueJS component lifecycle method.
     */
    ListPaginationComponent.prototype.mounted = function () {
        var itemsPerPage = this.itemsPerPage ? this.itemsPerPage : this.allowedItemsPerPage[Math.floor(this.allowedItemsPerPage.length / 2)];
        this.userItemsPerPage.setValue(itemsPerPage);
    };
    /**
     * Go to the previous page.
     */
    ListPaginationComponent.prototype.previousPage = function () {
        if (!this.isFirstPage) {
            this.context.decrementPage();
        }
    };
    /**
     * Go to the next page.
     */
    ListPaginationComponent.prototype.nextPage = function () {
        if (!this.isLastPage) {
            this.context.incrementPage();
        }
    };
    /**
     * Go to a specific page number.
     */
    ListPaginationComponent.prototype.goToPage = function (number) {
        this.context.setPage(Math.max(1, Math.min(this.lastPage, number)));
    };
    /**
     * Called when a key is pressed in the input.
     */
    ListPaginationComponent.prototype.onInputKeyUp = function (event) {
        if (event.key === "Enter") {
            this.applyUserInputPage();
        }
    };
    /**
     * Called when the user leaves the input.
     */
    ListPaginationComponent.prototype.onInputBlur = function () {
        this.applyUserInputPage();
    };
    /**
     * Apply the page number stored in the input text to the list component.
     */
    ListPaginationComponent.prototype.applyUserInputPage = function () {
        var nb = parseInt(this.userPage, 10);
        if (utils_1.isValidNumber(nb)) {
            this.context.setPage(Math.max(1, Math.min(this.lastPage, nb)));
        }
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", pagination_context_1.PaginationContext)
    ], ListPaginationComponent.prototype, "context", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", http_response_1.HttpResponse)
    ], ListPaginationComponent.prototype, "response", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 2 }),
        tslib_1.__metadata("design:type", Number)
    ], ListPaginationComponent.prototype, "delta", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 1 }),
        tslib_1.__metadata("design:type", Number)
    ], ListPaginationComponent.prototype, "page", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: null }),
        tslib_1.__metadata("design:type", Number)
    ], ListPaginationComponent.prototype, "itemsPerPage", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListPaginationComponent.prototype, "allowInput", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListPaginationComponent.prototype, "allowFirst", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListPaginationComponent.prototype, "allowLast", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Array, default: function () { return [10, 20, 30, 50, 100]; } }),
        tslib_1.__metadata("design:type", Array)
    ], ListPaginationComponent.prototype, "allowedItemsPerPage", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('response.result'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [paginated_results_1.PaginatedResults]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListPaginationComponent.prototype, "onResponseChange", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('page'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Number]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListPaginationComponent.prototype, "onPageChange", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('itemsPerPage'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Number]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListPaginationComponent.prototype, "onItemsPerPageChange", null);
    ListPaginationComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-pagination.component.html')
        })
    ], ListPaginationComponent);
    return ListPaginationComponent;
}(vue_1.default));
exports.ListPaginationComponent = ListPaginationComponent;
vue_app_1.VueApp.RegisterComponent('list-pagination', ListPaginationComponent);
