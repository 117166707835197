"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("essentials/api/constants");
var api_property_decorator_1 = require("essentials/api/decorators/api-property.decorator");
var api_metadata_factory_1 = require("essentials/api/metadata/api-metadata.factory");
var model_1 = require("essentials/model");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var api_transformation_model_schema_1 = require("../api-transformation-model.schema");
function ApiResource(identifier) {
    return function (target) {
        var schema = model_1.SchemasHolder.GetInstance().getOrCreate(api_transformation_model_schema_1.ApiTransformationModelSchema, target);
        // Nothing to do with the schema yet. The class is now "declared" to the transformer service.
        // We must not tell the api service that we have an Api resource.
        var metadataFactory = container_1.Container.getContainer().get(api_metadata_factory_1.ApiMetadataFactorySymbol);
        metadataFactory.registerEntity(identifier, target);
        metadataFactory.onCreated(function (metadata) {
            var resourceMetadata = metadata.getResourceMetadata(target);
            if (!resourceMetadata) {
                return;
            }
            // Register properties
            // TODO: ensure it's working because "resourceMetadata.properties" now contains properties of the resource PLUS properties of all its parents.
            // Properties declared on the entity itself are now available using the "resourceMetadata.ownProperties".
            for (var _i = 0, _a = resourceMetadata.properties; _i < _a.length; _i++) {
                var propertyMetadata = _a[_i];
                if (!utils_1.isUndefined(schema.properties[propertyMetadata.name])) {
                    continue;
                }
                var transformer = !utils_1.isUndefined(constants_1.ApiServerTypeTransformersMap[propertyMetadata.type]) ?
                    constants_1.ApiServerTypeTransformersMap[propertyMetadata.type] : null;
                var registerer = api_property_decorator_1.ApiProperty(transformer);
                var fakeConstructor = Object.create(Object.prototype, {
                    constructor: {
                        writable: false,
                        configurable: false,
                        enumerable: false,
                        value: target
                    }
                });
                registerer(fakeConstructor, propertyMetadata.name);
            }
            // Register relations
            for (var _b = 0, _c = resourceMetadata.relations; _b < _c.length; _b++) {
                var propertyMetadata = _c[_b];
                if (!utils_1.isUndefined(schema.properties[propertyMetadata.name])) {
                    continue;
                }
                var genericSchema = model_1.SchemasHolder.GetInstance().get(model_1.GenericTransformationModelSchema, resourceMetadata.entityType);
                if (genericSchema === null || utils_1.isUndefined(genericSchema.properties[propertyMetadata.name])) {
                    console.warn("No generic metadata found for relation \"" + propertyMetadata.name + "\" of entity \"" + resourceMetadata.entityType.name + "\". " +
                        "Please provide an @ApiProperty() decorator manually.");
                    continue;
                }
                var registerer = api_property_decorator_1.ApiProperty(genericSchema.properties[propertyMetadata.name]);
                var fakeConstructor = Object.create(Object.prototype, {
                    constructor: {
                        writable: false,
                        configurable: false,
                        enumerable: false,
                        value: target
                    }
                });
                registerer(fakeConstructor, propertyMetadata.name);
            }
        });
    };
}
exports.ApiResource = ApiResource;
