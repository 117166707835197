"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var component_1 = require("webeak-native/shared/component");
var utils_1 = require("webeak-native/util/utils");
var AbstractModule = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractModule, _super);
    function AbstractModule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Gets the jQuery element associated with the module.
     *
     * @returns jQuery
     */
    AbstractModule.prototype.getElement = function () {
        return this.$element;
    };
    /**
     * Sets the jQuery element associated with the module.
     *
     * @param {jQuery} $element
     */
    AbstractModule.prototype.setElement = function ($element) {
        this.$element = $element;
    };
    /**
     * @inheritDoc
     */
    AbstractModule.prototype.doInit = function () {
        if (!this.$element || utils_1.isUndefined(this.$element.jquery)) {
            console.error("You must set the root DOM element of a JQuery plugin by calling setElement(JQuery) with a JQuery object.");
            return;
        }
    };
    /**
     * @inheritDoc
     */
    AbstractModule.prototype.afterInit = function () {
        _super.prototype.afterInit.call(this);
        this.bind();
    };
    /**
     * Create DOM bindings.
     */
    AbstractModule.prototype.bind = function () {
        // ..
    };
    return AbstractModule;
}(component_1.Component));
exports.AbstractModule = AbstractModule;
