"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var tippy_js_1 = require("tippy.js");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var abstract_module_1 = require("essentials/modules/abstract-module");
var Tooltip = /** @class */ (function (_super) {
    tslib_1.__extends(Tooltip, _super);
    function Tooltip() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @inheritDoc
     */
    Tooltip.prototype.getDefaultOptionName = function () {
        return 'content';
    };
    /**
     * @inheritDoc
     */
    Tooltip.prototype.getDefaultOptions = function () {
        return {
            /**
             * The type of transition animation.
             */
            animation: 'shift-away',
            /**
             * Determines if content strings are parsed as HTML instead of text.
             * Make sure you are sanitizing any user data if rendering HTML to prevent XSS attacks.
             */
            allowHTML: false,
            /**
             * Determines if the tippy has an arrow.
             */
            arrow: true,
            /**
             * The content of the tippy.
             */
            content: '',
            /**
             * Delay in ms once a trigger event is fired before a tippy shows or hides.
             */
            delay: 0,
            /**
             * How far in pixels the tippy element is from the reference element.
             */
            distance: 4,
            /**
             * Duration of the CSS transition animation in ms.
             *
             * Possibles values: number, [number, number] = [show, hide].
             *
             * Specifying a number means both the show and hide delays are the same. Use null in the array to use the default value.
             */
            duration: [325, 275],
            /**
             * Determines if the tippy should hide if a mousedown event was fired outside of it
             * (i.e. clicking on the reference element or the body of the page).
             */
            hideOnClick: true,
            /**
             * Determines if the tippy is interactive, i.e. it can be hovered over or clicked without hiding.
             */
            interactive: false,
            /**
             * Determines the maximum width of the tippy.
             */
            maxWidth: 350,
            /**
             * Determines the offset of the tippy element.
             *
             * Unlike distance, it can work on both axes by using a string in the form "x, y", such as "50, 20".
             *
             * Avoid using an offset along the same axis as the placement prop if using interactive: true.
             * If using a number, there won't be any problems.
             */
            offset: 0,
            /**
             * Positions the tippy relative to its reference element.
             *
             * Use the suffix -start or -end to shift the tippy to the start or end of the reference element,
             * instead of centering it. For example, "top-start" or "left-end".
             */
            placement: "top",
            /**
             * The events (each separated by a space) which cause a tippy to show.
             *
             * Possible values: "mouseenter", "focus", "click", "manual".
             *
             * Use "manual" to only trigger the tippy programmatically.
             */
            trigger: "mouseenter focus",
            /**
             * Which element(s) the trigger event listeners are applied to instead of the reference element.
             *
             * Possible values: null, Element, or Element[].
             */
            triggerTarget: null,
            /**
             * Determines the z-index of the tippy.
             */
            zIndex: 9999
        };
    };
    /**
     * @inheritDoc
     */
    Tooltip.prototype.doInit = function () {
        tippy_js_1.default(this.$element.get(0), this.getOptions());
    };
    Tooltip = tslib_1.__decorate([
        inversify_1.injectable()
    ], Tooltip);
    return Tooltip;
}(abstract_module_1.AbstractModule));
exports.TooltipSymbol = Symbol("Tooltip");
container_1.Container.registerModule(exports.TooltipSymbol, Tooltip);
