"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var trim = require("lodash/trim");
var kebabCase = require("lodash/kebabCase");
var camelCase = require("lodash/camelCase");
var inversify_1 = require("inversify");
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var container_1 = require("webeak-native/inversify/container");
/**
 * Manages modules created using [vm-*] attributes in the DOM.
 */
var ModulesManager = /** @class */ (function () {
    function ModulesManager() {
        /**
         * List of instantiated modules.
         */
        this.modules = [];
        this.modulesAttributeSelectors = null;
        this.modules = [];
    }
    ModulesManager_1 = ModulesManager;
    /**
     * Scan the DOM in the search of [vm-*] attributes and create the VueJS components associated.
     */
    ModulesManager.prototype.scan = function () {
        var that = this;
        if (this.modulesAttributeSelectors === null) {
            this.modulesAttributeSelectors = this.computeModulesAttributeSelectors();
        }
        for (var selector in this.modulesAttributeSelectors) {
            if (!this.modulesAttributeSelectors.hasOwnProperty(selector)) {
                continue;
            }
            $("[" + selector + "]").each((function (attrName, moduleSymbol) {
                return function () {
                    var $el = $(this);
                    var attrValue = trim($el.attr(attrName));
                    var dataName = camelCase(attrName);
                    var moduleInstance = container_1.Container.getContainer().get(moduleSymbol);
                    var options = {};
                    if (!utils_1.isUndefined($el.data(attrName))) {
                        console.error("Multiple initialization of the TypeScript module \"" + object_1.getSymbolDescription(moduleSymbol) + "\".");
                        return;
                    }
                    if (attrValue) {
                        if (attrValue[0] === "{") {
                            try {
                                options = JSON.parse(attrValue);
                                if (typeof (options) !== "object") {
                                    options = {};
                                }
                            }
                            catch (e) {
                                console.error("Failed to decode options of the TypeScript module \"" + object_1.getSymbolDescription(moduleSymbol) + "\". Please provide a valid JSON object.");
                                return;
                            }
                        }
                        else {
                            var defaultOptionName = moduleInstance.getDefaultOptionName();
                            if (utils_1.isString(defaultOptionName)) {
                                options[defaultOptionName] = attrValue;
                            }
                            else {
                                console.error("No default option name has been defined for the TypeScript module \"" + object_1.getSymbolDescription(moduleSymbol) + "\".");
                            }
                        }
                    }
                    $el.removeAttr(attrName);
                    moduleInstance.setElement($el);
                    moduleInstance.initialize(options);
                    $el.data(dataName, moduleInstance);
                    that.modules.push({
                        $element: $el,
                        dataName: dataName,
                        instance: moduleInstance,
                        selector: attrName,
                        symbol: moduleSymbol,
                    });
                };
            })(selector, this.modulesAttributeSelectors[selector]));
        }
    };
    /**
     * Create an object containing:
     *   - as key: an HTML attribute
     *   - as value: the symbol corresponding to the module that should be created if the HTML attribute is found.
     *
     * @returns {object}
     */
    ModulesManager.prototype.computeModulesAttributeSelectors = function () {
        var output = {};
        var symbols = container_1.Container.getModulesSymbols();
        for (var _i = 0, symbols_1 = symbols; _i < symbols_1.length; _i++) {
            var item = symbols_1[_i];
            var attrName = ModulesManager_1.MODULES_HTML_ATTRIBUTES_PREFIX + kebabCase(object_1.getSymbolDescription(item));
            output[attrName] = item;
        }
        return output;
    };
    var ModulesManager_1;
    ModulesManager.MODULES_HTML_ATTRIBUTES_PREFIX = "tsm-";
    ModulesManager = ModulesManager_1 = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], ModulesManager);
    return ModulesManager;
}());
exports.ModulesManager = ModulesManager;
exports.ModulesManagerSymbol = Symbol("ModulesManager");
container_1.Container.registerService(exports.ModulesManagerSymbol, ModulesManager);
