"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var model_1 = require("essentials/model");
var raw_1 = require("essentials/model/transformer/types/raw");
var api_transformation_model_schema_1 = require("../api-transformation-model.schema");
function ApiProperty(transformer) {
    if (transformer === void 0) { transformer = null; }
    return function (target, prop) {
        var schema = model_1.SchemasHolder.GetInstance().getOrCreate(api_transformation_model_schema_1.ApiTransformationModelSchema, target.constructor);
        schema.registerProperty(prop, transformer, raw_1.Raw());
    };
}
exports.ApiProperty = ApiProperty;
