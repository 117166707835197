"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var var_holder_1 = require("essentials/storage/var-holder");
var error_1 = require("essentials/error");
var Constants = /** @class */ (function (_super) {
    tslib_1.__extends(Constants, _super);
    function Constants() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get the value of a constant.
     */
    Constants.Get = function (name) {
        return Constants.GetInstance().get(name);
    };
    /**
     * Register a constant.
     */
    Constants.Register = function (name, value) {
        if (Constants.GetInstance().has(name)) {
            throw new error_1.AppError("A constant named " + name + " is already defined. Choose another name of use the shared configuration if the value must be overridden.");
        }
        Constants.GetInstance().set(name, value);
    };
    /**
     * Get (and create if necessary) the singleton instance.
     */
    Constants.GetInstance = function () {
        if (Constants.Instance === null) {
            Constants.Instance = new Constants();
            /**
             * Register some global constants.
             */
            // Custom VueJS delimiters so they do not conflict with Twig.
            Constants.Register('DELIMITERS', ['${', '}']);
        }
        return Constants.Instance;
    };
    /**
     * Singleton.
     */
    Constants.Instance = null;
    return Constants;
}(var_holder_1.VarHolder));
exports.Constants = Constants;
