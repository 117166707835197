"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("essentials/network/utils");
/**
 * When the API respond with an array, a prefix is added to prevent XSSI attacks.
 * This filter is here to take it off or jQuery will crash when parsing the payload.
 */
jQuery.ajaxSetup({
    dataFilter: function (input) {
        return utils_1.stripXssiPrefix(input);
    }
});
