"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var api_fetcher_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/fetcher/api.fetcher");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var schemas_holder_1 = require("essentials/model/schemas-holder");
var error_1 = require("essentials/error");
var container_1 = require("webeak-native/inversify/container");
var router_1 = require("essentials/symfony/router");
var http_fetcher_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/fetcher/http.fetcher");
var list_context_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list-context");
var pagination_context_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-pagination/pagination-context");
var list_pagination_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-pagination/list-pagination.component");
var http_response_1 = require("essentials/network/http-response");
var constants_1 = require("essentials/network/constants");
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var storage_1 = require("essentials/storage");
var shared_configuration_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/config/shared-configuration");
var paginated_results_1 = require("essentials/network/paginated-results");
var utils_2 = require("essentials/utils/utils");
var list_row_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-row/list-row.component");
var ListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListComponent, _super);
    function ListComponent() {
        // Props
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.context = null;
        _this.columns = [];
        _this.indexedColumns = {};
        _this.rows = [];
        _this.httpResponse = null;
        _this.lastRequestHadFilters = false;
        _this.initialized = false;
        _this.fatalError = null;
        // Logic vars
        _this.fetcher = null;
        _this.columnsLocked = false;
        _this.waitingForFetch = false;
        _this.lastFetcherConfiguration = null;
        _this.sharedConfiguration = container_1.Container.getContainer().get(shared_configuration_1.SharedConfigurationSymbol);
        _this.filtersComponents = [];
        return _this;
    }
    // Watchers
    ListComponent.prototype.onFetchConfigurationChanged = function () {
        // We know that some part of the config have changed.
        // Just recreate a fetcher..
        this.fetcher = this.createFetcher();
    };
    ListComponent.prototype.onOrderByChange = function (newVal) {
        this.context.orderBy(newVal);
    };
    ListComponent.prototype.onOrderDirectionChange = function (newVal) {
        this.context.setOrderingDirection(newVal);
    };
    ListComponent.prototype.onFiltersChange = function (newVal) {
        this.context.replaceFilters(newVal);
    };
    Object.defineProperty(ListComponent.prototype, "hasResults", {
        // Computed
        get: function () {
            return this.resultsCount > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "resultsCount", {
        get: function () {
            return this.resultItems.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "resultItems", {
        get: function () {
            if (!this.httpResponse || !this.httpResponse.isSuccess) {
                return [];
            }
            return this.paginate ? this.httpResponse.result.items : this.httpResponse.result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "paginationProps", {
        get: function () {
            var props = {
                'page': object_1.getObjectValue(this.context, ['pagination', 'page'], this.page),
                'items-per-page': object_1.getObjectValue(this.context, ['pagination', 'itemsPerPage'], this.itemsPerPage),
                'allow-input': this.allowPageInput,
                'allow-first': this.allowFirstPage,
                'allow-last': this.allowLastPage,
                'allowed-items-per-age': this.allowedItemsPerPage
            };
            // Remove undefined values
            Object.keys(props).forEach(function (key) { return props[key] === undefined ? delete props[key] : ''; });
            return props;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "items", {
        get: function () {
            if (!this.httpResponse || this.httpResponse.isError) {
                return [];
            }
            return utils_1.ensureArray(this.httpResponse.result instanceof paginated_results_1.PaginatedResults ? this.httpResponse.result.items : this.httpResponse.result);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "visibleColumns", {
        get: function () {
            var columns = [];
            for (var _i = 0, _a = this.columns; _i < _a.length; _i++) {
                var candidate = _a[_i];
                if (this.context.isColumnVisible(candidate.name)) {
                    columns.push(candidate);
                }
            }
            return columns;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "visibleColumnsCount", {
        get: function () {
            return this.visibleColumns.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "hasHiddenActiveFilter", {
        get: function () {
            if (!this.filtersComponents.length) {
                return false;
            }
            for (var i = 0; i < this.columns.length; ++i) {
                if (!this.context.isColumnVisible(this.columns[i].name) &&
                    !utils_1.isUndefined(this.filtersComponents[i]) &&
                    this.filtersComponents[i].control.value &&
                    this.context.filters /* To trigger a recompute when the context changes.. */) {
                    return true;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle method.
     */
    ListComponent.prototype.mounted = function () {
        var _this = this;
        this.httpResponse = new http_response_1.HttpResponse(constants_1.HttpResponseStatus.Pending);
        this.fetcher = this.createFetcher();
        this.buildContext().then(function (context) {
            _this.context = context;
            _this.context.onChange(utils_1.proxy(_this.update, _this));
            // Let the time for sub components to initialize.
            utils_2.onNextCycle().then(function () {
                _this.firstLoad().finally(function () {
                    for (var _i = 0, _a = _this.columns; _i < _a.length; _i++) {
                        var candidate = _a[_i];
                        if (candidate.orderByName !== _this.context.orderingField) {
                            candidate.orderByStatus = 'n/a';
                        }
                        else {
                            candidate.orderByStatus = _this.context.orderingDirection || 'n/a';
                        }
                    }
                });
            });
        });
    };
    /**
     * Register a new column.
     */
    ListComponent.prototype.registerColumn = function (column) {
        var _this = this;
        if (this.columnsLocked) {
            return;
        }
        this.columns.push(column);
        this.indexedColumns[column.name] = column;
        if (this.columns.length === 1) {
            window.setTimeout(function () {
                _this.columnsLocked = true;
                _this.$emit('columns-locked');
            });
        }
    };
    /**
     * Try to get a column by name.
     */
    ListComponent.prototype.getColumn = function (name) {
        return this.indexedColumns[name];
    };
    /**
     * Register a new row.
     */
    ListComponent.prototype.registerRow = function (row) {
        this.rows.push(row);
    };
    /**
     * Register a new filter component.
     */
    ListComponent.prototype.registerFilter = function (filter) {
        this.filtersComponents.push(filter);
    };
    /**
     * Order by a column.
     */
    ListComponent.prototype.setOrderBy = function (column) {
        if (column.orderByStatus === 'n/a' || column.orderByStatus === 'desc') {
            column.orderByStatus = 'asc';
        }
        else {
            column.orderByStatus = 'desc';
        }
        for (var _i = 0, _a = this.columns; _i < _a.length; _i++) {
            var candidate = _a[_i];
            if (candidate !== column && candidate.orderByName) {
                candidate.orderByStatus = 'n/a';
            }
        }
        this.context.orderBy(column.orderByName, column.orderByStatus);
    };
    /**
     * Remove all filters.
     */
    ListComponent.prototype.clearFilters = function () {
        for (var _i = 0, _a = this.filtersComponents; _i < _a.length; _i++) {
            var filter = _a[_i];
            filter.clear();
        }
        this.context.removeAllFilters();
    };
    /**
     * Update the list.
     *
     * @param {boolean} force      if true, a request will be done even if no change has been made in the configuration
     * @param {boolean} discreetly if true, the update will not trigger any change in the UI
     */
    ListComponent.prototype.update = function (force, discreetly) {
        if (force === void 0) { force = false; }
        if (discreetly === void 0) { discreetly = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fetcherConfiguration;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.initialized && !force) {
                    return [2 /*return*/, null];
                }
                if (this.context === null || (this.httpResponse && this.httpResponse.isPending)) {
                    this.waitingForFetch = true;
                    return [2 /*return*/, null];
                }
                fetcherConfiguration = this.context.export(true);
                if (force === true || this.lastFetcherConfiguration === null || !object_1.areSameObjects(fetcherConfiguration, this.lastFetcherConfiguration)) {
                    this.httpResponse = this.fetcher.fetch(fetcherConfiguration);
                    this.lastRequestHadFilters = this.context.hasFilters();
                    this.saveCurrentContext();
                    this.httpResponse.promise.then(function () {
                        _this.restoreAfterError();
                    }).catch(function (error) {
                        // So a new request can be made.
                        _this.lastFetcherConfiguration = null;
                        if (!_this.initialized) {
                            _this.handleFatalError(new error_1.PublicAppError('Échec du chargement des éléments.', error));
                        }
                    }).finally(function () {
                        if (_this.waitingForFetch) {
                            _this.waitingForFetch = false;
                            _this.update();
                        }
                    });
                    return [2 /*return*/, this.httpResponse.promise];
                }
                else {
                    this.saveCurrentContext();
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Do the first call to update() for the list.
     *
     * Because how columns are registered, there is some gymnastic involved to keep them registered even
     * if filters have been applied and no results are found.
     */
    ListComponent.prototype.firstLoad = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var savedContext;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Because we have created a a fake HttpResponse with the Pending status we must remove it or the fetch will do nothing.
                        this.httpResponse = null;
                        return [4 /*yield*/, this.update(true)];
                    case 1:
                        _a.sent();
                        if (this.items.length > 0) {
                            return [2 /*return*/, void this.setAsInitialized()];
                        }
                        savedContext = this.context.export();
                        this.context.removeAllFilters();
                        return [4 /*yield*/, this.update(true)];
                    case 2:
                        _a.sent();
                        this.context.replaceFilters(savedContext.filters);
                        if (!this.items.length) {
                            // console.warn('Still no results. Can\'t do more.');
                            this.setAsInitialized();
                            return [2 /*return*/];
                        }
                        // console.warn('Results found, wait a cycle and do a new request.')
                        window.setTimeout(function () {
                            _this.update(true).finally(utils_1.proxy(_this.setAsInitialized, _this));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Function called by the panel-error component when the list fails its first load.
     */
    ListComponent.prototype.retryFirstLoad = function (event) {
        event.promise = this.firstLoad();
    };
    /**
     * Function called by the panel-error component when the list fails to update.
     */
    ListComponent.prototype.retryUpdate = function (event) {
        event.promise = this.update(true);
    };
    /**
     * Mark the list as initialized.
     */
    ListComponent.prototype.setAsInitialized = function () {
        this.initialized = true;
        this.$emit('initialized');
    };
    /**
     * Create the context object.
     */
    ListComponent.prototype.buildContext = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var context;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restoreSavedContext()];
                    case 1:
                        context = _a.sent();
                        if (context !== null) {
                            return [2 /*return*/, context];
                        }
                        return [2 /*return*/, new list_context_1.ListContext({
                                filters: this.filters,
                                pagination: (this.paginate ? new pagination_context_1.PaginationContext({
                                    page: this.page,
                                    itemsPerPage: this.itemsPerPage,
                                }) : null),
                                orderingField: this.orderBy,
                                orderingDirection: this.orderDirection,
                                hiddenColumns: []
                            })];
                }
            });
        });
    };
    /**
     * Try to restore a saved context from the url or the local storage.
     */
    ListComponent.prototype.restoreSavedContext = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, fragmentName, context, context, storage, existingContextStr;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.syncUrl) {
                            obj = utils_2.parseUrlFragment();
                            fragmentName = this.getUrlFragmentName();
                            if (utils_1.isString(obj[fragmentName])) {
                                context = new list_context_1.ListContext();
                                if (context.importFromString(obj[fragmentName])) {
                                    return [2 /*return*/, context];
                                }
                                else {
                                    delete obj[fragmentName];
                                    utils_2.updateUrlFragment(obj);
                                }
                            }
                        }
                        if (!(this.id && this.saveState)) return [3 /*break*/, 2];
                        context = new list_context_1.ListContext();
                        storage = container_1.Container.getContainer().get(storage_1.StorageServiceSymbol);
                        return [4 /*yield*/, storage.get(this.getStorageKeyName())];
                    case 1:
                        existingContextStr = _a.sent();
                        if (context.importFromString(existingContextStr)) {
                            return [2 /*return*/, context];
                        }
                        else {
                            storage.remove(this.getStorageKeyName());
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/, null];
                }
            });
        });
    };
    /**
     * Create the FetcherInterface object that corresponds to the current configuration.
     */
    ListComponent.prototype.createFetcher = function () {
        if (this.apiAction !== null) {
            if (this.route || this.url) {
                this.handleFatalError('You cannot define a route/url and an api action at the same time.');
            }
            var entityType = this.resolveEntityType();
            if (!entityType) {
                this.handleFatalError("You must define a type of entity if you use the api.");
            }
            return new api_fetcher_1.ApiFetcher(entityType, this.apiAction, this.urlParams);
        }
        var url = this.url;
        if (this.route) {
            if (url) {
                this.handleFatalError('You cannot define a route and a url at the same time.');
            }
            var router = container_1.Container.getContainer().get(router_1.RouterSymbol);
            try {
                url = router.generateUrl(this.route, this.urlParams);
            }
            catch (e) {
                this.handleFatalError(error_1.AppError.create(e).message);
            }
        }
        if (!url) {
            this.handleFatalError('You must define an api action, route or url in order to fetch data.');
        }
        return new http_fetcher_1.HttpFetcher(url, this.entity !== null ? this.resolveEntityType() : null);
    };
    /**
     * Convert an entity type from a string into an object constructor.
     */
    ListComponent.prototype.resolveEntityType = function () {
        var schema = schemas_holder_1.SchemasHolder.GetInstance().get(generic_transformation_model_schema_1.GenericTransformationModelSchema, this.entity);
        if (schema) {
            return schema.target;
        }
        this.handleFatalError("No schema have been found for entity \"" + this.entity + "\".");
    };
    /**
     * Set the list in a fatal error state.
     */
    ListComponent.prototype.handleFatalError = function (error) {
        error = error_1.AppError.create(error);
        this.fatalError = error.getPublicMessage();
        throw error;
    };
    /**
     * To call when a request succeed after a previous failure.
     */
    ListComponent.prototype.restoreAfterError = function () {
        if (this.fatalError) {
            this.fatalError = null;
            this.context.removeAllFilters();
        }
    };
    /**
     * Do everything that needs to be done after the context has been updated.
     */
    ListComponent.prototype.saveCurrentContext = function () {
        var contextStr = this.saveState || this.syncUrl ? this.context.exportAsString() : null;
        this.lastFetcherConfiguration = this.context.export(true);
        if (this.id && this.saveState) {
            var storage = container_1.Container.getContainer().get(storage_1.StorageServiceSymbol);
            storage.set(this.getStorageKeyName(), contextStr);
        }
        if (this.syncUrl) {
            var obj = {};
            obj[this.getUrlFragmentName()] = contextStr;
            utils_2.updateUrlFragment(obj);
        }
    };
    /**
     * Get the name of the key used to save the context the local storage.
     */
    ListComponent.prototype.getStorageKeyName = function () {
        return this.sharedConfiguration.vuejs.lists.contextStorageKey + '_' + this.id;
    };
    /**
     * Get the name of the key used to save the context the url fragment.
     */
    ListComponent.prototype.getUrlFragmentName = function () {
        return this.sharedConfiguration.vuejs.lists.urlFragmentName;
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "id", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "entity", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "apiAction", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "route", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "url", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () { return {}; } }),
        tslib_1.__metadata("design:type", Object)
    ], ListComponent.prototype, "urlParams", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListComponent.prototype, "syncUrl", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListComponent.prototype, "saveState", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "orderBy", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'asc' }),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "orderDirection", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () { return {}; } }),
        tslib_1.__metadata("design:type", Object)
    ], ListComponent.prototype, "filters", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListComponent.prototype, "paginate", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number }),
        tslib_1.__metadata("design:type", Number)
    ], ListComponent.prototype, "page", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number }),
        tslib_1.__metadata("design:type", Number)
    ], ListComponent.prototype, "itemsPerPage", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Array }),
        tslib_1.__metadata("design:type", Array)
    ], ListComponent.prototype, "allowedItemsPerPage", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: undefined }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListComponent.prototype, "allowFirstPage", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: undefined }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListComponent.prototype, "allowLastPage", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: undefined }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListComponent.prototype, "allowPageInput", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('entity'),
        vue_property_decorator_1.Watch('apiAction'),
        vue_property_decorator_1.Watch('route'),
        vue_property_decorator_1.Watch('url'),
        vue_property_decorator_1.Watch('urlParams'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListComponent.prototype, "onFetchConfigurationChanged", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('orderBy'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [String]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListComponent.prototype, "onOrderByChange", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('orderDirection'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [String]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListComponent.prototype, "onOrderDirectionChange", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('filters'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListComponent.prototype, "onFiltersChange", null);
    ListComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list.component.html'),
            components: {
                'list-pagination': list_pagination_component_1.ListPaginationComponent,
                'list-row': list_row_component_1.ListRowComponent,
            }
        })
    ], ListComponent);
    return ListComponent;
}(vue_1.default));
exports.ListComponent = ListComponent;
vue_app_1.VueApp.RegisterComponent('list', ListComponent);
