"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var trim = require("lodash/trim");
var container_1 = require("webeak-native/inversify/container");
var object_1 = require("webeak-native/util/object");
var utils_1 = require("webeak-native/util/utils");
var shared_configuration_1 = require("../config/shared-configuration");
var storage_service_1 = require("../storage/service/storage.service");
var constants_1 = require("./constants");
var LoggerService = /** @class */ (function () {
    function LoggerService(storage, config) {
        this.storage = storage;
        this.config = config;
        this.logs = [];
        this.persistQueue = [];
        this.isLoaded = false;
        this.isFlushing = false;
        this.loadingPromise = null;
        this.lastFlushTime = null;
        this.nextFlushTimerId = null;
        this.isProd = this.config.env === 'prod';
    }
    /**
     * Add an deubg log.
     */
    LoggerService.prototype.debug = function (message, extra) {
        if (this.isProd) {
            return;
        }
        this.add(constants_1.LogLevel.DEBUG, message, object_1.extend(extra || {}, { trace: this.getCallerName(3) }));
    };
    /**
     * Add an info log.
     */
    LoggerService.prototype.info = function (message, extra) {
        this.add(constants_1.LogLevel.INFO, message, extra || null);
    };
    /**
     * Add a log indicating the success of an operation.
     */
    LoggerService.prototype.success = function (message, extra) {
        this.add(constants_1.LogLevel.SUCCESS, message, extra || null);
    };
    /**
     * Add a warning log.
     */
    LoggerService.prototype.warning = function (message, extra) {
        this.add(constants_1.LogLevel.WARNING, message, extra || null);
    };
    /**
     * Add an error log.
     */
    LoggerService.prototype.error = function (message, extra) {
        this.add(constants_1.LogLevel.ERROR, message, extra || null);
    };
    /**
     * Clear the logs.
     */
    LoggerService.prototype.clear = function () {
        this.logs = [];
        this.persistQueue = [];
        this.storage.remove(this.config.debug.logs.storageKey).then(function () {
            // Don't care about the result.
        }, function () {
            // Don't care about the result.
        });
    };
    /**
     * Load existing logs from the storage.
     *
     * The loading can never fail.
     * If an error occurs when trying to fetch the logs or to decode the result, the error is ignored and
     * an empty array will be sent as a result.
     *
     * Logs are an optional feature, a debug feature.
     * No matter what happens, the logger service must NEVER prevent the application to run or notify the user of any problem.
     */
    LoggerService.prototype.load = function () {
        var _this = this;
        if (this.loadingPromise !== null) {
            return this.loadingPromise;
        }
        this.loadingPromise = new Promise(function (resolve, reject) {
            var onFinish = function () {
                _this.isLoaded = true;
                _this.loadingPromise = null;
                resolve(_this.logs);
            };
            _this.storage.get(_this.config.debug.logs.storageKey).then(function (result) {
                try {
                    var decoded = JSON.parse(result);
                    _this.logs = utils_1.ensureArray(decoded);
                }
                catch (e) {
                    _this.logs = [];
                }
                onFinish();
            }, function () {
                _this.logs = [];
                onFinish();
            });
        });
        return this.loadingPromise;
    };
    /**
     * Get all logs including non yet persisted ones.
     */
    LoggerService.prototype.getAll = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.load().then(function (items) {
                for (var _i = 0, _a = _this.persistQueue; _i < _a.length; _i++) {
                    var log = _a[_i];
                    items.push(log);
                }
                resolve(items);
            }).catch(function (error) {
                resolve(_this.persistQueue.concat([{
                        l: constants_1.LogLevel.ERROR,
                        e: { error: error },
                        m: 'Failed to get logs, storage may not be ready yet.'
                    }]));
            });
        });
    };
    /**
     * Add a new log.
     */
    LoggerService.prototype.add = function (level, message, extra) {
        var _this = this;
        if (level < this.config.debug.logs.level) {
            return;
        }
        if (level === constants_1.LogLevel.ERROR) {
            console.error(message);
        }
        else if (level === constants_1.LogLevel.WARNING) {
            console.warn(message);
        }
        else {
            console.log(message);
        }
        this.persistQueue.push({
            l: level,
            m: utils_1.isString(message) ? message.substring(0, 255) : null,
            e: extra ? object_1.prepareObjectForDump(extra, 5) : null
        });
        if (this.lastFlushTime === null || Date.now() - this.lastFlushTime >= this.config.debug.logs.storageWriteInterval) {
            this.flush();
        }
        else if (this.nextFlushTimerId === null) {
            var delay = Math.max(0, this.config.debug.logs.storageWriteInterval - (Date.now() - this.lastFlushTime));
            this.nextFlushTimerId = setTimeout(function () {
                _this.nextFlushTimerId = null;
                _this.flush();
            }, delay);
        }
    };
    /**
     * Flush logs in the persist queue into the storage.
     */
    LoggerService.prototype.flush = function () {
        var _this = this;
        if (this.isFlushing) {
            return;
        }
        if (!this.isLoaded) {
            this.load();
        }
        this.isFlushing = true;
        if (this.loadingPromise !== null) {
            this.loadingPromise.then(function () {
                _this.isFlushing = false;
                _this.flush();
            });
            return;
        }
        for (var _i = 0, _a = this.persistQueue; _i < _a.length; _i++) {
            var log = _a[_i];
            if (this.logs.length >= this.config.debug.logs.maximumCount) {
                this.logs.shift();
            }
            this.logs.push(log);
        }
        this.persistQueue = [];
        this.storage.set(this.config.debug.logs.storageKey, JSON.stringify(this.logs)).then(function () {
            _this.isFlushing = false;
            _this.lastFlushTime = Date.now();
        }, function () {
            // Ignore the error.
            _this.isFlushing = false;
        });
    };
    /**
     * Try to get the function and class name that called the logger.
     */
    LoggerService.prototype.getCallerName = function (index) {
        var e = new Error();
        if (!e.stack) {
            try {
                // IE requires the Error to actually be thrown or else the
                // Error's 'stack' property is undefined.
                throw e;
            }
            catch (e) {
                if (!e.stack) {
                    return null; // IE < 10, likely
                }
            }
        }
        var stack = e.stack.toString().split(/\r\n|\n/);
        if (utils_1.isArray(stack) && !utils_1.isUndefined(stack[index])) {
            var str = stack[index];
            var parenthesisPos = str.indexOf('(');
            if (parenthesisPos > 0 && utils_1.isString(str)) {
                str = str.substring(0, parenthesisPos);
            }
            var parts = str.split('.');
            if (parts.length >= 2) {
                parts = parts.splice(parts.length - 2, 2);
            }
            return trim(parts.join(':').replace(/\s*at\s+/, ''));
        }
        return null;
    };
    LoggerService = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(storage_service_1.StorageServiceSymbol)),
        tslib_1.__param(1, inversify_1.inject(shared_configuration_1.SharedConfigurationSymbol)),
        tslib_1.__metadata("design:paramtypes", [storage_service_1.StorageService,
            shared_configuration_1.SharedConfiguration])
    ], LoggerService);
    return LoggerService;
}());
exports.LoggerService = LoggerService;
exports.LoggerServiceSymbol = Symbol("LoggerService");
container_1.Container.registerService(exports.LoggerServiceSymbol, LoggerService);
