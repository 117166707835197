"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var var_holder_1 = require("essentials/storage/var-holder");
var symfony_user_factory_1 = require("essentials/symfony/symfony-user.factory");
var SymfonyBag = /** @class */ (function (_super) {
    tslib_1.__extends(SymfonyBag, _super);
    function SymfonyBag() {
        var _this = _super.call(this) || this;
        if (utils_1.isObject(window['SF_JSB'])) {
            _this.replace(window['SF_JSB']);
        }
        var factory = container_1.Container.getContainer().get(symfony_user_factory_1.SymfonyUserFactorySymbol);
        _this.user = factory.create(_this.get('user'));
        return _this;
    }
    SymfonyBag = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], SymfonyBag);
    return SymfonyBag;
}(var_holder_1.VarHolder));
exports.SymfonyBag = SymfonyBag;
exports.SymfonyBagSymbol = Symbol("SymfonyBag");
container_1.Container.registerService(exports.SymfonyBagSymbol, SymfonyBag);
