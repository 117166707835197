"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("essentials/api/api.service");
var container_1 = require("webeak-native/inversify/container");
var ApiFetcher = /** @class */ (function () {
    function ApiFetcher(entity, action, parameters) {
        this.entity = entity;
        this.action = action;
        this.parameters = parameters;
        this.api = container_1.Container.getContainer().get(api_service_1.ApiServiceSymbol);
    }
    /**
     * @inheritDoc
     */
    ApiFetcher.prototype.fetch = function (context) {
        var builder = this.api.get(this.entity, this.action).collection();
        for (var _i = 0, _a = Object.keys(this.parameters); _i < _a.length; _i++) {
            var paramName = _a[_i];
            builder.addUriReplacement(paramName, this.parameters[paramName]);
        }
        builder.where(context.filters);
        if (context.orderingField) {
            builder.orderBy(context.orderingField, context.orderingDirection);
        }
        if (context.pagination) {
            builder.paginate(context.pagination.page, context.pagination.itemsPerPage);
        }
        return builder.execute();
    };
    return ApiFetcher;
}());
exports.ApiFetcher = ApiFetcher;
