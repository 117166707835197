"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
/**
 * Convert an object into a JSON string an back.
 */
function ObjectToJson() {
    return {
        type: 'json',
        transform: function (value) {
            if (value === null || !utils_1.isObject(value)) {
                return;
            }
            try {
                return JSON.stringify(value);
            }
            catch (e) {
                // No point in displaying or not catching the error.
                // The transform may be applied while a value is still in edition (in forms)
                // so it is not a surprise to find an invalid value here.
            }
            return "{}";
        },
        transformInverse: function (value) {
            if (!utils_1.isString(value)) {
                return {};
            }
            try {
                return JSON.parse(value);
            }
            catch (e) {
                // No point in displaying or not catching the error.
                // The transform may be applied while a value is still in edition (in forms)
                // so it is not a surprise to find an invalid value here.
            }
            return {};
        },
        getChild: function () {
            return null;
        }
    };
}
exports.ObjectToJson = ObjectToJson;
