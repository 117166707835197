"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var validation_error_1 = require("../validation.error");
var utils_1 = require("webeak-native/util/utils");
var validator_container_1 = require("essentials/validation/validator-container");
function Max(count, message) {
    if (message === void 0) { message = 'auto'; }
    return {
        validate: function (value, context) {
            var valid = true;
            var defaultMessage = null;
            if (utils_1.isNumeric(value)) {
                valid = (!utils_1.isNumber(value) ? parseFloat(value) : value) >= count;
                defaultMessage = 'Doit être inférieur ou égal à %count%.';
            }
            else if (utils_1.isString(value)) {
                valid = value.length <= count;
                defaultMessage = 'Doit contenir au maximum %count% caractère' + (count > 1 ? 's' : '') + '.';
            }
            else if (utils_1.isArray(value)) {
                valid = value.length <= count;
                defaultMessage = 'Doit contenir au maximum %count% élément' + (count > 1 ? 's' : '') + '.';
            }
            else if (utils_1.isObject(value)) {
                valid = Object.keys(value).length <= count;
                defaultMessage = 'Doit contenir au maximum %count% élément' + (count > 1 ? 's' : '') + '.';
            }
            if (!valid) {
                context.addError(new validation_error_1.ValidationError('max', message === 'auto' ? defaultMessage : message, { count: count }));
            }
        }
    };
}
exports.Max = Max;
validator_container_1.ValidatorContainer.register('Max', Max);
