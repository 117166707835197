"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("essentials-root/vendor/froala/js/plugins/char_counter");
require("essentials-root/vendor/froala/css/plugins/char_counter.css");
exports.CharCounter = function (max) {
    if (max === void 0) { max = -1; }
    return {
        options: {
            charCounterCount: true,
            charCounterMax: max
        }
    };
};
