"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var RadioComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RadioComponent, _super);
    function RadioComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.picked = null;
        return _this;
    }
    // Watchers
    RadioComponent.prototype.onPicked = function (newValue, oldValue) {
        this.picked = newValue;
        this.control.setValue(newValue);
    };
    // Override the parent watch
    RadioComponent.prototype.onValueChanged = function (newValue, oldValue) {
        this.picked = newValue;
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: null, default: null }),
        tslib_1.__metadata("design:type", Object)
    ], RadioComponent.prototype, "value", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('picked'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [String, String]),
        tslib_1.__metadata("design:returntype", void 0)
    ], RadioComponent.prototype, "onPicked", null);
    RadioComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./radio.component.html'),
        })
    ], RadioComponent);
    return RadioComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.RadioComponent = RadioComponent;
vue_1.default.component('form-radio', RadioComponent);
