"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var list_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list.component");
var error_1 = require("essentials/error");
var form_array_1 = require("essentials/form/form-array");
var form_control_1 = require("essentials/form/form-control");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var string_1 = require("webeak-native/util/string");
var utils_1 = require("webeak-native/util/utils");
var ListColumnsTogglerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListColumnsTogglerComponent, _super);
    function ListColumnsTogglerComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Public vars
        _this.control = new form_array_1.FormArray();
        _this.selectVisible = false;
        _this.availableColumns = {};
        _this.availableColumnsSlugs = [];
        return _this;
    }
    /**
     * VueJS component lifecycle method.
     */
    ListColumnsTogglerComponent.prototype.mounted = function () {
        var _this = this;
        this.resolveList();
        if (!this.listComponent) {
            throw new error_1.AppError('Failed to find the list component. Please ensure you give a valid reference to the ' +
                '<list-columns-toggler></list-columns-toggler> component using the "list" prop.');
        }
        this.listComponent.$on('columns-locked', function () {
            var defaultVisible = !_this.listComponent.context.hasImportedData && utils_1.isArray(_this.defaultColumns) && _this.defaultColumns.length > 0 ? _this.defaultColumns : null;
            for (var i = 0; i < _this.listComponent.columns.length; ++i) {
                var column = _this.listComponent.columns[i];
                var slug = string_1.slugify(column.title);
                if (slug.length > 0) {
                    if (defaultVisible !== null) {
                        if (defaultVisible.indexOf(i) >= 0) {
                            _this.listComponent.context.showColumn(slug);
                        }
                        else {
                            _this.listComponent.context.hideColumn(slug);
                        }
                    }
                    var visible = _this.listComponent.context.isColumnVisible(slug);
                    if (visible) {
                        _this.control.add(new form_control_1.FormControl(slug));
                    }
                    _this.availableColumns[slug] = column.title;
                    _this.availableColumnsSlugs.push(slug);
                }
            }
            _this.control.onValueChange(function (event) {
                _this.updateContextHiddenColumns(event.newValue);
            });
            _this.updateContextHiddenColumns(_this.control.value);
        });
    };
    ListColumnsTogglerComponent.prototype.hideSelect = function () {
        this.selectVisible = false;
    };
    ListColumnsTogglerComponent.prototype.toggleSelect = function () {
        this.selectVisible = !this.selectVisible;
    };
    /**
     * Resolve the reference on the list component.
     */
    ListColumnsTogglerComponent.prototype.resolveList = function () {
        if (this.list instanceof list_component_1.ListComponent) {
            this.listComponent = this.list;
            return;
        }
        this.listComponent = null;
        if (utils_1.isString(this.list)) {
            var parent_1 = this.$parent;
            do {
                if (utils_1.isObject(parent_1.$refs) && parent_1.$refs[this.list] instanceof list_component_1.ListComponent) {
                    this.listComponent = parent_1.$refs[this.list];
                }
                parent_1 = parent_1.$parent;
            } while (utils_1.isObject(parent_1));
        }
    };
    /**
     * Update the array of hidden columns in the list's context.
     */
    ListColumnsTogglerComponent.prototype.updateContextHiddenColumns = function (visibleColumns) {
        this.listComponent.context.setHiddenColumns(this.availableColumnsSlugs.filter(function (item) {
            return visibleColumns.indexOf(item) < 0;
        }));
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Object], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], ListColumnsTogglerComponent.prototype, "list", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Array, default: function () { return []; } }),
        tslib_1.__metadata("design:type", Array)
    ], ListColumnsTogglerComponent.prototype, "defaultColumns", void 0);
    ListColumnsTogglerComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-columns-toggler.component.html')
        })
    ], ListColumnsTogglerComponent);
    return ListColumnsTogglerComponent;
}(vue_1.default));
exports.ListColumnsTogglerComponent = ListColumnsTogglerComponent;
vue_app_1.VueApp.RegisterComponent('list-columns-toggler', ListColumnsTogglerComponent);
