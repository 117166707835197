"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaginatedResults = /** @class */ (function () {
    function PaginatedResults(obj) {
        if (obj === void 0) { obj = {}; }
        /**
         * The current page number.
         */
        this.page = 1;
        /**
         * The total number of items in the result set.
         */
        this.totalResultsCount = 0;
        /**
         * The maximum number of results a page can contain for the current configuration.
         */
        this.totalResultsPerPage = 0;
        /**
         * The total number of pages in the result set.
         */
        this.totalPagesCount = 0;
        /**
         * True if the current page is the first one.
         */
        this.isFirstPage = true;
        /**
         * True if the current page is the last one.
         */
        this.isLastPage = true;
        /**
         * Index of the fist result of the current page in the whole result set (starting at 1).
         * For example:
         *
         * For 3 pages of results at 30 items per page, the value will be:
         *   - for page 1: 1
         *   - for page 2: 31
         *   - for page 3: 61
         */
        this.firstResultCount = 0;
        /**
         * Index of the last result of the current page in the whole result set.
         * For example:
         *
         * For 3 pages of results at 30 items per page, the value will be:
         *   - for page 1: 30
         *   - for page 2: 60
         *   - for page 3: 90
         */
        this.lastResultCount = 0;
        /**
         * The array of results for the current page.
         */
        this.items = [];
        Object.assign(this, obj);
    }
    /**
     * Create a PaginatedResults<T> instance from a raw server response that matches the PaginatedServerResponseInterface.
     */
    PaginatedResults.CreateFromServerResponse = function (response) {
        var page = Math.max(1, Math.min(response.page, response.lastPage));
        var firstResultCount = (response.itemsPerPage * (response.page - 1)) + 1;
        return new PaginatedResults({
            items: response.items,
            page: page,
            isFirstPage: page === 1,
            isLastPage: page === response.lastPage,
            totalPagesCount: response.lastPage,
            totalResultsCount: response.total,
            totalResultsPerPage: response.itemsPerPage,
            firstResultCount: firstResultCount,
            lastResultCount: firstResultCount + response.items.length - 1
        });
    };
    return PaginatedResults;
}());
exports.PaginatedResults = PaginatedResults;
