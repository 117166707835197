"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var model_1 = require("essentials/model");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var error_1 = require("../../error");
var schemas_holder_1 = require("../schemas-holder");
var model_transformer_factory_1 = require("./model-transformer.factory");
var TransformerService = /** @class */ (function () {
    function TransformerService(modelTransformersFactory) {
        this.modelTransformersFactory = modelTransformersFactory;
    }
    /**
     * Transform a model class into another format.
     */
    TransformerService.prototype.transform = function (data, schemaType) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                var transformer = _this.getModelTransformer(data.constructor, schemaType);
                Promise.all([transformer.transform(data)]).then(function (results) { return void resolve(results[0]); }, reject);
            }
            catch (e) {
                reject(e);
            }
        });
    };
    /**
     * Transform a single model's property to another format.
     */
    TransformerService.prototype.transformProperty = function (propertyName, schemaType, model) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var transformer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        transformer = this.getModelTransformer(model.constructor, schemaType);
                        return [4 /*yield*/, transformer.transformProperty(model, propertyName)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Transform a single generic value to the model format.
     */
    TransformerService.prototype.transformPropertyInverse = function (propertyName, value, schemaType, modelType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var transformer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        transformer = this.getModelTransformer(modelType, schemaType);
                        return [4 /*yield*/, transformer.transformPropertyInverse(value, propertyName)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Transform an already transformed model into another format.
     * You can obtain the same result by calling successively "transformInverse" and "transform" on the resulting model.
     */
    TransformerService.prototype.transformTransversal = function (data, sourceSchemaType, targetSchemaType, modelType) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var pipeline = Promise.resolve();
            pipeline = pipeline.then(function () { return _this.transformInverse(data, sourceSchemaType, modelType); });
            pipeline = pipeline.then(function (result) { return _this.transform(result, targetSchemaType); });
            pipeline.then(resolve, reject);
        });
    };
    /**
     * Transform back an object from the result of schema transform into a model class.
     */
    TransformerService.prototype.transformInverse = function (data, schemaType, modelType) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!utils_1.isFunction(modelType)) {
                return void reject(new error_1.AppError("Invalid model type given to transformInverse for schema \"" + schemaType.name + "\"."));
            }
            var schema = schemas_holder_1.SchemasHolder.GetInstance().get(schemaType, modelType);
            if (schema === null) {
                return void reject(new error_1.AppError("Object metadata corrupted, the schema \"" + schemaType.name + "\" doesn't exist for entity \"" + modelType.name + "\"."));
            }
            var transformer = _this.modelTransformersFactory.create(schema);
            if (transformer === null) {
                return void reject(new error_1.AppError('The target schema does not define a model transformer.'));
            }
            Promise.all([transformer.transformInverse(data)]).then(function (results) { return void resolve(results[0]); }).catch(reject);
        });
    };
    /**
     * Get the model transformer corresponding to a type of object and schema.
     */
    TransformerService.prototype.getModelTransformer = function (ctor, schemaType) {
        var schema = schemas_holder_1.SchemasHolder.GetInstance().get(schemaType, ctor);
        if (schema === null) {
            throw new error_1.AppError('No schema has been found for this model (' + ctor.name + ').');
        }
        var transformer = this.modelTransformersFactory.create(schema);
        if (transformer === null) {
            throw new error_1.AppError('The target schema does not define a model transformer.');
        }
        return transformer;
    };
    /**
     * Clone a model instance.
     *
     * @param {object} model
     */
    TransformerService.prototype.cloneModel = function (model) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var generic;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transform(model, model_1.GenericTransformationModelSchema)];
                    case 1:
                        generic = _a.sent();
                        return [4 /*yield*/, this.transformInverse(generic, model_1.GenericTransformationModelSchema, model.constructor)];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TransformerService = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(model_transformer_factory_1.ModelTransformerFactorySymbol)),
        tslib_1.__metadata("design:paramtypes", [model_transformer_factory_1.ModelTransformerFactory])
    ], TransformerService);
    return TransformerService;
}());
exports.TransformerService = TransformerService;
exports.TransformerServiceSymbol = Symbol("TransformerService");
container_1.Container.registerService(exports.TransformerServiceSymbol, TransformerService);
