"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var symfony_user_1 = require("essentials/symfony/symfony-user");
var SymfonyUserFactory = /** @class */ (function () {
    function SymfonyUserFactory() {
    }
    /**
     * Create a SymfonyUserInterface from the server data.
     */
    SymfonyUserFactory.prototype.create = function (obj) {
        return symfony_user_1.SymfonyUser.CreateFromBridgeData(obj);
    };
    SymfonyUserFactory = tslib_1.__decorate([
        inversify_1.injectable()
    ], SymfonyUserFactory);
    return SymfonyUserFactory;
}());
exports.SymfonyUserFactory = SymfonyUserFactory;
exports.SymfonyUserFactorySymbol = Symbol("SymfonyUserFactory");
container_1.Container.registerService(exports.SymfonyUserFactorySymbol, SymfonyUserFactory);
