"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var error_1 = require("essentials/error");
var utils_1 = require("webeak-native/util/utils");
var SchemasHolder = /** @class */ (function () {
    function SchemasHolder() {
        this.schemas = new WeakMap();
        this.objectNamesMap = {};
    }
    /**
     * Get the static instance of the holder and create it if it doesn't exist yet.
     */
    SchemasHolder.GetInstance = function () {
        if (SchemasHolder.Instance === null) {
            SchemasHolder.Instance = new SchemasHolder();
        }
        return SchemasHolder.Instance;
    };
    /**
     * Try to get a schema for an object.
     */
    SchemasHolder.prototype.get = function (schemaType, modelType) {
        if (!this.schemas.has(schemaType)) {
            return null;
        }
        var map = this.schemas.get(schemaType);
        if (utils_1.isString(modelType)) {
            modelType = this.resolveModelTypeFromString(modelType);
        }
        if (utils_1.isFunction(modelType) && map.has(modelType)) {
            return map.get(modelType);
        }
        return null;
    };
    /**
     * Get the schema of a certain type for an object and create it if necessary.
     */
    SchemasHolder.prototype.getOrCreate = function (schemaType, obj) {
        var map = this.schemas.has(schemaType) ? this.schemas.get(schemaType) : null;
        if (map === null) {
            map = new WeakMap();
            this.schemas.set(schemaType, map);
        }
        var schema = map.has(obj) ? map.get(obj) : null;
        if (schema === null) {
            schema = this.createSchema(schemaType, obj);
            map.set(obj, schema);
        }
        return schema;
    };
    /**
     * Try to resolve a model type form a string.
     */
    SchemasHolder.prototype.resolveModelTypeFromString = function (modelType) {
        if (!utils_1.isUndefined(this.objectNamesMap[modelType])) {
            return this.objectNamesMap[modelType];
        }
        throw new error_1.AppError("Failed to resolve string model type (" + modelType + "). Please check that you imported the model.");
    };
    /**
     * Create a new schema instance and inherit from parent schemas if necessary.
     */
    SchemasHolder.prototype.createSchema = function (schemaType, obj) {
        var schema = new schemaType();
        var objProto = Object.getPrototypeOf(obj);
        schema.target = obj;
        if (objProto !== Function.prototype) {
            var parentSchema = this.get(schemaType, objProto);
            if (parentSchema !== null) {
                schema.merge(parentSchema);
            }
        }
        if (utils_1.isUndefined(this.objectNamesMap[obj.name])) {
            this.objectNamesMap[obj.name] = obj;
        }
        return schema;
    };
    SchemasHolder.Instance = null;
    return SchemasHolder;
}());
exports.SchemasHolder = SchemasHolder;
