"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var event_1 = require("essentials/event");
/**
 * Represent an event emitted by a the validation process.
 */
var ValidationEvent = /** @class */ (function (_super) {
    tslib_1.__extends(ValidationEvent, _super);
    function ValidationEvent(type, context) {
        var _this = _super.call(this) || this;
        _this.context = context;
        _this.type = type;
        return _this;
    }
    return ValidationEvent;
}(event_1.EventArg));
exports.ValidationEvent = ValidationEvent;
