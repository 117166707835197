"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var Q = require("q");
var inversify_1 = require("inversify");
var utils_1 = require("../util/utils");
var utils_2 = require("webeak-native/util/utils");
/**
 * Generic component base class.
 * Can be used by anything from a module to a service.
 * Give some basic utilities almost every object may need.
 */
var Component = /** @class */ (function () {
    function Component() {
        this.eventListeners = {};
        this.eventListenersCount = 0;
        this.waitingForReadyDeferredQueue = [];
        this.options = this.getDefaultOptions();
        this.statesHolder = {
            current: {},
            tags: {},
        };
        this.registerStateTags(Component_1.STATES.INITIALIZING, Component_1.STATES.BUZY);
        this.registerStateTags(Component_1.STATES.INITIALIZING, Component_1.STATES.CONSTRUCTED);
        this.registerStateTags(Component_1.STATES.INITIALIZED, Component_1.STATES.CONSTRUCTED);
    }
    Component_1 = Component;
    /**
     * Initialize the module.
     *
     * @param {any} options
     *
     * @returns this
     */
    /* final */ Component.prototype.initialize = function (options) {
        var _this = this;
        this.enterState(Component_1.STATES.INITIALIZING);
        this.setOptions(options || {});
        Q.when(this.doInit(), function () {
            _this.enterState(Component_1.STATES.INITIALIZED);
            _this.leaveState(Component_1.STATES.INITIALIZING);
            for (var _i = 0, _a = _this.waitingForReadyDeferredQueue; _i < _a.length; _i++) {
                var deferred = _a[_i];
                deferred.resolve();
            }
            _this.afterInit();
        });
    };
    /**
     * Call the promise when the component has been initialized.
     *
     * @returns {Q.IPromise<any>}
     */
    Component.prototype.onReady = function () {
        var deferred = Q.defer();
        if (this.isReady()) {
            deferred.resolve();
        }
        else {
            this.waitingForReadyDeferredQueue.push(deferred);
        }
        return deferred.promise;
    };
    /**
     * Test if the module is ready to be used.
     *
     * @returns {boolean}
     */
    Component.prototype.isReady = function () {
        return this.isInState(Component_1.STATES.INITIALIZED);
    };
    /**
     * Gets an option by name.
     *
     * @param {string} name
     * @param {any}    defaultValue (optional, default: null) default value if the option if not found.
     *
     * @returns {any}
     */
    Component.prototype.getOption = function (name, defaultValue) {
        if (defaultValue === void 0) { defaultValue = null; }
        if (this.hasOption(name)) {
            return this.options[name];
        }
        return defaultValue;
    };
    /**
     * Sets an option by name.
     *
     * @param {string} name
     * @param {any}    value
     * @returns any
     */
    Component.prototype.setOption = function (name, value) {
        var hasOption = this.hasOption(name);
        var oldValue = hasOption ? this.options[name] : null;
        this.options[name] = value;
        if (this.isReady() && hasOption && !utils_1.areEqual(oldValue, value)) {
            this.onOptionChange(name, oldValue, value);
        }
    };
    /**
     * Merge an object of options into the internal one.
     *
     * @param {object}  options
     * @param {boolean} clearOther (optional, default: false) if true, the internal object is cleared before setting new options.
     *                  By default, new options are merged with existing ones.
     */
    Component.prototype.setOptions = function (options, clearOther) {
        if (clearOther === void 0) { clearOther = false; }
        if (clearOther) {
            this.options = {};
        }
        for (var name_1 in options) {
            if (options.hasOwnProperty(name_1)) {
                this.setOption(name_1, options[name_1]);
            }
        }
    };
    /**
     * Tests if an option is defined.
     *
     * @param name
     * @returns {boolean}
     */
    Component.prototype.hasOption = function (name) {
        return this.options[name] !== void 0;
    };
    /**
     * Get the name of the option to use when a scalar value is passed
     * to the html attribute, like: tsm-my-module="2".
     */
    Component.prototype.getDefaultOptionName = function () {
        return null;
    };
    /**
     * Bind to an event.
     *
     * @param {string}   eventName
     * @param {function} callback
     *
     * @returns {function} deregister function
     */
    Component.prototype.on = function (eventName, callback) {
        var _this = this;
        var deregister = (function (id, name) {
            return function () {
                if (!utils_1.isUndefined(_this.eventListeners[name]) && !utils_1.isUndefined(_this.eventListeners[name][id])) {
                    delete _this.eventListeners[name][id];
                }
            };
        })(++this.eventListenersCount, eventName);
        if (utils_1.isUndefined(this.eventListeners[eventName])) {
            this.eventListeners[eventName] = {};
        }
        this.eventListeners[eventName][this.eventListenersCount] = callback;
        return deregister;
    };
    /**
     * Unbind from an event.
     *
     * @param {string} eventName
     */
    Component.prototype.off = function (eventName) {
        if (!utils_1.isUndefined(this.eventListeners[eventName])) {
            this.eventListeners[eventName] = {};
        }
    };
    /**
     * Trigger an event.
     *
     * @param {string} eventName
     * @param {any}    data      (optional)
     */
    Component.prototype.trigger = function (eventName, data) {
        if (utils_1.isUndefined(this.eventListeners[eventName])) {
            return;
        }
        for (var id in this.eventListeners[eventName]) {
            if (this.eventListeners[eventName].hasOwnProperty(id)) {
                var eventObject = { name: eventName };
                var eventArgs = [eventObject];
                if (utils_2.isArray(data)) {
                    eventArgs = eventArgs.concat(data);
                }
                else if (!utils_1.isUndefined(data)) {
                    eventArgs.push(data);
                }
                this.eventListeners[eventName][id].apply(window, eventArgs);
            }
        }
    };
    /**
     * Initialization method.
     *
     * DO NOT override initialize(), implement this one.
     * DO NOT call this method manually.
     *
     * @return {any} could return a promise
     */
    Component.prototype.doInit = function () {
        // Override this method.
    };
    /**
     * Method called after the initialization is done.
     * Could be called asynchronously.
     *
     * DO NOT call this method manually.
     */
    Component.prototype.afterInit = function () {
        // Override this method.
    };
    /**
     * Gets the whole object of options.
     *
     * @returns {any}
     */
    Component.prototype.getOptions = function () {
        return this.options;
    };
    /**
     * Called when the value of an option changes.
     * Note, this method is not called while the initialization is not finished.
     *
     * Override this method.
     *
     * @param {string} optionName
     * @param {any}    oldValue
     * @param {any}    newValue
     */
    Component.prototype.onOptionChange = function (optionName, oldValue, newValue) {
        // Override this method.
    };
    /**
     * Gets the default options object.
     * Override this to add custom options.
     *
     * @return {any}
     */
    Component.prototype.getDefaultOptions = function () {
        return {};
    };
    /**
     * Checks whether if the component is in a specific state or not.
     *
     * @param {string} name
     *
     * @returns {boolean}
     */
    Component.prototype.isInState = function (name) {
        return this.statesHolder.current[name] && this.statesHolder.current[name] > 0;
    };
    /**
     * Enters a state.
     *
     * @param {string} name
     */
    Component.prototype.enterState = function (name) {
        var tags = [name].concat(this.statesHolder.tags[name] || []);
        for (var _i = 0, tags_1 = tags; _i < tags_1.length; _i++) {
            var tag = tags_1[_i];
            if (typeof (this.statesHolder.current[tag]) === "undefined") {
                this.statesHolder.current[tag] = 0;
            }
            this.statesHolder.current[tag]++;
        }
    };
    /**
     * Leaves a state.
     *
     * @param {string}  name
     * @param {boolean} absolute (optional, default: false) if true, the state counter is set to 0, no matter is value
     */
    Component.prototype.leaveState = function (name, absolute) {
        if (absolute === void 0) { absolute = false; }
        var tags = [name].concat(this.statesHolder.tags[name] || []);
        for (var _i = 0, tags_2 = tags; _i < tags_2.length; _i++) {
            var tag = tags_2[_i];
            if (typeof (this.statesHolder.current[tag]) !== "undefined") {
                if (absolute) {
                    this.statesHolder.current[tag] = 0;
                }
                else {
                    this.statesHolder.current[tag]--;
                }
            }
        }
    };
    /**
     * Registers an event or state.
     *
     * @param {string} name name of the state
     * @param {object} tags tags associated with it. If tags are already defined, new ones will be added to the list.
     */
    Component.prototype.registerStateTags = function (name, tags) {
        tags = utils_1.ensureArray(tags);
        if (typeof (this.statesHolder.tags[name]) === "undefined") {
            this.statesHolder.tags[name] = [];
        }
        for (var _i = 0, tags_3 = tags; _i < tags_3.length; _i++) {
            var candidate = tags_3[_i];
            if (this.statesHolder.tags[name].indexOf(candidate) < 0) {
                this.statesHolder.tags[name].push(candidate);
            }
        }
    };
    var Component_1;
    /**
     * Basic list of states.
     * You can extend the object in a subclass if you need to.
     *
     * @type {object}
     */
    Component.STATES = {
        /**
         * Means the object is created and the initialization has been called.
         */
        CONSTRUCTED: "constructed",
        /**
         * The object is currently on initialization, not yet finished.
         */
        INITIALIZING: "initializing",
        /**
         * The object is initialized, ready to be used.
         */
        INITIALIZED: "initialized",
        /**
         * The object is busy doing some processing.
         */
        BUZY: "buzy",
    };
    Component = Component_1 = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], Component);
    return Component;
}());
exports.Component = Component;
