"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var validation_schema_1 = require("../../validation/validation.schema");
var schemas_holder_1 = require("essentials/model/schemas-holder");
function Assert(validator) {
    return function (target, prop) {
        var schema = schemas_holder_1.SchemasHolder.GetInstance().getOrCreate(validation_schema_1.ValidationSchema, target.constructor);
        schema.properties[prop] = validator;
    };
}
exports.Assert = Assert;
