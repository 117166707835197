"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Placeholder transformer doing nothing.
 */
function Raw() {
    return {
        type: 'raw',
        transform: function (value) {
            return value;
        },
        transformInverse: function (value) {
            return value;
        },
        getChild: function () {
            return null;
        }
    };
}
exports.Raw = Raw;
