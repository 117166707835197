"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var moment = require("moment");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var api_resource_decorator_1 = require("essentials/api/decorators/api-resource.decorator");
var form_component_decorator_1 = require("essentials/form/decorators/form-component.decorator");
var abstract_entity_1 = require("essentials/entity/abstract.entity");
var model_1 = require("essentials/model/transformer/types/model");
var application_feature_entity_1 = require("backend/entity/application-feature.entity");
var form_group_1 = require("essentials/form/transformers/form-group");
var moment_to_js_date_1 = require("essentials/model/transformer/types/moment-to-js-date");
var user_entity_1 = require("backend/entity/user.entity");
var ApplicationUserFeatureEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationUserFeatureEntity, _super);
    function ApplicationUserFeatureEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expirationDate = null;
        _this.feature = null;
        _this.user = null;
        return _this;
    }
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(moment_to_js_date_1.MomentToJsDate()),
        transformable_decorator_1.Transformable(),
        tslib_1.__metadata("design:type", Object)
    ], ApplicationUserFeatureEntity.prototype, "expirationDate", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(form_group_1.FormGroup('feature', 'ApplicationFeatureEntity')),
        transformable_decorator_1.Transformable(model_1.Model('ApplicationFeatureEntity')),
        tslib_1.__metadata("design:type", application_feature_entity_1.ApplicationFeatureEntity)
    ], ApplicationUserFeatureEntity.prototype, "feature", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.Model('UserEntity')),
        tslib_1.__metadata("design:type", user_entity_1.UserEntity)
    ], ApplicationUserFeatureEntity.prototype, "user", void 0);
    ApplicationUserFeatureEntity = tslib_1.__decorate([
        api_resource_decorator_1.ApiResource('C4QSDG')
    ], ApplicationUserFeatureEntity);
    return ApplicationUserFeatureEntity;
}(abstract_entity_1.AbstractEntity));
exports.ApplicationUserFeatureEntity = ApplicationUserFeatureEntity;
