"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var file_component_1 = require("essentials/vuejs/components/form/file/file.component");
var FileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FileComponent, _super);
    function FileComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FileComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./file.component.html'),
        })
    ], FileComponent);
    return FileComponent;
}(file_component_1.FileComponent));
exports.FileComponent = FileComponent;
vue_1.default.component('form-file', FileComponent);
