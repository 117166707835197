"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var constants_1 = require("essentials/log/constants");
var var_holder_1 = require("essentials/storage/var-holder");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
/**
 * Current environment.
 */
exports.ENV = 'prod';
var SharedConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(SharedConfiguration, _super);
    function SharedConfiguration() {
        var _this = _super.call(this) || this;
        /**
         * Current environment.
         */
        _this.env = exports.ENV;
        /**
         * Version number.
         */
        _this.version = '0.0.1';
        /**
         * Current timezone in use in the app.
         */
        _this.timezone = 'Europe/Paris';
        /**
         * Debug configuration.
         */
        _this.debug = {
            logs: {
                level: constants_1.LogLevel.INFO,
                storageKey: 'debug:logs',
                storageWriteInterval: 10000,
                maximumCount: 50
            }
        };
        _this.network = {
            connectionErrorRetryDelay: 15000,
            reloadOnAuthenticationError: true
        };
        _this.storage = _this;
        return _this;
    }
    /**
     * SharedConfiguration must be registered twice in the container:
     *   - with a symbol identifier like any service
     *   - with a string constant identifier to be injectable anywhere in the app without risking a circular dependency.
     *
     * It must also be overridable so the last registration overrides the previous ones.
     *
     * For these reasons, the Register method has been created, to ensure it is registered properly.
     *
     * @param {object} obj a reference to the class to register
     */
    SharedConfiguration.Register = function (obj) {
        var container = container_1.Container.getContainer();
        try {
            container.get(exports.SharedConfigurationSymbol);
            container.unbind('SharedConfiguration');
            container.unbind(exports.SharedConfigurationSymbol);
        }
        catch (e) { }
        container.bind(exports.SharedConfigurationSymbol).to(obj).inSingletonScope();
        container.bind('SharedConfiguration').toConstantValue(container.get(exports.SharedConfigurationSymbol));
    };
    SharedConfiguration = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], SharedConfiguration);
    return SharedConfiguration;
}(var_holder_1.VarHolder));
exports.SharedConfiguration = SharedConfiguration;
exports.SharedConfigurationSymbol = Symbol("SharedConfiguration");
SharedConfiguration.Register(SharedConfiguration);
