"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var error_1 = require("essentials/error");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
var list_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list.component");
var ListGlobalFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListGlobalFilterComponent, _super);
    function ListGlobalFilterComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.value = '';
        return _this;
    }
    // Watchers
    ListGlobalFilterComponent.prototype.onValueChanged = function (newVal, oldVal) {
        this.update(newVal);
    };
    Object.defineProperty(ListGlobalFilterComponent.prototype, "filters", {
        // Computed
        get: function () {
            return this.fields.split(',');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS component lifecycle method.
     */
    ListGlobalFilterComponent.prototype.mounted = function () {
        var _this = this;
        this.resolveList();
        if (!this.listComponent) {
            throw new error_1.AppError('Failed to find the list component. Please ensure you give a valid reference to the ' +
                '<list-global-filter></list-global-filter> component using the "list" prop.');
        }
        window.setTimeout(function () {
            for (var _i = 0, _a = Object.keys(_this.listComponent.context.filters); _i < _a.length; _i++) {
                var key = _a[_i];
                if (_this.listComponent.context.filters[key]) {
                    _this.value = _this.listComponent.context.filters[key];
                    break;
                }
            }
        });
    };
    /**
     * Update the filtering on the associated list and start a new fetch.
     */
    ListGlobalFilterComponent.prototype.update = function (search) {
        if (!this.listComponent) {
            return;
        }
        for (var _i = 0, _a = this.filters; _i < _a.length; _i++) {
            var field = _a[_i];
            if (search) {
                this.listComponent.context.setFilter(field, search);
            }
            else {
                this.listComponent.context.removeFilter(field);
            }
        }
        this.listComponent.update();
    };
    /**
     * Resolve the reference on the list component.
     */
    ListGlobalFilterComponent.prototype.resolveList = function () {
        if (this.list instanceof list_component_1.ListComponent) {
            this.listComponent = this.list;
            return;
        }
        this.listComponent = null;
        if (utils_1.isString(this.list)) {
            var parent_1 = this.$parent;
            do {
                if (utils_1.isObject(parent_1.$refs) && parent_1.$refs[this.list] instanceof list_component_1.ListComponent) {
                    this.listComponent = parent_1.$refs[this.list];
                }
                parent_1 = parent_1.$parent;
            } while (utils_1.isObject(parent_1));
        }
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListGlobalFilterComponent.prototype, "entity", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, required: true }),
        tslib_1.__metadata("design:type", String)
    ], ListGlobalFilterComponent.prototype, "fields", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Object], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], ListGlobalFilterComponent.prototype, "list", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('value'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListGlobalFilterComponent.prototype, "onValueChanged", null);
    ListGlobalFilterComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-global-filter.component.html')
        })
    ], ListGlobalFilterComponent);
    return ListGlobalFilterComponent;
}(vue_1.default));
exports.ListGlobalFilterComponent = ListGlobalFilterComponent;
vue_app_1.VueApp.RegisterComponent('list-global-filter', ListGlobalFilterComponent);
