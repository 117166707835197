"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var api_resource_decorator_1 = require("essentials/api/decorators/api-resource.decorator");
var form_component_decorator_1 = require("essentials/form/decorators/form-component.decorator");
var abstract_entity_1 = require("essentials/entity/abstract.entity");
var not_blank_1 = require("essentials/validation/types/not-blank");
var assert_decorator_1 = require("essentials/validation/decorators/assert.decorator");
var collection_1 = require("essentials/model/transformer/types/collection");
var model_1 = require("essentials/model/transformer/types/model");
var application_feature_entity_1 = require("backend/entity/application-feature.entity");
var application_entity_1 = require("backend/entity/application.entity");
var form_array_1 = require("essentials/form/transformers/form-array");
var form_group_1 = require("essentials/form/transformers/form-group");
var and_1 = require("essentials/validation/types/and");
var max_1 = require("essentials/validation/types/max");
var pattern_1 = require("essentials/validation/types/pattern");
var schema_1 = require("essentials/validation/types/schema");
var foreach_1 = require("essentials/validation/types/foreach");
var ApplicationModuleEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationModuleEntity, _super);
    function ApplicationModuleEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = '';
        _this.description = '';
        _this.features = [];
        _this.application = null;
        return _this;
    }
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(not_blank_1.NotBlank(), max_1.Max(20), pattern_1.Pattern(/^[a-z-]+$/))),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationModuleEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationModuleEntity.prototype, "description", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(foreach_1.Foreach(schema_1.Schema(application_feature_entity_1.ApplicationFeatureEntity))),
        form_component_decorator_1.FormComponent(form_array_1.FormArray(form_group_1.FormGroup('features', 'ApplicationFeatureEntity'))),
        transformable_decorator_1.Transformable(collection_1.Collection(model_1.Model('ApplicationFeatureEntity'))),
        tslib_1.__metadata("design:type", Array)
    ], ApplicationModuleEntity.prototype, "features", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.Model('ApplicationEntity')),
        tslib_1.__metadata("design:type", application_entity_1.ApplicationEntity)
    ], ApplicationModuleEntity.prototype, "application", void 0);
    ApplicationModuleEntity = tslib_1.__decorate([
        api_resource_decorator_1.ApiResource('MN92VN')
    ], ApplicationModuleEntity);
    return ApplicationModuleEntity;
}(abstract_entity_1.AbstractEntity));
exports.ApplicationModuleEntity = ApplicationModuleEntity;
