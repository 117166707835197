"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var select_component_1 = require("essentials/vuejs/components/form/select/select.component");
var SelectNativeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectNativeComponent, _super);
    function SelectNativeComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SelectNativeComponent.prototype, "hasBeforeAddon", {
        // Computed
        get: function () {
            return this.hasSlot('before-addon');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectNativeComponent.prototype, "hasAfterAddon", {
        get: function () {
            return this.hasSlot('after-addon');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectNativeComponent.prototype, "hasBeforeAddonText", {
        get: function () {
            return this.hasSlot('before-addon-text');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectNativeComponent.prototype, "hasAfterAddonText", {
        get: function () {
            return this.hasSlot('after-addon-text');
        },
        enumerable: true,
        configurable: true
    });
    SelectNativeComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./select-native.component.html'),
        })
    ], SelectNativeComponent);
    return SelectNativeComponent;
}(select_component_1.SelectComponent));
exports.SelectNativeComponent = SelectNativeComponent;
vue_1.default.component('form-select-native', SelectNativeComponent);
