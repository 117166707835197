"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("backend/vuejs/vue-app");
var container_1 = require("webeak-native/inversify/container");
var api_service_1 = require("essentials/api/api.service");
var form_group_1 = require("essentials/form/form-group");
var form_model_binder_1 = require("essentials/form/form-model-binder");
var vue_property_decorator_1 = require("vue-property-decorator");
var application_entity_1 = require("backend/entity/application.entity");
var alertify_service_1 = require("essentials/dialog/alertify/alertify.service");
var LoginPageFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoginPageFormComponent, _super);
    function LoginPageFormComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.form = new form_group_1.FormGroup();
        _this.formBinder = null;
        _this.apiResponse = null;
        _this.codeMirrorConfig = null;
        _this.api = container_1.Container.getContainer().get(api_service_1.ApiServiceSymbol);
        _this.alertify = container_1.Container.getContainer().get(alertify_service_1.AlertifyServiceSymbol);
        _this.codeMirrorConfig = {
            mode: 'text/html'
        };
        return _this;
    }
    LoginPageFormComponent.prototype.created = function () {
        var _this = this;
        this.apiResponse = this.createOrFetchEntity();
        this.apiResponse.promise.then(function (user) {
            _this.formBinder = new form_model_binder_1.FormModelBinder(_this.form, user);
        }).catch(function (error) { return void _this.alertify.notifyError(error.getPublicMessage('Échec du chargement.')); });
    };
    LoginPageFormComponent.prototype.save = function () {
        var _this = this;
        this.apiResponse = this.api
            .persist(application_entity_1.ApplicationEntity, 'post_internal', 'put_internal')
            .data(this.formBinder)
            .execute();
        this.apiResponse.promise
            .then(function () { return void _this.alertify.notifySuccess("Sauvegarde effectuée avec succès."); })
            .catch(function (error) { return void _this.alertify.notifyError(error.getPublicMessage('Échec de la sauvegarde.')); });
    };
    LoginPageFormComponent.prototype.addRedirectUri = function () {
        this.formBinder.requestModelUpdate(function (model) {
            model.redirectUris.push('');
        });
    };
    LoginPageFormComponent.prototype.removeRedirectUri = function (index) {
        this.formBinder.requestModelUpdate(function (model) {
            model.redirectUris.splice(index, 1);
        });
    };
    LoginPageFormComponent.prototype.addGrantType = function () {
        this.formBinder.requestModelUpdate(function (model) {
            model.grantTypes.push('');
        });
    };
    LoginPageFormComponent.prototype.removeGrantType = function (index) {
        this.formBinder.requestModelUpdate(function (model) {
            model.grantTypes.splice(index, 1);
        });
    };
    LoginPageFormComponent.prototype.createOrFetchEntity = function () {
        if (!this.identifier) {
            return this.api.createFakeResponse(new application_entity_1.ApplicationEntity());
        }
        return this.api
            .get(application_entity_1.ApplicationEntity, 'get_internal_html')
            .item(this.identifier)
            .execute();
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], LoginPageFormComponent.prototype, "identifier", void 0);
    LoginPageFormComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./login-page-form.component.html')
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], LoginPageFormComponent);
    return LoginPageFormComponent;
}(vue_1.default));
exports.LoginPageFormComponent = LoginPageFormComponent;
vue_app_1.VueApp.RegisterComponent('application-login-page-form', LoginPageFormComponent);
