"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
require("moment-timezone/builds/moment-timezone-with-data-10-year-range");
var container_1 = require("webeak-native/inversify/container");
var app_1 = require("essentials/app");
var shared_configuration_1 = require("essentials/config/shared-configuration");
container_1.Container.getContainer().get(app_1.AppSymbol).registerInitializer(function () {
    var appTimezone = container_1.Container.getContainer().get(shared_configuration_1.SharedConfigurationSymbol).timezone;
    moment.tz.setDefault(appTimezone);
});
