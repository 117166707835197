"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var container_1 = require("webeak-native/inversify/container");
var api_get_item_request_builder_1 = require("essentials/api/request-builder/api-get-item-request-builder");
var api_get_collection_request_builder_1 = require("essentials/api/request-builder/api-get-collection-request-builder");
/**
 * Offers an easy way to create a GET request to the Api from the server side.
 */
var ApiGetRequestBuilder = /** @class */ (function () {
    function ApiGetRequestBuilder(entityType, actionName) {
        this.entityType = entityType;
        this.actionName = actionName;
    }
    /**
     * Sets the builder to create the request for a specific item.
     */
    ApiGetRequestBuilder.prototype.item = function (identifier) {
        if (identifier === void 0) { identifier = ''; }
        var builder = container_1.Container.getContainer().get(api_get_item_request_builder_1.ApiGetItemRequestBuilderSymbol);
        builder.entity(this.entityType);
        builder.action(this.actionName);
        builder.identifier(identifier);
        return builder;
    };
    /**
     * Sets the builder to create the request for a collection of items.
     */
    ApiGetRequestBuilder.prototype.collection = function () {
        var builder = container_1.Container.getContainer().get(api_get_collection_request_builder_1.ApiGetCollectionRequestBuilderSymbol);
        builder.entity(this.entityType);
        builder.action(this.actionName);
        return builder;
    };
    return ApiGetRequestBuilder;
}());
exports.ApiGetRequestBuilder = ApiGetRequestBuilder;
