"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toolbar_group_builder_1 = require("essentials/froala/config-builder/toolbar-group.builder");
var error_1 = require("essentials/error");
var utils_1 = require("webeak-native/util/utils");
var ToolbarBuilder = /** @class */ (function () {
    function ToolbarBuilder(parent) {
        this.parent = parent;
        this.groupsBuilders = [];
        this.implicitGroupBuilder = null;
    }
    /**
     * Add a button to the toolbar (incompatible with toolbar groups).
     */
    ToolbarBuilder.prototype.addButton = function (button) {
        if (this.groupsBuilders.length > 0) {
            throw new error_1.AppError('You cannot mix buttons with and without groups.');
        }
        if (this.implicitGroupBuilder === null) {
            this.implicitGroupBuilder = new toolbar_group_builder_1.ToolbarGroupBuilder('implicit', this);
        }
        this.implicitGroupBuilder.addButton(button);
        return this;
    };
    /**
     * Starts the configuration of a group.
     */
    ToolbarBuilder.prototype.group = function (name) {
        if (this.implicitGroupBuilder !== null) {
            throw new error_1.AppError('You cannot mix buttons with and without groups.');
        }
        var builder = new toolbar_group_builder_1.ToolbarGroupBuilder(name, this);
        this.groupsBuilders.push(builder);
        return builder;
    };
    /**
     * @inheritDoc
     */
    ToolbarBuilder.prototype.applyConfig = function (localConfig, globalConfig) {
        if (this.groupsBuilders.length > 0) {
            globalConfig.toolbarButtons = {};
            for (var _i = 0, _a = this.groupsBuilders; _i < _a.length; _i++) {
                var groupBuilder = _a[_i];
                var groupName = groupBuilder.getName();
                globalConfig.toolbarButtons[groupName] = {};
                groupBuilder.applyConfig(globalConfig.toolbarButtons[groupName], globalConfig);
            }
        }
        else {
            var tempConf = {};
            this.implicitGroupBuilder.applyConfig(tempConf, globalConfig);
            if (utils_1.isArray(tempConf.buttons)) {
                globalConfig.toolbarButtons = tempConf.buttons;
            }
        }
    };
    /**
     * @inheritDoc
     */
    ToolbarBuilder.prototype.end = function () {
        return this.parent;
    };
    return ToolbarBuilder;
}());
exports.ToolbarBuilder = ToolbarBuilder;
