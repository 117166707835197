"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var PanelLoaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PanelLoaderComponent, _super);
    function PanelLoaderComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PanelLoaderComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./panel-loader.component.html'),
        })
    ], PanelLoaderComponent);
    return PanelLoaderComponent;
}(vue_1.default));
exports.PanelLoaderComponent = PanelLoaderComponent;
vue_1.default.component('panel-loader', PanelLoaderComponent);
