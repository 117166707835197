"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//
// Shortcut for importing all VueJS components.
//
require("./button/button.component");
require("./collapsible-panel/collapsible-panel.component");
require("./form");
require("./list");
require("./misc");
require("./remote-action-button/remote-action-button.component");
require("./tree/tree.component");
