"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("essentials-root/vendor/froala/js/plugins/colors");
require("essentials-root/vendor/froala/css/plugins/colors.css");
var object_1 = require("webeak-native/util/object");
exports.Colors = function (options) {
    if (options === void 0) { options = {}; }
    return {
        name: ['textColor', 'backgroundColor'],
        options: object_1.extend({
            /**
             * An array of colors used in the colors popup for background.
             * Passing REMOVE as a value in the array will display the Clear Formatting button for colors.
             *
             * Default:
             * [
             *    '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
             *    '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
             *    '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
             *    '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'
             * ]
             */
            colorsBackground: undefined,
            /**
             * Set the buttons the colors popup.
             *
             * Default: ["colorsBack", "|", "-"]
             */
            colorsButtons: undefined,
            /**
             * Show HEX input to choose custom color.
             *
             * Default: true
             */
            colorsHEXInput: true,
            /**
             * The number of colors displayed on a line in the colors popup.
             *
             * Default: 7
             */
            colorsStep: 7,
            /**
             * An array of colors used in the colors popup for text.
             * Passing REMOVE as a value in the array will display the Clear Formatting button for colors.
             *
             * Default:
             * [
             *    '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
             *    '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
             *    '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
             *    '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'
             * ]
             */
            colorsText: undefined
        }, options)
    };
};
