"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var abstract_form_control_1 = require("essentials/form/abstract-form-control");
var AbstractFormControlCollection = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractFormControlCollection, _super);
    function AbstractFormControlCollection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get a control by a string path.
     * A path can be composed of any number of field names, separated by "." for form groups, and "." OR "[n]" for form arrays.
     *
     * Example:
     *   tags[1].label
     *
     * Here:
     *   - "tags" reference the FormArray named "tags" in a FormGroup
     *   - "[1]" reference the second element of the FormArray (which is a FormGroup)
     *   - "label" reference the FormControl "label" in the FormGroup
     */
    AbstractFormControlCollection.prototype.getForPath = function (path) {
        var parts = path.match(/(?:\[([0-9]+)\])|([a-z](?:\w|-)+)/ig);
        var control = this;
        for (var _i = 0, parts_1 = parts; _i < parts_1.length; _i++) {
            var part = parts_1[_i];
            if (!part) {
                continue;
            }
            if (part[0] === '[') {
                part = part.substring(1, part.length - 2);
            }
            if (!(control instanceof AbstractFormControlCollection) || !control.has(part)) {
                return null;
            }
            control = control.get(part);
        }
        return control;
    };
    return AbstractFormControlCollection;
}(abstract_form_control_1.AbstractFormControl));
exports.AbstractFormControlCollection = AbstractFormControlCollection;
