"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var api_resource_decorator_1 = require("essentials/api/decorators/api-resource.decorator");
var form_component_decorator_1 = require("essentials/form/decorators/form-component.decorator");
var abstract_entity_1 = require("essentials/entity/abstract.entity");
var not_blank_1 = require("essentials/validation/types/not-blank");
var assert_decorator_1 = require("essentials/validation/decorators/assert.decorator");
var collection_1 = require("essentials/model/transformer/types/collection");
var form_array_1 = require("essentials/form/transformers/form-array");
var choice_1 = require("essentials/validation/types/choice");
var foreach_1 = require("essentials/validation/types/foreach");
var and_1 = require("essentials/validation/types/and");
var model_1 = require("essentials/model/transformer/types/model");
var application_module_entity_1 = require("backend/entity/application-module.entity");
var form_group_1 = require("essentials/form/transformers/form-group");
var min_1 = require("essentials/validation/types/min");
var max_1 = require("essentials/validation/types/max");
var schema_1 = require("essentials/validation/types/schema");
var raw_1 = require("essentials/model/transformer/types/raw");
var object_to_yaml_1 = require("essentials/model/transformer/types/object-to-yaml");
var url_1 = require("essentials/validation/types/url");
var ApplicationEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationEntity, _super);
    function ApplicationEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = '';
        _this.description = '';
        _this.secret = '';
        _this.redirectUris = [];
        _this.homeUrl = null;
        _this.invalidateTokenUrl = null;
        _this.accountCreationUrl = null;
        _this.grantTypes = [];
        _this.loginHtml = '';
        _this.passwordForgottenStep1Html = '';
        _this.passwordForgottenStep2Html = '';
        _this.accountCreationEmailHtml = '';
        _this.accountValidationEmailHtml = '';
        _this.accountSuspensionEmailHtml = '';
        _this.accountActivationEmailHtml = '';
        _this.passwordForgottenEmailHtml = '';
        _this.passwordModifiedEmailHtml = '';
        _this.active = null;
        _this.delegatedRights = null;
        _this.rolesHierarchy = null;
        _this.modules = [];
        return _this;
    }
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(not_blank_1.NotBlank(), max_1.Max(255))),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(max_1.Max(512)),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "description", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "secret", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(min_1.Min(1), foreach_1.Foreach(and_1.And(not_blank_1.NotBlank(), url_1.Url())))),
        form_component_decorator_1.FormComponent(form_array_1.FormArray()),
        transformable_decorator_1.Transformable(collection_1.Collection(primitive_1.Primitive())),
        tslib_1.__metadata("design:type", Array)
    ], ApplicationEntity.prototype, "redirectUris", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(not_blank_1.NotBlank(), max_1.Max(255), url_1.Url())),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "homeUrl", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(max_1.Max(255), url_1.Url())),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "invalidateTokenUrl", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(max_1.Max(255), url_1.Url())),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "accountCreationUrl", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(min_1.Min(1), foreach_1.Foreach(choice_1.Choice('password', 'authcode')))),
        form_component_decorator_1.FormComponent(form_array_1.FormArray()),
        transformable_decorator_1.Transformable(collection_1.Collection(primitive_1.Primitive())),
        tslib_1.__metadata("design:type", Array)
    ], ApplicationEntity.prototype, "grantTypes", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "loginHtml", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "passwordForgottenStep1Html", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "passwordForgottenStep2Html", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "accountCreationEmailHtml", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "accountValidationEmailHtml", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "accountSuspensionEmailHtml", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "accountActivationEmailHtml", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "passwordForgottenEmailHtml", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationEntity.prototype, "passwordModifiedEmailHtml", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive('boolean')),
        tslib_1.__metadata("design:type", Boolean)
    ], ApplicationEntity.prototype, "active", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive('boolean')),
        tslib_1.__metadata("design:type", Boolean)
    ], ApplicationEntity.prototype, "delegatedRights", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(object_to_yaml_1.ObjectToYaml()),
        transformable_decorator_1.Transformable(raw_1.Raw()),
        tslib_1.__metadata("design:type", Object)
    ], ApplicationEntity.prototype, "rolesHierarchy", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(foreach_1.Foreach(schema_1.Schema(application_module_entity_1.ApplicationModuleEntity))),
        form_component_decorator_1.FormComponent(form_array_1.FormArray(form_group_1.FormGroup('modules', 'ApplicationModuleEntity'))),
        transformable_decorator_1.Transformable(collection_1.Collection(model_1.Model('ApplicationModuleEntity'))),
        tslib_1.__metadata("design:type", Array)
    ], ApplicationEntity.prototype, "modules", void 0);
    ApplicationEntity = tslib_1.__decorate([
        api_resource_decorator_1.ApiResource('QQQQ7M')
    ], ApplicationEntity);
    return ApplicationEntity;
}(abstract_entity_1.AbstractEntity));
exports.ApplicationEntity = ApplicationEntity;
