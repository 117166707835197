"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_error_1 = require("./http.error");
/**
 * Error thrown when rejecting a promise associated with a canceled request.
 */
var CancelError = /** @class */ (function (_super) {
    tslib_1.__extends(CancelError, _super);
    function CancelError() {
        return _super.call(this, 0) || this;
    }
    return CancelError;
}(http_error_1.HttpError));
exports.CancelError = CancelError;
