"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
require("essentials/vuejs/directives/click-outside");
var ButtonComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ButtonComponent, _super);
    function ButtonComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Public vars
        _this.dropdownVisible = false;
        return _this;
    }
    Object.defineProperty(ButtonComponent.prototype, "isDisabled", {
        // Computed
        get: function () {
            return this.loading || this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonComponent.prototype, "dropdownClasses", {
        get: function () {
            var obj = { show: this.dropdownVisible };
            obj['drop' + this.direction] = true;
            return obj;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Inverse the visibility of the dropdown.
     */
    ButtonComponent.prototype.toggleDropdown = function () {
        this.dropdownVisible = !this.dropdownVisible;
    };
    /**
     * Hide the dropdown.
     */
    ButtonComponent.prototype.hideDropdown = function () {
        this.dropdownVisible = false;
    };
    /**
     * Test if a slot is defined and contains something.
     *
     * \!/ IMPORTANT
     *
     * I don't use a computed property here because I want the ability to add / remove the dropdown
     * dynamically and slots are NOT reactive.
     *
     * So if a computed property is used to test the existence of the slot, it will never recompute.
     */
    ButtonComponent.prototype.hasSlot = function (name) {
        return (utils_1.isObject(this.$slots) && utils_1.isArray(this.$slots[name])) ||
            (utils_1.isObject(this.$scopedSlots) && utils_1.isArray(this.$scopedSlots[name]));
    };
    /**
     * Called when the main action is triggered.
     */
    ButtonComponent.prototype.onClick = function ($event) {
        if (this.href) {
            window.location.href = this.href;
        }
        this.$emit('click', $event);
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], ButtonComponent.prototype, "split", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ButtonComponent.prototype, "href", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], ButtonComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], ButtonComponent.prototype, "loading", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: '' }),
        tslib_1.__metadata("design:type", String)
    ], ButtonComponent.prototype, "classes", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'down' }),
        tslib_1.__metadata("design:type", String)
    ], ButtonComponent.prototype, "direction", void 0);
    ButtonComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./button.component.html'),
        })
    ], ButtonComponent);
    return ButtonComponent;
}(vue_1.default));
exports.ButtonComponent = ButtonComponent;
vue_1.default.component('v-button', ButtonComponent);
