"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var validator_container_1 = require("essentials/validation/validator-container");
var error_1 = require("essentials/error");
function And() {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return {
        validate: function (value, context) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var _i, validators_1, validator, errorsCount, e_1;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _i = 0, validators_1 = validators;
                            _a.label = 1;
                        case 1:
                            if (!(_i < validators_1.length)) return [3 /*break*/, 6];
                            validator = validators_1[_i];
                            errorsCount = Object.keys(context.errors).length;
                            _a.label = 2;
                        case 2:
                            _a.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, validator.validate(value, context)];
                        case 3:
                            _a.sent();
                            if (Object.keys(context.errors).length > errorsCount) {
                                return [2 /*return*/];
                            }
                            return [3 /*break*/, 5];
                        case 4:
                            e_1 = _a.sent();
                            console.error('Validator execution failed. Error: ' + error_1.AppError.create(e_1).getRealError().message);
                            return [3 /*break*/, 5];
                        case 5:
                            _i++;
                            return [3 /*break*/, 1];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
    };
}
exports.And = And;
validator_container_1.ValidatorContainer.register('And', And);
