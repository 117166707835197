"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_metadata_1 = require("essentials/api/metadata/resource.metadata");
var pagination_metadata_1 = require("essentials/api/metadata/pagination.metadata");
var ordering_metadata_1 = require("essentials/api/metadata/ordering.metadata");
var filtering_metadata_1 = require("essentials/api/metadata/filtering.metadata");
var grouping_metadata_1 = require("essentials/api/metadata/grouping.metadata");
var utils_1 = require("webeak-native/util/utils");
var error_1 = require("essentials/error");
var ApiMetadata = /** @class */ (function () {
    function ApiMetadata() {
    }
    /**
     * Create a ApiMetadata instance from the raw output from the server.
     */
    ApiMetadata.createFromServerResponse = function (config, modelsIdentifiersMap) {
        var instance = new ApiMetadata();
        var resourcesWithParent = [];
        instance.resourcesIndexedByPublicId = {};
        instance.resources = new WeakMap();
        for (var identifier in config[0]) {
            if (config[0].hasOwnProperty(identifier)) {
                if (utils_1.isUndefined(modelsIdentifiersMap[identifier])) {
                    console.warn("No entity has been found for identifier " + identifier + ".");
                    continue;
                }
                var resourceMetadata = resource_metadata_1.ResourceMetadata.createFromServerResponse(config[0][identifier], modelsIdentifiersMap[identifier]);
                instance.resources.set(modelsIdentifiersMap[identifier], resourceMetadata);
                if (resourceMetadata.parentIdentifier !== null) {
                    var i = 0;
                    for (; i < resourcesWithParent.length; ++i) {
                        if (resourcesWithParent[i].parentIdentifier === identifier) {
                            resourcesWithParent.splice(i, 0, resourceMetadata);
                            break;
                        }
                    }
                    if (i >= resourcesWithParent.length) {
                        resourcesWithParent.push(resourceMetadata);
                    }
                }
                instance.resourcesIndexedByPublicId[identifier] = resourceMetadata;
            }
        }
        // Now that all metadata have been created, we can resolve references on parents.
        for (var _i = 0, resourcesWithParent_1 = resourcesWithParent; _i < resourcesWithParent_1.length; _i++) {
            var resource = resourcesWithParent_1[_i];
            if (!utils_1.isUndefined(instance.resourcesIndexedByPublicId[resource.parentIdentifier])) {
                resource.setParent(instance.resourcesIndexedByPublicId[resource.parentIdentifier]);
            }
        }
        instance.pagination = pagination_metadata_1.PaginationMetadata.createFromServerResponse(config[1]);
        instance.filtering = filtering_metadata_1.FilteringMetadata.createFromServerResponse(config[2]);
        instance.ordering = ordering_metadata_1.OrderingMetadata.createFromServerResponse(config[3]);
        instance.grouping = grouping_metadata_1.GroupingMetadata.createFromServerResponse(config[4]);
        ApiMetadata.Instance = instance;
        return instance;
    };
    /**
     * Gets the api configuration of an entity.
     */
    ApiMetadata.prototype.getResourceMetadata = function (type) {
        if (utils_1.isFunction(type)) {
            var ctor = type;
            if (!this.resources.has(ctor)) {
                throw new error_1.AppError("No metadata found for entity " + ctor.name + ".");
            }
            return this.resources.get(ctor);
        }
        if (utils_1.isString(type)) {
            if (!utils_1.isNullOrUndefined(this.resourcesIndexedByPublicId[type])) {
                return this.resourcesIndexedByPublicId[type];
            }
            throw new error_1.AppError("No metadata found for public id " + type + ".");
        }
        throw new error_1.AppError("Invalid argument type. Expect a constructor or a string.");
    };
    /**
     * Try to resolve the real type of an entity based on its values.
     */
    ApiMetadata.prototype.getConcreteEntityType = function (knownType, data) {
        return ApiMetadata.Instance.getResourceMetadata(knownType).getConcreteEntityType(data);
    };
    /**
     * Offer an easy access to the instance.
     */
    ApiMetadata.Instance = null;
    return ApiMetadata;
}());
exports.ApiMetadata = ApiMetadata;
