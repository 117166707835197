"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var error_1 = require("essentials/error");
var FileUploadError = /** @class */ (function (_super) {
    tslib_1.__extends(FileUploadError, _super);
    function FileUploadError(type, message, previous, extra) {
        var _this = _super.call(this, message, previous, extra) || this;
        _this.type = type;
        return _this;
    }
    return FileUploadError;
}(error_1.AppError));
exports.FileUploadError = FileUploadError;
