"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var object_1 = require("webeak-native/util/object");
/**
 * Represent an error on a property.
 */
var ValidationError = /** @class */ (function () {
    /**
     * @param {string}      type     type of the error (example: notblank)
     * @param {string|null} message  optional error message (useful for errors set by the server for example)
     * @param {object}      params   optional parameters that will can be used in the message (%varName%).
     */
    function ValidationError(type, message, params) {
        if (message === void 0) { message = null; }
        if (params === void 0) { params = {}; }
        this.type = type;
        this.message = message;
        this.params = params;
        this.originalMessage = message;
        if (message !== null) {
            this.message = object_1.replaceStringVariables(this.message, this.params);
        }
    }
    return ValidationError;
}());
exports.ValidationError = ValidationError;
