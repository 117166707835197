"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.ClickOutside = {
    bind: function (el, binding, vnode) {
        el.__vueClickOutside__ = function (event) {
            if (!el.contains(event.target)) {
                // call method provided in v-click-outside value
                vnode.context[binding.expression](event);
                event.stopPropagation();
            }
        };
        document.body.addEventListener('click', el.__vueClickOutside__);
    },
    componentUpdated: function (el, binding, vnode) {
    },
    unbind: function (el) {
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    }
};
vue_1.default.directive('click-outside', exports.ClickOutside);
