"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var abstract_module_1 = require("essentials/modules/abstract-module");
var utils_1 = require("webeak-native/util/utils");
var Dropdown = /** @class */ (function (_super) {
    tslib_1.__extends(Dropdown, _super);
    function Dropdown() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @inheritDoc
     */
    Dropdown.prototype.doInit = function () {
        this.$link = $('> .dropdown-toggle', this.$element);
        this.$menu = $('> .dropdown-menu', this.$element);
        if (this.$link.length !== 1 || this.$menu.length !== 1) {
            this.$link = null;
            console.error('Invalid markup for dropdown. Expecting a unique <button class="dropdown-toggle"></button> and <div class="dropdown-menu"></div> as children.');
        }
        return _super.prototype.doInit.call(this);
    };
    /**
     * @inheritDoc
     */
    Dropdown.prototype.bind = function () {
        var _this = this;
        if (this.$link === null) {
            return;
        }
        this.$link.on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            _this.toggle();
        });
        $(document).on('click', utils_1.proxy(this.hide, this));
    };
    /**
     * Show / hide the dropdown depending on its current state.
     */
    Dropdown.prototype.toggle = function () {
        if (this.$menu.hasClass('show')) {
            this.hide();
        }
        else {
            this.show();
        }
    };
    /**
     * Show the dropdown.
     */
    Dropdown.prototype.show = function () {
        var offset = this.$element.offset();
        var left = offset.left;
        var top = offset.top;
        var width = this.$menu.width();
        var height = this.$menu.height();
        var docH = $(document).height();
        var docW = $(document).width();
        var fitH = (left + width <= docW);
        var fitV = (top + height <= docH);
        $(document).trigger('click');
        this.$menu.addClass('show');
        if (!fitH) {
            this.$menu.addClass('dropdown-menu-right');
        }
        else {
            this.$menu.removeClass('dropdown-menu-right');
        }
        if (!fitV) {
            this.$element.addClass('dropup');
        }
        else {
            this.$element.removeClass('dropup');
        }
    };
    /**
     * Hide the dropdown.
     */
    Dropdown.prototype.hide = function () {
        this.$menu.removeClass('show');
    };
    Dropdown = tslib_1.__decorate([
        inversify_1.injectable()
    ], Dropdown);
    return Dropdown;
}(abstract_module_1.AbstractModule));
exports.DropdownSymbol = Symbol("Dropdown");
container_1.Container.registerModule(exports.DropdownSymbol, Dropdown);
