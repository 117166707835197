/**
 * Main entry point of the "bootstrap-backend" starter pack.
 * This file is only meant to import other files.
 *
 * You must include all javascript/typescript files you need here.
 * BUT css/scss files must be included in the css/scss files on the project (so you can override variables).
 */

// Vendor
import '../ts/vendor/alertify';
import '../../styles/sass/vendor/froala/main.scss';
import 'popper.js';
import 'tippy.js';
import '../../../../core/styles/sass/vendor/tippyjs/main.scss';

// App
import '../ts/dependencies';
