"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var form_group_1 = require("essentials/form/form-group");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var container_1 = require("webeak-native/inversify/container");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var utils_1 = require("webeak-native/util/utils");
var form_transformation_model_schema_1 = require("essentials/form/form-transformation-model.schema");
var schemas_holder_1 = require("essentials/model/schemas-holder");
var error_1 = require("essentials/error");
var form_array_1 = require("essentials/form/form-array");
var form_control_1 = require("essentials/form/form-control");
var validation_schema_1 = require("essentials/validation/validation.schema");
var FormModelBinder = /** @class */ (function () {
    function FormModelBinder(form, model) {
        this.form = form;
        /**
         * Synchronize the form with the model.
         *
         * You should not call this manually.
         * You should always use "requestModelUpdate()" to get a reference on the model to change it.
         */
        this.digest = utils_1.throttle(utils_1.proxy(this.doDigest, this), FormModelBinder.MIN_DIGEST_INTERVAL, false);
        this.lastSnapshot = {};
        this.digesting = false;
        this.digestInQueue = false;
        this.ready = false;
        this.synchronizing = false;
        this.formChangeEventsQueue = [];
        this.onReadyPromise = null;
        this.onReadyPromiseResolve = null;
        this.transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
        this.initialize(model);
    }
    /**
     * Get the model managed by the binder.
     */
    FormModelBinder.prototype.getModel = function () {
        return this.model;
    };
    /**
     * Update the model managed by the binder.
     */
    FormModelBinder.prototype.setModel = function (model) {
        this.model = model;
        this.digest();
    };
    /**
     * Get the form managed by the binder.
     */
    FormModelBinder.prototype.getForm = function () {
        return this.form;
    };
    /**
     * Request a reference on the model associated with the form
     * to make changes on it.
     *
     * This method do a digest after the callback has ended, thus ensuring changes
     * are propagated to the form.
     *
     * The callback can return a promise if you have async work to do.
     *
     * @return a promise that will be resolved when the digest is done.
     */
    FormModelBinder.prototype.requestModelUpdate = function (cb) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            //
            // The timeout is important to ensure there is no race condition
            // occurring if there is already a digest running.
            //
            // Because of how async generators works, we may have the "requestModelUpdate()" callback called
            // in the middle of a previous digest, which is not what we want.
            //
            window.setTimeout(function () {
                Promise.all([cb(_this.model)]).then(function () {
                    //
                    // Force the digest to be done right now no matter
                    // when the last one occurred.
                    //
                    // This method is used instead of adding a flag to the digest method
                    // so there is no way from the outside of the class to force a digest
                    // because it's an expensive operation that should not be done too often.
                    //
                    _this.doDigest().then(resolve).catch(reject);
                });
            });
        });
    };
    /**
     * Define a callback to call when the binder is initialized.
     */
    FormModelBinder.prototype.onReady = function () {
        var _this = this;
        if (!this.onReadyPromise) {
            this.onReadyPromise = new Promise(function (resolve) {
                _this.onReadyPromiseResolve = resolve;
            });
        }
        return this.onReadyPromise;
    };
    /**
     * Initialize the binder.
     */
    FormModelBinder.prototype.initialize = function (model) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.transformer.cloneModel(model)];
                    case 1:
                        _a.model = _b.sent();
                        this.prepareTransformSchema();
                        this.assignValidationSchema(this.form, this.model);
                        this.listenForChanges();
                        this.digest();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get the schema associated with the model and extract values transformers out of it.
     */
    FormModelBinder.prototype.prepareTransformSchema = function () {
        this.schema = schemas_holder_1.SchemasHolder.GetInstance().get(form_transformation_model_schema_1.FormTransformationModelSchema, this.model.constructor);
        if (!this.schema) {
            var ctorName = this.model.constructor ? this.model.constructor.name : 'Unknown';
            throw new error_1.AppError("No form model schema found for " + ctorName + ". Please ensure that you added at least one @FormComponent() decorator on the entity.");
        }
        this.valueTransformers = this.extractValueTransformers(this.schema);
        this.valueInverseTransformers = this.extractValueTransformers(this.schema, true);
    };
    /**
     * Try to find a validation schema for the model and associate it with the form.
     */
    FormModelBinder.prototype.assignValidationSchema = function (formGroup, model) {
        var schema = schemas_holder_1.SchemasHolder.GetInstance().get(validation_schema_1.ValidationSchema, model.constructor);
        if (this.schema) {
            formGroup.setValidationSchema(schema);
        }
    };
    /**
     * Listen for changes on the form to apply them to the model.
     */
    FormModelBinder.prototype.listenForChanges = function () {
        var _this = this;
        this.form.onValueChange(function (event) {
            if (!_this.ready || _this.digesting) {
                return;
            }
            if (!_this.synchronizing) {
                _this.syncWithFormChange(event);
            }
            else {
                _this.formChangeEventsQueue.push(event);
            }
        });
    };
    /**
     * Synchronize the model to a change event coming from the form.
     */
    FormModelBinder.prototype.syncWithFormChange = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formChangeEventsQueue.push(event);
                return [2 /*return*/, this.flushFormChangeEvents()];
            });
        });
    };
    /**
     * Flush the queue of form change events.
     */
    FormModelBinder.prototype.flushFormChangeEvents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var event;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.formChangeEventsQueue.length) return [3 /*break*/, 3];
                        if (!this.digestInQueue) return [3 /*break*/, 2];
                        this.digestInQueue = false;
                        return [4 /*yield*/, this.doDigest()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                    case 3:
                        if (this.digesting || this.synchronizing) {
                            return [2 /*return*/];
                        }
                        event = this.formChangeEventsQueue.shift();
                        if (!event.stack.length || event.stack[event.stack.length - 1] instanceof form_group_1.FormGroup) {
                            return [2 /*return*/, this.flushFormChangeEvents()];
                        }
                        this.synchronizing = true;
                        return [4 /*yield*/, this.syncModelWithFormCollection(this.model, this.valueInverseTransformers, event.stack.slice(1), this.lastSnapshot)];
                    case 4:
                        _a.sent();
                        this.synchronizing = false;
                        return [2 /*return*/, this.flushFormChangeEvents()];
                }
            });
        });
    };
    /**
     * Do the actual digest work.
     */
    FormModelBinder.prototype.doDigest = function (validate) {
        if (validate === void 0) { validate = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.digesting || this.synchronizing) {
                            this.digestInQueue = true;
                            return [2 /*return*/];
                        }
                        this.digesting = true;
                        _a = this;
                        return [4 /*yield*/, this.syncFormGroupWithModel(this.model, this.form, this.valueTransformers, this.valueInverseTransformers, this.lastSnapshot)];
                    case 1:
                        _a.lastSnapshot = _b.sent();
                        this.digesting = false;
                        if (!this.digestInQueue) return [3 /*break*/, 3];
                        this.digestInQueue = false;
                        return [4 /*yield*/, this.doDigest(false)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        if (!(this.formChangeEventsQueue.length > 0)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.flushFormChangeEvents()];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        this.ready = true;
                        if (this.onReadyPromiseResolve !== null) {
                            this.onReadyPromiseResolve();
                        }
                        if (!validate) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.form.validate(true, true, false)];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    FormModelBinder.prototype.syncModelWithFormCollection = function (model, valuesInverseTransformers, stack, snapshot) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cur, nextTransformer, _a, _b, _c, _d, e_1;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        cur = stack[0];
                        nextTransformer = utils_1.isArray(valuesInverseTransformers) ? valuesInverseTransformers[0] : valuesInverseTransformers[cur.name];
                        if (!(cur instanceof form_group_1.FormGroup)) return [3 /*break*/, 2];
                        if (!utils_1.isObject(snapshot[cur.name])) {
                            snapshot[cur.name] = {};
                        }
                        return [4 /*yield*/, this.syncModelWithFormCollection(model[cur.name], nextTransformer, stack.splice(1), snapshot[cur.name])];
                    case 1: return [2 /*return*/, _e.sent()];
                    case 2:
                        _e.trys.push([2, 5, , 6]);
                        //
                        // TODO: for sub models, find a way to keep the original object instead of creating a new instance using transformPropertyInverse()
                        // because if properties are set and not exposed to the form, they will be lost in the process.
                        // The best would be to only change properties inside model[cur.name] instead of recreating a whole new object.
                        //
                        _a = model;
                        _b = cur.name;
                        return [4 /*yield*/, this.transformer.transformPropertyInverse(cur.name + '', cur, form_transformation_model_schema_1.FormTransformationModelSchema, model.constructor)];
                    case 3:
                        //
                        // TODO: for sub models, find a way to keep the original object instead of creating a new instance using transformPropertyInverse()
                        // because if properties are set and not exposed to the form, they will be lost in the process.
                        // The best would be to only change properties inside model[cur.name] instead of recreating a whole new object.
                        //
                        _a[_b] = _e.sent();
                        _c = snapshot;
                        _d = cur.name;
                        return [4 /*yield*/, this.transformer.transformProperty(cur.name + '', generic_transformation_model_schema_1.GenericTransformationModelSchema, model)];
                    case 4:
                        _c[_d] = _e.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _e.sent();
                        console.error(e_1);
                        model[cur.name] = null;
                        snapshot[cur.name] = null;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Create/update/remove a FormGroup to get it in sync with a model.
     */
    FormModelBinder.prototype.syncFormGroupWithModel = function (model, formGroup, valuesTransformers, valuesInverseTransformers, lastSnapshot) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newSnapshot, _a, _b, _i, propertyName, schema, currentSnapshotValue, _c, _d, subFormGroup, _e, _f, _g, _h, subGroup;
            return tslib_1.__generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        newSnapshot = {};
                        _a = [];
                        for (_b in valuesTransformers)
                            _a.push(_b);
                        _i = 0;
                        _j.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 10];
                        propertyName = _a[_i];
                        if (!valuesTransformers.hasOwnProperty(propertyName)) {
                            return [3 /*break*/, 9];
                        }
                        if (valuesTransformers[propertyName] === null && utils_1.isObject(model[propertyName])) {
                            schema = schemas_holder_1.SchemasHolder.GetInstance().get(form_transformation_model_schema_1.FormTransformationModelSchema, model[propertyName].constructor);
                            if (!schema) {
                                throw new error_1.AppError("No FormTransformationModelSchema found for entity " + model[propertyName].constructor.name + ". \n                        Ensure that you have at least one @FormComponent() decorator declared on the entity.");
                            }
                            valuesTransformers[propertyName] = this.extractValueTransformers(schema);
                            valuesInverseTransformers[propertyName] = this.extractValueTransformers(schema, true);
                        }
                        return [4 /*yield*/, this.transformer.transformProperty(propertyName, generic_transformation_model_schema_1.GenericTransformationModelSchema, model)];
                    case 2:
                        currentSnapshotValue = _j.sent();
                        if (!utils_1.isArray(valuesTransformers[propertyName])) return [3 /*break*/, 4];
                        _c = newSnapshot;
                        _d = propertyName;
                        return [4 /*yield*/, this.syncFormArrayWithModel(model, model[propertyName], propertyName, formGroup, valuesTransformers[propertyName], valuesInverseTransformers[propertyName], currentSnapshotValue, lastSnapshot[propertyName] || [])];
                    case 3:
                        _c[_d] = _j.sent();
                        return [3 /*break*/, 9];
                    case 4:
                        if (!(utils_1.isObject(valuesTransformers[propertyName]) && utils_1.isObject(model[propertyName]))) return [3 /*break*/, 6];
                        subFormGroup = formGroup.get(propertyName);
                        if (!(subFormGroup instanceof form_group_1.FormGroup)) {
                            subFormGroup = this.createFormControl(2 /* Group */, propertyName, formGroup);
                            this.assignValidationSchema(subFormGroup, model[propertyName]);
                        }
                        _e = newSnapshot;
                        _f = propertyName;
                        return [4 /*yield*/, this.syncFormGroupWithModel(model[propertyName], subFormGroup, valuesTransformers[propertyName], valuesInverseTransformers[propertyName], lastSnapshot[propertyName] || {})];
                    case 5:
                        _e[_f] = _j.sent();
                        return [3 /*break*/, 9];
                    case 6:
                        if (!utils_1.isFunction(valuesTransformers[propertyName])) return [3 /*break*/, 8];
                        _g = newSnapshot;
                        _h = propertyName;
                        return [4 /*yield*/, this.syncFormControlWithModel(model[propertyName], propertyName, formGroup, valuesTransformers[propertyName], currentSnapshotValue, lastSnapshot[propertyName])];
                    case 7:
                        _g[_h] = _j.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        if (valuesTransformers[propertyName] === null && !formGroup.has(propertyName) && utils_1.isObject(model[propertyName])) {
                            newSnapshot[propertyName] = null;
                            subGroup = this.createFormControl(2 /* Group */, propertyName, formGroup);
                            formGroup.add(subGroup);
                        }
                        _j.label = 9;
                    case 9:
                        _i++;
                        return [3 /*break*/, 1];
                    case 10: return [2 /*return*/, newSnapshot];
                }
            });
        });
    };
    /**
     * Create/update/remove a FormArray to get it in sync with a model's property.
     */
    FormModelBinder.prototype.syncFormArrayWithModel = function (model, modelValue, propertyName, formCollection, valueTransformer, valueInverseTransformer, currentSnapshotValue, lastSnapshotValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newSnapshot, control, i, _a, _b, i, _c, _d, schema, i, formGroup, _e, _f, children;
            return tslib_1.__generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        newSnapshot = [];
                        if (!utils_1.isArray(modelValue)) {
                            if (formCollection.has(propertyName)) {
                                this.recycleFormControlCollection(formCollection.get(propertyName));
                            }
                            return [2 /*return*/, null];
                        }
                        control = formCollection.get(propertyName);
                        if (!control || !(control instanceof form_array_1.FormArray)) {
                            control = this.createFormControl(1 /* Array */, propertyName, formCollection);
                        }
                        if (!utils_1.isFunction(valueTransformer[0])) return [3 /*break*/, 5];
                        i = 0;
                        _g.label = 1;
                    case 1:
                        if (!(i < modelValue.length)) return [3 /*break*/, 4];
                        _b = (_a = newSnapshot).push;
                        return [4 /*yield*/, this.syncFormControlWithModel(modelValue[i], i + '', control, valueTransformer[0], currentSnapshotValue[i], utils_1.isArray(lastSnapshotValue) ? lastSnapshotValue[i] : null)];
                    case 2:
                        _b.apply(_a, [_g.sent()]);
                        _g.label = 3;
                    case 3:
                        ++i;
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 14];
                    case 5:
                        if (!utils_1.isArray(valueTransformer[0])) return [3 /*break*/, 10];
                        i = 0;
                        _g.label = 6;
                    case 6:
                        if (!(i < modelValue.length)) return [3 /*break*/, 9];
                        _d = (_c = newSnapshot).push;
                        return [4 /*yield*/, this.syncFormArrayWithModel(model, modelValue[i], i + '', control, valueTransformer[0], valueInverseTransformer[0], currentSnapshotValue[i], utils_1.isArray(lastSnapshotValue) ? lastSnapshotValue[i] : null)];
                    case 7:
                        _d.apply(_c, [_g.sent()]);
                        _g.label = 8;
                    case 8:
                        ++i;
                        return [3 /*break*/, 6];
                    case 9: return [3 /*break*/, 14];
                    case 10:
                        if (!(valueTransformer[0] === null || utils_1.isObject(valueTransformer[0]))) return [3 /*break*/, 14];
                        if (valueTransformer[0] === null && modelValue.length && utils_1.isObject(modelValue[0])) {
                            schema = schemas_holder_1.SchemasHolder.GetInstance().get(form_transformation_model_schema_1.FormTransformationModelSchema, modelValue[0].constructor);
                            if (!schema) {
                                throw new error_1.AppError("No FormTransformationModelSchema found for entity " + modelValue[0].constructor.name + ". \n                        Ensure that you have at least one @FormComponent() decorator declared on the entity.");
                            }
                            valueTransformer[0] = this.extractValueTransformers(schema);
                            valueInverseTransformer[0] = this.extractValueTransformers(schema, true);
                        }
                        i = 0;
                        _g.label = 11;
                    case 11:
                        if (!(i < modelValue.length)) return [3 /*break*/, 14];
                        formGroup = control.get(i);
                        if (!formGroup) {
                            formGroup = this.createFormControl(2 /* Group */, i + '', control);
                            this.assignValidationSchema(formGroup, modelValue[i]);
                        }
                        _f = (_e = newSnapshot).push;
                        return [4 /*yield*/, this.syncFormGroupWithModel(modelValue[i], formGroup, valueTransformer[0], valueInverseTransformer[0], utils_1.isArray(lastSnapshotValue) && utils_1.isObject(lastSnapshotValue[i]) ? lastSnapshotValue[i] : {})];
                    case 12:
                        _f.apply(_e, [_g.sent()]);
                        _g.label = 13;
                    case 13:
                        ++i;
                        return [3 /*break*/, 11];
                    case 14:
                        while (control.controls.length > modelValue.length) {
                            children = control.controls;
                            this.recycleFormControl(children[children.length - 1]);
                        }
                        return [2 /*return*/, newSnapshot];
                }
            });
        });
    };
    /**
     * Create/update/remove a FormControl to synchronize it with a model property.
     */
    FormModelBinder.prototype.syncFormControlWithModel = function (modelValue, propertyName, parent, valueTransformer, currentSnapshotValue, lastSnapshotValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var control, formValue;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (utils_1.isUndefined(modelValue)) {
                            if (parent.has(propertyName)) {
                                this.recycleFormControl(parent.get(propertyName));
                            }
                            return [2 /*return*/, null];
                        }
                        if (!!utils_1.areEqual(currentSnapshotValue, lastSnapshotValue)) return [3 /*break*/, 2];
                        control = parent.get(propertyName);
                        if (!control) {
                            control = this.createFormControl(0 /* Control */, propertyName, parent);
                        }
                        return [4 /*yield*/, valueTransformer(modelValue)];
                    case 1:
                        formValue = _a.sent();
                        //
                        // The value of the field doesn't have to be validated because it's a new FormControl.
                        // We have to wait for the user to touch the field before displaying any validation error.
                        //
                        // We have to set emitEvent to true so the form control view can be updated.
                        // Otherwise the html will never know the value of the control has changed.
                        //
                        control.setValue(formValue, true, false, true);
                        parent.updateValue();
                        // This part is not relevant because the "setValue" method on both FormGroup and FormArray doesn't actually
                        // validate the collection. It only passe the flag to child controls which is exactly want we want to avoid.
                        // TODO: Check if the change didn't break anything
                        //
                        // Here we want to validate the parent because its value (the collection) changed
                        // because we added a new control to it.
                        //
                        // parent.setValue(parent.value, false, true, false);
                        parent.setValue(parent.value, false, false, true);
                        _a.label = 2;
                    case 2: return [2 /*return*/, currentSnapshotValue];
                }
            });
        });
    };
    /**
     * Create a form control or use a recycled one if available.
     */
    FormModelBinder.prototype.createFormControl = function (type, name, parent) {
        var control;
        if (type === 0 /* Control */) {
            control = new form_control_1.FormControl();
        }
        else if (type === 1 /* Array */) {
            control = new form_array_1.FormArray();
        }
        else if (type === 2 /* Group */) {
            control = new form_group_1.FormGroup();
        }
        else {
            throw new error_1.AppError("Invalid type of form control " + type + ".");
        }
        //
        // A control created by the binder is ALWAYS virtual.
        // The control MUST be used in order to be visible to the form.
        // For invisible controls, simply use a hidden input.
        //
        control.markAs('virtual', true, false, false);
        control.setName(name);
        // The following line was changed because newly created controls were validated upon creating
        // which is a problem because error messages are shown if the default value of the field doesn't validate.
        // So the "validate" flag has been set to false and the "silent" flag to true.
        // TODO: ensure this doesn't break anything..
        parent.add(control, true, false, true);
        // Old line was:
        // parent.add(control, true, this.ready, !this.ready);
        //
        // We cannot just do:
        // ```parent.add(control, ...)```
        // because VueJS will not see the change.
        //
        // So we need to create a new object and reassign the whole object of controls so the setter can pick up the change.
        //
        // Kind of ugly but I didn't find a way to make it better without altering the inner workings
        // of the forms (that I want to keep as detached as possible from VueJS).
        //
        if (parent instanceof form_group_1.FormGroup) {
            parent.controls = Object.assign({}, parent.controls);
        }
        else if (parent instanceof form_array_1.FormArray) {
            parent.controls = [].concat(parent.controls);
        }
        return control;
    };
    /**
     * Remove a form control from it's parent form collection and add it to the recycled controls list.
     */
    FormModelBinder.prototype.recycleFormControl = function (formControl) {
        var parent = formControl.parent;
        if (!parent) {
            return;
        }
        parent.remove(formControl.name);
        // Reassign the value so the setter is called and VueJS can notice the change.
        // We must create a new object/array because VueJS will not trigger a change if the reference is the same.
        if (parent instanceof form_group_1.FormGroup) {
            parent.controls = Object.assign({}, parent.controls);
        }
        else if (parent instanceof form_array_1.FormArray) {
            parent.controls = [].concat(parent.controls);
        }
    };
    /**
     * Remove a FormArray and all its children from their form and add them in their respective recycled controls lists.
     */
    FormModelBinder.prototype.recycleFormControlCollection = function (collection) {
        var _this = this;
        collection.forEachChild(function (control) {
            if (control instanceof form_control_1.FormControl) {
                _this.recycleFormControl(control);
            }
            else if (control instanceof form_group_1.FormGroup || control instanceof form_array_1.FormArray) {
                _this.recycleFormControlCollection(control);
            }
            else {
                throw new error_1.AppError('Unsupported type of control', null, { control: control });
            }
        });
        collection.parent.remove(collection.name);
    };
    /**
     * Create an object containing the schema's properties and their associated value transformer.
     */
    FormModelBinder.prototype.extractValueTransformers = function (schema, inverse) {
        if (inverse === void 0) { inverse = false; }
        var output = {};
        var transformFnName = inverse ? 'transformInverse' : 'transform';
        for (var key in schema.properties) {
            if (!schema.properties.hasOwnProperty(key)) {
                continue;
            }
            var propertyTransformer = schema.properties[key];
            var valueTransformer = null;
            var innerValueTransformer = null;
            do {
                var currentValue = null;
                if (propertyTransformer.type === 'form-control') {
                    currentValue = propertyTransformer.getChild()[transformFnName];
                }
                else if (propertyTransformer.type === 'form-array') {
                    currentValue = [];
                }
                else if (propertyTransformer.type === 'form-group') {
                    currentValue = null;
                }
                else {
                    currentValue = propertyTransformer[transformFnName];
                }
                if (utils_1.isArray(innerValueTransformer)) {
                    innerValueTransformer.push(currentValue);
                    innerValueTransformer = innerValueTransformer[0];
                }
                else {
                    valueTransformer = currentValue;
                    innerValueTransformer = valueTransformer;
                }
                if (utils_1.isFunction(valueTransformer)) {
                    break;
                }
                propertyTransformer = propertyTransformer.getChild();
            } while (propertyTransformer);
            output[key] = valueTransformer;
        }
        return output;
    };
    FormModelBinder.MIN_DIGEST_INTERVAL = 500;
    return FormModelBinder;
}());
exports.FormModelBinder = FormModelBinder;
