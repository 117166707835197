"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var abstract_choice_component_1 = require("essentials/vuejs/components/form/abstract-choice.component");
var SelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectComponent, _super);
    function SelectComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SelectComponent.prototype, "hasCustomChoices", {
        // Computed
        get: function () {
            return this.hasSlot('choices');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "hasEmptySlot", {
        get: function () {
            return this.hasSlot('empty');
        },
        enumerable: true,
        configurable: true
    });
    SelectComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./select.component.html'),
        })
    ], SelectComponent);
    return SelectComponent;
}(abstract_choice_component_1.AbstractChoiceComponent));
exports.SelectComponent = SelectComponent;
vue_1.default.component('form-select', SelectComponent);
