"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var pagination_context_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-pagination/pagination-context");
var abstract_context_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/abstract-context");
var utils_1 = require("webeak-native/util/utils");
/**
 * The context contains all the information describing the current state of the list.
 * Like active filters, the current page, the number of elements per page, etc.
 */
var ListContext = /** @class */ (function (_super) {
    tslib_1.__extends(ListContext, _super);
    function ListContext(obj) {
        if (obj === void 0) { obj = {}; }
        var _this = _super.call(this) || this;
        /**
         * List of hidden columns names.
         */
        _this.hiddenColumns = [];
        Object.assign(_this, obj);
        return _this;
    }
    Object.defineProperty(ListContext.prototype, "pagination", {
        get: function () {
            return this._pagination;
        },
        set: function (pagination) {
            this._pagination = pagination;
            if (pagination) {
                pagination.onChange(utils_1.proxy(this.notifyChange, this));
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Set a search value for a field name.
     */
    ListContext.prototype.setFilter = function (field, value) {
        if (field === '') {
            return;
        }
        this.filters[field] = value;
        this.notifyChange();
    };
    /**
     * Remove a filter from the search.
     */
    ListContext.prototype.removeFilter = function (field) {
        if (!utils_1.isUndefined(this.filters[field])) {
            delete this.filters[field];
        }
        this.notifyChange();
    };
    /**
     * Remove all filters.
     */
    ListContext.prototype.removeAllFilters = function () {
        this.filters = {};
        this.notifyChange();
    };
    /**
     * Replace the whole list of filters.
     */
    ListContext.prototype.replaceFilters = function (filters) {
        var newFilters = {};
        Object.keys(filters).forEach(function (key) {
            if (filters[key] !== '') {
                newFilters[key] = filters[key];
            }
        });
        this.filters = newFilters;
    };
    /**
     * Test if the context contains filters.
     */
    ListContext.prototype.hasFilters = function () {
        return Object.keys(this.filters).length > 0;
    };
    /**
     * Set the field (and optionally the direction) to use to order the results.
     */
    ListContext.prototype.orderBy = function (field, direction) {
        if (direction === void 0) { direction = null; }
        this.set('orderingField', field);
        if (direction !== null) {
            this.setOrderingDirection(direction);
        }
    };
    /**
     * Set the ordering direction.
     */
    ListContext.prototype.setOrderingDirection = function (direction) {
        this.set('orderingDirection', direction);
    };
    /**
     * Set the whole list of hidden columns names.
     */
    ListContext.prototype.setHiddenColumns = function (names) {
        this.set('hiddenColumns', [].concat(names));
    };
    /**
     * Show a column to the user.
     */
    ListContext.prototype.showColumn = function (name) {
        var pos = this.hiddenColumns.indexOf(name);
        if (pos >= 0) {
            this.hiddenColumns.splice(pos, 1);
        }
        this.notifyChange();
    };
    /**
     * Hide a column to the user.
     */
    ListContext.prototype.hideColumn = function (name) {
        if (this.hiddenColumns.indexOf(name) < 0) {
            this.hiddenColumns.push(name);
        }
        this.notifyChange();
    };
    /**
     * Check if a column is visible to the user.
     */
    ListContext.prototype.isColumnVisible = function (name) {
        return this.hiddenColumns.indexOf(name) < 0;
    };
    /**
     * @inheritDoc
     */
    ListContext.prototype.getExportFields = function (remoteOnly) {
        var remoteFields = ['filters', 'orderingField', 'orderingDirection', ['pagination', pagination_context_1.PaginationContext]];
        if (remoteOnly) {
            return remoteFields;
        }
        return remoteFields.concat(['hiddenColumns']);
    };
    return ListContext;
}(abstract_context_1.AbstractContext));
exports.ListContext = ListContext;
