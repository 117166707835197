"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var api_metadata_factory_1 = require("essentials/api/metadata/api-metadata.factory");
var error_1 = require("essentials/error");
var abstract_api_request_builder_1 = require("essentials/api/request-builder/abstract-api-request-builder");
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var api_request_1 = require("essentials/api/api-request");
var constants_1 = require("essentials/network/constants");
/**
 * Offers an easy way to create a GET request to the Api for a collection of entities.
 */
var ApiGetCollectionRequestBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(ApiGetCollectionRequestBuilder, _super);
    function ApiGetCollectionRequestBuilder(metadataFactory) {
        var _this = _super.call(this, metadataFactory) || this;
        _this.isPaginated = false;
        _this.pageNumber = null;
        _this.numberElementsPerPage = null;
        _this.paginationConfigurationErrorsDisabled = false;
        _this.ordering = null;
        _this.grouping = null;
        _this.action('get');
        return _this;
    }
    /**
     * Sets the name of the action to execute.
     */
    ApiGetCollectionRequestBuilder.prototype.action = function (name) {
        this.actionName = name + '_collection';
        return this;
    };
    /**
     * Sets the current page number.
     * Starting at 1.
     */
    ApiGetCollectionRequestBuilder.prototype.page = function (page) {
        return this.paginate(page, this.numberElementsPerPage);
    };
    /**
     * Sets the maximum number of elements to return.
     */
    ApiGetCollectionRequestBuilder.prototype.maxResults = function (count) {
        return this.paginate(this.pageNumber, this.numberElementsPerPage);
    };
    /**
     * Ordering can be defined as a string or an array of strings:
     * 'name_of_the_column_to_filter'
     *   or
     * ['col1', 'col2']
     *
     * but in this case, it will only work if a default direction has been given is the Api's configuration.
     *
     * You can define it as an associative array like so:
     *
     * {'column_to_sort_on': 'asc', 'other': 'desc'}
     */
    ApiGetCollectionRequestBuilder.prototype.orderBy = function (keyOrObject, value) {
        var _a;
        if (utils_1.isString(keyOrObject)) {
            keyOrObject = (_a = {}, _a[keyOrObject] = value, _a);
        }
        this.ordering = object_1.merge(this.ordering || {}, utils_1.ensureObject(keyOrObject));
        return this;
    };
    /**
     * Sets the columns to group the results on.
     *
     * You can define multiple columns by giving an array:
     * ['col1', 'col2']
     */
    ApiGetCollectionRequestBuilder.prototype.groupBy = function (columns) {
        this.grouping = utils_1.ensureArray(columns);
        return this;
    };
    /**
     * Set the request to be a paginated request.
     *
     * Depending of the configuration of the Api, you can either enable/disabled the pagination,
     * choose the current page and/or the number of element per page.
     *
     * - Setting the parameter "$page" to "false" means you want to totally disable the pagination (must be allowed by the configuration).
     * - Setting the parameter "$page" to "null" means you don't want any parameter concerning the pagination to be set.
     * - Setting the parameter "$page" to an integer >= 1 means you want the specified page. Other values are invalid.
     *
     * - Setting the parameter "$numberElementsPerPage" to "null" means you don't want to set this parameter.
     * - Setting the parameter "$numberElementsPerPage" to an integer >= 1 means you want the specified number of element per page.
     *
     * Every case may not be allowed by the Api's configuration, so beware.
     */
    ApiGetCollectionRequestBuilder.prototype.paginate = function (page, nbItemsPerPage) {
        if (page === void 0) { page = null; }
        if (nbItemsPerPage === void 0) { nbItemsPerPage = null; }
        if (page !== false && page !== null && (!utils_1.isValidNumber(page) || page <= 0)) {
            throw new error_1.AppError('Invalid page number. Must be an integer >= 1.');
        }
        if (nbItemsPerPage !== null && (!utils_1.isValidNumber(nbItemsPerPage) || nbItemsPerPage <= 0)) {
            throw new error_1.AppError('Invalid number of elements per page. Must be an integer >= 1.');
        }
        this.pageNumber = page;
        this.numberElementsPerPage = nbItemsPerPage;
        this.isPaginated = true;
        return this;
    };
    /**
     * Silent off errors relative to the configuration of the pagination.
     */
    ApiGetCollectionRequestBuilder.prototype.disablePaginationConfigurationErrors = function () {
        this.paginationConfigurationErrorsDisabled = true;
        return this;
    };
    /**
     * @inheritdoc
     */
    ApiGetCollectionRequestBuilder.prototype.createRequest = function (metadata) {
        var resourceMetadata = metadata.getResourceMetadata(this.entityType);
        var actionMetadata = resourceMetadata.getActionMetadata(this.actionName);
        var queryParameters = object_1.merge({}, this.getPaginationQueryParameters(metadata), this.getFilteringQueryParameters(metadata), this.getOrderingQueryParameters(metadata), this.getGroupingQueryParameters(metadata));
        var request = new api_request_1.ApiRequest(resourceMetadata, actionMetadata, constants_1.HttpMethods.GET, this.generateUri(metadata, this.entityType, this.actionName, true, this.uriReplacements, object_1.merge(this.queryParameters, queryParameters)), {}, {});
        request.isPaginated = this.isPaginated;
        return request;
    };
    /**
     * Analyse the input parameters concerning pagination and returns the corresponding array of
     * query parameters to include in the request.
     */
    ApiGetCollectionRequestBuilder.prototype.getPaginationQueryParameters = function (metadata) {
        var actionMetadata = this.getActionMetadata(metadata);
        var queryParameters = {};
        var options = {};
        var optionsNames = {
            'enabled': 'isEnabled',
            'client_enabled': 'isClientEnabled',
            'enabled_parameter_name': 'enabledParameterName',
            'page_parameter_name': 'pageParameterName',
            'maximum_items_per_page': 'maximumItemsPerPage',
            'items_per_page_parameter_name': 'itemsPerPageParameterName'
        };
        for (var actionOptionName in optionsNames) {
            if (optionsNames.hasOwnProperty(actionOptionName)) {
                options[actionOptionName] = actionMetadata.getOption('pagination.' + actionOptionName, metadata.pagination[optionsNames[actionOptionName]]);
            }
        }
        if (this.pageNumber === false) {
            if (!this.paginationConfigurationErrorsDisabled && !options['client_enabled']) {
                throw new error_1.AppError('You cannot disable the pagination as it is forbidden by the configuration.');
            }
            queryParameters[options['enabled_parameter_name']] = false;
            this.isPaginated = false;
        }
        else if (this.pageNumber !== null) {
            if (!options['enabled']) {
                if (!this.paginationConfigurationErrorsDisabled && !options['client_enabled']) {
                    throw new error_1.AppError('The pagination is disabled and you are not allowed to enable it by the configuration.');
                }
                queryParameters[options['enabled_parameter_name']] = true;
            }
            queryParameters[options['page_parameter_name']] = this.pageNumber;
        }
        if (options['enabled'] && this.numberElementsPerPage !== null) {
            if (!this.paginationConfigurationErrorsDisabled && options['maximum_items_per_page'] && this.numberElementsPerPage > options['maximum_items_per_page']) {
                throw new error_1.AppError("You can't ask for more than \"" + options['maximum_items_per_page'] + "\" elements per page, the configuration prevents it.");
            }
            queryParameters[options['items_per_page_parameter_name']] = this.numberElementsPerPage;
        }
        return queryParameters;
    };
    /**
     * Analyse the input parameters concerning ordering and returns the corresponding array of
     * query parameters to include in the request.
     */
    ApiGetCollectionRequestBuilder.prototype.getOrderingQueryParameters = function (metadata) {
        var queryParameters = {};
        var orderingObj = utils_1.ensureObject(utils_1.isString(this.ordering) ? [this.ordering] : this.ordering);
        for (var key in orderingObj) {
            if (!this.ordering.hasOwnProperty(key)) {
                continue;
            }
            var propertyName = null;
            var direction = null;
            if (utils_1.isValidNumber(key)) {
                propertyName = this.ordering[key];
                direction = metadata.ordering.direction;
            }
            else {
                propertyName = key;
                direction = this.ordering[key];
            }
            if (direction === null) {
                throw new error_1.AppError("You must defined an ordering manually for property \"" + propertyName + "\" as no default has been defined in the configuration.");
            }
            direction = direction.toUpperCase();
            if (['ASC', 'DESC'].indexOf(direction) < 0) {
                throw new error_1.AppError("Invalid direction \"" + direction + "\", \"ASC\" or \"DESC\" expected.");
            }
            var queryParameterName = metadata.ordering.parameterName + '[' + propertyName + ']';
            queryParameters[queryParameterName] = direction;
        }
        return queryParameters;
    };
    /**
     * Transform the input parameter and in a set of query parameters to include in the request.
     */
    ApiGetCollectionRequestBuilder.prototype.getGroupingQueryParameters = function (metadata) {
        var _a;
        if (!metadata.grouping.isEnabled) {
            throw new error_1.AppError('You must enable grouping in the configuration in order to use it.');
        }
        return _a = {}, _a[metadata.grouping.parameterName] = utils_1.ensureArray(this.grouping), _a;
    };
    ApiGetCollectionRequestBuilder = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(api_metadata_factory_1.ApiMetadataFactorySymbol)),
        tslib_1.__metadata("design:paramtypes", [api_metadata_factory_1.ApiMetadataFactory])
    ], ApiGetCollectionRequestBuilder);
    return ApiGetCollectionRequestBuilder;
}(abstract_api_request_builder_1.AbstractApiRequestBuilder));
exports.ApiGetCollectionRequestBuilder = ApiGetCollectionRequestBuilder;
exports.ApiGetCollectionRequestBuilderSymbol = Symbol("ApiGetCollectionRequestBuilder");
container_1.Container.registerModule(exports.ApiGetCollectionRequestBuilderSymbol, ApiGetCollectionRequestBuilder);
