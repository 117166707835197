"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var form_control_1 = require("essentials/form/form-control");
var utils_1 = require("webeak-native/util/utils");
function FormControl(transformer) {
    return {
        type: 'form-control',
        transform: function (value, context) {
            if (utils_1.isUndefined(value)) {
                return null;
            }
            var formControl = new form_control_1.FormControl(transformer.transform(value, context));
            formControl.setName(context.currentPropertyName);
            return formControl;
        },
        transformInverse: function (value, context) {
            return transformer.transformInverse(value.value, context);
        },
        getChild: function () {
            return transformer;
        }
    };
}
exports.FormControl = FormControl;
