"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var abstract_schema_1 = require("../model/abstract-schema");
var utils_1 = require("webeak-native/util/utils");
var ValidationSchema = /** @class */ (function (_super) {
    tslib_1.__extends(ValidationSchema, _super);
    function ValidationSchema(validators) {
        if (validators === void 0) { validators = {}; }
        var _this = _super.call(this) || this;
        _this.properties = Object.assign({}, validators);
        return _this;
    }
    /**
     * @inheritDoc
     */
    ValidationSchema.prototype.merge = function (schema) {
        if (!utils_1.isObject(schema.properties)) {
            return;
        }
        for (var property in schema.properties) {
            if (schema.properties.hasOwnProperty(property)) {
                this.properties[property] = schema.properties[property];
            }
        }
    };
    return ValidationSchema;
}(abstract_schema_1.AbstractSchema));
exports.ValidationSchema = ValidationSchema;
