"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var panel_error_retry_event_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/misc/panel-error/panel-error-retry.event");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var utils_1 = require("webeak-native/util/utils");
var PanelErrorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PanelErrorComponent, _super);
    function PanelErrorComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.retrying = false;
        return _this;
    }
    Object.defineProperty(PanelErrorComponent.prototype, "hasRetryListeners", {
        // Computed
        get: function () {
            return this.$listeners && this.$listeners.retry;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Retry the operation that have failed.
     */
    PanelErrorComponent.prototype.retry = function () {
        var _this = this;
        if (this.retrying) {
            return;
        }
        this.retrying = true;
        var event = new panel_error_retry_event_1.PanelErrorRetryEvent();
        this.$emit('retry', event);
        if (utils_1.isPromiseLike(event.promise)) {
            Promise.all([event.promise]).finally(function () {
                _this.retrying = false;
            });
        }
        else {
            this.retrying = false;
        }
    };
    PanelErrorComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./panel-error.component.html'),
        })
    ], PanelErrorComponent);
    return PanelErrorComponent;
}(vue_1.default));
exports.PanelErrorComponent = PanelErrorComponent;
vue_1.default.component('panel-error', PanelErrorComponent);
