"use strict";
//
// Shortcut for importing all form components.
//
Object.defineProperty(exports, "__esModule", { value: true });
// First import required shared components
require("essentials/vuejs/components/form/generic/generic.component");
require("essentials/vuejs/components/form/file/file.component");
require("essentials/vuejs/components/form/errors/errors.component");
require("essentials/vuejs/components/form/hidden/hidden.component");
// Then import local components.
require("./input/input.component");
require("./checkbox/checkbox.component");
require("./code/code.component");
require("./date/date.component");
require("./dialog/dialog.component");
require("./file/file.component");
require("./froala/froala.component");
require("./radio/radio.component");
require("./select/select.component");
require("./select/select-native.component");
require("./tags/tags.component");
