"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var error_1 = require("essentials/error");
var model_1 = require("essentials/model");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var transformer_service_1 = require("../transformer.service");
/**
 * Proxy to transform a child model.
 */
function Model(type) {
    if (!utils_1.isString(type) && !utils_1.isFunction(type)) {
        var message = 'Invalid type given to Model() transformer.';
        if (utils_1.isUndefined(type)) {
            message += ' You may have a circular dependency. Check that 2 files doesn\'t include each other. ' +
                'You can pass the type as a string to overcome the circular dependency problem.';
        }
        throw new error_1.AppError(message);
    }
    return {
        type: 'model',
        transform: function (value, context) {
            if (!utils_1.isObject(value)) {
                return null;
            }
            var transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
            return transformer.transform(value, context.schema);
        },
        transformInverse: function (value, context) {
            if (utils_1.isNullOrUndefined(value)) {
                return null;
            }
            if (utils_1.isString(type)) {
                type = model_1.SchemasHolder.GetInstance().resolveModelTypeFromString(type);
            }
            var transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
            return transformer.transformInverse(value, context.schema, type);
        },
        getChild: function () {
            return null;
        }
    };
}
exports.Model = Model;
