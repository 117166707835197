"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var abstract_module_1 = require("essentials/modules/abstract-module");
var utils_1 = require("webeak-native/util/utils");
var ClassToggle = /** @class */ (function (_super) {
    tslib_1.__extends(ClassToggle, _super);
    function ClassToggle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClassToggle_1 = ClassToggle;
    /**
     * @inheritDoc
     */
    ClassToggle.prototype.getDefaultOptionName = function () {
        return 'target';
    };
    /**
     * @inheritDoc
     */
    ClassToggle.prototype.getDefaultOptions = function () {
        return {
            // Name of the class to apply.
            class: 'active',
            // Target selector.
            // If null, the class will be applied on the source element.
            //
            // The selector is relative to the source element except if it begins by '!'.
            target: null,
            // Events to listen to on the source element.
            events: "click tap",
            // To control if event.preventDefault() and event.stopPropagation() must be called or not.
            preventDefault: true,
            stopPropagation: true,
            // If multiple modules have the same group, only the target of the module triggered last
            // will have the active class applied.
            group: null
        };
    };
    /**
     * Force the removing of the active class.
     */
    ClassToggle.prototype.removeClass = function () {
        this.getTarget().removeClass(this.getOption('class'));
    };
    /**
     * @inheritDoc
     */
    ClassToggle.prototype.doInit = function () {
        _super.prototype.doInit.call(this);
        var group = this.getOption('group');
        if (group !== null) {
            if (utils_1.isUndefined(ClassToggle_1.GroupsInstancesMap[group])) {
                ClassToggle_1.GroupsInstancesMap[group] = [];
            }
            ClassToggle_1.GroupsInstancesMap[group].push(this);
        }
    };
    /**
     * @inheritDoc
     */
    ClassToggle.prototype.bind = function () {
        var that = this;
        var events = this.getOption("events");
        var group = this.getOption('group');
        var className = that.getOption("class");
        var callback = function (e) {
            var $target = that.getTarget();
            $target.toggleClass(className);
            if (group !== null && $target.hasClass(className)) {
                for (var _i = 0, _a = ClassToggle_1.GroupsInstancesMap[group]; _i < _a.length; _i++) {
                    var instance = _a[_i];
                    if (instance !== this) {
                        instance.removeClass();
                    }
                }
            }
            if (that.getOption("preventDefault")) {
                e.preventDefault();
            }
            if (that.getOption("stopPropagation")) {
                e.stopPropagation();
            }
        };
        _super.prototype.bind.call(this);
        if (events === "hover") {
            this.$element.hover(callback);
        }
        else {
            this.$element.on(events, callback);
        }
    };
    /**
     * Gets the target element to toggle the class on.
     *
     * @return JQuery
     */
    ClassToggle.prototype.getTarget = function () {
        var selector = this.getOption("target");
        if (typeof (selector) === "string" && selector.length > 0) {
            if (selector[0] === "!") {
                return $(selector.substring(1));
            }
            if (selector[0] === ">") {
                var $parent = this.$element.parents(selector.substring(1));
                if ($parent.length) {
                    return $($parent.get(0));
                }
            }
            return $(selector, this.$element);
        }
        return this.$element;
    };
    var ClassToggle_1;
    ClassToggle.GroupsInstancesMap = {};
    ClassToggle = ClassToggle_1 = tslib_1.__decorate([
        inversify_1.injectable()
    ], ClassToggle);
    return ClassToggle;
}(abstract_module_1.AbstractModule));
exports.ClassToggleSymbol = Symbol("ClassToggle");
container_1.Container.registerModule(exports.ClassToggleSymbol, ClassToggle);
