"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
var EllipsisLoaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EllipsisLoaderComponent, _super);
    function EllipsisLoaderComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EllipsisLoaderComponent_1 = EllipsisLoaderComponent;
    Object.defineProperty(EllipsisLoaderComponent.prototype, "classes", {
        // Computed
        get: function () {
            if (this.size !== null) {
                return this.size;
            }
            return {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EllipsisLoaderComponent.prototype, "styles", {
        get: function () {
            if (!this.color) {
                return null;
            }
            if (!utils_1.isUndefined(EllipsisLoaderComponent_1.ColorsPreset[this.color])) {
                return 'background-color: ' + EllipsisLoaderComponent_1.ColorsPreset[this.color];
            }
            return 'background-color: ' + this.color;
        },
        enumerable: true,
        configurable: true
    });
    var EllipsisLoaderComponent_1;
    EllipsisLoaderComponent.ColorsPreset = {
        red: '#c82828',
        green: '#5cb85c',
        blue: '#0098f7',
        gray: '#616161',
        disabled: '#969696',
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], EllipsisLoaderComponent.prototype, "color", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], EllipsisLoaderComponent.prototype, "size", void 0);
    EllipsisLoaderComponent = EllipsisLoaderComponent_1 = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./ellipsis-loader.component.html'),
        })
    ], EllipsisLoaderComponent);
    return EllipsisLoaderComponent;
}(vue_1.default));
exports.EllipsisLoaderComponent = EllipsisLoaderComponent;
vue_1.default.component('ellipsis-loader', EllipsisLoaderComponent);
