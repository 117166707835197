"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var context_event_arg_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/event/context.event-arg");
var event_dispatcher_1 = require("essentials/event/event-dispatcher");
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var utils_2 = require("essentials/utils/utils");
var AbstractContext = /** @class */ (function () {
    function AbstractContext() {
        this.hasImportedData = false;
        /**
         * Because multiple calls to notifyChange() may be done in sequence,
         *
         * the notification is not immediately sent, a timeout is used to ensure the context is fully updated
         * before doing any request.
         *
         * This property hold the timer id to avoid creating multiple timers.
         */
        this.notificationTimerId = null;
        this.eventDispatcher = new event_dispatcher_1.EventDispatcher();
    }
    /**
     * Update a value.
     */
    AbstractContext.prototype.set = function (key, value) {
        if (this[key] !== value) {
            this[key] = value;
            this.notifyChange();
        }
    };
    /**
     * Register a callback that will be called when a change is made on the context.
     * Returns an unregister callback.
     */
    AbstractContext.prototype.onChange = function (cb) {
        return this.eventDispatcher.subscribe('change', function (context) {
            cb(context.context);
        });
    };
    /**
     * Export the context into a POJO.
     */
    AbstractContext.prototype.export = function (remoteOnly) {
        if (remoteOnly === void 0) { remoteOnly = false; }
        var result = {};
        var fields = this.getExportFields(remoteOnly);
        for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
            var key = fields_1[_i];
            if (utils_1.isArray(key)) {
                var subContext = this[key[0]];
                result[key[0]] = ((subContext instanceof AbstractContext) ? subContext.export(remoteOnly) : null);
            }
            else if (utils_1.isArray(this[key])) {
                result[key] = [].concat(this[key]);
            }
            else if (utils_1.isObject(this[key])) {
                result[key] = object_1.extend({}, this[key], true);
            }
            else {
                result[key] = this[key];
            }
        }
        return result;
    };
    /**
     * Export the context into an array.
     */
    AbstractContext.prototype.exportAsArray = function () {
        var result = [];
        var fields = this.getExportFields(false);
        for (var _i = 0, fields_2 = fields; _i < fields_2.length; _i++) {
            var key = fields_2[_i];
            if (utils_1.isArray(key)) {
                var subContext = this[key[0]];
                result.push((subContext instanceof AbstractContext) ? subContext.exportAsArray() : null);
            }
            else if (utils_1.isArray(this[key])) {
                result.push([].concat(this[key]));
            }
            else if (utils_1.isObject(this[key])) {
                result.push(object_1.extend({}, this[key], true));
            }
            else {
                result.push(this[key]);
            }
        }
        return result;
    };
    /**
     * Export the context into an url safe string.
     */
    AbstractContext.prototype.exportAsString = function () {
        return utils_2.base64encodeUrlSafe(JSON.stringify(this.exportAsArray()));
    };
    /**
     * Try to create a ListContext from an array created by "ListContext::exportAsArray()".
     * Will return null if the format is invalid.
     */
    AbstractContext.prototype.importFromArray = function (context) {
        var fields = this.getExportFields(false);
        if (!utils_1.isArray(context) || context.length !== fields.length) {
            return false;
        }
        for (var i = 0; i < fields.length; ++i) {
            if (utils_1.isArray(fields[i])) {
                var ctor = fields[i][1];
                var subContext = new ctor();
                if (!subContext.importFromArray(context[i])) {
                    return false;
                }
                this[fields[i][0]] = subContext;
            }
            else {
                this[fields[i]] = context[i];
            }
        }
        this.hasImportedData = true;
        return true;
    };
    /**
     * Try to create a ListContext object from a string created by "ListContext::exportAsString()".
     * Will return null if the format is invalid.
     */
    AbstractContext.prototype.importFromString = function (context) {
        try {
            if (!context) {
                return false;
            }
            var arr = JSON.parse(utils_2.base64decodeUrlSafe(context));
            if (this.importFromArray(arr)) {
                return true;
            }
        }
        catch (e) {
            console.error(e);
            return false;
        }
        return false;
    };
    /**
     * Notify the listeners that a change has occurred.
     */
    AbstractContext.prototype.notifyChange = function () {
        var _this = this;
        if (this.notificationTimerId !== null) {
            return;
        }
        this.notificationTimerId = window.setTimeout(function () {
            _this.notificationTimerId = null;
            _this.eventDispatcher.dispatch('change', new context_event_arg_1.ContextEventArg(_this));
        });
    };
    return AbstractContext;
}());
exports.AbstractContext = AbstractContext;
