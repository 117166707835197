"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var select_native_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/form/select/select-native.component");
var select_choice_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/form/select/select-choice.component");
var select_preview_choice_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/form/select/select-preview-choice.component");
var utils_1 = require("webeak-native/util/utils");
var string_1 = require("webeak-native/util/string");
var form_control_1 = require("essentials/form/form-control");
var select_choice_form_event_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/form/select/select-choice.form-event");
var SelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectComponent, _super);
    function SelectComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.choicesVisible = false;
        _this.selectedChoices = [];
        _this.implicitSearchBuffer = '';
        _this.isSearching = false;
        _this.searchControl = new form_control_1.FormControl();
        _this.firstSelectedChoice = null;
        _this.dropdownDirection = 'bottom';
        // Logic vars
        _this.implicitSearchBufferTimeout = null;
        _this.suggestedChoice = null;
        _this.lastVisibilityChangeTime = 0;
        _this.isSearchFocused = false;
        _this.lastRemoteSearchText = null;
        _this.choicesVersion = 0;
        _this.lastChoicesVersion = 0;
        _this.visibleChildren = [];
        _this.selectedChildren = [];
        _this.bodyDropdownIntervalId = null;
        /**
         * Update the "selected" flag of each child by comparing it to the current value.
         */
        _this.throttledUpdateSelection = utils_1.throttle(function () {
            //const visibleChildren = this.getVisibleChildren();
            for (var _i = 0, _a = this.visibleChildren; _i < _a.length; _i++) {
                var child = _a[_i];
                var htmlValue = this.formControlToHtmlValue(child.realItem.value);
                if (this.multiple) {
                    child.select(utils_1.isArray(this.controlValue) && this.controlValue.indexOf(htmlValue) >= 0, true);
                }
                else {
                    child.select(htmlValue === this.controlValue, true);
                }
                child.suggest(false, true);
            }
            this.selectedChildren = this.getSelectedChildren();
            // this.foreachChild((child: SelectChoiceComponent) => {
            //     const htmlValue: any = this.formControlToHtmlValue(child.realItem.value);
            //     if (this.multiple) {
            //         child.select(isArray(this.controlValue) && this.controlValue.indexOf(htmlValue) >= 0, true);
            //     } else {
            //         child.select(htmlValue === this.controlValue, true);
            //     }
            //     child.suggest(false, true);
            // });
        }, 0);
        return _this;
    }
    // Watches
    SelectComponent.prototype.onValueChanged = function (newVal, oldVal) {
        if (!this.multiple || utils_1.isArray(newVal)) {
            for (var _i = 0, _a = this.visibleChildren; _i < _a.length; _i++) {
                var child = _a[_i];
                if (this.multiple) {
                    if (newVal.indexOf(child.realItem.value) >= 0) {
                        this.selectChoice(child, false);
                    }
                }
                else {
                    if (child.realItem.value === newVal) {
                        this.selectChoice(child, false);
                        break;
                    }
                }
            }
        }
        // Equivalent to super.onValueChange()
        // but VueJS doesn't add methods to the constructor so...
        this.constructor.super.options.methods.onValueChanged.apply(this, [newVal, oldVal]);
    };
    SelectComponent.prototype.onOpenedChanged = function (newVal) {
        if (newVal) {
            this.showChoices();
        }
        else {
            this.hideChoices();
        }
    };
    Object.defineProperty(SelectComponent.prototype, "hasCustomChoices", {
        // Computed
        get: function () {
            return this.hasSlot('choices');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "isImplicitSearch", {
        get: function () {
            return this.searchType === 'implicit';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "isPermanentSearch", {
        get: function () {
            return this.searchType === 'local' || this.searchType === 'remote';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "isRemoteSearch", {
        get: function () {
            return this.searchType === 'remote';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "filteredChoices", {
        get: function () {
            var choices = this.normalizedChoices;
            for (var _i = 0, choices_1 = choices; _i < choices_1.length; _i++) {
                var choice = choices_1[_i];
                choice.visible = true;
            }
            ++this.choicesVersion;
            return this.filterNormalizedChoices(choices);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "visibleChoicesCount", {
        get: function () {
            var count = 0;
            for (var _i = 0, _a = this.filteredChoices; _i < _a.length; _i++) {
                var choice = _a[_i];
                if (choice.visible) {
                    ++count;
                }
            }
            if (this.customChoicesVisible) {
                count += this.$children.length;
            }
            return count;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "customChoicesVisible", {
        get: function () {
            return !this.isActiveSearch;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "isActiveSearch", {
        get: function () {
            return this.searchType !== 'implicit' && this.searchControl.value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle method.
     */
    SelectComponent.prototype.updated = function () {
        if (this.choicesVersion !== this.lastChoicesVersion) {
            this.visibleChildren = this.getVisibleChildren();
            this.selectedChildren = this.getSelectedChildren();
            if (!this.multiple) {
                var htmlValue = this.formControlToHtmlValue(this.control.value);
                if (!utils_1.isObject(this.control.value) || htmlValue) {
                    this.onValueChanged(htmlValue, this.controlValue);
                    this.updateSelection();
                }
            }
            this.lastChoicesVersion = this.choicesVersion;
        }
    };
    /**
     * Show/hide the dropdown of choices.
     */
    SelectComponent.prototype.toggleChoices = function () {
        // In case the mousedown is triggered AFTER the focus, in which case the dropdown will appear and disappear immediately.
        // By adding a minimum delay we ensure this never happens.
        if ((new Date()).getTime() - this.lastVisibilityChangeTime < 50) {
            return;
        }
        if (!this.choicesVisible) {
            this.showChoices();
        }
        else {
            this.hideChoices();
        }
    };
    /**
     * Show the dropdown of choices.
     */
    SelectComponent.prototype.showChoices = function () {
        if (this.disabledOrLoading || this.choicesVisible) {
            return;
        }
        if (this.selectedChildren.length > 0) {
            this.scrollTo(this.selectedChildren[0]);
        }
        this.choicesVisible = true;
        this.lastVisibilityChangeTime = (new Date()).getTime();
        if (this.isPermanentSearch) {
            window.setTimeout(utils_1.proxy(this.focusSearchInput, this));
        }
        // Direction always to bottom for the first render with opacity to 0 so we can calculate if it get out of the screen.
        this.dropdownDirection = 'bottom';
        this.$dropdown.css('opacity', 0);
        if (this.bodyDropdown) {
            this.appendDropdownToBody();
        }
        else {
            window.setTimeout(utils_1.proxy(this.updateDropdownDirection, this));
        }
    };
    SelectComponent.prototype.updateDropdownDirection = function () {
        var dropdownHeight = this.$dropdown.outerHeight();
        var offsetTop = this.$input.offset().top + this.$input.outerHeight();
        if (dropdownHeight > 0 && offsetTop + dropdownHeight > $(window).height()) {
            this.dropdownDirection = 'top';
        }
        else {
            this.dropdownDirection = 'bottom';
        }
        this.$dropdown.css('opacity', 1);
    };
    SelectComponent.prototype.appendDropdownToBody = function () {
        $('body').append(this.$refs.dropdown);
        this.$dropdown.css('opacity', 1);
        this.updateBodyDropdown();
        this.bodyDropdownIntervalId = window.setInterval(utils_1.proxy(this.updateBodyDropdown, this), this.bodyDropdownUpdateInterval);
    };
    SelectComponent.prototype.revertBodyDropdown = function () {
        this.$dropdownWrapper.append(this.$refs.dropdown);
        window.clearInterval(this.bodyDropdownIntervalId);
        this.bodyDropdownIntervalId = null;
    };
    SelectComponent.prototype.updateBodyDropdown = function () {
        var inputOffset = this.$input.offset();
        this.updateDropdownDirection();
        if (this.dropdownDirection === 'bottom') {
            this.$dropdown.css({
                'top': (inputOffset.top + this.$input.outerHeight() - 1) + 'px',
                'left': inputOffset.left + 'px',
                'width': this.$input.outerWidth() + 'px'
            });
        }
        else {
            this.$dropdown.css({
                'top': (inputOffset.top + 1) + 'px',
                'left': inputOffset.left + 'px',
                'width': this.$input.outerWidth() + 'px'
            });
        }
    };
    /**
     * Hide the dropdown of choices.
     */
    SelectComponent.prototype.hideChoices = function () {
        this.setSuggestedChoice(null);
        this.choicesVisible = false;
        this.lastVisibilityChangeTime = (new Date()).getTime();
        if (this.bodyDropdownIntervalId !== null) {
            this.revertBodyDropdown();
        }
    };
    /**
     * Ensure the focus on the search input is set before the focus is lost on the main component
     * to avoid closing the dropdown when the user tries to enter a search.
     */
    SelectComponent.prototype.focusSearchInput = function () {
        this.isSearchFocused = true;
        this.$refs.search.focus();
    };
    /**
     * Reset the focus flag of the search input.
     */
    SelectComponent.prototype.unfocusSearchInput = function () {
        this.isSearchFocused = false;
    };
    /**
     * @inheritDoc
     */
    SelectComponent.prototype.onFocus = function () {
        if (this.control.focused) {
            return;
        }
        this.showChoices();
        this.constructor.super.options.methods.onFocus.apply(this);
    };
    /**
     * @inheritDoc
     */
    SelectComponent.prototype.onBlur = function () {
        if (!this.control.focused) {
            return;
        }
        if (!this.isSearchFocused && !this.bodyDropdown) {
            this.hideChoices();
        }
        this.constructor.super.options.methods.onBlur.apply(this);
    };
    /**
     * Called when the search input's value is modified.
     */
    SelectComponent.prototype.throttledOnSearchChange = function () {
        var _this = this;
        if (this.searchType === 'remote') {
            if (this.searchControl.value && this.searchControl.value.length >= this.searchMinLength) {
                this.isSearching = true;
                this.updateChoices([]);
                this.updateRemoteChoices().then(function (result) {
                    if (result === null && !_this.searchControl.value) {
                        _this.cancelCurrentRemoteChoicesUpdate();
                    }
                }).finally(function () {
                    _this.isSearching = false;
                });
            }
            else {
                this.lastRemoteSearchText = null;
                this.updateChoices([]);
            }
        }
    };
    SelectComponent.prototype.onSearchChange = function () {
        this.throttledOnSearchChange.apply(this);
    };
    SelectComponent.prototype.getChoiceComponentByValue = function (value) {
        for (var _i = 0, _a = this.$children; _i < _a.length; _i++) {
            var child = _a[_i];
            if (child instanceof select_choice_component_1.SelectChoiceComponent && child.realItem.value === value) {
                return child;
            }
        }
        return null;
    };
    /**
     * Make a child form-select-choice component as the current selected item.
     */
    SelectComponent.prototype.selectChoice = function (choice, closeDropdown) {
        if (closeDropdown === void 0) { closeDropdown = true; }
        if (!choice.selectable) {
            return;
        }
        this.notifySelection(this.HtmlToFormControlValue(choice.realItem.value), closeDropdown);
        if (closeDropdown) {
            this.hideChoices();
        }
        if (this.multiple) {
            if (this.selectedChoices.indexOf(choice) >= 0) {
                return;
            }
            this.selectedChoices.push(choice);
            if (!utils_1.isArray(this.controlValue)) {
                this.controlValue = [];
            }
            // "choice.realItem.value" is already an Html value but calling formControlToHtmlValue() ensures
            // the correct type is returned (for example: true will be returned as the string "true").
            this.controlValue.push(this.formControlToHtmlValue(choice.realItem.value));
        }
        else {
            if (this.selectedChoices.length > 0 && this.selectedChoices[0] === choice) {
                return;
            }
            this.selectedChoices = [choice];
            // "choice.realItem.value" is already an Html value but calling formControlToHtmlValue() ensures
            // the correct type is returned (for example: true will be returned as the string "true").
            this.controlValue = this.formControlToHtmlValue(choice.realItem.value);
        }
        if (this.firstSelectedChoice === null || this.firstSelectedChoice.realItem.selected) {
            this.firstSelectedChoice = choice;
        }
        if (!this.multiple) {
            this.scrollTo(choice);
            this.setSuggestedChoice(null);
        }
        this.updateSelection();
    };
    /**
     * Remove the selection of a choice.
     */
    SelectComponent.prototype.unselectChoice = function (choice) {
        if (this.multiple) {
            var newValues = [];
            for (var i = 0; i < this.selectedChoices.length; ++i) {
                if (choice === this.selectedChoices[i]) {
                    this.selectedChoices.splice(i--, 1);
                }
                else {
                    newValues.push(this.selectedChoices[i].realItem.value);
                }
            }
            this.controlValue = newValues;
        }
        else {
            this.selectedChoices = [];
        }
        this.updateSelection();
    };
    SelectComponent.prototype.restoreFromPreviousSelection = function (choice) {
        var found = false;
        var target = this.HtmlToFormControlValue(choice.realItem.value);
        for (var i = 0; i < this.selectedChoices.length; ++i) {
            var selectedChoice = this.selectedChoices[i];
            var candidate = this.HtmlToFormControlValue(selectedChoice.realItem.value);
            if (target === candidate) {
                this.selectedChoices.splice(i--, 1);
                found = true;
            }
        }
        if (found) {
            choice.select(true);
        }
    };
    SelectComponent.prototype.clearSearch = function () {
        if (this.searchType === 'implicit') {
            this.implicitSearchBuffer = '';
        }
        else {
            this.searchControl.setValue('');
            window.setTimeout(utils_1.proxy(this.focusSearchInput, this));
        }
    };
    /**
     * Called when a key is pressed with the select on focus.
     */
    SelectComponent.prototype.onKeyDown = function ($event) {
        if ($event.key === ' ' && this.searchType === 'implicit') {
            $event.preventDefault();
        }
        if ($event.key === 'Enter') {
            if (this.suggestedChoice !== null && this.suggestedChoice.realItem.visible) {
                if (!this.suggestedChoice.realItem.selected) {
                    this.selectChoice(this.suggestedChoice, !this.multiple);
                }
                else {
                    this.unselectChoice(this.suggestedChoice);
                }
            }
            else {
                this.notifySelection(this.control.value, true);
                this.hideChoices();
            }
        }
        else if ($event.key === 'ArrowUp') {
            this.moveSelection(-1);
        }
        else if ($event.key === 'ArrowDown') {
            this.moveSelection(1);
        }
        else if ($event.key === 'Escape') {
            this.hideChoices();
        }
        else if ($event.key === 'Backspace' && this.searchType === 'implicit') {
            this.updateSearchBuffer(this.implicitSearchBuffer.substring(0, this.implicitSearchBuffer.length - 1));
        }
        else if ($event.key.length > 1) {
            return;
        }
        else {
            if (this.searchType === 'implicit') {
                this.updateSearchBuffer(this.implicitSearchBuffer + $event.key);
            }
            return;
        }
        $event.stopPropagation();
        $event.preventDefault();
    };
    /**
     * @inheritDoc
     */
    SelectComponent.prototype.doInitialize = function () {
        if (!this.controlValue || this.searchType !== 'remote') {
            return this.constructor.super.options.methods.doInitialize.apply(this);
        }
        this.updateChoices(utils_1.ensureArray(this.controlValue));
        return true;
    };
    /**
     * @inheritDoc
     */
    SelectComponent.prototype.onReady = function () {
        this.constructor.super.options.methods.onReady.apply(this);
        var rawValues = utils_1.ensureArray(this.controlValue);
        var normalizedValues = [];
        for (var _i = 0, rawValues_1 = rawValues; _i < rawValues_1.length; _i++) {
            var rawValue = rawValues_1[_i];
            normalizedValues.push(this.HtmlToFormControlValue(rawValue));
        }
        for (var _a = 0, _b = this.$children; _a < _b.length; _a++) {
            var child = _b[_a];
            if (child instanceof select_choice_component_1.SelectChoiceComponent && normalizedValues.indexOf(this.HtmlToFormControlValue(child.realItem.value)) >= 0) {
                child.select(true);
            }
        }
        this.$input = $(this.$refs.input);
        this.$dropdown = $(this.$refs.dropdown);
        this.$dropdownWrapper = $(this.$refs.dropdownWrapper);
        if (this.opened) {
            this.showChoices();
        }
        // Force update..
        this.normalizedChoices = [].concat(this.normalizedChoices);
    };
    /**
     * @inheritDoc
     */
    SelectComponent.prototype.normalizeChoices = function () {
        ++this.choicesVersion;
        return this.constructor.super.options.methods.normalizeChoices.apply(this);
    };
    /**
     * @inheritDoc
     */
    SelectComponent.prototype.prepareRemoteChoicesRequest = function () {
        if (this.searchType !== 'remote') {
            return this.constructor.super.options.methods.prepareRemoteChoicesRequest.apply(this);
        }
        var search = utils_1.isString(this.searchControl.value) ? string_1.slugify(this.searchControl.value) : '';
        if (search === this.lastRemoteSearchText) {
            return null;
        }
        this.lastRemoteSearchText = search;
        if (search.length < this.searchMinLength) {
            this.updateChoices([]);
            return null;
        }
        var context = this.constructor.super.options.methods.prepareRemoteChoicesRequest.apply(this);
        var paramsNames = utils_1.ensureArray(this.searchRemoteParamName);
        if (!paramsNames.length) {
            console.warn('No filter parameter name defined. Please define at least one filter name using the "search-remote-param-name" property.');
            return null;
        }
        for (var _i = 0, paramsNames_1 = paramsNames; _i < paramsNames_1.length; _i++) {
            var paramName = paramsNames_1[_i];
            context.urlParams[paramName] = search;
        }
        context.silent = true;
        return context;
    };
    /**
     * Filter the list of choices using the local search data.
     * The number of items doesn't change but their visibility flag do.
     */
    SelectComponent.prototype.filterNormalizedChoices = function (choices) {
        if (!this.isPermanentSearch || !this.searchControl.value) {
            return choices;
        }
        var search = string_1.slugify(this.searchControl.value);
        if (!search) {
            return choices;
        }
        for (var _i = 0, choices_2 = choices; _i < choices_2.length; _i++) {
            var choice = choices_2[_i];
            choice.visible = string_1.slugify(choice.label).indexOf(search) >= 0;
        }
        return choices;
    };
    /**
     * Add a string to the implicit search buffer and update the search results.
     */
    SelectComponent.prototype.updateSearchBuffer = function (text) {
        var _this = this;
        this.implicitSearchBuffer = text;
        if (this.implicitSearchBufferTimeout !== null) {
            window.clearTimeout(this.implicitSearchBufferTimeout);
        }
        var slug = string_1.slugify(this.implicitSearchBuffer);
        var slugLength = slug.length;
        for (var _i = 0, _a = this.filteredChoices; _i < _a.length; _i++) {
            var choice = _a[_i];
            if (choice.label && string_1.slugify(choice.label).substring(0, slugLength) === slug) {
                this.setSuggestedChoice(this.getChoiceComponentByValue(choice.value));
                break;
            }
        }
        // this.foreachChild((child: SelectChoiceComponent) => {
        //     if (child.realItem && child.realItem.label && slugify(child.realItem.label).substring(0, slugLength) === slug) {
        //         this.setSuggestedChoice(child);
        //         return false;
        //     }
        // });
        if (this.isImplicitSearch) {
            this.implicitSearchBufferTimeout = window.setTimeout(function () {
                _this.implicitSearchBuffer = '';
            }, 1000);
        }
    };
    SelectComponent.prototype.moveSelection = function (incr) {
        var currentIndex = -incr;
        var targetItem = this.selectedChoices.length > 0 || this.suggestedChoice ? (this.suggestedChoice ? this.suggestedChoice : this.selectedChoices[0]) : null;
        //const visibleChildren = this.getVisibleChildren();
        if (!this.visibleChildren.length) {
            this.setSuggestedChoice(null);
            return;
        }
        for (var i = 0; i < this.visibleChildren.length; ++i) {
            if (targetItem && this.visibleChildren[i].realItem.value === targetItem.realItem.value) {
                currentIndex = i;
                break;
            }
        }
        var newIndex = Math.max(0, Math.min(this.visibleChildren.length - 1, currentIndex + incr));
        this.setSuggestedChoice(this.visibleChildren[newIndex]);
        if (!this.multiple) {
            this.selectChoice(this.visibleChildren[newIndex], false);
        }
    };
    /**
     * Mark a choice as a suggestion (related to the implicit search).
     */
    SelectComponent.prototype.setSuggestedChoice = function (choice) {
        if (this.suggestedChoice !== null) {
            this.suggestedChoice.suggest(false);
            this.suggestedChoice.$forceUpdate();
        }
        this.suggestedChoice = choice;
        if (this.suggestedChoice !== null) {
            choice.suggest();
            this.suggestedChoice.$forceUpdate();
            this.scrollTo(this.suggestedChoice);
        }
    };
    /**
     * Make the dropdown scroll so the given component is in the center of the view.
     */
    SelectComponent.prototype.scrollTo = function (child) {
        var _this = this;
        window.setTimeout(function () {
            var containerEl = _this.$refs.choices;
            var childEl = child.$el;
            containerEl.scrollTop = childEl.offsetTop - (containerEl.offsetHeight / 2) + (childEl.offsetHeight / 2);
        });
    };
    /**
     * Update the "selected" flag of each child by comparing it to the current value.
     */
    SelectComponent.prototype.updateSelection = function () {
        this.throttledUpdateSelection.apply(this);
    };
    // /**
    //  * Returns an array containing every child components currently selected.
    //  */
    // private getSelectedChildren(): SelectChoiceComponent[] {
    //     const selected: SelectChoiceComponent[] = [];
    //     this.foreachChild((child: SelectChoiceComponent) => {
    //         if (child.realItem.selected) {
    //             selected.push(child);
    //         }
    //     });
    //     for (const child of this.visibleChoicesComponents) {
    //         if (child.realItem.selected) {
    //             selected.push(child);
    //         }
    //     }
    //     return selected;
    // }
    /**
     * Iterate through each child choice component.
     */
    // private foreachChild(cb: (c: SelectChoiceComponent, index?: number) => any): void {
    //     for (let i = 0; i < this.$children.length; ++i) {
    //         const child = this.$children[i];
    //         if (child instanceof SelectChoiceComponent && child.realItem.visible) {
    //             if (cb.apply(this, [this.$children[i], i]) === false) {
    //                 break ;
    //             }
    //         }
    //     }
    // }
    SelectComponent.prototype.getVisibleChildren = function () {
        var children = [];
        for (var _i = 0, _a = this.filteredChoices; _i < _a.length; _i++) {
            var choice = _a[_i];
            var child = this.getChoiceComponentByValue(choice.value);
            if (child) {
                children.push(child);
            }
        }
        var slotChildren = [];
        for (var _b = 0, _c = this.$children; _b < _c.length; _b++) {
            var child = _c[_b];
            if (child instanceof select_choice_component_1.SelectChoiceComponent && children.indexOf(child) < 0 && child.realItem.visible) {
                slotChildren.push(child);
            }
        }
        return [].concat(slotChildren, children);
    };
    SelectComponent.prototype.getSelectedChildren = function () {
        var selected = [];
        for (var _i = 0, _a = this.filteredChoices; _i < _a.length; _i++) {
            var choice = _a[_i];
            var child = this.getChoiceComponentByValue(choice.value);
            if (child && child.realItem.selected) {
                selected.push(child);
            }
        }
        return selected;
    };
    SelectComponent.prototype.notifySelection = function (value, dropdownClosed) {
        this.$emit('select', new select_choice_form_event_1.SelectChoiceFormEvent(this.control, value, dropdownClosed));
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: '' }),
        tslib_1.__metadata("design:type", String)
    ], SelectComponent.prototype, "placeholder", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 0 }),
        tslib_1.__metadata("design:type", Number)
    ], SelectComponent.prototype, "tabindex", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'implicit' }),
        tslib_1.__metadata("design:type", String)
    ], SelectComponent.prototype, "searchType", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Array], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], SelectComponent.prototype, "searchRemoteParamName", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 1 }),
        tslib_1.__metadata("design:type", Number)
    ], SelectComponent.prototype, "searchMinLength", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], SelectComponent.prototype, "bodyDropdown", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 400 }),
        tslib_1.__metadata("design:type", Number)
    ], SelectComponent.prototype, "bodyDropdownUpdateInterval", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], SelectComponent.prototype, "opened", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('opened'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Boolean]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SelectComponent.prototype, "onOpenedChanged", null);
    SelectComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./select.component.html'),
            components: {
                'form-select-choice': select_choice_component_1.SelectChoiceComponent,
                'form-select-preview-choice': select_preview_choice_component_1.SelectPreviewChoiceComponent
            }
        })
    ], SelectComponent);
    return SelectComponent;
}(select_native_component_1.SelectNativeComponent));
exports.SelectComponent = SelectComponent;
vue_1.default.component('form-select', SelectComponent);
