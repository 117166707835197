"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var shared_configuration_1 = require("essentials/config/shared-configuration");
exports.SharedConfigurationSymbol = shared_configuration_1.SharedConfigurationSymbol;
var SharedConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(SharedConfiguration, _super);
    function SharedConfiguration() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * VueJS configuration.
         */
        _this.vuejs = {
            forms: {
                datePicker: {
                    locale: 'fr',
                    datePickerTintColor: '#595d6e',
                    firstDayOfTheWeek: 2,
                    popoverVisibility: 'focus'
                },
                froala: {
                    key: 'jUA1yD5B1D2D2G3H2pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2B1B10C2D2E6F1H2=='
                }
            },
            lists: {
                contextStorageKey: '_wb_sp_bb_lc',
                urlFragmentName: 'lc'
            }
        };
        return _this;
    }
    SharedConfiguration.Register = function (obj) {
        shared_configuration_1.SharedConfiguration.Register(obj);
    };
    SharedConfiguration = tslib_1.__decorate([
        inversify_1.injectable()
    ], SharedConfiguration);
    return SharedConfiguration;
}(shared_configuration_1.SharedConfiguration));
exports.SharedConfiguration = SharedConfiguration;
shared_configuration_1.SharedConfiguration.Register(SharedConfiguration);
