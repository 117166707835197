"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    StorageService = tslib_1.__decorate([
        inversify_1.injectable()
    ], StorageService);
    return StorageService;
}());
exports.StorageService = StorageService;
exports.StorageServiceSymbol = Symbol("StorageService");
