"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var router_1 = require("essentials/symfony/router");
var container_1 = require("webeak-native/inversify/container");
var network_1 = require("essentials/network");
var error_1 = require("essentials/error");
var http_response_1 = require("essentials/network/http-response");
var constants_1 = require("essentials/network/constants");
var alertify_service_1 = require("essentials/dialog/alertify/alertify.service");
var object_1 = require("webeak-native/util/object");
var utils_1 = require("webeak-native/util/utils");
var form_utils_1 = require("essentials/form/form-utils");
var form_group_1 = require("essentials/form/form-group");
var RemoteActionButtonComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RemoteActionButtonComponent, _super);
    function RemoteActionButtonComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.httpResponse = null;
        _this.error = null;
        _this.form = new form_group_1.FormGroup();
        _this.formVisible = false;
        _this.resetTimeoutId = null;
        _this.alertify = container_1.Container.getContainer().get(alertify_service_1.AlertifyServiceSymbol);
        _this.http = container_1.Container.getContainer().get(network_1.HttpServiceSymbol);
        _this.router = container_1.Container.getContainer().get(router_1.RouterSymbol);
        return _this;
    }
    Object.defineProperty(RemoteActionButtonComponent.prototype, "currentType", {
        // Computed
        get: function () {
            if (this.httpResponse) {
                if (this.httpResponse.isError) {
                    return 'danger';
                }
                if (this.httpResponse.isSuccess) {
                    return 'success';
                }
            }
            return this.type;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Execute the action.
     */
    RemoteActionButtonComponent.prototype.execute = function () {
        var _this = this;
        try {
            var method = this.method.toLowerCase();
            var args = [this.buildTargetUrl(this.form.value)];
            if (this.method === 'post' && this.data !== null) {
                args.push(this.data);
            }
            var urlReplacements = object_1.extend({}, utils_1.ensureObject(this.form.value), utils_1.ensureObject(this.data));
            args[0] = object_1.replaceStringVariables(args[0], urlReplacements, '{', '}');
            this.httpResponse = this.http[method].apply(this.http, args);
            this.httpResponse.promise.then(function () {
                _this.alertify.notifySuccess('La requête s\'est déroulée avec succès.');
            }).catch(function () {
                _this.alertify.notifyError('Échec de la requête au serveur.');
            }).finally(function () {
                if (_this.showResult || _this.resetDelay <= 0) {
                    return;
                }
                _this.resetTimeoutId = window.setTimeout(function () {
                    _this.httpResponse = null;
                    _this.$nextTick(function () {
                        _this.$forceUpdate();
                    });
                }, _this.resetDelay);
            });
            this.clearResetTimeout();
        }
        catch (e) {
            this.handleError(e);
        }
    };
    /**
     * Display the result of the execution in a dialog.
     */
    RemoteActionButtonComponent.prototype.openResultDialog = function () {
        var _this = this;
        if (!this.httpResponse) {
            return;
        }
        var options = { title: 'Résultat de la requête', maximizable: false };
        var $content = $('<div style="height: 100%; display: flex; flex-direction: column"></div>');
        $content.append($('<div style="background: ' + (this.httpResponse.isError ? '#c82828' : '#5cb85c') + ';padding: 3px 10px;border-radius: 3px;color: #fff;font-weight: 600;text-align: center;margin-bottom: 15px">' +
            this.httpResponse.httpStatusCode + ': ' + this.httpResponse.httpStatusText +
            '</div>'));
        if (this.httpResponse.rawResultType === 'json') {
            $content.append($('<pre style="color: #6c757d; background: #f6f6f6;border: 1px solid #f1f1f1;padding: 10px 15px;border-radius: 3px">' +
                this.highlightJson(JSON.stringify(object_1.prepareObjectForDump(this.httpResponse.result, 10), undefined, 2)) +
                '</pre>'));
        }
        else if (this.httpResponse.rawResultType === 'html') {
            var $iframe_1 = $('<iframe frameBorder="0" style="flex: 1"></iframe>');
            $iframe_1.on('load', function () {
                $iframe_1.contents().find('body').append(_this.httpResponse.rawResult);
            });
            $content.append($iframe_1);
            options.startMaximized = true;
        }
        else {
            $content.append($('<pre style="color: #6c757d; background: #f6f6f6;border: 1px solid #f1f1f1;padding: 10px 15px;border-radius: 3px">' + this.httpResponse.rawResult + '</pre>'));
        }
        this.alertify.dialog({ source: $content, options: options });
    };
    RemoteActionButtonComponent.prototype.openForm = function () {
        this.formVisible = true;
    };
    RemoteActionButtonComponent.prototype.hideForm = function () {
        this.formVisible = false;
    };
    /**
     * Get a form control by name.
     */
    RemoteActionButtonComponent.prototype.getOrCreateFormControl = function (name, ensureFormControl) {
        if (ensureFormControl === void 0) { ensureFormControl = true; }
        var control = null;
        try {
            control = form_utils_1.getFormControlByPath(this.form, name, ensureFormControl);
        }
        catch (e) {
            control = form_utils_1.getOrCreateChildComponentByPath(this.form, name);
        }
        // if (this.defaultValues !== null && this.defaultValuesWaitingToApply !== true) {
        //     this.defaultValuesWaitingToApply = true;
        //     onNextCycle().then(() => {
        //         this.form.setValue(this.defaultValues, true, false, true);
        //     });
        // }
        return control;
    };
    /**
     * Test if a slot is defined and contains something.
     *
     * \!/ IMPORTANT
     *
     * I don't use a computed property here because I want the ability to add / remove the dropdown
     * dynamically and slots are NOT reactive.
     *
     * So if a computed property is used to test the existence of the slot, it will never recompute.
     */
    RemoteActionButtonComponent.prototype.hasSlot = function (name) {
        return (utils_1.isObject(this.$slots) && !utils_1.isUndefined(this.$slots[name])) ||
            (utils_1.isObject(this.$scopedSlots) && !utils_1.isUndefined(this.$scopedSlots[name]));
    };
    /**
     * Add colors to the JSON string.
     */
    RemoteActionButtonComponent.prototype.highlightJson = function (json) {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'color: #3934e2'; // number
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'color: #953bc4'; // key
                }
                else {
                    cls = 'color: #ca394e'; // string
                }
            }
            else if (/true|false/.test(match)) {
                cls = 'color: #8334e2'; // boolean
            }
            else if (/null/.test(match)) {
                cls = 'color: magent'; // null
            }
            return '<span style="' + cls + '">' + match + '</span>';
        });
    };
    /**
     * Try to build the url to call from the input props.
     */
    RemoteActionButtonComponent.prototype.buildTargetUrl = function (externalRouteParams) {
        if (this.route !== null) {
            return this.router.generateUrl(this.route, object_1.extend({}, [utils_1.ensureObject(this.routeParams), utils_1.ensureObject(externalRouteParams)]));
        }
        if (this.url === null) {
            throw new error_1.AppError('You must define a target route or url.');
        }
        return this.url;
    };
    RemoteActionButtonComponent.prototype.clearResetTimeout = function () {
        if (this.resetTimeoutId !== null) {
            window.clearTimeout(this.resetTimeoutId);
            this.resetTimeoutId = null;
        }
    };
    /**
     * Make the button on error with a guaranteed error message for the user.
     */
    RemoteActionButtonComponent.prototype.handleError = function (error) {
        this.httpResponse = new http_response_1.HttpResponse();
        this.httpResponse.setStatus(constants_1.HttpResponseStatus.Error);
        this.httpResponse.error = error_1.AppError.create(error, 'Une erreur inconnue s\'est produite.');
        this.alertify.notifyError(this.httpResponse.error.message);
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], RemoteActionButtonComponent.prototype, "route", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () { return {}; } }),
        tslib_1.__metadata("design:type", Object)
    ], RemoteActionButtonComponent.prototype, "routeParams", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], RemoteActionButtonComponent.prototype, "url", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'get' }),
        tslib_1.__metadata("design:type", String)
    ], RemoteActionButtonComponent.prototype, "method", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", Object)
    ], RemoteActionButtonComponent.prototype, "data", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'primary' }),
        tslib_1.__metadata("design:type", String)
    ], RemoteActionButtonComponent.prototype, "type", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], RemoteActionButtonComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], RemoteActionButtonComponent.prototype, "showResult", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 5000 }),
        tslib_1.__metadata("design:type", Number)
    ], RemoteActionButtonComponent.prototype, "resetDelay", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'btn-lg' }),
        tslib_1.__metadata("design:type", String)
    ], RemoteActionButtonComponent.prototype, "classes", void 0);
    RemoteActionButtonComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./remote-action-button.component.html'),
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], RemoteActionButtonComponent);
    return RemoteActionButtonComponent;
}(vue_1.default));
exports.RemoteActionButtonComponent = RemoteActionButtonComponent;
vue_1.default.component('remote-action-button', RemoteActionButtonComponent);
