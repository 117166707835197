"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var utils_1 = require("webeak-native/util/utils");
var event_arg_1 = require("./event-arg");
var EventDispatcher = /** @class */ (function () {
    function EventDispatcher() {
        this.listeners = {};
    }
    /**
     * Subscribe to an event.
     */
    EventDispatcher.prototype.subscribe = function (eventName, callback) {
        var _this = this;
        if (utils_1.isUndefined(this.listeners[eventName])) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName].push(callback);
        return function () {
            if (!utils_1.isUndefined(_this.listeners[eventName])) {
                for (var i = 0; i < _this.listeners[eventName].length; ++i) {
                    if (_this.listeners[eventName][i] === callback) {
                        _this.listeners[eventName].splice(i, 1);
                        return;
                    }
                }
            }
        };
    };
    /**
     * Trigger an event.
     */
    EventDispatcher.prototype.dispatch = function (eventName, arg) {
        if (utils_1.isUndefined(this.listeners[eventName])) {
            return;
        }
        if (!arg) {
            arg = new event_arg_1.EventArg();
        }
        for (var _i = 0, _a = this.listeners[eventName]; _i < _a.length; _i++) {
            var callback = _a[_i];
            callback(arg);
            if (arg.isPropagationStopped()) {
                return;
            }
        }
    };
    /**
     * Trigger an event and return responses of callbacks.
     * This method will wait for promises to resolve.
     */
    EventDispatcher.prototype.dispatchForResponse = function (eventName, arg) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (utils_1.isUndefined(_this.listeners[eventName])) {
                resolve([]);
                return;
            }
            if (!arg) {
                arg = new event_arg_1.EventArg();
            }
            var responses = null;
            var pipeline = Promise.resolve();
            var propagationStopped = false;
            var _loop_1 = function (callback) {
                pipeline = pipeline.then((function (innerCallback) {
                    return function (previousResponse) {
                        if (propagationStopped) {
                            return null;
                        }
                        if (responses === null) {
                            responses = [];
                        }
                        else {
                            responses.push(previousResponse);
                        }
                        var response = callback(arg);
                        if (arg.isPropagationStopped()) {
                            propagationStopped = true;
                        }
                        return response;
                    };
                })(callback));
            };
            for (var _i = 0, _a = _this.listeners[eventName]; _i < _a.length; _i++) {
                var callback = _a[_i];
                _loop_1(callback);
            }
            pipeline.then(function (previousResponse) {
                responses.push(previousResponse);
                resolve(responses);
            }, reject);
        });
    };
    /**
     * Remove all registered listeners.
     */
    EventDispatcher.prototype.clear = function () {
        this.listeners = {};
    };
    EventDispatcher = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], EventDispatcher);
    return EventDispatcher;
}());
exports.EventDispatcher = EventDispatcher;
