"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_metadata_factory_1 = require("essentials/api/metadata/api-metadata.factory");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("backend/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var container_1 = require("webeak-native/inversify/container");
var router_1 = require("essentials/symfony/router");
var error_1 = require("essentials/error");
var api_fetcher_1 = require("essentials/vuejs/components/form/generic/fetcher/api.fetcher");
var http_fetcher_1 = require("essentials/vuejs/components/form/generic/fetcher/http.fetcher");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var schemas_holder_1 = require("essentials/model/schemas-holder");
var utils_1 = require("webeak-native/util/utils");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var form_group_1 = require("essentials/form/form-group");
var form_model_binder_1 = require("essentials/form/form-model-binder");
var form_utils_1 = require("essentials/form/form-utils");
var utils_2 = require("essentials/utils/utils");
var http_response_1 = require("essentials/network/http-response");
var api_persister_1 = require("essentials/vuejs/components/form/generic/persister/api.persister");
var http_persister_1 = require("essentials/vuejs/components/form/generic/persister/http.persister");
var constants_1 = require("essentials/network/constants");
var form_error_1 = require("essentials/form/error/form.error");
var alertify_service_1 = require("essentials/dialog/alertify/alertify.service");
var object_1 = require("webeak-native/util/object");
var abstract_form_control_1 = require("essentials/form/abstract-form-control");
var GenericComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GenericComponent, _super);
    function GenericComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.form = null;
        _this.formBinder = null;
        _this.initialized = false;
        _this.ready = false;
        _this.fatalError = null;
        _this.persistResponse = null;
        _this.defaultValues = null;
        _this.defaultValuesWaitingToApply = false;
        _this.formOriginalValues = null;
        _this.fetcherResult = null;
        var container = container_1.Container.getContainer();
        _this.apiMetadataFactory = container.get(api_metadata_factory_1.ApiMetadataFactorySymbol);
        _this.alertify = container.get(alertify_service_1.AlertifyServiceSymbol);
        _this.transformer = container.get(transformer_service_1.TransformerServiceSymbol);
        return _this;
    }
    // Watchers
    GenericComponent.prototype.onFetchConfigurationChanged = function () {
        // We know that some part of the config have changed.
        // For simplicity, just reload the form.
        this.loadForm().catch(utils_1.proxy(this.handleFatalError, this));
    };
    GenericComponent.prototype.onPersistConfigurationChanged = function () {
        var _this = this;
        // We know that some part of the config have changed.
        // For simplicity, just reload the form.
        this.createPersister().then(function (persister) {
            _this.persister = persister;
        }).catch(utils_1.proxy(this.handleFatalError, this));
    };
    /**
     * VueJS lifecycle method.
     */
    GenericComponent.prototype.mounted = function () {
        var _this = this;
        this.persistResponse = new http_response_1.HttpResponse(constants_1.HttpResponseStatus.Success);
        this.onPersistConfigurationChanged();
        this.loadForm().then(function () {
            _this.initialized = true;
            // Let the time for VueJS's components to render so the form's value is updated.
            utils_2.onNextCycle().then(function () {
                _this.formOriginalValues = object_1.extend({}, _this.form.value);
                _this.setAsReady().catch(utils_1.proxy(_this.handleFatalError, _this));
                return null;
            });
            return null;
        }).catch(utils_1.proxy(this.handleFatalError, this));
    };
    /**
     * Get a form control by name.
     */
    GenericComponent.prototype.getOrCreateFormControl = function (name, ensureFormControl) {
        var _this = this;
        if (ensureFormControl === void 0) { ensureFormControl = false; }
        var control = null;
        try {
            control = form_utils_1.getFormControlByPath(this.form, name, ensureFormControl);
        }
        catch (e) {
            if (this.entity) {
                throw e;
            }
            control = form_utils_1.getOrCreateChildComponentByPath(this.form, name);
        }
        if (this.defaultValues !== null && this.defaultValuesWaitingToApply !== true) {
            this.defaultValuesWaitingToApply = true;
            utils_2.onNextCycle().then(function () {
                _this.form.setValue(_this.defaultValues, true, false, true);
            });
        }
        return control;
    };
    /**
     * Test if a form control is present in the form.
     */
    GenericComponent.prototype.hasFormControl = function (name) {
        try {
            return form_utils_1.getFormControlByPath(this.form, name) instanceof abstract_form_control_1.AbstractFormControl;
        }
        catch (e) {
            // Ignore
        }
        return false;
    };
    /**
     * Perform a persist request with the current values of the form.
     */
    GenericComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isValid;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.form.validate()];
                    case 1:
                        isValid = _a.sent();
                        if (isValid) {
                            this.form.clearErrors(false, true);
                            this.persistResponse = this.persister.persist(this.form);
                            this.persistResponse.promise.then(utils_1.proxy(this.handlePersistSuccess, this)).catch(function (error) {
                                var message = error.getPublicMessage('Échec de la sauvegarde pour une erreur interne.');
                                _this.alertify.notifyError(message);
                                _this.form.addError(new form_error_1.FormError(message, {}, 'default', true));
                            });
                        }
                        else {
                            this.alertify.notifyError('Le formulaire contient des erreurs.');
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    /**
     * Restore the form to its original state.
     */
    GenericComponent.prototype.cancel = function () {
        if (utils_1.isObject(this.formOriginalValues)) {
            this.form.reset();
            this.form.setValue(this.formOriginalValues, true, false, true);
        }
        return null;
    };
    /**
     * Called after the initialization has finished and before the form is marked as "ready".
     * The form should be disabled while not ready but rendered as soon as initialized.
     */
    GenericComponent.prototype.onReady = function () {
        // Override me.
    };
    /**
     * Set the list in a fatal error state.
     */
    GenericComponent.prototype.handleFatalError = function (error) {
        error = error_1.AppError.create(error);
        this.fatalError = error.getPublicMessage();
        throw error;
    };
    /**
     * Do the after work when a persist succeeded.
     */
    GenericComponent.prototype.handlePersistSuccess = function () {
        var router = container_1.Container.getContainer().get(router_1.RouterSymbol);
        var url = this.persistSuccessUrl;
        if (!url && this.persistSuccessRoute) {
            try {
                url = router.generateUrl(this.persistSuccessRoute);
            }
            catch (e) {
                this.alertify.notifyWarning('Échec de la redirection, route invalide.');
            }
        }
        if (url) {
            this.alertify.notifyAfterRedirect('success', this.persistSuccessMessage, url);
        }
        else if (this.persistSuccessMessage !== null) {
            this.alertify.notifySuccess(this.persistSuccessMessage);
        }
    };
    /**
     * Create a new form and load it with default data depending on the component's inputs.
     */
    GenericComponent.prototype.loadForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, fetcher, httpResponse;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        input = null;
                        return [4 /*yield*/, this.createFetcher()];
                    case 1:
                        fetcher = _a.sent();
                        this.form = new form_group_1.FormGroup();
                        if (!fetcher) return [3 /*break*/, 3];
                        if (!utils_1.isNullOrUndefined(this.loadData)) {
                            throw new error_1.AppError('You cannot load the form with local and remote data at the same time.');
                        }
                        httpResponse = fetcher.fetch();
                        return [4 /*yield*/, httpResponse.promise];
                    case 2:
                        _a.sent();
                        input = httpResponse.result;
                        _a.label = 3;
                    case 3:
                        if (!(input === null)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getLocalLoadData()];
                    case 4:
                        input = _a.sent();
                        _a.label = 5;
                    case 5:
                        if (!utils_1.isObject(input)) {
                            return [2 /*return*/];
                        }
                        this.fetcherResult = input;
                        if (!(this.entity !== null)) return [3 /*break*/, 7];
                        this.formBinder = new form_model_binder_1.FormModelBinder(this.form, input);
                        return [4 /*yield*/, this.formBinder.onReady()];
                    case 6:
                        _a.sent();
                        return [2 /*return*/];
                    case 7:
                        // The form has no structure yet and we can't transform it using the TransformerService
                        // because no entity has been defined.
                        //
                        // So the object is stored and values will be set on the fly after the fields have been requested.
                        this.defaultValues = input;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Create the FetcherInterface object that corresponds to the current configuration.
     */
    GenericComponent.prototype.createFetcher = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.resolveRemoteProxyType(this.loadApiAction, this.loadRoute, this.loadUrl, this.loadUrlParams)];
                    case 1:
                        result = _a.sent();
                        if (result === null) {
                            return [2 /*return*/, null];
                        }
                        if (result.type === 'api') {
                            return [2 /*return*/, new api_fetcher_1.ApiFetcher(result.entityType, this.loadApiEntityId, this.loadApiAction, this.loadUrlParams, this.loadApiFilters)];
                        }
                        if (this.loadApiEntityId) {
                            throw new error_1.AppError('The attribute "load-api-entity-id" is meant to be used with the Api. Please use "load-uri-params" otherwise.');
                        }
                        return [2 /*return*/, new http_fetcher_1.HttpFetcher(result.url, result.entityType)];
                }
            });
        });
    };
    /**
     * Create the PersisterInterface object that corresponds to the current configuration.
     */
    GenericComponent.prototype.createPersister = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.resolveRemoteProxyType(this.persistApiAction, this.persistRoute, this.persistUrl, this.persistUrlParams)];
                    case 1:
                        result = _a.sent();
                        if (result === null) {
                            throw new error_1.AppError('You must define a persist configuration (using "persist-api-action", "persist-route" or "persist-url").');
                        }
                        if (result.type === 'api') {
                            return [2 /*return*/, new api_persister_1.ApiPersister(result.entityType, this.persistApiAction)];
                        }
                        return [2 /*return*/, new http_persister_1.HttpPersister(result.url, result.entityType, this.method)];
                }
            });
        });
    };
    /**
     * Try to get local data to use to pre fill the form.
     */
    GenericComponent.prototype.getLocalLoadData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var entityType;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        entityType = null;
                        if (!(this.entity !== null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.resolveEntityType()];
                    case 1:
                        entityType = _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!utils_1.isObject(this.loadData) && entityType === null) {
                            return [2 /*return*/, null];
                        }
                        if (!(entityType !== null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.transformer.transformInverse(this.loadData || {}, generic_transformation_model_schema_1.GenericTransformationModelSchema, entityType)];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4: return [2 /*return*/, this.loadData];
                }
            });
        });
    };
    /**
     * Convert an entity type from a string into an object constructor.
     */
    GenericComponent.prototype.resolveEntityType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var schema, apiMetadata;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        schema = schemas_holder_1.SchemasHolder.GetInstance().get(generic_transformation_model_schema_1.GenericTransformationModelSchema, this.entity);
                        if (!schema) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiMetadataFactory.create()];
                    case 1:
                        apiMetadata = _a.sent();
                        return [2 /*return*/, apiMetadata.getConcreteEntityType(schema.target, this.loadData || {})];
                    case 2: throw new error_1.AppError("No schema have been found for entity \"" + this.entity + "\".");
                }
            });
        });
    };
    /**
     * Resolve what type of proxy interface (for reading or writing) to use depending on the input.
     */
    GenericComponent.prototype.resolveRemoteProxyType = function (apiAction, route, url, urlParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var entityType, finalUrl, router, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(apiAction !== null)) return [3 /*break*/, 2];
                        if (route || url) {
                            throw new error_1.AppError('You cannot define a route/url and an api action at the same time.');
                        }
                        return [4 /*yield*/, this.resolveEntityType()];
                    case 1:
                        entityType = _c.sent();
                        if (!entityType) {
                            throw new error_1.AppError("You must define a type of entity if you use the api.");
                        }
                        return [2 /*return*/, { type: 'api', entityType: entityType }];
                    case 2:
                        finalUrl = url;
                        if (route) {
                            if (finalUrl) {
                                throw new error_1.AppError('You cannot define a route and a url at the same time.');
                            }
                            router = container_1.Container.getContainer().get(router_1.RouterSymbol);
                            try {
                                finalUrl = router.generateUrl(route, urlParams);
                            }
                            catch (e) {
                                throw new error_1.AppError(error_1.AppError.create(e).message);
                            }
                        }
                        if (!finalUrl) {
                            return [2 /*return*/, null];
                        }
                        _a = { type: 'http', url: finalUrl };
                        if (!(this.entity !== null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.resolveEntityType()];
                    case 3:
                        _b = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        _b = null;
                        _c.label = 5;
                    case 5: return [2 /*return*/, (_a.entityType = _b, _a)];
                }
            });
        });
    };
    /**
     * Mark the form as ready.
     */
    GenericComponent.prototype.setAsReady = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.onReady()];
                    case 1:
                        _a.sent();
                        this.ready = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "entity", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "loadApiAction", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Number], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], GenericComponent.prototype, "loadApiEntityId", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () { return {}; } }),
        tslib_1.__metadata("design:type", Object)
    ], GenericComponent.prototype, "loadApiFilters", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "loadRoute", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "loadUrl", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", Object)
    ], GenericComponent.prototype, "loadData", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () { return {}; } }),
        tslib_1.__metadata("design:type", Object)
    ], GenericComponent.prototype, "loadUrlParams", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "persistApiAction", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "persistRoute", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "persistUrl", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () { return {}; } }),
        tslib_1.__metadata("design:type", Object)
    ], GenericComponent.prototype, "persistUrlParams", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'post' }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "method", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "persistSuccessRoute", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "persistSuccessUrl", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'Sauvegarde effectuée avec succès.' }),
        tslib_1.__metadata("design:type", String)
    ], GenericComponent.prototype, "persistSuccessMessage", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('entity'),
        vue_property_decorator_1.Watch('loadApiAction'),
        vue_property_decorator_1.Watch('loadRoute'),
        vue_property_decorator_1.Watch('loadUrl'),
        vue_property_decorator_1.Watch('loadUrlParams'),
        vue_property_decorator_1.Watch('loadData'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], GenericComponent.prototype, "onFetchConfigurationChanged", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('entity'),
        vue_property_decorator_1.Watch('persistApiAction'),
        vue_property_decorator_1.Watch('persistRoute'),
        vue_property_decorator_1.Watch('persistUrl'),
        vue_property_decorator_1.Watch('persistUrlParams'),
        vue_property_decorator_1.Watch('method'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], GenericComponent.prototype, "onPersistConfigurationChanged", null);
    GenericComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./generic.component.html')
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], GenericComponent);
    return GenericComponent;
}(vue_1.default));
exports.GenericComponent = GenericComponent;
vue_app_1.VueApp.RegisterComponent('form-generic', GenericComponent);
