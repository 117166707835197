"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
var validation_error_1 = require("essentials/validation/validation.error");
var validator_container_1 = require("essentials/validation/validator-container");
function Url(message) {
    if (message === void 0) { message = 'Doit être une url valide.'; }
    var reg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    return {
        validate: function (value, context) {
            if (value && (!utils_1.isString(value) || !reg.test(value))) {
                context.addError(new validation_error_1.ValidationError('url', message));
            }
        }
    };
}
exports.Url = Url;
validator_container_1.ValidatorContainer.register('Url', Url);
