"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var moment = require("moment");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("backend/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var user_entity_1 = require("backend/entity/user.entity");
var container_1 = require("webeak-native/inversify/container");
var form_group_1 = require("essentials/form/form-group");
var api_service_1 = require("essentials/api/api.service");
var error_1 = require("essentials/error");
var application_entity_1 = require("backend/entity/application.entity");
var constants_1 = require("essentials/network/constants");
var form_array_1 = require("essentials/form/form-array");
var form_control_1 = require("essentials/form/form-control");
var utils_1 = require("webeak-native/util/utils");
var application_user_feature_entity_1 = require("backend/entity/application-user-feature.entity");
var alertify_service_1 = require("essentials/dialog/alertify/alertify.service");
var AccessRightsManagerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AccessRightsManagerComponent, _super);
    function AccessRightsManagerComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.form = null;
        _this.apiResponse = null;
        _this.api = container_1.Container.getContainer().get(api_service_1.ApiServiceSymbol);
        _this.alertify = container_1.Container.getContainer().get(alertify_service_1.AlertifyServiceSymbol);
        _this.appsFeaturesIndex = {};
        _this.userFeaturesIndex = {};
        _this.availableApps = null;
        return _this;
    }
    AccessRightsManagerComponent.prototype.created = function () {
        var _this = this;
        this.apiResponse = this.api.createFakeResponse(null);
        this.apiResponse.setStatus(constants_1.HttpResponseStatus.Pending);
        this.loadApps().then(function (applications) {
            _this.availableApps = applications;
            _this.indexFeatures();
            _this.loadUser();
        });
    };
    AccessRightsManagerComponent.prototype.save = function () {
        var _this = this;
        this.syncUserWithFormValue(this.apiResponse.result, this.form.value);
        this.apiResponse.result.roles = null;
        this.apiResponse = this.api
            .persist(user_entity_1.UserEntity, null, 'put_internal_for_access_rights')
            .data(this.apiResponse.result)
            .execute();
        this.apiResponse.promise
            .then(function () { return void _this.alertify.notifySuccess("Sauvegarde effectuée avec succès."); })
            .catch(function (error) { return void _this.alertify.notifyError(error.getPublicMessage('Échec de la sauvegarde.')); });
    };
    AccessRightsManagerComponent.prototype.onApplicationChange = function (applicationGroup) {
        if (applicationGroup.controls.selected.value) {
            this.selectAllModulesInApplication(applicationGroup);
        }
        else {
            var modulesArray = applicationGroup.controls.modules;
            if (applicationGroup.controls.modulesSelectedCount.value === modulesArray.controls.length) {
                modulesArray.forEachChild(function (moduleGroup) {
                    moduleGroup.controls.selected.setValue(false);
                });
            }
        }
    };
    AccessRightsManagerComponent.prototype.onModuleChange = function (moduleGroup) {
        var parent = moduleGroup.parent.parent;
        if (moduleGroup.controls.selected.value) {
            parent.controls.modulesSelectedCount.setValue(parent.controls.modulesSelectedCount.value + 1);
            if (parent.controls.modulesSelectedCount.value === parent.controls.modules.controls.length) {
                parent.controls.selected.setValue(true);
            }
            this.selectAllFeaturesInModule(moduleGroup);
        }
        else {
            var featuresArray = moduleGroup.controls.features;
            if (moduleGroup.controls.featuresSelectedCount.value === featuresArray.controls.length) {
                featuresArray.forEachChild(function (featureGroup) {
                    featureGroup.controls.selected.setValue(false);
                });
            }
            moduleGroup.parent.parent.controls.selected.setValue(false);
            parent.controls.modulesSelectedCount.setValue(parent.controls.modulesSelectedCount.value - 1);
        }
    };
    AccessRightsManagerComponent.prototype.onFeatureChange = function (featureGroup) {
        var parent = featureGroup.parent.parent;
        if (featureGroup.controls.selected.value) {
            parent.controls.featuresSelectedCount.setValue(parent.controls.featuresSelectedCount.value + 1);
            if (parent.controls.featuresSelectedCount.value === parent.controls.features.controls.length) {
                parent.controls.selected.setValue(true);
            }
        }
        else {
            featureGroup.parent.parent.controls.selected.setValue(false);
            parent.controls.featuresSelectedCount.setValue(parent.controls.featuresSelectedCount.value - 1);
        }
    };
    AccessRightsManagerComponent.prototype.isApplicationOpened = function (application) {
        return application.controls.opened.value;
    };
    AccessRightsManagerComponent.prototype.toggleApplication = function (application) {
        application.controls.opened.setValue(!application.controls.opened.value);
        this.$forceUpdate();
    };
    AccessRightsManagerComponent.prototype.onApplicationExpirationDateChange = function (application) {
        application.controls.modules.forEachChild(function (module) {
            module.controls.expirationDate.setValue(application.controls.expirationDate.value);
        });
    };
    AccessRightsManagerComponent.prototype.onModuleExpirationDateChange = function (module) {
        module.controls.features.forEachChild(function (feature) {
            feature.controls.expirationDate.setValue(module.controls.expirationDate.value);
        });
        this.$forceUpdate();
    };
    AccessRightsManagerComponent.prototype.openAccessToApplication = function (application) {
        application.controls.accessible.setValue(true);
        this.$forceUpdate();
    };
    AccessRightsManagerComponent.prototype.closeAccessToApplication = function (application) {
        application.controls.accessible.setValue(false);
        this.$forceUpdate();
    };
    AccessRightsManagerComponent.prototype.indexFeatures = function () {
        this.appsFeaturesIndex = {};
        for (var _i = 0, _a = this.availableApps; _i < _a.length; _i++) {
            var application = _a[_i];
            for (var _b = 0, _c = application.modules; _b < _c.length; _b++) {
                var module_1 = _c[_b];
                for (var _d = 0, _e = module_1.features; _d < _e.length; _d++) {
                    var feature = _e[_d];
                    this.appsFeaturesIndex[feature.ref] = feature;
                }
            }
        }
    };
    AccessRightsManagerComponent.prototype.selectAllModulesInApplication = function (application) {
        var _this = this;
        application.controls.modules.forEachChild(function (module) {
            module.controls.selected.setValue(true);
            _this.selectAllFeaturesInModule(module);
        });
    };
    AccessRightsManagerComponent.prototype.selectAllFeaturesInModule = function (module) {
        module.controls.features.forEachChild(function (feature) {
            feature.controls.selected.setValue(true);
        });
    };
    AccessRightsManagerComponent.prototype.loadApps = function () {
        return this.api
            .get(application_entity_1.ApplicationEntity, 'get_internal_for_access_rights')
            .collection()
            .execute()
            .promise;
    };
    AccessRightsManagerComponent.prototype.loadUser = function () {
        var _this = this;
        this.apiResponse = this.api
            .get(user_entity_1.UserEntity, 'get_internal')
            .item(this.identifier)
            .execute();
        this.apiResponse.promise.then(function () {
            _this.buildForm(_this.availableApps);
        }).catch(function (error) { return void _this.alertify.notifyError(error_1.AppError.create(error).getPublicMessage('Échec du chargement.')); });
    };
    AccessRightsManagerComponent.prototype.syncUserWithFormValue = function (user, formValue) {
        user.applicationsFeatures = [];
        user.accessibleApplications = [];
        for (var _i = 0, _a = formValue.applications; _i < _a.length; _i++) {
            var application = _a[_i];
            for (var _b = 0, _c = application.modules; _b < _c.length; _b++) {
                var module_2 = _c[_b];
                for (var _d = 0, _e = module_2.features; _d < _e.length; _d++) {
                    var feature = _e[_d];
                    if (feature.selected && !utils_1.isUndefined(this.appsFeaturesIndex[feature.ref])) {
                        var selectedUserFeature = null;
                        if (!utils_1.isUndefined(this.userFeaturesIndex[feature.ref])) {
                            selectedUserFeature = this.userFeaturesIndex[feature.ref];
                        }
                        else {
                            selectedUserFeature = new application_user_feature_entity_1.ApplicationUserFeatureEntity();
                            selectedUserFeature.feature = this.appsFeaturesIndex[feature.ref];
                        }
                        selectedUserFeature.expirationDate = feature.expirationDate instanceof Date ? moment(feature.expirationDate) : null;
                        user.applicationsFeatures.push(selectedUserFeature);
                    }
                }
            }
            if (application.accessible) {
                var applicationEntity = new application_entity_1.ApplicationEntity();
                applicationEntity.ref = application.ref;
                user.accessibleApplications.push(applicationEntity);
            }
        }
    };
    AccessRightsManagerComponent.prototype.buildForm = function (availableApps) {
        var applicationsArray = new form_array_1.FormArray([], 'applications');
        var userFeaturesFeatureIndex = {};
        var featuresRefs = [];
        for (var _i = 0, _a = this.apiResponse.result.applicationsFeatures; _i < _a.length; _i++) {
            var userFeature = _a[_i];
            featuresRefs.push(userFeature.feature.ref);
            this.userFeaturesIndex[userFeature.ref] = userFeature;
            userFeaturesFeatureIndex[userFeature.feature.ref] = userFeature;
        }
        for (var _b = 0, availableApps_1 = availableApps; _b < availableApps_1.length; _b++) {
            var applicationEntity = availableApps_1[_b];
            var applicationGroup = new form_group_1.FormGroup();
            var modulesArray = new form_array_1.FormArray([], 'modules');
            var globalFeaturesCount = 0;
            var modulesSelectedCount = 0;
            for (var _c = 0, _d = applicationEntity.modules; _c < _d.length; _c++) {
                var moduleEntity = _d[_c];
                var moduleGroup = new form_group_1.FormGroup();
                var featuresArray = new form_array_1.FormArray([], 'features');
                var featuresSelectedCount = 0;
                for (var _e = 0, _f = moduleEntity.features; _e < _f.length; _e++) {
                    var featureEntity = _f[_e];
                    var featureGroup = new form_group_1.FormGroup();
                    var featureSelected = featuresRefs.indexOf(featureEntity.ref) >= 0;
                    var expirationDate = !utils_1.isUndefined(userFeaturesFeatureIndex[featureEntity.ref]) && userFeaturesFeatureIndex[featureEntity.ref].expirationDate ?
                        userFeaturesFeatureIndex[featureEntity.ref].expirationDate.toDate() : null;
                    featureGroup.add(new form_control_1.FormControl(featureEntity.ref, 'ref'));
                    featureGroup.add(new form_control_1.FormControl(featureEntity.name, 'name'));
                    featureGroup.add(new form_control_1.FormControl(featureEntity.description, 'description'));
                    featureGroup.add(new form_control_1.FormControl(featureSelected, 'selected'));
                    featureGroup.add(new form_control_1.FormControl(expirationDate, 'expirationDate'));
                    featuresArray.add(featureGroup);
                    if (featureSelected) {
                        ++featuresSelectedCount;
                        ++globalFeaturesCount;
                    }
                }
                moduleGroup.add(featuresArray);
                moduleGroup.add(new form_control_1.FormControl(moduleEntity.ref, 'ref'));
                moduleGroup.add(new form_control_1.FormControl(moduleEntity.name, 'name'));
                moduleGroup.add(new form_control_1.FormControl(featuresSelectedCount === moduleEntity.features.length, 'selected'));
                moduleGroup.add(new form_control_1.FormControl(featuresSelectedCount, 'featuresSelectedCount'));
                moduleGroup.add(new form_control_1.FormControl(null, 'expirationDate'));
                modulesArray.add(moduleGroup);
                if (moduleGroup.controls.selected.value) {
                    ++modulesSelectedCount;
                }
            }
            applicationGroup.add(modulesArray);
            applicationGroup.add(new form_control_1.FormControl(applicationEntity.ref, 'ref'));
            applicationGroup.add(new form_control_1.FormControl(applicationEntity.name, 'name'));
            applicationGroup.add(new form_control_1.FormControl(applicationEntity.description, 'description'));
            applicationGroup.add(new form_control_1.FormControl(modulesSelectedCount === applicationEntity.modules.length, 'selected'));
            applicationGroup.add(new form_control_1.FormControl(modulesSelectedCount, 'modulesSelectedCount'));
            applicationGroup.add(new form_control_1.FormControl(globalFeaturesCount > 0, 'opened'));
            applicationGroup.add(new form_control_1.FormControl(applicationEntity.delegatedRights, 'delegatedRights'));
            applicationGroup.add(new form_control_1.FormControl(this.apiResponse.result.hasAccessToApplication(applicationEntity.ref), 'accessible'));
            applicationGroup.add(new form_control_1.FormControl(null, 'expirationDate'));
            applicationsArray.add(applicationGroup);
        }
        this.form = new form_group_1.FormGroup();
        this.form.add(applicationsArray);
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, required: true }),
        tslib_1.__metadata("design:type", String)
    ], AccessRightsManagerComponent.prototype, "identifier", void 0);
    AccessRightsManagerComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./access-rights-manager.component.html')
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], AccessRightsManagerComponent);
    return AccessRightsManagerComponent;
}(vue_1.default));
exports.AccessRightsManagerComponent = AccessRightsManagerComponent;
vue_app_1.VueApp.RegisterComponent('user-access-rights-manager', AccessRightsManagerComponent);
