"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var var_holder_1 = require("../../storage/var-holder");
var TransformContext = /** @class */ (function (_super) {
    tslib_1.__extends(TransformContext, _super);
    function TransformContext(source, result, schema) {
        var _this = _super.call(this) || this;
        _this.result = result;
        _this.source = source;
        _this.schema = schema;
        _this.currentPropertyName = null;
        return _this;
    }
    return TransformContext;
}(var_holder_1.VarHolder));
exports.TransformContext = TransformContext;
