"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var resource_property_metadata_1 = require("essentials/api/metadata/resource-property.metadata");
var ResourceRelationMetadata = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceRelationMetadata, _super);
    function ResourceRelationMetadata() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a ResourcePropertyMetadata instance from the raw output from the server.
     */
    ResourceRelationMetadata.createFromServerResponse = function (config) {
        var instance = new ResourceRelationMetadata();
        instance.importServerData(config);
        return instance;
    };
    /**
     * Import raw output from the server into the current instance.
     */
    ResourceRelationMetadata.prototype.importServerData = function (config) {
        _super.prototype.importServerData.call(this, config);
        this.relationType = config[9];
    };
    return ResourceRelationMetadata;
}(resource_property_metadata_1.ResourcePropertyMetadata));
exports.ResourceRelationMetadata = ResourceRelationMetadata;
