"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var list_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list.component");
var ListSingleColumnRowComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListSingleColumnRowComponent, _super);
    function ListSingleColumnRowComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Logic vars
        _this.list = null;
        return _this;
    }
    Object.defineProperty(ListSingleColumnRowComponent.prototype, "columnsCount", {
        // Computed
        get: function () {
            if (this.list) {
                return this.list.visibleColumnsCount;
            }
            return 1;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle method.
     */
    ListSingleColumnRowComponent.prototype.mounted = function () {
        var $parent = this;
        do {
            $parent = $parent.$parent;
            if ($parent instanceof list_component_1.ListComponent) {
                this.list = $parent;
                return;
            }
        } while ($parent);
        if (!($parent instanceof list_component_1.ListComponent)) {
            return void console.error('A <list-single-column-row></list-single-column-row> must be placed inside a <list></list> component.');
        }
    };
    ListSingleColumnRowComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-single-column-row.component.html')
        })
    ], ListSingleColumnRowComponent);
    return ListSingleColumnRowComponent;
}(vue_1.default));
exports.ListSingleColumnRowComponent = ListSingleColumnRowComponent;
vue_app_1.VueApp.RegisterComponent('list-single-column-row', ListSingleColumnRowComponent);
