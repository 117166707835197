"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
var HiddenComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HiddenComponent, _super);
    function HiddenComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HiddenComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./hidden.component.html'),
        })
    ], HiddenComponent);
    return HiddenComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.HiddenComponent = HiddenComponent;
vue_1.default.component('form-hidden', HiddenComponent);
