"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toolbar_builder_1 = require("essentials/froala/config-builder/toolbar.builder");
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var FroalaConfigBuilder = /** @class */ (function () {
    function FroalaConfigBuilder() {
        this.toolbarBuilder = new toolbar_builder_1.ToolbarBuilder(this);
        this.toolbarMDBuilder = null;
        this.toolbarSMBuilder = null;
        this.toolbarXSBuilder = null;
        this.plugins = [];
    }
    /**
     * Add a plugin to the editor.
     * This method is intended for plugins non associated with toolbar buttons.
     *
     * When you want to add a button to the toolbar, you DON'T have to call this method,
     * the plugin will be added implicitly.
     *
     * This method is intended to be used with plugins like "charCounter" that doesn't have any interactive behavior.
     */
    FroalaConfigBuilder.prototype.addPlugin = function (plugin) {
        this.plugins.push(plugin);
        return this;
    };
    /**
     * Start the configuration of the toolbar.
     */
    FroalaConfigBuilder.prototype.toolbar = function () {
        return this.toolbarBuilder;
    };
    /**
     * Start the configuration of the toolbar that will be visible on medium screen sizes.
     */
    FroalaConfigBuilder.prototype.toolbarMd = function () {
        if (this.toolbarMDBuilder === null) {
            this.toolbarMDBuilder = new toolbar_builder_1.ToolbarBuilder(this);
        }
        return this.toolbarMDBuilder;
    };
    /**
     * Start the configuration of the toolbar that will be visible on small screen sizes.
     */
    FroalaConfigBuilder.prototype.toolbarSm = function () {
        if (this.toolbarSMBuilder === null) {
            this.toolbarSMBuilder = new toolbar_builder_1.ToolbarBuilder(this);
        }
        return this.toolbarSMBuilder;
    };
    /**
     * Start the configuration of the toolbar that will be visible on very small screen sizes.
     */
    FroalaConfigBuilder.prototype.toolbarXs = function () {
        if (this.toolbarXSBuilder === null) {
            this.toolbarXSBuilder = new toolbar_builder_1.ToolbarBuilder(this);
        }
        return this.toolbarXSBuilder;
    };
    /**
     * Get the final configuration object.
     */
    FroalaConfigBuilder.prototype.getConfig = function () {
        var config = { attribution: false };
        var toolbarsSuffixes = ['', 'MD', 'SM', 'XS'];
        for (var _i = 0, toolbarsSuffixes_1 = toolbarsSuffixes; _i < toolbarsSuffixes_1.length; _i++) {
            var suffix = toolbarsSuffixes_1[_i];
            var toolbar_1 = this['toolbar' + suffix + 'Builder'];
            if (toolbar_1) {
                toolbar_1.applyConfig(config, config);
            }
        }
        for (var _a = 0, _b = this.plugins; _a < _b.length; _a++) {
            var plugin = _b[_a];
            if (utils_1.isObject(plugin.options)) {
                object_1.extend(config, plugin.options);
            }
        }
        Object.keys(config).forEach(function (key) { return config[key] === undefined && delete config[key]; });
        return config;
    };
    /**
     * Create a configuration builder.
     */
    FroalaConfigBuilder.Create = function () {
        return new FroalaConfigBuilder();
    };
    return FroalaConfigBuilder;
}());
exports.FroalaConfigBuilder = FroalaConfigBuilder;
