"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var abstract_module_1 = require("essentials/modules/abstract-module");
var SidebarMenu = /** @class */ (function (_super) {
    tslib_1.__extends(SidebarMenu, _super);
    function SidebarMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @inheritDoc
     */
    SidebarMenu.prototype.bind = function () {
        var that = this;
        $('.menu__item--has-children').on('click', function (e) {
            var $this = $(this);
            var $a = $('> a:first-child', $this);
            var offset = $this.offset();
            var x = e.clientX - offset.left;
            var y = e.clientY - offset.top;
            if (($a.attr('href') === '#' && (e.target === $a.get(0) || e.target.parentNode === $a.get(0))) || $this.width() - x < 45 && y < 40) {
                e.preventDefault();
                e.stopPropagation();
                that.toggleDropdown($this);
            }
        });
        $('.menu__item--current-ancestor, .menu__item--current.menu__item--has-children').each(function () {
            that.openDropdown($(this));
        });
    };
    /**
     * Open / close the sub menu of a menu item.
     */
    SidebarMenu.prototype.toggleDropdown = function ($el) {
        if ($el.hasClass('menu__item--opened')) {
            this.closeDropdown($el);
        }
        else {
            this.openDropdown($el);
        }
    };
    /**
     * Open the sub menu of a menu item.
     */
    SidebarMenu.prototype.openDropdown = function ($el) {
        $el.addClass('menu__item--opened');
    };
    /**
     * Close the sub menu of a menu item.
     */
    SidebarMenu.prototype.closeDropdown = function ($el) {
        $el.removeClass('menu__item--opened');
    };
    SidebarMenu = tslib_1.__decorate([
        inversify_1.injectable()
    ], SidebarMenu);
    return SidebarMenu;
}(abstract_module_1.AbstractModule));
exports.SidebarMenuSymbol = Symbol("SidebarMenu");
container_1.Container.registerModule(exports.SidebarMenuSymbol, SidebarMenu);
