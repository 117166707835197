"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("essentials-root/vendor/froala/js/plugins/code_view");
require("essentials-root/vendor/froala/js/plugins/code_beautifier");
require("essentials-root/vendor/froala/css/plugins/code_view.css");
var CodeMirror = require("codemirror");
require("codemirror/mode/xml/xml");
require("codemirror/lib/codemirror.css");
var object_1 = require("webeak-native/util/object");
exports.CodeView = function (options) {
    if (options === void 0) { options = {}; }
    return {
        name: 'html',
        options: object_1.extend({
            /**
             * Disable or enable using the CodeMirror library to highlight the HTML view.
             *
             * Default: window.CodeMirror
             */
            codeMirror: CodeMirror,
            /**
             * Specify the options for CodeMirror.
             *
             * Default:
             * {
             *    indentWithTabs: true,
             *    lineNumbers: true,
             *    lineWrapping: true,
             *    mode: 'text/html',
             *    tabMode: 'indent',
             *    tabSize: 2
             *  }
             */
            codeMirrorOptions: {
                indentWithTabs: true,
                lineNumbers: true,
                lineWrapping: true,
                mode: 'text/html',
                tabMode: 'indent',
                tabSize: 4
            },
            /**
             * A list of buttons to keep active while editor is in Code View mode.
             *
             * Default: ['fullscreen']
             */
            codeViewKeepActiveButtons: undefined,
            /**
             * Specify the options for Code Beautifier.
             *
             * Default:
             * 	{
             *    end_with_newline: true,
             *    indent_inner_html: true,
             *    extra_liners: "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
             *    brace_style: 'expand',
             *    indent_char: '\t',
             *    indent_size: 1,
             *    wrap_line_length: 0
             *  }
             */
            codeBeautifierOptions: undefined
        }, options)
    };
};
