"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
var SelectPreviewChoiceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectPreviewChoiceComponent, _super);
    function SelectPreviewChoiceComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SelectPreviewChoiceComponent.prototype.render = function (h) {
        var elems = [];
        if (this.selected.length === 1) {
            elems.push(!utils_1.isUndefined(this.selected[0].$slots.preview) ? this.selected[0].$slots.preview : this.selected[0].$slots.default);
        }
        else if (this.selected.length > 1) {
            elems.push(this.selected.length + ' éléments sélectionnés');
        }
        return h('div', {}, elems);
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Array, required: true }),
        tslib_1.__metadata("design:type", Array)
    ], SelectPreviewChoiceComponent.prototype, "selected", void 0);
    SelectPreviewChoiceComponent = tslib_1.__decorate([
        vue_class_component_1.default({})
    ], SelectPreviewChoiceComponent);
    return SelectPreviewChoiceComponent;
}(vue_1.default));
exports.SelectPreviewChoiceComponent = SelectPreviewChoiceComponent;
vue_1.default.component('form-select-preview-choice', SelectPreviewChoiceComponent);
