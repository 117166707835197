"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var form_event_1 = require("essentials/form/event/form-event");
var ValueChangedFormEvent = /** @class */ (function (_super) {
    tslib_1.__extends(ValueChangedFormEvent, _super);
    function ValueChangedFormEvent(control, old, current) {
        var _this = _super.call(this, control) || this;
        _this.oldValue = old;
        _this.newValue = current;
        return _this;
    }
    return ValueChangedFormEvent;
}(form_event_1.FormEvent));
exports.ValueChangedFormEvent = ValueChangedFormEvent;
