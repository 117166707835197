"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var select_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/form/select/select.component");
var utils_1 = require("webeak-native/util/utils");
var form_control_1 = require("essentials/form/form-control");
var error_1 = require("essentials/error");
var SelectChoiceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectChoiceComponent, _super);
    function SelectChoiceComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.checkboxControl = new form_control_1.FormControl();
        // Logic vars
        _this._item = null;
        _this.selecting = false;
        _this.parent = null;
        return _this;
    }
    Object.defineProperty(SelectChoiceComponent.prototype, "realItem", {
        // Computed
        get: function () {
            return this.item !== null ? this.item : this._item;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectChoiceComponent.prototype, "multiple", {
        /**
         * The "good" way of doing this would have been to add a @Prop "multiple" set by the parent.
         * BUT.. because I want to give the ability to set the <form-select-choice> manually like this:
         *
         * ```
         *    <form-select :control="myControl" :multiple="true">
         *        <template>Example select:</template>
         *        <template #choices>
         *            <form-select-choice :value="true">Yes</form-select-choice>
         *            <form-select-choice :value="true">No</form-select-choice>
         *        </template>
         *    </form-select>
         * ```
         *
         * I dont' want the user to have anything to think about except the value of the choice.
         *
         * Asking the user to set the "multiple" prop on each choice even if it's already set it on the "form-select"
         * is counter intuitive and asking for trouble.
         *
         * That's also why there is two props for the value ("value" and "item").
         * It's easier to let the user set any value their want and to create the ChoiceInterface in the background.
         *
         * TL;DR, there is no "multiple" prop to keep it simple to the end user.
         */
        get: function () {
            if (this.$parent instanceof select_component_1.SelectComponent) {
                return this.$parent.multiple;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle method.
     */
    SelectChoiceComponent.prototype.created = function () {
        var _this = this;
        this.parent = this.$parent instanceof select_component_1.SelectComponent ? this.$parent : null;
        if (!this.parent) {
            throw new error_1.AppError('A <form-select-choice></form-select-choice> must be placed inside a <form-select></form-select> component.');
        }
        if (this.item === null) {
            this._item = {
                id: this._uid,
                value: this.parent.formControlToHtmlValue(this.value),
                label: utils_1.isArray(this.$slots.default) && this.$slots.default.length > 0 ? this.$slots.default[0].text : '',
                selected: false,
                suggested: false,
                visible: true
            };
        }
        else {
            this.item.selected = utils_1.ensureBoolean(this.item.selected);
            this.item.suggested = utils_1.ensureBoolean(this.item.suggested);
        }
        this.parent.restoreFromPreviousSelection(this);
        this.checkboxControl.onValueChange(function (event) {
            if (!_this.selecting) {
                _this.select(event.newValue);
            }
        });
    };
    /**
     * Change the status of the selection of the item.
     */
    SelectChoiceComponent.prototype.select = function (value, flagOnly) {
        if (value === void 0) { value = true; }
        if (flagOnly === void 0) { flagOnly = false; }
        if (!this.selectable) {
            return;
        }
        if (this.multiple) {
            this.selecting = true;
            this.checkboxControl.setValue(value, true, false);
            this.selecting = false;
        }
        this.realItem.selected = value;
        if (!flagOnly) {
            if (value) {
                this.parent.selectChoice(this, !this.multiple);
            }
            else {
                this.parent.unselectChoice(this);
            }
        }
    };
    /**
     * Inverse the state of the selection.
     */
    SelectChoiceComponent.prototype.toggleSelection = function (flagOnly) {
        if (flagOnly === void 0) { flagOnly = false; }
        this.select(!this.realItem.selected, flagOnly);
    };
    /**
     * Inverse the state of the selection.
     */
    SelectChoiceComponent.prototype.selectOrToggleSelectionIfMultiple = function (flagOnly) {
        if (flagOnly === void 0) { flagOnly = false; }
        this.select(!this.multiple || !this.realItem.selected, flagOnly);
    };
    /**
     * Set if the item is suggested or not.
     */
    SelectChoiceComponent.prototype.suggest = function (value, flagOnly) {
        if (value === void 0) { value = true; }
        if (flagOnly === void 0) { flagOnly = false; }
        if (this.realItem.suggested === value || !this.selectable) {
            return;
        }
        this.realItem.suggested = value;
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [Object, String, Boolean, Number] }),
        tslib_1.__metadata("design:type", Object)
    ], SelectChoiceComponent.prototype, "value", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", Object)
    ], SelectChoiceComponent.prototype, "item", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], SelectChoiceComponent.prototype, "selectable", void 0);
    SelectChoiceComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./select-choice.component.html'),
            name: 'form-select-choice'
        })
    ], SelectChoiceComponent);
    return SelectChoiceComponent;
}(vue_1.default));
exports.SelectChoiceComponent = SelectChoiceComponent;
vue_1.default.component('form-select-choice', SelectChoiceComponent);
