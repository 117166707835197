"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var object_1 = require("webeak-native/util/object");
var utils_1 = require("webeak-native/util/utils");
var SymfonyUser = /** @class */ (function () {
    function SymfonyUser(obj) {
        if (obj === void 0) { obj = {}; }
        Object.assign(this, obj);
    }
    /**
     * Test if the user has at least one the roles given as parameter.
     */
    SymfonyUser.prototype.hasRoles = function (roles) {
        roles = utils_1.ensureArray(roles);
        return this.roles.filter(function (value) { return roles.indexOf(value) >= 0; }).length > 0;
    };
    /**
     * Create a SymfonyUser instance from the data shared by the Symfony's javascript bridge.
     */
    SymfonyUser.CreateFromBridgeData = function (input) {
        return new SymfonyUser({
            username: object_1.getObjectValue(input, 'username', null),
            isAuthenticated: object_1.getObjectValue(input, 'isAuthenticated', false),
            roles: object_1.getObjectValue(input, 'roles', [])
        });
    };
    return SymfonyUser;
}());
exports.SymfonyUser = SymfonyUser;
