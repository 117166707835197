"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("backend/vuejs/vue-app");
var container_1 = require("webeak-native/inversify/container");
var api_service_1 = require("essentials/api/api.service");
var form_group_1 = require("essentials/form/form-group");
var form_model_binder_1 = require("essentials/form/form-model-binder");
var vue_property_decorator_1 = require("vue-property-decorator");
var user_entity_1 = require("backend/entity/user.entity");
var router_1 = require("essentials/symfony/router");
var alertify_service_1 = require("essentials/dialog/alertify/alertify.service");
var FormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormComponent, _super);
    function FormComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.form = new form_group_1.FormGroup();
        _this.formBinder = null;
        _this.apiResponse = null;
        _this.api = container_1.Container.getContainer().get(api_service_1.ApiServiceSymbol);
        _this.alertify = container_1.Container.getContainer().get(alertify_service_1.AlertifyServiceSymbol);
        _this.router = container_1.Container.getContainer().get(router_1.RouterSymbol);
        return _this;
    }
    /**
     * VueJS lifecycle callback.
     */
    FormComponent.prototype.created = function () {
        var _this = this;
        this.apiResponse = this.createOrFetchEntity();
        this.apiResponse.promise.then(function (user) {
            _this.formBinder = new form_model_binder_1.FormModelBinder(_this.form, user);
        }).catch(function (error) { return void _this.alertify.notifyError(error.getPublicMessage('Échec du chargement.')); });
    };
    FormComponent.prototype.save = function () {
        var _this = this;
        this.formBinder.requestModelUpdate(function (user) {
            user.roles = null;
        }).then(function () {
            _this.apiResponse = _this.api
                .persist(user_entity_1.UserEntity, 'post_internal', 'put_internal')
                .data(_this.formBinder)
                .execute();
            _this.apiResponse.promise
                .then(function (entity) {
                _this.alertify.notifySuccess("Sauvegarde effectuée avec succès.");
                if (!_this.identifier) {
                    window.location.href = _this.router.generateUrl('app_user_detail_edit', { ref: entity.ref });
                }
            })
                .catch(function (error) { return void _this.alertify.notifyError(error.getPublicMessage('Échec de la sauvegarde.')); });
        });
    };
    FormComponent.prototype.createOrFetchEntity = function () {
        if (!this.identifier) {
            return this.api.createFakeResponse(new user_entity_1.UserEntity());
        }
        return this.api
            .get(user_entity_1.UserEntity, 'get_internal')
            .item(this.identifier)
            .execute();
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], FormComponent.prototype, "identifier", void 0);
    FormComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./form.component.html')
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], FormComponent);
    return FormComponent;
}(vue_1.default));
exports.FormComponent = FormComponent;
vue_app_1.VueApp.RegisterComponent('user-form', FormComponent);
