"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var Dropzone = require("dropzone");
var utils_1 = require("webeak-native/util/utils");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
var error_1 = require("essentials/error");
var uploading_file_1 = require("essentials/file/uploading-file");
var file_upload_error_1 = require("essentials/vuejs/components/form/file/file-upload.error");
var form_error_1 = require("essentials/form/error/form.error");
var FileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FileComponent, _super);
    function FileComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Logic vars
        _this.activeFilesCount = 0;
        _this.uploadQueue = [];
        _this.uploadingFilePromise = null;
        return _this;
    }
    // Watchers
    FileComponent.prototype.onValueChanged = function (newVal, oldVal) {
    };
    Object.defineProperty(FileComponent.prototype, "firstUploadingFile", {
        // Computed
        get: function () {
            return this.uploadQueue.length > 0 ? this.uploadQueue[0] : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileComponent.prototype, "hasFiles", {
        get: function () {
            return this.hasUploadingFiles || this.hasFormFiles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileComponent.prototype, "hasFormFiles", {
        get: function () {
            if (this.multiple) {
                return utils_1.isArray(this.controlValue) && this.controlValue.length > 0;
            }
            return this.controlValue !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileComponent.prototype, "hasUploadingFiles", {
        get: function () {
            return this.uploadQueue.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle method.
     */
    FileComponent.prototype.doInitialize = function () {
        if (utils_1.isUndefined(this.$refs.dropzone)) {
            throw new error_1.AppError("You must define an element with a ref=\"dropzone\" in your template the uploader will bind to.");
        }
        if (!utils_1.isArray(this.controlValue) && this.multiple) {
            this.controlValue = [];
        }
        this.dropzone = new Dropzone(this.$refs.dropzone, this.getDropzoneOptions());
        this.dropzone.on('addedfile', utils_1.proxy(this.onFileAdded, this));
        this.dropzone.on('error', utils_1.proxy(this.onError, this));
        this.dropzone.on('uploadprogress', utils_1.proxy(this.onUploadProgress, this));
        this.dropzone.on('sending', utils_1.proxy(this.onSending, this));
        this.dropzone.on('success', utils_1.proxy(this.onSuccess, this));
        this.dropzone.on('canceled', utils_1.proxy(this.onCanceled, this));
        return true;
    };
    /**
     * VueJS lifecycle method.
     */
    FileComponent.prototype.beforeDestroy = function () {
        this.dropzone.destroy();
        this.dropzone = null;
    };
    /**
     * Open the native file browser.
     *
     * DO NOT call this using "@click.prevent" (or any other modifier) because we will not get the event directly
     * and the browser will prevent the file browser to pop.
     */
    FileComponent.prototype.browse = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.dropzone.hiddenFileInput.click();
    };
    /**
     * Cancel the current file upload.
     */
    FileComponent.prototype.cancel = function (file) {
        this.dropzone.cancelUpload(file.originalFile);
        this.removeUploadingFile(file);
    };
    /**
     * Remove a file from the form control.
     */
    FileComponent.prototype.removeFormFile = function (file) {
        if (file === void 0) { file = null; }
        if (!this.multiple) {
            this.control.setValue(null);
        }
        else if (utils_1.isArray(this.control.value)) {
            var existing = [].concat(this.control.value);
            var pos = existing.indexOf(file);
            if (pos >= 0) {
                existing.splice(pos, 1);
                this.control.setValue(existing);
            }
        }
    };
    /**
     * Dropzone event.
     *
     * When a file is added to the list
     */
    FileComponent.prototype.onFileAdded = function (file) {
        var _this = this;
        var uploadingFile = this.createUploadingFile(file);
        if (!this.multiple && this.firstUploadingFile) {
            this.cancel(this.firstUploadingFile);
        }
        //
        // The setTimeout is important because the calling function has to finish
        // its processing to set the "accepted" flag.
        // Here we will only check if the maximum number of files is reached.
        //
        // The validation of the file type, size etc is still done by dropdown.js
        // AFTER this hook has been processed, thus the timeout.
        //
        window.setTimeout(function () {
            if (file.accepted) {
                // This will trigger the upload
                _this.dropzone.enqueueFile(file);
            }
            else {
                uploadingFile.addClientError(new form_error_1.FormError('Fichier invalide.', null, 'validation.upload.accepts'));
            }
        });
        // This will update the view
        this.uploadQueue.push(uploadingFile);
        this.control.markAs('touched', true);
    };
    /**
     * Dropzone event.
     *
     * Gets called periodically whenever the file upload progress changes.
     * When an upload finishes dropzone ensures that uploadprogress will be called with a percentage of 100 at least once.
     */
    FileComponent.prototype.onUploadProgress = function (file, progress) {
        var uploadingFile = this.getUploadingFile(file);
        if (!uploadingFile) {
            return;
        }
        // console.log('upload progress', file, progress);
        uploadingFile.progress = progress;
        if (progress < 100) {
            uploadingFile.setStatus(uploading_file_1.UploadingFileStatus.Uploading);
        }
    };
    /**
     * Dropzone event.
     *
     * Called just before each file is sent.
     */
    FileComponent.prototype.onSending = function (file, xhr, formData) {
        var uploadingFile = this.getUploadingFile(file);
        if (!uploadingFile) {
            return;
        }
        // console.log('sending', file, formData);
        if (this.preset) {
            formData.append('preset', this.preset);
        }
        uploadingFile.setStatus(uploading_file_1.UploadingFileStatus.Uploading);
    };
    /**
     * Dropzone event.
     *
     * The file has been uploaded successfully. Gets the server response as second argument.
     */
    FileComponent.prototype.onSuccess = function (file, serverResponse) {
        var uploadingFile = this.getUploadingFile(file);
        if (!uploadingFile) {
            return;
        }
        // console.log('success', file, serverResponse);
        uploadingFile.setStatus(uploading_file_1.UploadingFileStatus.Uploaded);
        this.resolveUploadingFilePromise(serverResponse);
        if (!this.multiple) {
            this.control.setValue(serverResponse);
            this.removeUploadingFile(uploadingFile);
        }
        else {
            var existing = utils_1.isArray(this.control.value) ? this.control.value : [];
            if (this.maxCount <= 0 || existing.length < this.maxCount) {
                existing.push(serverResponse);
                this.control.setValue(existing);
                this.removeUploadingFile(uploadingFile);
            }
            else {
                uploadingFile.addClientError(new form_error_1.FormError('Trop de fichiers'));
            }
        }
    };
    /**
     * Dropzone event.
     *
     * Called when a file upload gets canceled.
     */
    FileComponent.prototype.onCanceled = function (file) {
        var uploadingFile = this.getUploadingFile(file);
        if (!uploadingFile) {
            return;
        }
        // console.log('canceled', file);
        uploadingFile.setStatus(uploading_file_1.UploadingFileStatus.Canceled);
        this.rejectUploadingFilePromise('canceled');
    };
    /**
     * Dropzone event.
     *
     * An error occurred.
     */
    FileComponent.prototype.onError = function (file, message, xhr) {
        var uploadingFile = this.getUploadingFile(file);
        if (file.status === 'canceled' || !uploadingFile) {
            return;
        }
        var payload = null;
        var errors = ['Échec de l\'envoi pour une raison inconnue.'];
        try {
            payload = JSON.parse(xhr.response);
        }
        catch (e) { }
        // console.log('error', file, message, xhr);
        if (utils_1.isObject(payload) && utils_1.isArray(payload.errors) && payload.errors.length > 0) {
            errors = payload.errors;
        }
        uploadingFile.setStatus(uploading_file_1.UploadingFileStatus.Error);
        uploadingFile.serverErrors = errors;
        this.rejectUploadingFilePromise('error', errors[0]);
    };
    /**
     * Create an UploadingFile instance from a File instance.
     */
    FileComponent.prototype.createUploadingFile = function (file) {
        var _this = this;
        var uploadingFile = new uploading_file_1.UploadingFile();
        uploadingFile.name = file.name;
        uploadingFile.size = file.size;
        uploadingFile.type = file.type;
        uploadingFile.originalFile = file;
        uploadingFile.file = null;
        uploadingFile.clientErrors = [];
        uploadingFile.hasError = false;
        uploadingFile.illustration = null;
        uploadingFile.progress = 0;
        uploadingFile.setStatus(uploading_file_1.UploadingFileStatus.Paused);
        uploadingFile.promise = new Promise(function (resolve, reject) {
            _this.uploadingFilePromise = { resolve: resolve, reject: reject };
        });
        return uploadingFile;
    };
    /**
     * Try to get the UploadingFile instance corresponding to a dropzone file instance.
     */
    FileComponent.prototype.getUploadingFile = function (file) {
        for (var _i = 0, _a = this.uploadQueue; _i < _a.length; _i++) {
            var candidate = _a[_i];
            if (candidate.originalFile === file) {
                return candidate;
            }
        }
        return null;
    };
    /**
     * Remove a file from the queue.
     * Note: this method will not stop the upload or do anything with Dropzone.js.
     */
    FileComponent.prototype.removeUploadingFile = function (file) {
        for (var i = 0; i < this.uploadQueue.length; ++i) {
            if (this.uploadQueue[i] === file) {
                this.uploadQueue.splice(i, 1);
                return true;
            }
        }
        return false;
    };
    /**
     * Get the object of options to pass to the Dropzone instance.
     */
    FileComponent.prototype.getDropzoneOptions = function () {
        var options = {
            autoQueue: false,
            url: this.url,
            method: this.method,
            headers: this.headers,
            withCredentials: this.withCredentials,
            timeout: this.timeout,
            parallelUploads: this.parallelUploads,
            uploadMultiple: false,
            chunking: false,
            forceChunking: false,
            maxFilesize: this.maxSize,
            paramName: this.paramName,
            createImageThumbnails: this.createImageThumbnails,
            maxThumbnailFilesize: this.maxThumbnailFileSize,
            thumbnailWidth: this.thumbnailWidth,
            thumbnailHeight: this.thumbnailHeight,
            thumbnailMethod: this.thumbnailMethod,
            maxFiles: this.maxCount === 1 ? 1 : null,
            clickable: this.clickable,
            ignoreHiddenFiles: this.ignoreHiddenFiles,
            acceptedFiles: this.accepts,
            autoProcessQueue: this.autoProcessQueue,
            previewsContainer: false,
            hiddenInputContainer: "body" // Wrong typings, should accept strings
        };
        // Typings out of date, the "renameFile" option is not implemented yet.
        options.renameFile = this.renameFile;
        return options;
    };
    /**
     * Resolve the promise associated with an uploading file (if available).
     */
    FileComponent.prototype.resolveUploadingFilePromise = function (file) {
        if (this.uploadingFilePromise) {
            this.uploadingFilePromise.resolve(file);
            this.uploadingFilePromise = null;
        }
    };
    /**
     * Reject the promise associated with an uploading file (if available).
     */
    FileComponent.prototype.rejectUploadingFilePromise = function (errorType, message) {
        if (errorType === void 0) { errorType = null; }
        if (message === void 0) { message = null; }
        if (this.uploadingFilePromise) {
            this.uploadingFilePromise.reject(file_upload_error_1.FileUploadError.create(errorType, message));
            this.uploadingFilePromise = null;
        }
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: '/file/upload-single' }),
        tslib_1.__metadata("design:type", String)
    ], FileComponent.prototype, "url", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], FileComponent.prototype, "preset", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'post' }),
        tslib_1.__metadata("design:type", String)
    ], FileComponent.prototype, "method", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 0 }),
        tslib_1.__metadata("design:type", Number)
    ], FileComponent.prototype, "timeout", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], FileComponent.prototype, "withCredentials", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], FileComponent.prototype, "ignoreHiddenFiles", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], FileComponent.prototype, "accepts", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [Boolean, String, Array], default: true }),
        tslib_1.__metadata("design:type", Object)
    ], FileComponent.prototype, "clickable", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", Object)
    ], FileComponent.prototype, "headers", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'file' }),
        tslib_1.__metadata("design:type", String)
    ], FileComponent.prototype, "paramName", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: null }),
        tslib_1.__metadata("design:type", Number)
    ], FileComponent.prototype, "maxSize", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], FileComponent.prototype, "multiple", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: null }),
        tslib_1.__metadata("design:type", Number)
    ], FileComponent.prototype, "maxCount", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 2 }),
        tslib_1.__metadata("design:type", Number)
    ], FileComponent.prototype, "parallelUploads", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], FileComponent.prototype, "autoProcessQueue", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], FileComponent.prototype, "createImageThumbnails", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 10 }),
        tslib_1.__metadata("design:type", Number)
    ], FileComponent.prototype, "maxThumbnailFileSize", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 120 }),
        tslib_1.__metadata("design:type", Number)
    ], FileComponent.prototype, "thumbnailWidth", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Number, default: 120 }),
        tslib_1.__metadata("design:type", Number)
    ], FileComponent.prototype, "thumbnailHeight", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'crop' }),
        tslib_1.__metadata("design:type", String)
    ], FileComponent.prototype, "thumbnailMethod", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Function, default: null }),
        tslib_1.__metadata("design:type", Function)
    ], FileComponent.prototype, "renameFile", void 0);
    FileComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./file.component.html'),
        })
    ], FileComponent);
    return FileComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.FileComponent = FileComponent;
vue_1.default.component('form-file', FileComponent);
