"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var list_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list.component");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
var ListRowComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListRowComponent, _super);
    function ListRowComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Logic vars
        _this.components = {};
        return _this;
    }
    /**
     * VueJS lifecycle method.
     */
    ListRowComponent.prototype.mounted = function () {
        var firstLine = this.$el.parentNode.parentNode.children[0];
        if (firstLine && firstLine === this.$el.parentNode) {
            var $parent = this;
            do {
                $parent = $parent.$parent;
                if ($parent instanceof list_component_1.ListComponent) {
                    break;
                }
            } while ($parent);
            if (!($parent instanceof list_component_1.ListComponent)) {
                return void console.error('A <list-row></list-row> must be placed inside a <list></list> component.');
            }
            $parent.registerRow({});
        }
    };
    /**
     * VueJS lifecycle method.
     */
    ListRowComponent.prototype.beforeDestroy = function () {
        this.components = {};
    };
    /**
     * Get a registered component.
     */
    ListRowComponent.prototype.getComponent = function (id) {
        if (utils_1.isUndefined(this.components[id])) {
            return null;
        }
        return this.components[id];
    };
    /**
     * Register a component so it is accessible by other components on the row.
     */
    ListRowComponent.prototype.registerComponent = function (id, component) {
        this.components[id] = component;
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], ListRowComponent.prototype, "detailsVisible", void 0);
    ListRowComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-row.component.html')
        })
    ], ListRowComponent);
    return ListRowComponent;
}(vue_1.default));
exports.ListRowComponent = ListRowComponent;
vue_app_1.VueApp.RegisterComponent('list-row', ListRowComponent);
