"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var error_1 = require("essentials/error");
/**
 * Error indicating the server cannot be contacted.
 */
var NetworkError = /** @class */ (function (_super) {
    tslib_1.__extends(NetworkError, _super);
    function NetworkError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NetworkError;
}(error_1.AppError));
exports.NetworkError = NetworkError;
