"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Validators can optionally register in this container so
 * they can be resolve from a string expression.
 */
var ValidatorContainer = /** @class */ (function () {
    function ValidatorContainer() {
    }
    /**
     * Associate a string to a ValidatorInterface so it can be resolved from a string.
     */
    ValidatorContainer.register = function (name, validatorFactory) {
        this.Validators[name] = validatorFactory;
    };
    ValidatorContainer.Validators = {};
    return ValidatorContainer;
}());
exports.ValidatorContainer = ValidatorContainer;
