"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var api_resource_decorator_1 = require("essentials/api/decorators/api-resource.decorator");
var form_component_decorator_1 = require("essentials/form/decorators/form-component.decorator");
var abstract_entity_1 = require("essentials/entity/abstract.entity");
var not_blank_1 = require("essentials/validation/types/not-blank");
var assert_decorator_1 = require("essentials/validation/decorators/assert.decorator");
var model_1 = require("essentials/model/transformer/types/model");
var application_module_entity_1 = require("backend/entity/application-module.entity");
var collection_1 = require("essentials/model/transformer/types/collection");
var and_1 = require("essentials/validation/types/and");
var max_1 = require("essentials/validation/types/max");
var pattern_1 = require("essentials/validation/types/pattern");
var ApplicationFeatureEntity = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationFeatureEntity, _super);
    function ApplicationFeatureEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = '';
        _this.description = '';
        _this.module = null;
        _this.usersFeatures = [];
        return _this;
    }
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(not_blank_1.NotBlank(), max_1.Max(20), pattern_1.Pattern(/^[a-z-]+$/))),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationFeatureEntity.prototype, "name", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], ApplicationFeatureEntity.prototype, "description", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.Model('ApplicationModuleEntity')),
        tslib_1.__metadata("design:type", application_module_entity_1.ApplicationModuleEntity)
    ], ApplicationFeatureEntity.prototype, "module", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(collection_1.Collection(model_1.Model('ApplicationUserFeatureEntity'))),
        tslib_1.__metadata("design:type", Array)
    ], ApplicationFeatureEntity.prototype, "usersFeatures", void 0);
    ApplicationFeatureEntity = tslib_1.__decorate([
        api_resource_decorator_1.ApiResource('3IY22A')
    ], ApplicationFeatureEntity);
    return ApplicationFeatureEntity;
}(abstract_entity_1.AbstractEntity));
exports.ApplicationFeatureEntity = ApplicationFeatureEntity;
