"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_response_1 = require("essentials/network/http-response");
var network_1 = require("essentials/network");
var container_1 = require("webeak-native/inversify/container");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var constants_1 = require("essentials/network/constants");
var form_transformation_model_schema_1 = require("essentials/form/form-transformation-model.schema");
var utils_1 = require("webeak-native/util/utils");
var error_1 = require("essentials/error");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var HttpPersister = /** @class */ (function () {
    function HttpPersister(url, entity, method) {
        this.url = url;
        this.entity = entity;
        this.method = method;
        this.http = container_1.Container.getContainer().get(network_1.HttpServiceSymbol);
        this.transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
    }
    /**
     * @inheritDoc
     */
    HttpPersister.prototype.persist = function (form) {
        var _this = this;
        var response = new http_response_1.HttpResponse(constants_1.HttpResponseStatus.Pending);
        response.promise = new Promise(function (resolve, reject) {
            _this.prepareData(form).then(function (normalizedData) {
                var realResponse = _this.http[_this.method].apply(_this.http, [_this.url, normalizedData]);
                realResponse.promise.finally(function () {
                    response.syncWith(realResponse);
                    if (response.isSuccess) {
                        _this.applyResponseToForm(response, form).finally(resolve);
                    }
                    else {
                        reject(response.error);
                    }
                });
            });
        });
        return response;
    };
    /**
     * If the server returned an object, apply these values to the form.
     */
    HttpPersister.prototype.applyResponseToForm = function (response, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tempForm;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!utils_1.isObject(response.result)) {
                            return [2 /*return*/];
                        }
                        if (!this.entity) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.transformer.transformTransversal(response.result, generic_transformation_model_schema_1.GenericTransformationModelSchema, form_transformation_model_schema_1.FormTransformationModelSchema, this.entity)];
                    case 1:
                        tempForm = _a.sent();
                        tempForm.markAs('virtual', false, true, true);
                        form.setValue(tempForm.value);
                        return [2 /*return*/];
                    case 2:
                        form.setValue(response.result);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Try to normalize the input form into a format the http service can handle.
     *
     * No transformation can be done if no entity has been defined.
     * In such a case we must ensure the form data only contains scalar values, otherwise problems could arise.
     */
    HttpPersister.prototype.prepareData = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.entity) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.transformer.transformInverse(form, form_transformation_model_schema_1.FormTransformationModelSchema, this.entity)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        if (!utils_1.isPojo(form.value, true)) {
                            throw new error_1.AppError('You cannot save this form without binding it to an entity because it ' +
                                'contains complex values that cannot be normalized without a transformation schema.');
                        }
                        return [2 /*return*/, form.value];
                }
            });
        });
    };
    return HttpPersister;
}());
exports.HttpPersister = HttpPersister;
