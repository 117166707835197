"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var network_error_1 = require("./error/network.error");
var authentication_error_1 = require("./error/authentication.error");
var http_error_1 = require("./error/http.error");
var network_watcher_service_1 = require("./network-watcher.service");
var log_1 = require("../log");
var error_1 = require("../error");
var container_1 = require("webeak-native/inversify/container");
var shared_configuration_1 = require("essentials/config/shared-configuration");
var utils_1 = require("webeak-native/util/utils");
var http_request_1 = require("essentials/network/http-request");
var constants_1 = require("essentials/network/constants");
var http_response_1 = require("essentials/network/http-response");
var utils_2 = require("essentials/network/utils");
var cancel_error_1 = require("essentials/network/error/cancel.error");
var HttpService = /** @class */ (function () {
    function HttpService(config, networkWatcher, logger) {
        this.config = config;
        this.networkWatcher = networkWatcher;
        this.logger = logger;
        this.requestsQueue = [];
        this.queueProcessTimeout = null;
        this.networkWatcher.watch().subscribe(utils_1.proxy(this.onNetworkAvailabilityChange, this));
    }
    /**
     * Do a GET request.
     */
    HttpService.prototype.get = function (url, headers, retryCount) {
        if (retryCount === void 0) { retryCount = 3; }
        return this.request(new http_request_1.HttpRequest(constants_1.HttpMethods.GET, url, null, headers, retryCount));
    };
    /**
     * Do a POST request.
     */
    HttpService.prototype.post = function (url, body, headers, retryCount) {
        if (retryCount === void 0) { retryCount = 3; }
        return this.request(new http_request_1.HttpRequest(constants_1.HttpMethods.POST, url, body, headers, retryCount));
    };
    /**
     * Do a PUT request.
     */
    HttpService.prototype.put = function (url, body, headers, retryCount) {
        if (retryCount === void 0) { retryCount = 3; }
        return this.request(new http_request_1.HttpRequest(constants_1.HttpMethods.PUT, url, body, headers, retryCount));
    };
    /**
     * Do a request.
     */
    HttpService.prototype.request = function (request) {
        var _this = this;
        var response = new http_response_1.HttpResponse();
        response.setStatus(constants_1.HttpResponseStatus.Pending);
        response.promise = new Promise(function (resolve, reject) {
            // In case the request has been canceled right after the call to request()
            // and before the promise micro task have been executed.
            if (response.isCanceled) {
                reject(null);
                return;
            }
            response.cancel = function () {
                response.setStatus(constants_1.HttpResponseStatus.Canceled);
                reject(null);
            };
            _this.queueRequest(request, response, 0, resolve, reject);
        });
        return response;
    };
    /**
     * Do a request.
     */
    HttpService.prototype.executeQueuedRequest = function (request) {
        var _this = this;
        // The request may have been canceled while in queue, in such a case simply ignore it.
        // The promise has already been resolved by the default "cancel()" callback inside the HttpResponse.
        if (request.response.isCanceled) {
            this.removeFromQueue(request);
            return;
        }
        var jqueryAjaxOptions = {
            url: request.url,
            method: request.method,
            headers: request.headers || {},
            dataType: 'json',
            contentType: 'application/json',
            xhrFields: {
                withCredentials: true
            },
        };
        if (request.method === constants_1.HttpMethods.POST || request.method === constants_1.HttpMethods.PUT) {
            jqueryAjaxOptions.data = JSON.stringify(request.payload);
        }
        this.logger.debug('Execute request.', { request: request });
        request.jqXHR = $.ajax(jqueryAjaxOptions);
        request.isExecuting = true;
        request.jqXHR.then(function (response, statusText, jqXHR) {
            _this.logger.debug('Request success.', { request: request });
            _this.setRequestRawResult(jqXHR, request.response);
            request.response.result = response;
            request.response.setStatus(constants_1.HttpResponseStatus.Success);
            request.resolve(response);
            _this.removeFromQueue(request);
        }).catch(function (jqXHR) {
            if (request.response.isCanceled) {
                return;
            }
            _this.setRequestRawResult(jqXHR, request.response);
            request.isExecuting = false;
            request.onError = true;
            // We may have a network issue, but we will have to be sure before choosing what to do.
            if (!_this.networkWatcher.isOnline()) {
                _this.logger.error('Request failed maybe because of a connection error.', { request: request, jqXHR: jqXHR });
                request.executeAt = (new Date()).getTime() + _this.config.network.connectionErrorRetryDelay;
                if (request.triesLeft > 0) {
                    _this.logger.debug(request.triesLeft + ' tries left. Request will be queued again.');
                    request.triesLeft--;
                }
                else {
                    _this.logger.debug('Request has expired all its tries, rejecting its promise.');
                    _this.removeFromQueue(request);
                    _this.rejectRequest(request, new error_1.PublicAppError('Impossible de joindre le serveur.', new network_error_1.NetworkError(jqXHR.responseText)));
                    return;
                }
                _this.scheduleQueueForProcess();
                return;
            }
            if (jqXHR.status === 401 && _this.config.network.reloadOnAuthenticationError) {
                window.location.reload();
                return;
            }
            if (jqXHR.status === 401 || jqXHR.status === 403) {
                _this.logger.error('Request failed because of an authentication error.', { request: request });
                _this.rejectRequest(request, new error_1.PublicAppError('Vous n\'avez pas les droits nécessaires pour accéder à cette ressource.', new authentication_error_1.AuthenticationError(401)));
            }
            else if (jqXHR.status === 404) {
                _this.logger.error('Request failed because the url was not found.', { request: request, jqXHR: jqXHR });
                _this.rejectRequest(request, new error_1.PublicAppError('Resource non trouvée sur le serveur.', http_error_1.HttpError.create(jqXHR.statusText)));
            }
            else if (utils_1.isObject(jqXHR.responseJSON) && jqXHR.responseJSON.type.indexOf("PublicException", "Webeak\\Bundle\\EssentialBundle\\Exception\\PublicException") >= 0 && utils_1.isString(jqXHR.responseJSON.message)) {
                _this.rejectRequest(request, new error_1.PublicAppError(jqXHR.responseJSON.message, http_error_1.HttpError.create(jqXHR.statusText)));
            }
            else {
                _this.logger.error('Request failed for an unknown reason.', { request: request, jqXHR: jqXHR });
                _this.rejectRequest(request, new error_1.PublicAppError('Échec de la requête au serveur pour une erreur interne.', http_error_1.HttpError.create(jqXHR.statusText, 'Unknown reason', jqXHR.responseJSON)));
            }
            _this.removeFromQueue(request);
        });
        // Setup the cancel callback.
        request.response.cancel = function () {
            request.jqXHR.abort();
            request.response.setStatus(constants_1.HttpResponseStatus.Canceled);
            request.reject(new cancel_error_1.CancelError());
            _this.removeFromQueue(request);
        };
    };
    /**
     * Mark the request as on error, reject its promise and set the error.
     */
    HttpService.prototype.rejectRequest = function (request, error) {
        request.response.setStatus(constants_1.HttpResponseStatus.Error);
        request.response.error = error;
        request.reject(error);
    };
    /**
     * Take the raw response form the server and assign it to the client response.
     */
    HttpService.prototype.setRequestRawResult = function (xhr, clientResponse) {
        clientResponse.httpStatusCode = xhr.status;
        clientResponse.httpStatusText = xhr.statusText;
        if (!utils_1.isNullOrUndefined(xhr.responseJSON)) {
            clientResponse.rawResult = utils_2.stripXssiPrefix(xhr.responseText);
            clientResponse.rawResultType = 'json';
            return;
        }
        var contentType = xhr.getResponseHeader("content-type") || '';
        clientResponse.rawResultType = (contentType.indexOf('html') >= 0) ? 'html' : 'text';
        clientResponse.rawResult = xhr.responseText;
    };
    /**
     * Process available request and prepare the next process queue if the queue still contains request.
     */
    HttpService.prototype.processQueue = function () {
        this.logger.debug('Process queue.');
        var currentTime = (new Date()).getTime();
        for (var _i = 0, _a = this.requestsQueue; _i < _a.length; _i++) {
            var request = _a[_i];
            if (!request.isExecuting && request.executeAt <= currentTime) {
                this.executeQueuedRequest(request);
            }
        }
        this.scheduleQueueForProcess();
    };
    /**
     * Remove a request from the queue.
     */
    HttpService.prototype.removeFromQueue = function (request) {
        for (var i = 0; i < this.requestsQueue.length; ++i) {
            if (this.requestsQueue[i] === request) {
                this.logger.debug('Remove request from queue.', { request: request });
                this.requestsQueue.splice(i, 1);
                return;
            }
        }
    };
    /**
     * Queue a request for retry.
     */
    HttpService.prototype.queueRequest = function (request, response, executeAt, resolve, reject) {
        this.requestsQueue.push({
            method: request.method,
            url: request.url,
            payload: request.payload,
            headers: request.headers,
            triesLeft: request.maxRetryCount,
            executeAt: executeAt,
            resolve: resolve,
            reject: reject,
            isExecuting: false,
            onError: false,
            jqXHR: null,
            response: response
        });
        this.logger.debug('Queue http request', { request: this.requestsQueue[this.requestsQueue.length - 1] });
        this.scheduleQueueForProcess();
    };
    /**
     * Put a timeout to process the queue as soon as the less delayed request is available for retry.
     * A timeout will always be set even if no request ask for a delay.
     */
    HttpService.prototype.scheduleQueueForProcess = function () {
        var _this = this;
        if (this.queueProcessTimeout !== null || !this.requestsQueue.length) {
            return;
        }
        var currentTime = (new Date()).getTime();
        var delay = null;
        for (var _i = 0, _a = this.requestsQueue; _i < _a.length; _i++) {
            var request = _a[_i];
            if (request.isExecuting) {
                continue;
            }
            var delta = request.executeAt > 0 ? Math.max(0, request.executeAt - currentTime) : 0;
            if (delay === null || delay > delta) {
                delay = delta;
            }
        }
        if (delay !== null) {
            this.logger.debug('Waiting ' + delay + 'ms before processing http requests queue.');
            this.queueProcessTimeout = setTimeout(function () {
                _this.queueProcessTimeout = null;
                _this.processQueue();
            }, delay);
        }
    };
    /**
     * Called when the status on the internet connection changes.
     */
    HttpService.prototype.onNetworkAvailabilityChange = function (online) {
        if (online) {
            this.logger.info('Network retrieved, schedule the http requests queue for processing.');
            for (var _i = 0, _a = this.requestsQueue; _i < _a.length; _i++) {
                var queuedRequest = _a[_i];
                if (queuedRequest.onError) {
                    queuedRequest.executeAt = 0;
                }
            }
            if (this.queueProcessTimeout !== null) {
                clearTimeout(this.queueProcessTimeout);
                this.queueProcessTimeout = null;
            }
            this.scheduleQueueForProcess();
        }
    };
    HttpService = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(shared_configuration_1.SharedConfigurationSymbol)),
        tslib_1.__param(1, inversify_1.inject(network_watcher_service_1.NetworkWatcherServiceSymbol)),
        tslib_1.__param(2, inversify_1.inject(log_1.LoggerServiceSymbol)),
        tslib_1.__metadata("design:paramtypes", [shared_configuration_1.SharedConfiguration,
            network_watcher_service_1.NetworkWatcherService,
            log_1.LoggerService])
    ], HttpService);
    return HttpService;
}());
exports.HttpService = HttpService;
exports.HttpServiceSymbol = Symbol("HttpService");
container_1.Container.registerService(exports.HttpServiceSymbol, HttpService);
