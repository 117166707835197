"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var api_resource_decorator_1 = require("essentials/api/decorators/api-resource.decorator");
var form_component_decorator_1 = require("essentials/form/decorators/form-component.decorator");
var abstract_entity_1 = require("essentials/entity/abstract.entity");
var not_blank_1 = require("essentials/validation/types/not-blank");
var assert_decorator_1 = require("essentials/validation/decorators/assert.decorator");
var and_1 = require("essentials/validation/types/and");
var email_1 = require("essentials/validation/types/email");
var collection_1 = require("essentials/model/transformer/types/collection");
var model_1 = require("essentials/model/transformer/types/model");
var form_array_1 = require("essentials/form/transformers/form-array");
var form_group_1 = require("essentials/form/transformers/form-group");
var raw_1 = require("essentials/model/transformer/types/raw");
var application_entity_1 = require("backend/entity/application.entity");
var object_1 = require("webeak-native/util/object");
var UserEntity = /** @class */ (function (_super) {
    tslib_1.__extends(UserEntity, _super);
    function UserEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.firstName = '';
        _this.lastName = '';
        _this.email = '';
        _this.active = null;
        _this.validated = null;
        _this.bot = null;
        _this.roles = null;
        _this.applicationsFeatures = [];
        _this.mainApplication = null;
        _this.accessibleApplications = [];
        return _this;
    }
    UserEntity.prototype.hasApplicationFeature = function (feature) {
        for (var _i = 0, _a = this.applicationsFeatures; _i < _a.length; _i++) {
            var candidate = _a[_i];
            if (candidate.feature.ref === feature.ref) {
                return true;
            }
        }
        return false;
    };
    UserEntity.prototype.getAllApplicationsNames = function () {
        var names = [];
        for (var _i = 0, _a = this.applicationsFeatures; _i < _a.length; _i++) {
            var applicationFeature = _a[_i];
            var application = object_1.getObjectValue(applicationFeature, ['feature', 'module', 'application']);
            if (application && names.indexOf(application.name) < 0) {
                names.push(application.name);
            }
        }
        return names;
    };
    UserEntity.prototype.hasAccessToApplication = function (applicationRef) {
        for (var _i = 0, _a = this.accessibleApplications; _i < _a.length; _i++) {
            var candidate = _a[_i];
            if (candidate.ref === applicationRef) {
                return true;
            }
        }
        return false;
    };
    tslib_1.__decorate([
        assert_decorator_1.Assert(not_blank_1.NotBlank()),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], UserEntity.prototype, "firstName", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(not_blank_1.NotBlank()),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], UserEntity.prototype, "lastName", void 0);
    tslib_1.__decorate([
        assert_decorator_1.Assert(and_1.And(not_blank_1.NotBlank(), email_1.Email())),
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], UserEntity.prototype, "email", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive('boolean')),
        tslib_1.__metadata("design:type", Boolean)
    ], UserEntity.prototype, "active", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive('boolean')),
        tslib_1.__metadata("design:type", Boolean)
    ], UserEntity.prototype, "validated", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(primitive_1.Primitive('boolean')),
        tslib_1.__metadata("design:type", Boolean)
    ], UserEntity.prototype, "bot", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(),
        transformable_decorator_1.Transformable(raw_1.Raw()),
        tslib_1.__metadata("design:type", Object)
    ], UserEntity.prototype, "roles", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(form_array_1.FormArray(form_group_1.FormGroup('applicationsFeatures', 'ApplicationUserFeatureEntity'))),
        transformable_decorator_1.Transformable(collection_1.Collection(model_1.Model('ApplicationUserFeatureEntity'))),
        tslib_1.__metadata("design:type", Array)
    ], UserEntity.prototype, "applicationsFeatures", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(raw_1.Raw()),
        transformable_decorator_1.Transformable(model_1.Model('ApplicationEntity')),
        tslib_1.__metadata("design:type", application_entity_1.ApplicationEntity)
    ], UserEntity.prototype, "mainApplication", void 0);
    tslib_1.__decorate([
        form_component_decorator_1.FormComponent(form_array_1.FormArray()),
        transformable_decorator_1.Transformable(collection_1.Collection(model_1.Model('ApplicationEntity'))),
        tslib_1.__metadata("design:type", Array)
    ], UserEntity.prototype, "accessibleApplications", void 0);
    UserEntity = tslib_1.__decorate([
        api_resource_decorator_1.ApiResource('J9JWFJ')
    ], UserEntity);
    return UserEntity;
}(abstract_entity_1.AbstractEntity));
exports.UserEntity = UserEntity;
