"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Base class of all events.
 */
var EventArg = /** @class */ (function () {
    function EventArg() {
        /**
         * Holds if the propagation is stopped.
         */
        this.propagationStopped = false;
    }
    /**
     * Stop the event propagation so no other listener is called.
     */
    EventArg.prototype.stopPropagation = function () {
        this.propagationStopped = true;
    };
    /**
     * Test if the propagation has been stopped for this event.
     */
    EventArg.prototype.isPropagationStopped = function () {
        return this.propagationStopped === true;
    };
    return EventArg;
}());
exports.EventArg = EventArg;
