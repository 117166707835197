"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var api_metadata_factory_1 = require("essentials/api/metadata/api-metadata.factory");
var abstract_api_request_builder_1 = require("essentials/api/request-builder/abstract-api-request-builder");
var api_request_1 = require("essentials/api/api-request");
var constants_1 = require("essentials/network/constants");
var object_1 = require("webeak-native/util/object");
/**
 * Offers an easy way to create a GET request for a single entity in the Api.
 */
var ApiGetItemRequestBuilder = /** @class */ (function (_super) {
    tslib_1.__extends(ApiGetItemRequestBuilder, _super);
    function ApiGetItemRequestBuilder(metadataFactory) {
        var _this = _super.call(this, metadataFactory) || this;
        _this.action('get');
        return _this;
    }
    /**
     * Sets the name of the action to execute.
     */
    ApiGetItemRequestBuilder.prototype.action = function (name) {
        this.actionName = name + '_item';
        return this;
    };
    /**
     * Sets the builder to create the request for a specific item.
     */
    ApiGetItemRequestBuilder.prototype.identifier = function (identifier) {
        this.entityIdentifier = identifier;
        return this;
    };
    /**
     * @inheritdoc
     */
    ApiGetItemRequestBuilder.prototype.createRequest = function (metadata) {
        var queryParameters = this.getFilteringQueryParameters(metadata);
        var resourceMetadata = metadata.getResourceMetadata(this.entityType);
        var actionMetadata = resourceMetadata.getActionMetadata(this.actionName);
        var additionalUriReplacements = {};
        if (this.entityIdentifier) {
            additionalUriReplacements['id'] = this.entityIdentifier;
        }
        return new api_request_1.ApiRequest(resourceMetadata, actionMetadata, constants_1.HttpMethods.GET, this.generateUri(metadata, this.entityType, this.actionName, false, object_1.merge(this.uriReplacements, additionalUriReplacements), object_1.merge(this.queryParameters, queryParameters)), {}, {});
    };
    ApiGetItemRequestBuilder = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(api_metadata_factory_1.ApiMetadataFactorySymbol)),
        tslib_1.__metadata("design:paramtypes", [api_metadata_factory_1.ApiMetadataFactory])
    ], ApiGetItemRequestBuilder);
    return ApiGetItemRequestBuilder;
}(abstract_api_request_builder_1.AbstractApiRequestBuilder));
exports.ApiGetItemRequestBuilder = ApiGetItemRequestBuilder;
exports.ApiGetItemRequestBuilderSymbol = Symbol("ApiGetItemRequestBuilder");
container_1.Container.registerModule(exports.ApiGetItemRequestBuilderSymbol, ApiGetItemRequestBuilder);
