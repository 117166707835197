"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var app_1 = require("essentials/app");
var container_1 = require("webeak-native/inversify/container");
var symfony_bag_1 = require("essentials/symfony/symfony-bag");
var utils_1 = require("webeak-native/util/utils");
var alertify_service_1 = require("essentials/dialog/alertify/alertify.service");
var App = /** @class */ (function (_super) {
    tslib_1.__extends(App, _super);
    function App() {
        var _this = _super.call(this) || this;
        _this.alertify = container_1.Container.getContainer().get(alertify_service_1.AlertifyServiceSymbol);
        return _this;
    }
    /**
     * @inheritDoc
     */
    App.prototype.start = function () {
        _super.prototype.start.call(this);
        this.showServerFlashes();
    };
    /**
     * Call alertify for each flashes added in the Symfony.
     */
    App.prototype.showServerFlashes = function () {
        var symfonyBag = container_1.Container.getContainer().get(symfony_bag_1.SymfonyBagSymbol);
        var flashes = symfonyBag.get('flashes');
        if (utils_1.isObject(flashes)) {
            for (var type in flashes) {
                var fn = 'notify' + type.substring(0, 1).toUpperCase() + type.substring(1);
                if (flashes.hasOwnProperty(type) && utils_1.isFunction(this.alertify[fn])) {
                    for (var _i = 0, _a = flashes[type]; _i < _a.length; _i++) {
                        var message = _a[_i];
                        this.alertify[fn].call(fn, message);
                    }
                }
            }
        }
    };
    return App;
}(app_1.App));
exports.App = App;
// Unbind the core app symbol so we can register a new one.
container_1.Container.getContainer().unbind(app_1.AppSymbol);
container_1.Container.getContainer().bind(app_1.AppSymbol).to(App).inSingletonScope();
