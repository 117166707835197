"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var rxjs_1 = require("rxjs");
var utils_1 = require("webeak-native/util/utils");
var event_1 = require("essentials/event");
var log_1 = require("essentials/log");
var container_1 = require("webeak-native/inversify/container");
var NetworkWatcherService = /** @class */ (function () {
    function NetworkWatcherService(eventDispatcher, logger) {
        this.eventDispatcher = eventDispatcher;
        this.logger = logger;
        this.isSupported = utils_1.isObject(window.navigator);
        this.isOnlineAttr = this.isSupported ? window.navigator.onLine !== false : true;
        this.monitoringSubscription = null;
        this.onConnectionRetrievedFn = null;
        this.onConnectionLostFn = null;
        this.observers = [];
    }
    /**
     * Test if the current connection has access to the internet.
     */
    NetworkWatcherService.prototype.isOnline = function () {
        return this.isOnlineAttr;
    };
    /**
     * Start watching the network status.
     */
    NetworkWatcherService.prototype.watch = function () {
        var _this = this;
        this.logger.debug('Start watching the network...');
        if (this.onConnectionLostFn === null) {
            this.onConnectionRetrievedFn = utils_1.proxy(this.onConnectionRetrieved, this);
            this.onConnectionLostFn = utils_1.proxy(this.onConnectionLost, this);
            window.addEventListener('online', this.onConnectionRetrievedFn);
            window.addEventListener('offline', this.onConnectionLostFn);
        }
        return new rxjs_1.Observable(function (observer) {
            _this.observers.push(observer);
        });
    };
    /**
     * Stop watching the network.
     */
    NetworkWatcherService.prototype.unwatch = function () {
        if (this.onConnectionLostFn === null) {
            return;
        }
        this.logger.debug('Stop watching the network.');
        window.addEventListener('online', this.onConnectionRetrievedFn);
        window.addEventListener('offline', this.onConnectionLostFn);
    };
    /**
     * Called when the connection is lost.
     */
    NetworkWatcherService.prototype.onConnectionLost = function () {
        this.logger.info('Connection lost.');
        for (var _i = 0, _a = this.observers; _i < _a.length; _i++) {
            var observer = _a[_i];
            observer.next(false);
        }
        this.eventDispatcher.dispatch('network:offline');
        this.isOnlineAttr = false;
    };
    /**
     * Called when the connection becomes available again.
     */
    NetworkWatcherService.prototype.onConnectionRetrieved = function () {
        this.logger.info('Connection retrieved.');
        for (var _i = 0, _a = this.observers; _i < _a.length; _i++) {
            var observer = _a[_i];
            observer.next(true);
        }
        this.eventDispatcher.dispatch('network:online');
        this.isOnlineAttr = true;
    };
    NetworkWatcherService = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(event_1.EventDispatcherServiceSymbol)),
        tslib_1.__param(1, inversify_1.inject(log_1.LoggerServiceSymbol)),
        tslib_1.__metadata("design:paramtypes", [event_1.EventDispatcherService,
            log_1.LoggerService])
    ], NetworkWatcherService);
    return NetworkWatcherService;
}());
exports.NetworkWatcherService = NetworkWatcherService;
exports.NetworkWatcherServiceSymbol = Symbol("NetworkWatcherService");
container_1.Container.registerService(exports.NetworkWatcherServiceSymbol, NetworkWatcherService);
