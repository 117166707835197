"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var validation_event_type_1 = require("essentials/validation/event/validation-event-type");
var validation_event_1 = require("essentials/validation/event/validation-event");
/**
 * Triggered when the validation process has finished.
 */
var ValidationCompleteEvent = /** @class */ (function (_super) {
    tslib_1.__extends(ValidationCompleteEvent, _super);
    function ValidationCompleteEvent(result) {
        var _this = _super.call(this, validation_event_type_1.ValidationEventType.ValidationComplete, result.context) || this;
        _this.result = result;
        return _this;
    }
    return ValidationCompleteEvent;
}(validation_event_1.ValidationEvent));
exports.ValidationCompleteEvent = ValidationCompleteEvent;
