"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var ToolbarGroupBuilder = /** @class */ (function () {
    function ToolbarGroupBuilder(name, parent) {
        this.name = name;
        this.parent = parent;
        this.buttonsVisibleAttr = 5;
        this.alignAttr = null;
        this.buttons = [];
    }
    ToolbarGroupBuilder.prototype.getName = function () {
        return this.name;
    };
    /**
     * Add a button to the group.
     */
    ToolbarGroupBuilder.prototype.addButton = function (button) {
        this.buttons.push(button);
        return this;
    };
    /**
     * Defines the number of buttons that should be visible at all time.
     * Other buttons will be available under a submenu.
     */
    ToolbarGroupBuilder.prototype.buttonsVisible = function (count) {
        this.buttonsVisibleAttr = count;
        return this;
    };
    /**
     * Defines how buttons are aligned.
     */
    ToolbarGroupBuilder.prototype.align = function (direction) {
        this.alignAttr = direction;
        return this;
    };
    /**
     * @inheritDoc
     */
    ToolbarGroupBuilder.prototype.applyConfig = function (localConfig, globalConfig) {
        localConfig.buttons = [];
        for (var _i = 0, _a = this.buttons; _i < _a.length; _i++) {
            var button = _a[_i];
            localConfig.buttons = localConfig.buttons.concat(utils_1.ensureArray(button.name));
            if (utils_1.isObject(button.options)) {
                object_1.extend(globalConfig, button.options);
            }
        }
        if (this.alignAttr) {
            localConfig.align = this.alignAttr;
        }
        localConfig.buttonsVisible = this.buttonsVisibleAttr;
    };
    /**
     * @inheritDoc
     */
    ToolbarGroupBuilder.prototype.end = function () {
        return this.parent;
    };
    return ToolbarGroupBuilder;
}());
exports.ToolbarGroupBuilder = ToolbarGroupBuilder;
