"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("essentials/api/api.service");
var container_1 = require("webeak-native/inversify/container");
var form_transformation_model_schema_1 = require("essentials/form/form-transformation-model.schema");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var ApiPersister = /** @class */ (function () {
    function ApiPersister(entity, action) {
        this.entity = entity;
        this.action = action;
        this.api = container_1.Container.getContainer().get(api_service_1.ApiServiceSymbol);
        this.transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
    }
    /**
     * @inheritDoc
     */
    ApiPersister.prototype.persist = function (form) {
        var _this = this;
        var response = this.api.persist(this.entity, this.action, this.action).data(form).execute();
        response.promise.then(function (model) {
            _this.applyResponseToForm(model, form);
        });
        return response;
    };
    /**
     * If the server returned an object, apply these values to the form.
     */
    ApiPersister.prototype.applyResponseToForm = function (model, form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tempForm;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transformer.transform(model, form_transformation_model_schema_1.FormTransformationModelSchema)];
                    case 1:
                        tempForm = _a.sent();
                        tempForm.markAs('virtual', false, true, true);
                        form.setValue(tempForm.value);
                        return [2 /*return*/];
                }
            });
        });
    };
    return ApiPersister;
}());
exports.ApiPersister = ApiPersister;
