"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var list_link_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-link/list-link.component");
var error_1 = require("essentials/error");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("essentials/utils/utils");
var api_form_dialog_1 = require("essentials/dialog/alertify/dialog/api-form.dialog");
var ListDialogEditButtonComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListDialogEditButtonComponent, _super);
    function ListDialogEditButtonComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.initialized = false;
        _this.dialog = new api_form_dialog_1.ApiFormDialog();
        return _this;
    }
    Object.defineProperty(ListDialogEditButtonComponent.prototype, "form", {
        // Computed
        get: function () {
            return this.dialog.getForm();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle.
     */
    ListDialogEditButtonComponent.prototype.mounted = function () {
        if (this.id !== null) {
            if (!this.row) {
                var tag = this.$options._componentTag;
                throw new error_1.AppError("You must place your <" + tag + "></" + tag + "> inside a <list-row></list-row> component in order to bind it to a dialog.");
            }
            this.row.registerComponent(this.id, this);
        }
    };
    /**
     * Get a form control by name.
     */
    ListDialogEditButtonComponent.prototype.getFormControl = function (name) {
        return this.dialog.getFormControl(name);
    };
    /**
     * Show the dialog.
     */
    ListDialogEditButtonComponent.prototype.openDialog = function () {
        var _this = this;
        if (this.dialogPromise) {
            return this.dialogPromise;
        }
        this.dialogPromise = new Promise(function (resolve) {
            var _resolve = function () {
                resolve();
                _this.dialogPromise = null;
            };
            _this.dialog.action(_this.apiAction)
                .entity(_this.item)
                .title(_this.title)
                .initialize().then(function () {
                _this.initialize().then(function () {
                    _this.dialog.content($(_this.$refs.dialog).show().get(0));
                    _this.dialog.open().then(function (result) {
                        if (result === true) {
                            _this.list.update(true, true);
                        }
                    }).finally(_resolve);
                });
            }).catch(_resolve);
        });
        return this.dialogPromise;
    };
    /**
     * Initialize the dialog.
     */
    ListDialogEditButtonComponent.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.initialized) {
                            return [2 /*return*/];
                        }
                        this.initialized = true;
                        return [4 /*yield*/, utils_1.onNextCycle()];
                    case 1:
                        _a.sent();
                        if (!this.$scopedSlots.dialog || !this.$refs.dialog) {
                            throw new error_1.AppError('You must define a <template #dialog>You content</template> ' +
                                'inside your <list-dialog-edit-button></list-dialog-edit-button> component.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListDialogEditButtonComponent.prototype, "id", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, required: true }),
        tslib_1.__metadata("design:type", String)
    ], ListDialogEditButtonComponent.prototype, "apiAction", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", Object)
    ], ListDialogEditButtonComponent.prototype, "item", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'Édition rapide' }),
        tslib_1.__metadata("design:type", String)
    ], ListDialogEditButtonComponent.prototype, "title", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Number], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], ListDialogEditButtonComponent.prototype, "dialogWidth", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [String, Number], default: null }),
        tslib_1.__metadata("design:type", Object)
    ], ListDialogEditButtonComponent.prototype, "dialogHeight", void 0);
    ListDialogEditButtonComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-dialog-edit-button.component.html')
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], ListDialogEditButtonComponent);
    return ListDialogEditButtonComponent;
}(list_link_component_1.ListLinkComponent));
exports.ListDialogEditButtonComponent = ListDialogEditButtonComponent;
vue_app_1.VueApp.RegisterComponent('list-dialog-edit-button', ListDialogEditButtonComponent);
