"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var utils_1 = require("webeak-native/util/utils");
var error_1 = require("essentials/error");
/**
 * Error relative to an http request.
 */
var HttpError = /** @class */ (function (_super) {
    tslib_1.__extends(HttpError, _super);
    function HttpError(status, error, message, payload, previous) {
        var _this = _super.call(this, message, previous) || this;
        _this.status = status;
        _this.error = error;
        _this.payload = payload;
        return _this;
    }
    /**
     * Create an HttpError instance from a mixed input.
     * Input can be:
     *   - a string
     *   - an Error object
     *   - an AppError object
     *   - a plain object containing a "message" key
     *   - an HttpErrorResponse
     */
    HttpError.create = function (input, defaultMessage, payload) {
        if (defaultMessage === void 0) { defaultMessage = 'Unknown error'; }
        if (payload === void 0) { payload = null; }
        if (input instanceof HttpError) {
            return input;
        }
        if (input instanceof error_1.AppError) {
            return new HttpError(0, null, input.message, payload, input);
        }
        // if (input instanceof HttpErrorResponse) {
        //     let message: any = input.error;
        //     if (isObject(message)) {
        //         message = isString(message.message) ? message.message : 'Unknown error.';
        //     }
        //     return new HttpError(input.status, input.error, ensureString(message));
        // }
        if (utils_1.isString(input)) {
            return new HttpError(0, null, input, payload);
        }
        if (utils_1.isObject(input) && utils_1.isString(input.message)) {
            return new HttpError(0, null, input.message, payload);
        }
        return new HttpError(0, null, defaultMessage, payload);
    };
    return HttpError;
}(error_1.AppError));
exports.HttpError = HttpError;
