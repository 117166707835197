"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var symfony_bag_1 = require("essentials/symfony/symfony-bag");
var utils_1 = require("webeak-native/util/utils");
var error_1 = require("essentials/error");
var utils_2 = require("essentials/network/utils");
var object_1 = require("webeak-native/util/object");
var Router = /** @class */ (function () {
    function Router(symfonyBag) {
        this.symfonyBag = symfonyBag;
        var routes = symfonyBag.get('routes');
        this.routes = {};
        for (var _i = 0, routes_1 = routes; _i < routes_1.length; _i++) {
            var route = routes_1[_i];
            this.routes[route[0]] = { name: route[0], methods: route[1], path: route[2], defaults: route[3] };
        }
    }
    /**
     * Generate a url for a route name.
     */
    Router.prototype.generateUrl = function (name, params) {
        if (params === void 0) { params = {}; }
        var clonedParams = object_1.extend({}, params);
        if (utils_1.isUndefined(this.routes[name])) {
            throw new error_1.AppError("Route \"" + name + "\" not found.");
        }
        var path = this.routes[name].path, m;
        var matches = [];
        var reg = /{(\w+)}/gi;
        while ((m = reg.exec(path))) {
            matches.push(m);
        }
        for (var _i = 0, matches_1 = matches; _i < matches_1.length; _i++) {
            var match = matches_1[_i];
            var value = !utils_1.isUndefined(clonedParams[match[1]]) ? clonedParams[match[1]] : this.routes[name].defaults[match[1]];
            if (utils_1.isUndefined(value)) {
                throw new error_1.AppError("Missing mandatory route parameter \"" + match[1] + "\".");
            }
            path = path.replace(match[0], value);
            delete clonedParams[match[1]];
        }
        path = path.replace(/\/?null(?:\/|$)/g, '');
        return (path[0] === '/' ? path : ('/' + path)) + (Object.keys(clonedParams).length > 0 ? utils_2.buildQueryParameters(clonedParams) : '');
    };
    Router = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__param(0, inversify_1.inject(symfony_bag_1.SymfonyBagSymbol)),
        tslib_1.__metadata("design:paramtypes", [symfony_bag_1.SymfonyBag])
    ], Router);
    return Router;
}());
exports.Router = Router;
exports.RouterSymbol = Symbol("Router");
container_1.Container.registerService(exports.RouterSymbol, Router);
