"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var radio_component_1 = require("essentials/vuejs/components/form/radio/radio.component");
var RadioComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RadioComponent, _super);
    function RadioComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RadioComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./radio.component.html'),
        })
    ], RadioComponent);
    return RadioComponent;
}(radio_component_1.RadioComponent));
exports.RadioComponent = RadioComponent;
vue_1.default.component('form-radio', RadioComponent);
