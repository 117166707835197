"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var storage_1 = require("essentials/storage");
var LocalStorageDriverService = /** @class */ (function (_super) {
    tslib_1.__extends(LocalStorageDriverService, _super);
    function LocalStorageDriverService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get the value associated with the given key.
     */
    LocalStorageDriverService.prototype.get = function (key) {
        return new Promise(function (resolve) {
            resolve(window.localStorage.getItem(key));
        });
    };
    /**
     * Set the value for the given key.
     */
    LocalStorageDriverService.prototype.set = function (key, value) {
        return new Promise(function (resolve) {
            window.localStorage.setItem(key, value);
            resolve();
        });
    };
    /**
     * Remove any value associated with this key.
     */
    LocalStorageDriverService.prototype.remove = function (key) {
        return new Promise(function (resolve) {
            window.localStorage.removeItem(key);
            resolve();
        });
    };
    /**
     * Clear the entire key value store.
     */
    LocalStorageDriverService.prototype.clear = function () {
        return new Promise(function (resolve) {
            window.localStorage.clear();
            resolve();
        });
    };
    /**
     * Gets how many keys are stored in the storage.
     */
    LocalStorageDriverService.prototype.length = function () {
        return new Promise(function (resolve) {
            resolve(window.localStorage.length);
        });
    };
    /**
     * Gets the list of all keys stored in the storage.
     */
    LocalStorageDriverService.prototype.keys = function () {
        return new Promise(function (resolve) {
            var keys = [];
            for (var i = 0, c = localStorage.length; i < c; ++i) {
                keys.push(localStorage.key(i));
            }
            resolve(keys);
        });
    };
    /**
     * Gets the type of storage used.
     */
    LocalStorageDriverService.prototype.getDriverName = function () {
        return 'Browser (local storage)';
    };
    LocalStorageDriverService = tslib_1.__decorate([
        inversify_1.injectable()
    ], LocalStorageDriverService);
    return LocalStorageDriverService;
}(storage_1.StorageService));
exports.LocalStorageDriverService = LocalStorageDriverService;
exports.LocalStorageDriverServiceSymbol = Symbol("LocalStorageDriverService");
container_1.Container.registerService(exports.LocalStorageDriverServiceSymbol, LocalStorageDriverService);
