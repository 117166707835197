"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var form_group_1 = require("essentials/form/form-group");
var form_transformation_model_schema_1 = require("essentials/form/form-transformation-model.schema");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var schemas_holder_1 = require("essentials/model/schemas-holder");
/**
 * Convert a model into a form group and vice versa.
 */
function FormGroup(name, modelType) {
    var transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
    return {
        type: 'form-group',
        transform: function (model, context) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var formGroup;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!utils_1.isNullOrUndefined(model)) return [3 /*break*/, 1];
                            formGroup = new form_group_1.FormGroup();
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, transformer.transform(model, form_transformation_model_schema_1.FormTransformationModelSchema)];
                        case 2:
                            formGroup = _a.sent();
                            _a.label = 3;
                        case 3:
                            if (formGroup instanceof form_group_1.FormGroup) {
                                formGroup.setName(context.currentPropertyName);
                            }
                            return [2 /*return*/, formGroup];
                    }
                });
            });
        },
        transformInverse: function (group, context) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (utils_1.isString(modelType)) {
                                modelType = schemas_holder_1.SchemasHolder.GetInstance().resolveModelTypeFromString(modelType);
                            }
                            return [4 /*yield*/, transformer.transformInverse(group.controls, form_transformation_model_schema_1.FormTransformationModelSchema, modelType)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        getChild: function () {
            return null;
        }
    };
}
exports.FormGroup = FormGroup;
