"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_class_component_1 = require("vue-class-component");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
var util_1 = require("vue-class-component/lib/util");
var value_changed_form_event_1 = require("essentials/form/event/value-changed.form-event");
var form_array_1 = require("essentials/form/form-array");
var form_control_1 = require("essentials/form/form-control");
var container_1 = require("webeak-native/inversify/container");
var router_1 = require("essentials/symfony/router");
var api_service_1 = require("essentials/api/api.service");
var network_1 = require("essentials/network");
var schemas_holder_1 = require("essentials/model/schemas-holder");
var error_1 = require("essentials/error");
var object_1 = require("webeak-native/util/object");
var form_validation_error_1 = require("essentials/form/error/form-validation.error");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var AbstractChoiceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractChoiceComponent, _super);
    function AbstractChoiceComponent() {
        var _this = _super.call(this) || this;
        // /**
        //  * Normalize the choices into a key/value pair object.
        //  * The logic is the following:
        //  *   - if the input is an array, two options:
        //  *      - if its an array of primitive, the value will be used as value and label
        //  *      - if its an array of objects, the value will managed internally and the label will be the value of the property designated by the "choicesLabelProperty" prop.
        //  *   - if the input is an object, it will be used as a collection of key/value pairs.
        //  *   - any other type will result in an empty result set
        //  */
        // get normalizedChoices(): ChoiceInterface[] {
        //     return this.normalizeChoices();
        // }
        /**
         * Holds if the component should handle multiple values or not.
         * This is NOT a prop because the fact that the component is multiple or not is determined by the form control
         * and not by a property.
         *
         * If the form control is a FormArray, then the component is multiple.
         */
        _this.multiple = false;
        /**
         * If true, the select will cease to be interactive and show a loader.
         */
        _this.loading = false;
        /**
         * Choices normalized into a key/value pair object.
         * The logic is the following:
         *   - if the input is an array, two options:
         *      - if its an array of primitive, the value will be used as value and label
         *      - if its an array of objects, the value will managed internally and the label will be the value of the property designated by the "choicesLabelProperty" prop.
         *   - if the input is an object, it will be used as a collection of key/value pairs.
         *   - any other type will result in an empty result set
         */
        _this.normalizedChoices = [];
        /**
         * Holds the mapping between objects choices and the form control.
         */
        _this.objectsMap = {};
        _this.onControlValueUpdate = false;
        /**
         * True if the select is currently fetching remote data.
         * This property is private because it is only used to avoid doing multiple requests at once.
         * A different property is used for displaying a loader so it's possible to fetch data without changing the UI.
         */
        _this.fetching = false;
        /**
         * List of events that cannot be processed immediately and so are deferred.
         */
        _this.eventsQueue = [];
        /**
         * The real choices that are being normalized.
         */
        _this._choices = null;
        _this.objectChoicesLabelIdsMap = {};
        _this.primitiveChoicesIds = {};
        _this.choicesIdsIncrement = 0;
        _this.transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
        return _this;
    }
    // Watchers
    AbstractChoiceComponent.prototype.onValueChanged = function (newVal, oldVal) {
        if (this.onControlValueUpdate || this.isDefaultValue(newVal)) {
            return;
        }
        var oldControlValue = this.control.value;
        var isFirstChange = this.isDefaultValue(oldVal);
        this.onControlValueUpdate = true;
        if (this.multiple) {
            var container = this.control;
            var usedControls = [];
            var newValClone = [].concat(newVal);
            for (var _i = 0, newValClone_1 = newValClone; _i < newValClone_1.length; _i++) {
                var item = newValClone_1[_i];
                var i = void 0;
                var formValue = this.HtmlToFormControlValue(item);
                for (i = 0; i < container.controls.length; ++i) {
                    if (container.controls[i].value === formValue) {
                        usedControls.push(container.controls[i].id);
                        break;
                    }
                }
                if (i >= container.controls.length) {
                    var newControl = new form_control_1.FormControl(formValue);
                    container.add(newControl, false);
                    usedControls.push(newControl.id);
                }
            }
            for (var i = 0; i < container.controls.length; ++i) {
                if (usedControls.indexOf(container.controls[i].id) < 0) {
                    container.remove(i, false);
                    i = -1;
                }
            }
            // To force VueJS to update
            container.setValue(container.value, !isFirstChange, !isFirstChange, isFirstChange);
        }
        else {
            this.control.setValue(this.HtmlToFormControlValue(newVal), !isFirstChange, !isFirstChange, isFirstChange);
        }
        this.onControlValueUpdate = false;
        if (!isFirstChange) {
            this.$emit('change', new value_changed_form_event_1.ValueChangedFormEvent(this.control, oldControlValue, this.control.value));
        }
    };
    AbstractChoiceComponent.prototype.onChoicesApiActionChanged = function (newVal, oldVal) {
        if (!object_1.areSame(newVal, oldVal)) {
            this.updateRemoteChoices();
        }
    };
    AbstractChoiceComponent.prototype.onChoicesChanged = function (newVal) {
        this._choices = newVal;
    };
    Object.defineProperty(AbstractChoiceComponent.prototype, "hasLabel", {
        // Computed
        get: function () {
            return this.hasSlot('label');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractChoiceComponent.prototype, "disabledOrLoading", {
        get: function () {
            return this.disabled || this.loading;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Convert the FromControl's value into a value usable by the HTML component.
     */
    AbstractChoiceComponent.prototype.formControlToHtmlValue = function (value) {
        if (utils_1.isObject(value)) {
            if (!this.choicesIdentifierProperty) {
                console.warn('You must define an identifier property if you want to assign objects to your choices. ' +
                    'Please add a "choices-identifier-property" attribute to your form element.');
                return null;
            }
            for (var _i = 0, _a = Object.keys(this.objectsMap); _i < _a.length; _i++) {
                var key = _a[_i];
                if (this.objectsMap[key][this.choicesIdentifierProperty] === value[this.choicesIdentifierProperty]) {
                    return key;
                }
            }
            return null;
        }
        if (this.multiple) {
            return value;
        }
        return value !== null ? (value + '') : null;
    };
    /**
     * Convert the HTML component's value into a FormControl value.
     */
    AbstractChoiceComponent.prototype.HtmlToFormControlValue = function (value) {
        if (utils_1.isObject(value)) {
            if (!this.choicesIdentifierProperty) {
                console.warn('You must define an identifier property if you want to assign objects to your choices. ' +
                    'Please add a "choices-identifier-property" attribute to your form element.');
                return null;
            }
            for (var _i = 0, _a = Object.keys(this.objectsMap); _i < _a.length; _i++) {
                var key = _a[_i];
                var candidate = this.objectsMap[key];
                if (utils_1.isObject(candidate) && candidate[this.choicesIdentifierProperty] === value[this.choicesIdentifierProperty]) {
                    value = key;
                    break;
                }
            }
        }
        if (utils_1.isString(value) && value.substring(0, 8) === '(object[' && utils_1.isObject(this.objectsMap[value])) {
            return this.objectsMap[value];
        }
        return value;
    };
    /**
     * @inheritDoc
     */
    AbstractChoiceComponent.prototype.doInitialize = function () {
        this.updateChoices(this.choices);
        this.multiple = this.control instanceof form_array_1.FormArray;
        if (this.choicesApiAction || this.choicesRoute || this.choicesUrl) {
            // If we already have an object value in controlValue here we must save it aside
            // because we will not be able to resolve it until the remote choices are fetched.
            this.waitingForRemoteChoicesControlValue = utils_1.isObject(this.controlValue) ? this.controlValue : null;
            // We don't want to set the control value yet.
            // We'll wait to have the choices loaded before doing that.
            this.controlValue = abstract_form_component_1.AbstractFormComponent.DefaultValue;
            return this.updateRemoteChoices().then(function () {
                return true;
            });
        }
        return true;
    };
    AbstractChoiceComponent.prototype.normalizeChoices = function () {
        var _this = this;
        if (utils_1.isUndefined(this._choices)) {
            this._choices = this.choices;
        }
        var added = [];
        var normalized = [];
        var addItem = function (value, label) {
            if (label === null && utils_1.isObject(value) && (_this.choicesLabelProperty || _this.choicesLabelPropertyExpr)) {
                label = _this.resolveChoiceLabel(value);
            }
            else if (!utils_1.isString(label)) {
                console.warn('Cannot work out the label for choice item:', value);
                return;
            }
            if (util_1.isPrimitive(value)) {
                var idKey = utils_1.ensureString(value) + '#' + label;
                var id_1 = _this.primitiveChoicesIds[idKey];
                if (utils_1.isUndefined(id_1)) {
                    id_1 = 'c_' + (++_this.choicesIdsIncrement);
                    _this.primitiveChoicesIds[idKey] = id_1;
                }
                normalized.push({ id: id_1, value: value, label: label });
                return;
            }
            var id = !utils_1.isUndefined(_this.objectChoicesLabelIdsMap[label]) ? _this.objectChoicesLabelIdsMap[label] : null;
            if (id === null) {
                id = '(object[' + ((++_this.choicesIdsIncrement) /* + incr*/) + '])';
                _this.objectChoicesLabelIdsMap[label] = id;
            }
            if (added.indexOf(id) >= 0) {
                console.warn('Duplicate entry with label "' + label + '". Ignoring the second occurrence.');
                return;
            }
            added.push(id);
            normalized.push({ id: id, value: id, label: label });
            _this.objectsMap[id] = value;
        };
        if (utils_1.isArray(this._choices)) {
            for (var _i = 0, _a = this._choices; _i < _a.length; _i++) {
                var item = _a[_i];
                if (util_1.isPrimitive(item)) {
                    addItem(item, item);
                }
                else {
                    addItem(item, null);
                }
            }
        }
        else if (utils_1.isObject(this._choices)) {
            for (var key in this._choices) {
                if (this._choices.hasOwnProperty(key)) {
                    if (util_1.isPrimitive(this._choices[key])) {
                        addItem(key, this._choices[key] + '');
                    }
                    else {
                        addItem(this._choices[key], !this.choicesLabelProperty && !this.choicesLabelPropertyExpr ? key : null);
                    }
                }
            }
        }
        if (!utils_1.isNullOrUndefined(this.waitingForRemoteChoicesControlValue) && Object.keys(this.objectsMap).length > 0) {
            this.controlValue = this.waitingForRemoteChoicesControlValue;
            this.waitingForRemoteChoicesControlValue = null;
        }
        return normalized;
    };
    /**
     * Called when the value of the control has changed.
     */
    AbstractChoiceComponent.prototype.onFormValueChange = function (event) {
        if (event.control !== this.control) {
            return;
        }
        if (this.fetching) {
            this.eventsQueue.push(event);
            return;
        }
        if (this.multiple) {
            for (var _i = 0, _a = event.newValue; _i < _a.length; _i++) {
                var item = _a[_i];
                var resolved = this.formControlToHtmlValue(item);
                if (this.controlValue.indexOf(resolved) < 0) {
                    this.controlValue.push(resolved);
                }
            }
        }
        else {
            this.controlValue = this.formControlToHtmlValue(event.newValue);
        }
    };
    /**
     * Create a context containing all the data necessary to make a request to the server.
     */
    AbstractChoiceComponent.prototype.prepareRemoteChoicesRequest = function () {
        return {
            apiAction: this.choicesApiAction,
            apiEntity: this.choicesApiEntity,
            route: this.choicesRoute,
            url: this.choicesUrl,
            urlParams: this.choicesUrlParams,
            silent: false
        };
    };
    /**
     * Fetch remote data and update the list of choices with the result.
     */
    AbstractChoiceComponent.prototype.updateRemoteChoices = function () {
        var _this = this;
        if (!utils_1.isNullOrUndefined(this.updateRemoteChoicesPromise)) {
            if (!utils_1.isNullOrUndefined(this.currentRemoteChoicesHttpResponse)) {
                this.currentRemoteChoicesHttpResponse.cancel();
            }
            this.waitingForRemoteChoicesUpdate = true;
            return this.updateRemoteChoicesPromise;
        }
        this.updateRemoteChoicesPromise = new Promise(function (resolve, reject) {
            _this.updateRemoteChoicesPromiseResolve = resolve;
            _this.updateRemoteChoicesPromiseReject = reject;
            // Timeout in case doUpdateRemoteChoices() resolves immediately, in which case
            // the assignation of "updateRemoteChoicesPromise" will not be done yet and the promise
            // will keep being set where it should be null.
            window.setTimeout(utils_1.proxy(_this.doUpdateRemoteChoices, _this));
        });
        return this.updateRemoteChoicesPromise;
    };
    AbstractChoiceComponent.prototype.doUpdateRemoteChoices = function () {
        var _this = this;
        var resolve = function (resolver, result) {
            if (_this.waitingForRemoteChoicesUpdate) {
                _this.waitingForRemoteChoicesUpdate = false;
                _this.doUpdateRemoteChoices();
            }
            else {
                resolver(result);
                _this.updateRemoteChoicesPromise = null;
                _this.updateRemoteChoicesPromiseResolve = null;
                _this.updateRemoteChoicesPromiseReject = null;
            }
        };
        var context = this.prepareRemoteChoicesRequest();
        if (context === null) {
            return void resolve(this.updateRemoteChoicesPromiseResolve, null);
        }
        var httpResponse = null;
        if (context.apiAction) {
            httpResponse = this.updateRemoteChoicesFromApi(context);
        }
        else {
            httpResponse = this.updateRemoteChoicesFromUrl(context);
        }
        if (!context.silent) {
            this.fetching = true;
            this.loading = true;
        }
        httpResponse.promise.then(function (result) {
            resolve(_this.updateRemoteChoicesPromiseResolve, result);
        }).catch(function (error) {
            resolve(_this.updateRemoteChoicesPromiseReject, error);
        }).finally(function () {
            if (httpResponse === _this.currentRemoteChoicesHttpResponse) {
                _this.currentRemoteChoicesHttpResponse = null;
            }
        });
        this.currentRemoteChoicesHttpResponse = httpResponse;
    };
    /**
     * Cancel the current remote choices request (if there is one).
     */
    AbstractChoiceComponent.prototype.cancelCurrentRemoteChoicesUpdate = function () {
        if (!utils_1.isNullOrUndefined(this.currentRemoteChoicesHttpResponse)) {
            this.currentRemoteChoicesHttpResponse.cancel();
        }
        this.currentRemoteChoicesHttpResponse = null;
    };
    /**
     * Replace the whole the list of choices by a new one.
     */
    AbstractChoiceComponent.prototype.updateChoices = function (choices) {
        if (utils_1.isArray(choices)) {
            this._choices = [];
            for (var _i = 0, choices_1 = choices; _i < choices_1.length; _i++) {
                var item = choices_1[_i];
                this._choices.push(item);
            }
        }
        else if (utils_1.isObject(choices)) {
            this._choices = choices;
        }
        else {
            console.error('Invalid choices returned by the server.');
        }
        this.normalizedChoices = this.normalizeChoices();
    };
    /**
     * Fetch the choices from the api.
     */
    AbstractChoiceComponent.prototype.updateRemoteChoicesFromApi = function (context) {
        if (!context.apiEntity) {
            throw new error_1.AppError('You must define the type of entity associated with the action using the "choices-api-entity" property.');
        }
        var api = container_1.Container.getContainer().get(api_service_1.ApiServiceSymbol);
        var modelType = schemas_holder_1.SchemasHolder.GetInstance().resolveModelTypeFromString(context.apiEntity);
        var result = api.get(modelType, context.apiAction)
            .collection()
            .paginate(false)
            .disablePaginationConfigurationErrors()
            .where(context.urlParams)
            .execute();
        return this.handleRemoteChoicesResult(result, utils_1.proxy(this.updateChoices, this));
    };
    /**
     * Fetch the choices from the a symfony route or custom url.
     */
    AbstractChoiceComponent.prototype.updateRemoteChoicesFromUrl = function (context) {
        var _this = this;
        var url = context.url;
        if (!url) {
            if (context.route) {
                var router = container_1.Container.getContainer().get(router_1.RouterSymbol);
                url = router.generateUrl(context.route, context.urlParams);
            }
        }
        if (!url) {
            return;
        }
        var http = container_1.Container.getContainer().get(network_1.HttpServiceSymbol);
        url = object_1.replaceStringVariables(url, context.urlParams, '{', '}');
        return this.handleRemoteChoicesResult(http.get(url), function (results) {
            _this.processEntitiesResult(results).then(utils_1.proxy(_this.updateChoices, _this));
        });
    };
    /**
     * Convert the raw result from the server into an array of entities (if a type of entity is defined).
     */
    AbstractChoiceComponent.prototype.processEntitiesResult = function (items) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modelType, i, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.choicesApiEntity) return [3 /*break*/, 4];
                        modelType = schemas_holder_1.SchemasHolder.GetInstance().resolveModelTypeFromString(this.choicesApiEntity);
                        i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(i < items.length)) return [3 /*break*/, 4];
                        _a = items;
                        _b = i;
                        return [4 /*yield*/, this.transformer.transformInverse(items[i], generic_transformation_model_schema_1.GenericTransformationModelSchema, modelType)];
                    case 2:
                        _a[_b] = _c.sent();
                        _c.label = 3;
                    case 3:
                        ++i;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, items];
                }
            });
        });
    };
    /**
     * Handle the result of a remote call to get choices.
     */
    AbstractChoiceComponent.prototype.handleRemoteChoicesResult = function (response, onSuccess) {
        var _this = this;
        response.promise.then(onSuccess).catch(function (error) {
            if (response.isCanceled) {
                return;
            }
            var message = error_1.AppError.create(error).getPublicMessage('Échec du chargement des choix.');
            _this.control.addError(new form_validation_error_1.FormValidationError(message, {}, 'fetch', false));
        }).finally(function () {
            _this.fetching = false;
            _this.loading = false;
            _this.processEventsInQueue([value_changed_form_event_1.ValueChangedFormEvent]);
            _this.controlValue = _this.formControlToHtmlValue(_this.control.value);
        });
        return response;
    };
    AbstractChoiceComponent.prototype.processEventsInQueue = function (types) {
        for (var _i = 0, _a = this.eventsQueue; _i < _a.length; _i++) {
            var event_1 = _a[_i];
            if (types.indexOf(event_1.constructor) >= 0) {
                switch (event_1.constructor) {
                    case value_changed_form_event_1.ValueChangedFormEvent:
                        this.onFormValueChange(event_1);
                        break;
                }
            }
        }
    };
    AbstractChoiceComponent.prototype.resolveChoiceLabel = function (obj) {
        if (this.choicesLabelPropertyExpr) {
            return object_1.replaceStringVariables(this.choicesLabelPropertyExpr, obj);
        }
        if (!utils_1.isNullOrUndefined(obj[this.choicesLabelProperty])) {
            return obj[this.choicesLabelProperty];
        }
        return obj[this.choicesLabelProperty] === null ? 'null' : 'undefined';
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: [Array, Object], default: function () { return []; } }),
        tslib_1.__metadata("design:type", Object)
    ], AbstractChoiceComponent.prototype, "choices", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractChoiceComponent.prototype, "choicesApiAction", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractChoiceComponent.prototype, "choicesApiEntity", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractChoiceComponent.prototype, "choicesRoute", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () { return {}; } }),
        tslib_1.__metadata("design:type", Object)
    ], AbstractChoiceComponent.prototype, "choicesUrlParams", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractChoiceComponent.prototype, "choicesUrl", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractChoiceComponent.prototype, "choicesLabelProperty", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractChoiceComponent.prototype, "choicesLabelPropertyExpr", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], AbstractChoiceComponent.prototype, "choicesIdentifierProperty", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('choicesApiAction'),
        vue_property_decorator_1.Watch('choicesRoute'),
        vue_property_decorator_1.Watch('choicesUrlParams'),
        vue_property_decorator_1.Watch('choicesUrl'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AbstractChoiceComponent.prototype, "onChoicesApiActionChanged", null);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('choices'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AbstractChoiceComponent.prototype, "onChoicesChanged", null);
    AbstractChoiceComponent = tslib_1.__decorate([
        vue_class_component_1.default({}),
        tslib_1.__metadata("design:paramtypes", [])
    ], AbstractChoiceComponent);
    return AbstractChoiceComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.AbstractChoiceComponent = AbstractChoiceComponent;
