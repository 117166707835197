"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
require("./input.component.scss");
var vue_property_decorator_1 = require("vue-property-decorator");
var utils_1 = require("webeak-native/util/utils");
var InputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputComponent, _super);
    function InputComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InputComponent.prototype, "dynamicAttrs", {
        // Computed
        get: function () {
            var output = {};
            if (this.step) {
                output['step'] = this.step;
            }
            if (this.autocomplete) {
                output['autocomplete'] = this.autocomplete;
            }
            return output;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Focus the input field.
     */
    InputComponent.prototype.focus = function () {
        if (utils_1.isUndefined(this.$refs.input)) {
            return void console.warn('Unable to focus input field because no "input" ref has been set in the template.');
        }
        $(this.$refs.input).focus();
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'text' }),
        tslib_1.__metadata("design:type", String)
    ], InputComponent.prototype, "type", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], InputComponent.prototype, "step", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], InputComponent.prototype, "autocomplete", void 0);
    InputComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./input.component.html'),
        })
    ], InputComponent);
    return InputComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.InputComponent = InputComponent;
vue_1.default.component('form-input', InputComponent);
