"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Build the query parameters string for an url.
 *
 * For example:
 *   buildQueryParameters({
 *       param1: 'http://example.org/?a=12&b=55',
 *       param2: 99
 *   })
 *
 * will output:
 * ?param1=http%3A%2F%2Fexample.org%2F%Ffa%3D12%26b%3D55&param2=99
 */
function buildQueryParameters(obj) {
    var queryParametersArray = [];
    for (var _i = 0, _a = Object.keys(obj); _i < _a.length; _i++) {
        var key = _a[_i];
        queryParametersArray.push(key + '=' + encodeURIComponent(obj[key]));
    }
    if (queryParametersArray.length > 0) {
        return '?' + queryParametersArray.join('&');
    }
    return '';
}
exports.buildQueryParameters = buildQueryParameters;
/**
 * Construct a query parameter string from an key/value pair object and append it the an existing url.
 */
function appendQueryParameters(url, params) {
    var queryString = buildQueryParameters(params);
    var pos = url.indexOf('?');
    if (pos >= 0) {
        return url + '&' + queryString.substring(1);
    }
    return url + queryString;
}
exports.appendQueryParameters = appendQueryParameters;
/**
 * Remove the prefix set by the API when responding with an array to prevent XSSI attacks.
 */
function stripXssiPrefix(input) {
    var prefix = ")]}'\n";
    if (input.substring(0, prefix.length) === prefix) {
        return input.substring(prefix.length);
    }
    return input;
}
exports.stripXssiPrefix = stripXssiPrefix;
