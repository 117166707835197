"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var perfect_scrollbar_1 = require("perfect-scrollbar");
var abstract_module_1 = require("essentials/modules/abstract-module");
require("perfect-scrollbar/css/perfect-scrollbar.css");
var PerfectScrollbar = /** @class */ (function (_super) {
    tslib_1.__extends(PerfectScrollbar, _super);
    function PerfectScrollbar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @inheritDoc
     */
    PerfectScrollbar.prototype.bind = function () {
        this.ps = new perfect_scrollbar_1.default(this.$element.get(0), {
            useBothWheelAxes: false,
            suppressScrollX: true
        });
    };
    PerfectScrollbar = tslib_1.__decorate([
        inversify_1.injectable()
    ], PerfectScrollbar);
    return PerfectScrollbar;
}(abstract_module_1.AbstractModule));
exports.PerfectScrollbarSymbol = Symbol("PerfectScrollbar");
container_1.Container.registerModule(exports.PerfectScrollbarSymbol, PerfectScrollbar);
