"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var moment = require("moment");
var transformable_decorator_1 = require("essentials/model/decorators/transformable.decorator");
var primitive_1 = require("essentials/model/transformer/types/primitive");
var api_resource_decorator_1 = require("essentials/api/decorators/api-resource.decorator");
var abstract_entity_1 = require("essentials/entity/abstract.entity");
var user_entity_1 = require("backend/entity/user.entity");
var model_1 = require("essentials/model/transformer/types/model");
var application_entity_1 = require("backend/entity/application.entity");
var moment_to_utc_string_1 = require("essentials/model/transformer/types/moment-to-utc-string");
var AccessTokenEntity = /** @class */ (function (_super) {
    tslib_1.__extends(AccessTokenEntity, _super);
    function AccessTokenEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = '';
        _this.revoked = null;
        _this.expirationDate = null;
        _this.client = null;
        _this.user = null;
        return _this;
    }
    AccessTokenEntity.prototype.isActive = function () {
        return !this.revoked && moment().isBefore(this.expirationDate);
    };
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", String)
    ], AccessTokenEntity.prototype, "ref", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(primitive_1.Primitive()),
        tslib_1.__metadata("design:type", Boolean)
    ], AccessTokenEntity.prototype, "revoked", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(moment_to_utc_string_1.MomentToUtcString()),
        tslib_1.__metadata("design:type", Object)
    ], AccessTokenEntity.prototype, "expirationDate", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.Model('ApplicationEntity')),
        tslib_1.__metadata("design:type", application_entity_1.ApplicationEntity)
    ], AccessTokenEntity.prototype, "client", void 0);
    tslib_1.__decorate([
        transformable_decorator_1.Transformable(model_1.Model('UserEntity')),
        tslib_1.__metadata("design:type", user_entity_1.UserEntity)
    ], AccessTokenEntity.prototype, "user", void 0);
    AccessTokenEntity = tslib_1.__decorate([
        api_resource_decorator_1.ApiResource('PAOYDX')
    ], AccessTokenEntity);
    return AccessTokenEntity;
}(abstract_entity_1.AbstractEntity));
exports.AccessTokenEntity = AccessTokenEntity;
