"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var validation_error_1 = require("essentials/validation/validation.error");
var validator_container_1 = require("essentials/validation/validator-container");
var utils_1 = require("webeak-native/util/utils");
function Pattern(pattern, message) {
    if (message === void 0) { message = 'Format invalide.'; }
    return {
        validate: function (value, context) {
            if (!utils_1.isString(value) || !pattern.exec(value)) {
                context.addError(new validation_error_1.ValidationError('pattern', message));
            }
        }
    };
}
exports.Pattern = Pattern;
validator_container_1.ValidatorContainer.register('Pattern', Pattern);
