"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var event_1 = require("essentials/event");
var ContextEventArg = /** @class */ (function (_super) {
    tslib_1.__extends(ContextEventArg, _super);
    function ContextEventArg(context) {
        var _this = _super.call(this) || this;
        _this.context = context;
        return _this;
    }
    return ContextEventArg;
}(event_1.EventArg));
exports.ContextEventArg = ContextEventArg;
