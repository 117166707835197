"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var utils_1 = require("webeak-native/util/utils");
var validation_context_1 = require("essentials/validation/validation-context");
var validator_container_1 = require("essentials/validation/validator-container");
function Foreach(validator) {
    var validateProperty = function (context, value, propertyName) {
        return new Promise(function (resolve) {
            var currentContext = validation_context_1.ValidationContext.CreateSubContext(context, propertyName + '');
            currentContext.enter();
            var result = validator.validate(value, currentContext);
            Promise.all([result]).then(function () {
                currentContext.leave();
                resolve();
            });
        });
    };
    return {
        validate: function (values, context) {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var promises, i;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!utils_1.isArray(values)) {
                                return [2 /*return*/];
                            }
                            promises = [];
                            for (i = 0; i < values.length; ++i) {
                                if (context.shouldBeValidated(i)) {
                                    promises.push(validateProperty(context, values[i], i));
                                }
                            }
                            return [4 /*yield*/, Promise.all(promises)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    };
}
exports.Foreach = Foreach;
validator_container_1.ValidatorContainer.register('Foreach', Foreach);
