"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var model_1 = require("essentials/model");
var api_transformation_model_schema_1 = require("./api-transformation-model.schema");
var ApiModelTransformer = /** @class */ (function (_super) {
    tslib_1.__extends(ApiModelTransformer, _super);
    function ApiModelTransformer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create the TransformContext object that will hold context data of the current transformation.
     */
    ApiModelTransformer.prototype.createContext = function (source, result) {
        return new model_1.TransformContext(source, result, api_transformation_model_schema_1.ApiTransformationModelSchema);
    };
    return ApiModelTransformer;
}(model_1.AbstractModelTransformer));
exports.ApiModelTransformer = ApiModelTransformer;
