"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var raw_1 = require("./raw");
var utils_1 = require("webeak-native/util/utils");
/**
 * Apply a transformer to a collection of values.
 */
function Collection(transformer) {
    if (transformer === void 0) { transformer = raw_1.Raw(); }
    var apply = function (fn, values, context) {
        return new Promise(function (resolve, reject) {
            var pipeline = Promise.resolve();
            var output = [];
            if (utils_1.isArray(values)) {
                // tslint:disable-next-line:prefer-for-of
                for (var i = 0; i < values.length; ++i) {
                    pipeline = pipeline.then((function (value) {
                        return function () {
                            return Promise.all([fn.call(context.result, value, context)]).then(function (results) {
                                output.push(results[0]);
                            });
                        };
                    })(values[i]));
                }
                pipeline.then(function () { return void resolve(output); }).catch(reject);
            }
            else {
                resolve(output);
            }
        });
    };
    return {
        type: 'collection',
        transform: function (values, context) {
            return apply(transformer.transform, values, context);
        },
        transformInverse: function (values, context) {
            return apply(transformer.transformInverse, values, context);
        },
        getChild: function () {
            return transformer;
        }
    };
}
exports.Collection = Collection;
