"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var inversify_1 = require("inversify");
var validation_schema_1 = require("./validation.schema");
var schemas_holder_1 = require("../model/schemas-holder");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var validation_context_1 = require("essentials/validation/validation-context");
var validation_result_1 = require("essentials/validation/validation-result");
var rxjs_1 = require("rxjs");
var validation_complete_event_1 = require("essentials/validation/event/validation-complete.event");
var ValidationService = /** @class */ (function () {
    function ValidationService() {
    }
    /**
     * Validate an object against its validation schema and return and array of ValidationError objects.
     *
     * To create a validation schema, simply add "@Assert()" decorators to the properties of the class.
     * The schema will be created automatically once there is at least one validation rule applied to the object.
     *
     * If the object doesn't have a validation schema, no error will be returned.
     */
    ValidationService.prototype.validate = function (model, mask) {
        if (mask === void 0) { mask = null; }
        var schema = schemas_holder_1.SchemasHolder.GetInstance().get(validation_schema_1.ValidationSchema, model.constructor);
        return this.validateFromSchema(schema, model, mask);
    };
    /**
     * Validate an object using a manually created schema.
     */
    ValidationService.prototype.validateFromSchema = function (schema, obj, mask, context) {
        var _this = this;
        if (mask === void 0) { mask = null; }
        if (context === void 0) { context = null; }
        return new rxjs_1.Observable(function (observer) {
            context = context ? context : new validation_context_1.ValidationContext(null, obj, schema, observer, null, mask);
            var onComplete = function () {
                observer.next(new validation_complete_event_1.ValidationCompleteEvent(new validation_result_1.ValidationResult(context)));
                observer.complete();
            };
            if (!utils_1.isObject(obj)) {
                return void onComplete();
            }
            _this.validateProperties(context, schema, obj).then(onComplete);
        });
    };
    /**
     * Validate a set of properties in parallel.
     */
    ValidationService.prototype.validateProperties = function (context, schema, obj) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            for (var propertyName in schema.properties) {
                if (!schema.properties.hasOwnProperty(propertyName)) {
                    continue;
                }
                promises.push(_this.validateProperty(context, schema, obj, propertyName));
            }
            Promise.all(promises).then(function () { return void resolve(new validation_result_1.ValidationResult(context)); });
        });
    };
    /**
     * Validate a single property of an object.
     */
    ValidationService.prototype.validateProperty = function (context, schema, obj, propertyName) {
        return new Promise(function (resolve) {
            var currentContext = validation_context_1.ValidationContext.CreateSubContext(context, propertyName);
            if (context.shouldBeValidated(propertyName)) {
                currentContext.enter();
                var result = schema.properties[propertyName].validate(obj[propertyName], currentContext);
                Promise.all([result]).then(function () {
                    currentContext.leave();
                    resolve();
                });
            }
            else {
                resolve();
            }
        });
    };
    ValidationService = tslib_1.__decorate([
        inversify_1.injectable()
    ], ValidationService);
    return ValidationService;
}());
exports.ValidationService = ValidationService;
exports.ValidationServiceSymbol = Symbol("ValidationService");
container_1.Container.registerService(exports.ValidationServiceSymbol, ValidationService);
