"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_codemirror_1 = require("vue-codemirror");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
var object_1 = require("webeak-native/util/object");
vue_1.default.use(vue_codemirror_1.default, {});
var CodeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CodeComponent, _super);
    function CodeComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Logic vars
        _this.editorReady = false;
        return _this;
    }
    CodeComponent_1 = CodeComponent;
    Object.defineProperty(CodeComponent.prototype, "mergedConfig", {
        // Computed
        get: function () {
            return object_1.extend({}, [CodeComponent_1.DefaultConfig, this.config], true);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @inheritDoc
     */
    CodeComponent.prototype.doInitialize = function () {
        // Force the value to be a string as soon as possible because code mirror will fail with the object used as default value.
        this.controlValue = this.control.value;
        return true;
    };
    /**
     * Called when code mirror is ready.
     */
    CodeComponent.prototype.onCodeMirrorReady = function (editor) {
        for (var key in this.config) {
            if (this.config.hasOwnProperty(key)) {
                editor.setOption(key, this.config[key]);
            }
        }
    };
    CodeComponent.prototype.onChange = function (newVal) {
        this.control.setValue(newVal);
    };
    var CodeComponent_1;
    CodeComponent.DefaultConfig = {
        lineNumbers: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object }),
        tslib_1.__metadata("design:type", Object)
    ], CodeComponent.prototype, "config", void 0);
    CodeComponent = CodeComponent_1 = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./code.component.html'),
        })
    ], CodeComponent);
    return CodeComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.CodeComponent = CodeComponent;
vue_1.default.component('form-code', CodeComponent);
