"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var error_1 = require("essentials/error");
var model_1 = require("essentials/model");
var utils_1 = require("webeak-native/util/utils");
var AbstractTransformationSchema = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractTransformationSchema, _super);
    function AbstractTransformationSchema() {
        var _this = _super.call(this) || this;
        _this.properties = {};
        _this.factory = function () { return new _this.target(); };
        return _this;
    }
    /**
     * Register a property into the schema.
     * If "customTransformer" is null, it will try to find a transformer in the generic schema of the model (if available).
     * If no transformer has been defined in the generic schema (or no generic schema exist for this model at all), the "defaultTransformer" will be used (which is mandatory in this case).
     */
    AbstractTransformationSchema.prototype.registerProperty = function (name, customTransformer, defaultTransformer) {
        if (customTransformer === null && !(this instanceof model_1.GenericTransformationModelSchema)) {
            var genericSchema = model_1.SchemasHolder.GetInstance().get(model_1.GenericTransformationModelSchema, this.target);
            if (genericSchema !== null && utils_1.isFunction(genericSchema.properties[name])) {
                customTransformer = genericSchema.properties[name];
            }
        }
        if (customTransformer === null) {
            customTransformer = defaultTransformer;
        }
        if (!utils_1.isObject(customTransformer)) {
            throw new error_1.AppError("No transformer has been defined for " + name + " and no fallback or default transformer have been found.");
        }
        this.properties[name] = customTransformer;
    };
    /**
     * Gets the PropertyTransformerInterface associated with a property.
     */
    AbstractTransformationSchema.prototype.getPropertyTransformer = function (propertyName) {
        if (!utils_1.isUndefined(this.properties[propertyName])) {
            return this.properties[propertyName];
        }
        return null;
    };
    /**
     * @inheritDoc
     */
    AbstractTransformationSchema.prototype.merge = function (schema) {
        if (!utils_1.isObject(schema.properties) || !utils_1.isObject(this.properties)) {
            return;
        }
        for (var name_1 in schema.properties) {
            if (schema.properties.hasOwnProperty(name_1)) {
                this.properties[name_1] = schema.properties[name_1];
            }
        }
    };
    return AbstractTransformationSchema;
}(model_1.AbstractSchema));
exports.AbstractTransformationSchema = AbstractTransformationSchema;
