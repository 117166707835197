"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var error_1 = require("essentials/error");
var moment = require("moment");
var utils_1 = require("webeak-native/util/utils");
/**
 * Convert a MomentJs object into a native Javascript date.
 */
function MomentToJsDate(nullable) {
    if (nullable === void 0) { nullable = true; }
    return {
        type: 'date',
        transform: function (value) {
            if (nullable && !value) {
                return null;
            }
            if (!(value instanceof moment)) {
                throw new error_1.AppError('Expecting an instance of Moment.');
            }
            return value.toDate();
        },
        transformInverse: function (value, context) {
            if (nullable && !value) {
                return null;
            }
            if (!utils_1.isObject(value) || !(value instanceof Date)) {
                throw new error_1.AppError('Expecting a Date object as input.');
            }
            var output = moment(value);
            if (!utils_1.isValidMomentDate(output)) {
                throw new error_1.AppError('Invalid input date.');
            }
            return output;
        },
        getChild: function () {
            return null;
        }
    };
}
exports.MomentToJsDate = MomentToJsDate;
