"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
var validation_error_1 = require("essentials/validation/validation.error");
var validator_container_1 = require("essentials/validation/validator-container");
function Email(message) {
    if (message === void 0) { message = 'Doit être une adresse email valide.'; }
    var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return {
        validate: function (value, context) {
            if (value && (!utils_1.isString(value) || !reg.test(value))) {
                context.addError(new validation_error_1.ValidationError('email', message));
            }
        }
    };
}
exports.Email = Email;
validator_container_1.ValidatorContainer.register('Email', Email);
