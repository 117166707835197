"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var CollapsiblePanelComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CollapsiblePanelComponent, _super);
    function CollapsiblePanelComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.isOpened = true;
        return _this;
    }
    // Watchers
    CollapsiblePanelComponent.prototype.onOpenedChange = function (newVal) {
        this.isOpened = newVal;
    };
    /**
     * VueJS lifecycle event.
     */
    CollapsiblePanelComponent.prototype.created = function () {
        this.isOpened = this.opened && this.openedAtStart;
    };
    CollapsiblePanelComponent.prototype.toggle = function () {
        this.isOpened = !this.isOpened;
        this.$emit('update:opened', this.isOpened);
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], CollapsiblePanelComponent.prototype, "opened", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: true }),
        tslib_1.__metadata("design:type", Boolean)
    ], CollapsiblePanelComponent.prototype, "openedAtStart", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('opened'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Boolean]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CollapsiblePanelComponent.prototype, "onOpenedChange", null);
    CollapsiblePanelComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./collapsible-panel.component.html'),
        })
    ], CollapsiblePanelComponent);
    return CollapsiblePanelComponent;
}(vue_1.default));
exports.CollapsiblePanelComponent = CollapsiblePanelComponent;
vue_1.default.component('collapsible-panel', CollapsiblePanelComponent);
