"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("essentials/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var object_1 = require("webeak-native/util/object");
var list_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list/list.component");
var list_row_component_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/vuejs/components/list/list-row/list-row.component");
var ListLinkComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListLinkComponent, _super);
    function ListLinkComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Logic vars
        _this.list = null;
        _this.row = null;
        return _this;
    }
    Object.defineProperty(ListLinkComponent.prototype, "finalHref", {
        // Computed
        get: function () {
            if (!this.href) {
                return null;
            }
            return object_1.replaceStringVariables(decodeURIComponent(this.href), this.item, '{', '}');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle.
     */
    ListLinkComponent.prototype.mounted = function () {
        this.initializeParents();
    };
    ListLinkComponent.prototype.onClick = function () {
        if (this.dialogId !== null) {
            this.openExternalDialog(this.dialogId);
            return;
        }
        if (this.finalHref === null) {
            this.$emit('click');
            return;
        }
        document.location.href = this.finalHref;
    };
    /**
     * Try to find the parent ListComponent.
     */
    ListLinkComponent.prototype.initializeParents = function () {
        var parent = this;
        do {
            parent = parent.$parent;
            if (parent instanceof list_row_component_1.ListRowComponent) {
                this.row = parent;
            }
            else if (parent instanceof list_component_1.ListComponent) {
                this.list = parent;
                return;
            }
        } while (parent);
    };
    ListLinkComponent.prototype.openExternalDialog = function (id) {
        if (this.row) {
            var component = this.row.getComponent(id);
            if (component !== null) {
                component.openDialog();
            }
        }
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: null }),
        tslib_1.__metadata("design:type", Object)
    ], ListLinkComponent.prototype, "item", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListLinkComponent.prototype, "href", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: null }),
        tslib_1.__metadata("design:type", String)
    ], ListLinkComponent.prototype, "dialogId", void 0);
    ListLinkComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./list-link.component.html')
        })
    ], ListLinkComponent);
    return ListLinkComponent;
}(vue_1.default));
exports.ListLinkComponent = ListLinkComponent;
vue_app_1.VueApp.RegisterComponent('list-link', ListLinkComponent);
