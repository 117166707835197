"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var form_error_1 = require("essentials/form/error/form.error");
var FormValidationError = /** @class */ (function (_super) {
    tslib_1.__extends(FormValidationError, _super);
    function FormValidationError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a FormValidationError from a ValidationError object.
     */
    FormValidationError.CreateFromValidationError = function (error) {
        return new FormValidationError(error.message, error.params, 'validation.' + error.type);
    };
    return FormValidationError;
}(form_error_1.FormError));
exports.FormValidationError = FormValidationError;
