"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var app_1 = require("essentials/app");
var model_1 = require("essentials/model");
var container_1 = require("webeak-native/inversify/container");
var GenericTransformationModelSchema = /** @class */ (function (_super) {
    tslib_1.__extends(GenericTransformationModelSchema, _super);
    function GenericTransformationModelSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GenericTransformationModelSchema;
}(model_1.AbstractTransformationSchema));
exports.GenericTransformationModelSchema = GenericTransformationModelSchema;
container_1.Container.getContainer().get(app_1.AppSymbol).registerInitializer(function () {
    var factory = container_1.Container.getContainer().get(model_1.ModelTransformerFactorySymbol);
    factory.register(GenericTransformationModelSchema, model_1.GenericModelTransformer);
});
