/**
 * Extensions of the String interface.
 * Here are regrouped all string utilities directly integrated to the String prototype.
 *
 * Do NOT put export function here.
 */
/* tslint:disable:no-bitwise */
/**
 * Simple string format function.
 *
 * "{} {}".format("a", "b") => "a b"
 * "{1} {0}".format("a", "b") => "b a"
 * "{foo} {bar}".format({ foo: "a", bar: "b" }) => "a b"
 *
 * @param {...any} params
 *
 * @returns string
 */
String.prototype.format = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    var args = arguments;
    var argNum = 0;
    return this.replace(/\{(\w*)\}/gi, function (match) {
        var curArgNum;
        var prop = null;
        if (match === "{}") {
            curArgNum = argNum;
            argNum++;
        }
        else {
            curArgNum = match.substr(1, match.length - 2);
            var parsed = ~~curArgNum;
            if (parsed.toString() === curArgNum) {
                curArgNum = parsed;
            }
            else {
                prop = curArgNum;
                curArgNum = 0;
            }
        }
        return curArgNum >= args.length ? "" : prop ? args[curArgNum][prop] || "" : args[curArgNum];
    });
};
/* tslint:enable:no-bitwise */
if (!String.prototype.trim) {
    String.prototype.trim = function () {
        return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    };
}
