"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_froala_wysiwyg_1 = require("vue-froala-wysiwyg");
var vue_property_decorator_1 = require("vue-property-decorator");
var abstract_form_component_1 = require("essentials/vuejs/components/form/abstract-form-component");
var utils_1 = require("webeak-native/util/utils");
var shared_configuration_1 = require("essentials-root/starter-packs/bootstrap-backend/scripts/ts/config/shared-configuration");
var container_1 = require("webeak-native/inversify/container");
var utils_2 = require("essentials/utils/utils");
var object_1 = require("webeak-native/util/object");
vue_1.default.use(vue_froala_wysiwyg_1.default);
var FroalaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FroalaComponent, _super);
    function FroalaComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Logic vars
        _this.editorConfig = null;
        return _this;
    }
    // Watcher
    FroalaComponent.prototype.onConfigChanged = function (newVal) {
        this.editorConfig = object_1.extend({}, newVal, true);
    };
    /**
     * VueJS lifecycle callback.
     */
    FroalaComponent.prototype.beforeMount = function () {
        var _this = this;
        var sharedConfiguration = container_1.Container.getContainer().get(shared_configuration_1.SharedConfigurationSymbol);
        // The initialization has to be deferred to let time for the editor to initialize.
        // Only after the "initialized" event from the editor we can initialize the form component.
        var initializeCallback = this.deferInitialization();
        this.config.key = sharedConfiguration.vuejs.forms.froala.key;
        // Force the value to be a string as soon as possible because code mirror will fail with the object used as default value.
        this.controlValue = this.control.value;
        this.editorConfig = object_1.extend({}, this.config, true);
        this.bindEditorEvent(this.editorConfig, 'initialized', function () {
            utils_2.onNextCycle().then(initializeCallback);
        });
        this.bindEditorEvent(this.editorConfig, 'focus', function () {
            _this.onFocus();
        });
        this.bindEditorEvent(this.editorConfig, 'blur', function () {
            _this.onBlur();
        });
    };
    /**
     * Bind to an editor event while ensuring the original callback is called (if one is defined).
     */
    FroalaComponent.prototype.bindEditorEvent = function (config, name, callback) {
        if (!utils_1.isObject(config.events)) {
            config.events = {};
        }
        var previousFn = config.events[name];
        config.events[name] = function () {
            if (utils_1.isFunction(previousFn)) {
                previousFn.apply(this, arguments);
            }
            callback.apply(this, arguments);
        };
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, default: function () {
                return {};
            } }),
        tslib_1.__metadata("design:type", Object)
    ], FroalaComponent.prototype, "config", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('config'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], FroalaComponent.prototype, "onConfigChanged", null);
    FroalaComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./froala.component.html'),
        })
    ], FroalaComponent);
    return FroalaComponent;
}(abstract_form_component_1.AbstractFormComponent));
exports.FroalaComponent = FroalaComponent;
vue_1.default.component('form-froala', FroalaComponent);
