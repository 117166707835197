"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var abstract_form_control_1 = require("essentials/form/abstract-form-control");
var ErrorsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ErrorsComponent, _super);
    function ErrorsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ErrorsComponent.prototype, "hasErrors", {
        // Computed
        get: function () {
            return this.control && this.control.hasErrors();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle callback.
     */
    ErrorsComponent.prototype.mounted = function () {
        this.control.markAs('virtual', false, false, false);
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ required: true }),
        tslib_1.__metadata("design:type", abstract_form_control_1.AbstractFormControl)
    ], ErrorsComponent.prototype, "control", void 0);
    ErrorsComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./errors.component.html'),
        })
    ], ErrorsComponent);
    return ErrorsComponent;
}(vue_1.default));
exports.ErrorsComponent = ErrorsComponent;
vue_1.default.component('form-errors', ErrorsComponent);
