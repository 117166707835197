"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Include elements that should be globally available in the app.
 */
require("reflect-metadata");
require("webeak-native/util/extensions/all");
require("essentials/jquery/strip-xssi");
// Must be imported before anything else.
require("essentials/storage/storage.factory");
// Custom App class
require("./app");
//
// Shortcut for importing all scripts the "bootstrap-backend" starter has to offer.
//
// Modules
require("./modules/sidebar-menu");
// VueJS
require("./vuejs");
