"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
require("codemirror/mode/yaml/yaml");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("backend/vuejs/vue-app");
var utils_1 = require("webeak-native/util/utils");
var router_1 = require("essentials/symfony/router");
var container_1 = require("webeak-native/inversify/container");
var network_1 = require("essentials/network");
var OnlineUsersCounterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OnlineUsersCounterComponent, _super);
    function OnlineUsersCounterComponent() {
        var _this = _super.call(this) || this;
        // Template vars
        _this.count = null;
        _this.timerId = null;
        _this.router = container_1.Container.getContainer().get(router_1.RouterSymbol);
        _this.http = container_1.Container.getContainer().get(network_1.HttpServiceSymbol);
        return _this;
    }
    /**
     * VueJS lifecycle callback.
     */
    OnlineUsersCounterComponent.prototype.mounted = function () {
        this.timerId = window.setInterval(utils_1.proxy(this.update, this), 10000);
        window.setTimeout(utils_1.proxy(this.update, this), 2000); // For style..
    };
    /**
     * VueJS lifecycle callback.
     */
    OnlineUsersCounterComponent.prototype.beforeDestroy = function () {
        if (this.timerId !== null) {
            window.clearInterval(this.timerId);
            this.timerId = null;
        }
    };
    /**
     * Update the number of logged users.
     */
    OnlineUsersCounterComponent.prototype.update = function () {
        var _this = this;
        this.http.get(this.router.generateUrl('app_ajax_online_users_count')).promise.then(function (result) {
            _this.count = result.count;
        });
    };
    OnlineUsersCounterComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./online-users-counter.component.html')
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], OnlineUsersCounterComponent);
    return OnlineUsersCounterComponent;
}(vue_1.default));
exports.OnlineUsersCounterComponent = OnlineUsersCounterComponent;
vue_app_1.VueApp.RegisterComponent('online-users-counter', OnlineUsersCounterComponent);
