"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("backend/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var tree_item_component_1 = require("./tree-item.component");
var TreeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TreeComponent, _super);
    function TreeComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Array, default: null }),
        tslib_1.__metadata("design:type", Array)
    ], TreeComponent.prototype, "items", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'children' }),
        tslib_1.__metadata("design:type", String)
    ], TreeComponent.prototype, "childrenKey", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'class' }),
        tslib_1.__metadata("design:type", String)
    ], TreeComponent.prototype, "classKey", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'opened' }),
        tslib_1.__metadata("design:type", String)
    ], TreeComponent.prototype, "openedKey", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeComponent.prototype, "interactiveContent", void 0);
    TreeComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./tree.component.html'),
            components: { 'tree-item': tree_item_component_1.TreeItemComponent }
        })
    ], TreeComponent);
    return TreeComponent;
}(vue_1.default));
exports.TreeComponent = TreeComponent;
vue_app_1.VueApp.RegisterComponent('tree', TreeComponent);
