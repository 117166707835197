"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var error_1 = require("essentials/error");
var moment = require("moment");
var utils_1 = require("webeak-native/util/utils");
/**
 * Convert a MomentJs object into an ISO string and vice versa.
 *
 * TODO: apply the timezone in use in the app to the moment object (if applicable).
 */
function MomentToIsoString(nullable) {
    if (nullable === void 0) { nullable = true; }
    return {
        type: 'date',
        transform: function (value) {
            if (nullable && value === null) {
                return null;
            }
            if (!(value instanceof moment)) {
                throw new error_1.AppError('Expecting an instance of Moment.');
            }
            return value.toISOString();
        },
        transformInverse: function (value, context) {
            if (nullable && !value) {
                return null;
            }
            if (!utils_1.isString(value)) {
                throw new error_1.AppError('Expecting a string as input.');
            }
            var output = moment(value);
            if (!utils_1.isValidMomentDate(output)) {
                throw new error_1.AppError('Invalid date string.');
            }
            return output;
        },
        getChild: function () {
            return null;
        }
    };
}
exports.MomentToIsoString = MomentToIsoString;
