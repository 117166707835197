"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var app_error_1 = require("./app.error");
/**
 * Error used to stop a promise chain without applying the normal error behavior.
 */
var StopError = /** @class */ (function (_super) {
    tslib_1.__extends(StopError, _super);
    function StopError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return StopError;
}(app_error_1.AppError));
exports.StopError = StopError;
