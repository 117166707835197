"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var event_1 = require("essentials/event");
var PanelErrorRetryEvent = /** @class */ (function (_super) {
    tslib_1.__extends(PanelErrorRetryEvent, _super);
    function PanelErrorRetryEvent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Allow the retry function to set a promise indicating that the process is running
         * to the panel error. This way the panel can disabled the retry button and show a loader.
         */
        _this.promise = null;
        return _this;
    }
    return PanelErrorRetryEvent;
}(event_1.EventArg));
exports.PanelErrorRetryEvent = PanelErrorRetryEvent;
