"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_response_1 = require("essentials/network/http-response");
var network_1 = require("essentials/network");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("essentials/network/utils");
var paginated_results_1 = require("essentials/network/paginated-results");
var constants_1 = require("essentials/network/constants");
var transformer_service_1 = require("essentials/model/transformer/transformer.service");
var generic_transformation_model_schema_1 = require("essentials/model/generic-transformation-model.schema");
var HttpFetcher = /** @class */ (function () {
    function HttpFetcher(url, entity) {
        this.url = url;
        this.entity = entity;
        this.http = container_1.Container.getContainer().get(network_1.HttpServiceSymbol);
        this.transformer = container_1.Container.getContainer().get(transformer_service_1.TransformerServiceSymbol);
    }
    /**
     * @inheritDoc
     */
    HttpFetcher.prototype.fetch = function (context) {
        var _this = this;
        var response = new http_response_1.HttpResponse();
        response.setStatus(constants_1.HttpResponseStatus.Pending);
        response.promise = new Promise(function (resolve, reject) {
            var queryParameters = Object.assign({}, context.filters);
            if (context.pagination) {
                queryParameters.page = context.pagination.page;
                queryParameters.itemsPerPage = context.pagination.itemsPerPage;
            }
            if (context.orderingField) {
                queryParameters.orderBy = context.orderingField;
                queryParameters.orderDir = context.orderingDirection;
            }
            var realResponse = _this.http.get(utils_1.appendQueryParameters(_this.url, queryParameters));
            realResponse.promise.finally(function () {
                response.syncWith(realResponse);
                if (response.isSuccess) {
                    _this.processResponse(response, context).then(resolve).catch(reject);
                }
                else {
                    reject(response.error);
                }
            });
        });
        return response;
    };
    /**
     * Make the necessary transformations to the response so the list can handle it.
     */
    HttpFetcher.prototype.processResponse = function (response, context) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.processPagination(response, context);
                        if (!(response.result instanceof paginated_results_1.PaginatedResults)) return [3 /*break*/, 2];
                        _a = response.result;
                        return [4 /*yield*/, this.processEntities(response.result.items)];
                    case 1:
                        _a.items = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _b = response;
                        return [4 /*yield*/, this.processEntities(response.result)];
                    case 3:
                        _b.result = _c.sent();
                        _c.label = 4;
                    case 4: return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Create an entity for each result.
     */
    HttpFetcher.prototype.processEntities = function (items) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var entities, i, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.entity) {
                            return [2 /*return*/, items];
                        }
                        entities = [];
                        i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(i < items.length)) return [3 /*break*/, 4];
                        _b = (_a = entities).push;
                        return [4 /*yield*/, this.transformer.transformInverse(items[i], generic_transformation_model_schema_1.GenericTransformationModelSchema, this.entity)];
                    case 2:
                        _b.apply(_a, [_c.sent()]);
                        _c.label = 3;
                    case 3:
                        ++i;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, entities];
                }
            });
        });
    };
    /**
     * So pagination related modifications.
     */
    HttpFetcher.prototype.processPagination = function (response, context) {
        if (context.pagination) {
            response.result = paginated_results_1.PaginatedResults.CreateFromServerResponse(response.result);
        }
    };
    return HttpFetcher;
}());
exports.HttpFetcher = HttpFetcher;
