"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("webeak-native/util/utils");
var ResourceInheritanceMetadata = /** @class */ (function () {
    function ResourceInheritanceMetadata() {
    }
    /**
     * Create a ResourceInheritanceMetadata instance from the raw output from the server.
     */
    ResourceInheritanceMetadata.createFromServerResponse = function (config) {
        if (!utils_1.isArray(config)) {
            return null;
        }
        var instance = new ResourceInheritanceMetadata();
        instance.importServerData(config);
        return instance;
    };
    /**
     * Import raw output from the server into the current instance.
     */
    ResourceInheritanceMetadata.prototype.importServerData = function (config) {
        this.discriminator = config[0];
        this.map = config[1];
    };
    return ResourceInheritanceMetadata;
}());
exports.ResourceInheritanceMetadata = ResourceInheritanceMetadata;
