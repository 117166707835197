"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_model_transformer_1 = require("essentials/api/api-model.transformer");
var app_1 = require("essentials/app");
var model_1 = require("essentials/model");
var container_1 = require("webeak-native/inversify/container");
var ApiTransformationModelSchema = /** @class */ (function (_super) {
    tslib_1.__extends(ApiTransformationModelSchema, _super);
    function ApiTransformationModelSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ApiTransformationModelSchema;
}(model_1.AbstractTransformationSchema));
exports.ApiTransformationModelSchema = ApiTransformationModelSchema;
container_1.Container.getContainer().get(app_1.AppSymbol).registerInitializer(function () {
    var factory = container_1.Container.getContainer().get(model_1.ModelTransformerFactorySymbol);
    factory.register(ApiTransformationModelSchema, api_model_transformer_1.ApiModelTransformer);
});
