"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var api_service_1 = require("essentials/api/api.service");
var api_metadata_factory_1 = require("essentials/api/metadata/api-metadata.factory");
var error_1 = require("essentials/error");
var object_1 = require("webeak-native/util/object");
var utils_1 = require("webeak-native/util/utils");
var inversify_1 = require("inversify");
var container_1 = require("webeak-native/inversify/container");
var utils_2 = require("essentials/network/utils");
/**
 * Base class of request builders.
 */
var AbstractApiRequestBuilder = /** @class */ (function () {
    function AbstractApiRequestBuilder(metadataFactory) {
        this.metadataFactory = metadataFactory;
        this.requestLocale = null;
        this.filtering = null;
        this.uriReplacements = {};
        this.queryParameters = {};
        this.asyncTasks = [];
    }
    /**
     * Sets the name of the action to execute.
     */
    AbstractApiRequestBuilder.prototype.action = function (name) {
        this.actionName = name;
        return this;
    };
    /**
     * Sets the type of the entity concerned by the request.
     */
    AbstractApiRequestBuilder.prototype.entity = function (type) {
        if (!utils_1.isFunction(type) || !utils_1.isString(type.name)) {
            throw new error_1.AppError('Invalid entity type, must be a function.');
        }
        this.entityType = type;
        return this;
    };
    /**
     * Sets a specific locale the request will have to use.
     * If not defined, the locale of the current request will be inherited.
     */
    AbstractApiRequestBuilder.prototype.locale = function (locale) {
        this.requestLocale = locale;
        return this;
    };
    /**
     * Execute the request.
     */
    AbstractApiRequestBuilder.prototype.execute = function () {
        var api = container_1.Container.getContainer().get(api_service_1.ApiServiceSymbol);
        return api.execute(this);
    };
    /**
     * Set a the value of a dynamic part of the action's path (example: /user/{id}).
     */
    AbstractApiRequestBuilder.prototype.addUriReplacement = function (key, value) {
        this.uriReplacements[key] = value;
        return this;
    };
    /**
     * Set the value of a query parameter that will be added to the final url (like: ?var=2).
     */
    AbstractApiRequestBuilder.prototype.addQueryParameter = function (key, value) {
        this.queryParameters[key] = value;
        return this;
    };
    /**
     * Take a property name and a value or an key/value pair object holding the filters for multiple values.
     *
     * Filters are defined like so:
     * {'columnName': 'value of the filter'}
     *
     * You can also use nested properties like so:
     * {'product.color': 'red'}
     *   or
     * `builder->where('product.color', 'red')`
     */
    AbstractApiRequestBuilder.prototype.where = function (keyOrObject, value) {
        var _a;
        if (value === void 0) { value = null; }
        if (utils_1.isString(keyOrObject)) {
            keyOrObject = (_a = {}, _a[keyOrObject] = value, _a);
        }
        this.filtering = object_1.merge(this.filtering || {}, utils_1.ensureObject(keyOrObject));
        return this;
    };
    /**
     * Creates the HTTP request.
     */
    AbstractApiRequestBuilder.prototype.getRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metadata, request, headerStr;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataFactory.create()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, this.executeAsyncTasks(metadata)];
                    case 2:
                        _a.sent();
                        request = this.createRequest(metadata);
                        if (this.requestLocale !== null) {
                            headerStr = this.createAcceptLanguageHeader([this.requestLocale]);
                            if (headerStr === null) {
                                throw new error_1.AppError('Invalid array of accepted locales.');
                            }
                            request.headers['Accept-Language'] = headerStr;
                        }
                        return [2 /*return*/, request];
                }
            });
        });
    };
    /**
     * Register an async task that will be executed before the request is created.
     */
    AbstractApiRequestBuilder.prototype.addAsyncTask = function (cb) {
        this.asyncTasks.push(cb);
    };
    /**
     * Generates the URI of an Api request.
     */
    AbstractApiRequestBuilder.prototype.generateUri = function (metadata, entityType, actionName, isCollection, uriReplacements, queryParameters) {
        if (uriReplacements === void 0) { uriReplacements = {}; }
        if (queryParameters === void 0) { queryParameters = {}; }
        var resourceMetadata = metadata.getResourceMetadata(entityType);
        if (!resourceMetadata) {
            throw new error_1.AppError("'No entity \"" + entityType.name + "\" has been defined in the Api");
        }
        var actionMetadata = resourceMetadata.getActionMetadata(actionName);
        if (!actionMetadata) {
            throw new error_1.AppError("No " + (isCollection ? 'item' : 'collection') + " action named \"" + actionName + "\" has been found for entity \"" + entityType.name + "\".");
        }
        var uri = object_1.replaceStringVariables(actionMetadata.path, uriReplacements, '{', '}');
        return uri + utils_2.buildQueryParameters(queryParameters);
    };
    /**
     * Gets metadata of the action the builder will create a request for.
     */
    AbstractApiRequestBuilder.prototype.getActionMetadata = function (metadata) {
        var resourceMetadata = metadata.getResourceMetadata(this.entityType);
        return resourceMetadata.getActionMetadata(this.actionName);
    };
    /**
     * Analyse the input parameters concerning filtering and returns the corresponding array of
     * query parameters to include in the request.
     */
    AbstractApiRequestBuilder.prototype.getFilteringQueryParameters = function (metadata) {
        if (!utils_1.isObject(this.filtering) || !Object.keys(this.filtering).length) {
            return {};
        }
        var output = {};
        var wrapperName = metadata.filtering.wrapperParameterName;
        var pnamePrefix = wrapperName ? (wrapperName + '[') : '';
        var pnameSuffix = wrapperName ? ']' : '';
        for (var fname in this.filtering) {
            var fvalue = this.filtering[fname];
            var innerSuffix = '';
            var pos = void 0;
            if ((pos = fname.indexOf('[')) >= 0) {
                innerSuffix = fname.substring(pos);
                fname = fname.substring(0, pos);
            }
            var pname = pnamePrefix + fname + pnameSuffix + innerSuffix;
            if (utils_1.isArray(fvalue)) {
                var idx = 0;
                for (var _i = 0, fvalue_1 = fvalue; _i < fvalue_1.length; _i++) {
                    var candidate = fvalue_1[_i];
                    var _pname = pname + '[' + (++idx) + ']';
                    output[_pname] = candidate;
                }
            }
            else {
                output[pname] = fvalue;
            }
        }
        return output;
    };
    /**
     * Create the Accept-Language string from a list of locales ordered by preference.
     */
    AbstractApiRequestBuilder.prototype.createAcceptLanguageHeader = function (acceptedLocales) {
        var locales = [];
        for (var i = 0, p = 1, ii = acceptedLocales.length; i < ii; ++i, p -= (ii - i < 10) ? 0.1 : 0) {
            var priority = p.toFixed(1);
            locales.push(acceptedLocales[i] + ';q=' + priority);
        }
        if (!locales.length) {
            return null;
        }
        return locales.join(', ');
    };
    /**
     * Execute the async methods required by the builder before the request is created.
     */
    AbstractApiRequestBuilder.prototype.executeAsyncTasks = function (metadata) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, cb;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = this.asyncTasks;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        cb = _a[_i];
                        return [4 /*yield*/, cb.apply(this, [metadata])];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AbstractApiRequestBuilder = tslib_1.__decorate([
        inversify_1.injectable(),
        tslib_1.__metadata("design:paramtypes", [api_metadata_factory_1.ApiMetadataFactory])
    ], AbstractApiRequestBuilder);
    return AbstractApiRequestBuilder;
}());
exports.AbstractApiRequestBuilder = AbstractApiRequestBuilder;
