"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var app_1 = require("essentials/app");
var model_transformer_factory_1 = require("essentials/model/transformer/model-transformer.factory");
var container_1 = require("webeak-native/inversify/container");
var form_model_transformer_1 = require("essentials/form/form-model.transformer");
var abstract_transformation_schema_1 = require("essentials/model/abstract-transformation-schema");
var FormTransformationModelSchema = /** @class */ (function (_super) {
    tslib_1.__extends(FormTransformationModelSchema, _super);
    function FormTransformationModelSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FormTransformationModelSchema;
}(abstract_transformation_schema_1.AbstractTransformationSchema));
exports.FormTransformationModelSchema = FormTransformationModelSchema;
container_1.Container.getContainer().get(app_1.AppSymbol).registerInitializer(function () {
    var factory = container_1.Container.getContainer().get(model_transformer_factory_1.ModelTransformerFactorySymbol);
    factory.register(FormTransformationModelSchema, form_model_transformer_1.FormModelTransformer);
});
