"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var constants_1 = require("essentials/network/constants");
var http_request_1 = require("essentials/network/http-request");
var utils_1 = require("webeak-native/util/utils");
var ApiRequest = /** @class */ (function (_super) {
    tslib_1.__extends(ApiRequest, _super);
    function ApiRequest(resourceMetadata, actionMetadata, method, url, payload, headers, maxRetryCount) {
        if (payload === void 0) { payload = null; }
        if (headers === void 0) { headers = {}; }
        if (maxRetryCount === void 0) { maxRetryCount = 3; }
        var _this = _super.call(this, method, url, payload, headers, maxRetryCount) || this;
        _this.resourceMetadata = resourceMetadata;
        _this.actionMetadata = actionMetadata;
        /**
         * Reference on the form associated with the request.
         * If defined, in case of an error, it will be propagated into the form.
         */
        _this.boundForm = null;
        /**
         * Defines if the response will be paginated (only apply to GET requests).
         */
        _this.isPaginated = false;
        return _this;
    }
    /**
     * Test if the response will be a single entity object.
     */
    ApiRequest.prototype.isSingleEntityResponse = function (result) {
        return this.method !== constants_1.HttpMethods.DELETE && (!this.actionMetadata.isCollection || this.method === constants_1.HttpMethods.POST) && !utils_1.isArray(result);
    };
    /**
     * Test if the response will be a simple array of entities.
     */
    ApiRequest.prototype.isSimpleEntitiesCollectionResponse = function () {
        return this.method !== constants_1.HttpMethods.DELETE && this.actionMetadata.isCollection && !this.isPaginated;
    };
    /**
     * Test if the response will be a paginated array of entities.
     */
    ApiRequest.prototype.isPaginatedEntitiesCollectionResponse = function () {
        return this.method === constants_1.HttpMethods.GET && this.actionMetadata.isCollection && this.isPaginated;
    };
    /**
     * Test if the response will not contain anything.
     */
    ApiRequest.prototype.isEmptyResponse = function () {
        return this.method === constants_1.HttpMethods.DELETE;
    };
    return ApiRequest;
}(http_request_1.HttpRequest));
exports.ApiRequest = ApiRequest;
