"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_app_1 = require("backend/vuejs/vue-app");
var vue_property_decorator_1 = require("vue-property-decorator");
var tooltip_1 = require("essentials/vuejs/directives/tooltip/tooltip");
var utils_1 = require("webeak-native/util/utils");
var object_1 = require("webeak-native/util/object");
var TreeItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TreeItemComponent, _super);
    function TreeItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Template vars
        _this.manuallyOpened = null;
        return _this;
    }
    // Watchers
    TreeItemComponent.prototype.onItemChange = function () {
        this.manuallyOpened = null;
    };
    Object.defineProperty(TreeItemComponent.prototype, "children", {
        // Computed
        get: function () {
            return utils_1.isArray(this.item[this.childrenKey]) ? this.item[this.childrenKey] : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeItemComponent.prototype, "compiledClasses", {
        get: function () {
            var obj = {
                'vc-tree-item--has-children': this.hasChildren,
                'vc-tree-item--opened': this.isOpened,
                'vc-tree-item--interactive-content': this.interactiveContent
            };
            if (this.classKey && !utils_1.isUndefined(this.item[this.classKey])) {
                if (utils_1.isObject(this.item[this.classKey])) {
                    obj = object_1.extend(obj, this.item[this.classKey]);
                }
                else if (utils_1.isString(this.item[this.classKey])) {
                    obj[this.item[this.classKey]] = true;
                }
            }
            return obj;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeItemComponent.prototype, "isOpened", {
        get: function () {
            if (this.manuallyOpened !== null) {
                return this.manuallyOpened;
            }
            return this.item[this.openedKey] === true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeItemComponent.prototype, "hasChildren", {
        get: function () {
            return utils_1.isObject(this.item[this.childrenKey]) && this.item[this.childrenKey].length > 0;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * VueJS lifecycle method.
     */
    TreeItemComponent.prototype.mounted = function () {
        this.manuallyOpened = false;
    };
    /**
     * Show/hide child nodes.
     */
    TreeItemComponent.prototype.toggleChildren = function () {
        this.manuallyOpened = (this.manuallyOpened !== null) ? !this.manuallyOpened : !this.item[this.openedKey];
    };
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Object, required: true }),
        tslib_1.__metadata("design:type", Object)
    ], TreeItemComponent.prototype, "item", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'children' }),
        tslib_1.__metadata("design:type", String)
    ], TreeItemComponent.prototype, "childrenKey", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'class' }),
        tslib_1.__metadata("design:type", String)
    ], TreeItemComponent.prototype, "classKey", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: String, default: 'opened' }),
        tslib_1.__metadata("design:type", String)
    ], TreeItemComponent.prototype, "openedKey", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Prop({ type: Boolean, default: false }),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeItemComponent.prototype, "interactiveContent", void 0);
    tslib_1.__decorate([
        vue_property_decorator_1.Watch('item', { deep: true }),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], TreeItemComponent.prototype, "onItemChange", null);
    TreeItemComponent = tslib_1.__decorate([
        vue_class_component_1.default({
            template: require('./tree-item.component.html'),
            name: 'tree-item',
            directives: { 'v-tooltip': tooltip_1.Tooltip }
        })
    ], TreeItemComponent);
    return TreeItemComponent;
}(vue_1.default));
exports.TreeItemComponent = TreeItemComponent;
vue_app_1.VueApp.RegisterComponent('tree-item', TreeItemComponent);
