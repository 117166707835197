"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var alertify = require("alertifyjs");
var container_1 = require("webeak-native/inversify/container");
var utils_1 = require("webeak-native/util/utils");
var api_service_1 = require("essentials/api/api.service");
var alertify_service_1 = require("essentials/dialog/alertify/alertify.service");
var form_control_1 = require("essentials/form/form-control");
var error_1 = require("essentials/error");
var abstract_form_control_collection_1 = require("essentials/form/abstract-form-control-collection");
var form_model_binder_1 = require("essentials/form/form-model-binder");
var form_group_1 = require("essentials/form/form-group");
var utils_2 = require("essentials/utils/utils");
var api_metadata_factory_1 = require("essentials/api/metadata/api-metadata.factory");
var schemas_holder_1 = require("essentials/model/schemas-holder");
var ApiFormDialog = /** @class */ (function () {
    function ApiFormDialog() {
        // Template vars
        this.form = new form_group_1.FormGroup();
        this.initialized = false;
        this.formBinder = null;
        this.saveButton = null;
        this.cancelButton = null;
        this.dialog = null;
        this.isLoading = false;
        this.openPromise = null;
        this.openPromiseResolve = null;
        var container = container_1.Container.getContainer();
        this.api = container.get(api_service_1.ApiServiceSymbol);
        this.apiMetadataFactory = container.get(api_metadata_factory_1.ApiMetadataFactorySymbol);
        this.alertify = container.get(alertify_service_1.AlertifyServiceSymbol);
    }
    /**
     * Set the entity or type of entity to use.
     * Depending on what you give as input, a post or put request will be done.
     */
    ApiFormDialog.prototype.entity = function (entity) {
        if (utils_1.isString(entity)) {
            entity = schemas_holder_1.SchemasHolder.GetInstance().resolveModelTypeFromString(entity);
        }
        if (utils_1.isConstructor(entity)) {
            entity = new entity();
        }
        this._entity = entity;
        return this;
    };
    /**
     * Set the name of the action to call when saving.
     */
    ApiFormDialog.prototype.action = function (name) {
        this._action = name;
        return this;
    };
    /**
     * Set the title of the modal window.
     */
    ApiFormDialog.prototype.title = function (title) {
        this._title = title;
        return this;
    };
    /**
     * Set the width of the dialog. Supports pourcentages.
     */
    ApiFormDialog.prototype.width = function (width) {
        this._width = width;
        return this;
    };
    /**
     * Set the height of the dialog. Supports pourcentages.
     * If none is defined, the height will adapt to the content of the dialog.
     */
    ApiFormDialog.prototype.height = function (height) {
        this._height = height;
        return this;
    };
    /**
     * Set the content of the dialog.
     * Can be an html string, an html or jquery element.
     */
    ApiFormDialog.prototype.content = function (content) {
        this._content = content;
        return this;
    };
    /**
     * Get the root form group.
     */
    ApiFormDialog.prototype.getForm = function () {
        return this.form;
    };
    /**
     * Get the alertify dialog object.
     */
    ApiFormDialog.prototype.getDialog = function () {
        return this.dialog;
    };
    /**
     * Get a form control by name.
     */
    ApiFormDialog.prototype.getFormControl = function (name) {
        var parts = name.split('.');
        var currentControl = this.form;
        for (var _i = 0, parts_1 = parts; _i < parts_1.length; _i++) {
            var part = parts_1[_i];
            if (!(currentControl instanceof abstract_form_control_collection_1.AbstractFormControlCollection) || !currentControl.has(part)) {
                throw new error_1.AppError("No form control named " + name + " has been found. Failed on part \"" + part + "\".");
            }
            currentControl = currentControl.get(part);
        }
        if (!(currentControl instanceof form_control_1.FormControl)) {
            throw new error_1.AppError("The form component " + name + " is not a FormControl. Please verify that the path is correct.");
        }
        return currentControl;
    };
    /**
     * Initialize the dialog.
     */
    ApiFormDialog.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metadata;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.initialized) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.apiMetadataFactory.create()];
                    case 1:
                        metadata = _a.sent();
                        this.entityMetadata = metadata.getResourceMetadata(this._entity.constructor);
                        return [4 /*yield*/, this.initializeForm()];
                    case 2:
                        _a.sent();
                        this.initialized = true;
                        return [4 /*yield*/, utils_2.onNextCycle()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Show the form dialog.
     */
    ApiFormDialog.prototype.open = function () {
        var _this = this;
        if (this.openPromise) {
            return this.openPromise;
        }
        this.openPromise = new Promise(function (resolve) {
            var that = _this;
            var onClosingTimer = null;
            _this.openPromiseResolve = resolve;
            _this.initialize().then(function () {
                if (_this.dialog === null) {
                    _this.dialog = _this.alertify.dialog({
                        source: _this._content,
                        buttons: [
                            {
                                text: 'Sauvegarder',
                                key: 27,
                                invokeOnClose: false,
                                className: alertify.defaults.theme.ok,
                            },
                            {
                                text: 'Annuler'
                            }
                        ],
                        options: {
                            title: _this._title,
                            closableByDimmer: false,
                            resizable: true,
                            maximizable: false,
                            onshow: function () {
                                that.saveButton = $(this.__internal.buttons[0].element);
                                that.cancelButton = $(this.__internal.buttons[1].element);
                            }
                        },
                        onCallback: function (event) {
                            if (event.index === 0) {
                                _this.save();
                                event.cancel = true;
                            }
                            else {
                                _this.resolveOpenPromise(false);
                            }
                            if (onClosingTimer !== null) {
                                window.clearTimeout(onClosingTimer);
                                onClosingTimer = null;
                            }
                        }
                    });
                    _this.dialog.set('onclosing', function () {
                        if (_this.openPromiseResolve !== null) {
                            onClosingTimer = window.setTimeout(function () {
                                _this.resolveOpenPromise(false);
                            });
                        }
                    });
                    if (_this._width !== null) {
                        if (_this._height !== null) {
                            _this.dialog.resizeTo(_this._width, _this._height);
                        }
                        else {
                            $(_this.dialog.elements.dialog).css({
                                'max-width': _this._width,
                                'max-height': 'none',
                                'height': 'auto'
                            });
                        }
                    }
                }
                else {
                    _this.dialog.show();
                }
            });
        });
        return this.openPromise;
    };
    /**
     * Submit the changes.
     */
    ApiFormDialog.prototype.save = function () {
        var _this = this;
        var handleError = function (e) {
            _this.alertify.notifyError(error_1.AppError.create(e).getPublicMessage('Échec de la sauvegarde pour une raison inconnue.'));
            _this.removeLoadingStatus();
        };
        this.setLoadingStatus();
        try {
            var id = this._entity[this.entityMetadata.identifierProperty];
            var postActionName = utils_1.isNullOrUndefined(id) ? this._action : null;
            var putActionName = postActionName === null ? this._action : null;
            this.api
                .persist(this._entity.constructor, postActionName, putActionName)
                .data(this.formBinder)
                .execute().promise.then(function () {
                // this.list.update(true, true);
                _this.alertify.notifySuccess('Sauvegarde effectuée avec succès.');
                _this.removeLoadingStatus();
                _this.resolveOpenPromise(true);
                _this.dialog.destroy();
            }).catch(utils_1.proxy(handleError, this));
        }
        catch (e) {
            handleError(e);
        }
    };
    /**
     * Create a FormBinder if necessary.
     */
    ApiFormDialog.prototype.initializeForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.formBinder !== null) {
                            return [2 /*return*/];
                        }
                        this.formBinder = new form_model_binder_1.FormModelBinder(this.form, this._entity);
                        return [4 /*yield*/, this.formBinder.onReady()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Set the dialog as loading.
     */
    ApiFormDialog.prototype.setLoadingStatus = function () {
        this.isLoading = true;
        this.saveButton
            .prop('disabled', 'disabled')
            .data('_ot', this.saveButton.html())
            .html('<i class="fas fa-spinner-third fa-spin"></i>');
        this.cancelButton.prop('disabled', 'disabled');
    };
    /**
     * Remove the loading state of the dialog.
     */
    ApiFormDialog.prototype.removeLoadingStatus = function () {
        this.isLoading = false;
        this.saveButton.html(this.saveButton.data('_ot')).prop('disabled', false);
        this.cancelButton.prop('disabled', false);
    };
    /**
     * Resolve the promise returned by the open() method.
     */
    ApiFormDialog.prototype.resolveOpenPromise = function (value) {
        this.openPromiseResolve(value);
        this.openPromise = null;
        this.openPromiseResolve = null;
    };
    return ApiFormDialog;
}());
exports.ApiFormDialog = ApiFormDialog;
