"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var event_1 = require("essentials/event");
/**
 * Represent an event emitted by a form control.
 */
var FormEvent = /** @class */ (function (_super) {
    tslib_1.__extends(FormEvent, _super);
    function FormEvent(control) {
        var _this = _super.call(this) || this;
        _this.control = control;
        _this.stack = [];
        return _this;
    }
    Object.defineProperty(FormEvent.prototype, "source", {
        /**
         * Get the source of the event.
          */
        get: function () {
            return this.stack.length > 0 ? this.stack[this.stack.length - 1] : null;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Append a control to the beginning of the stack.
     */
    FormEvent.prototype.prependToStack = function (control) {
        this.stack.unshift(control);
    };
    return FormEvent;
}(event_1.EventArg));
exports.FormEvent = FormEvent;
