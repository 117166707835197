/**
 * Main entry point of the backend.
 * This file is only meant to import other files.
 *
 * You must include all javascript/typescript AND css/scss files you need here.
 */
import 'codemirror';
import 'codemirror/mode/xml/xml';
import 'codemirror/lib/codemirror.css';
import "essentials-root/starter-packs/bootstrap-backend/scripts/js/entry-point";
import 'essentials-root/vendor/froala/js/plugins/code_view';
import 'essentials-root/vendor/froala/js/languages/fr';

import 'backend-root/styles/sass/vendor/main.scss';
import 'backend-root/styles/sass/vendor/froala.scss';
import 'backend-root/styles/sass/main.scss';
import 'backend-root/scripts/ts/main';
