"use strict";
/**
 * Client side entry point.
 * ------------------------
 *
 * Before anything else, import the dependencies file.
 * The role of this file is to import all modules in use in the app.
 */
Object.defineProperty(exports, "__esModule", { value: true });
require("./dependencies");
var app_1 = require("essentials/app");
var container_1 = require("webeak-native/inversify/container");
$(document).ready(function () {
    container_1.Container.getContainer().get(app_1.AppSymbol).start();
});
