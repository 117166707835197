"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var paginated_results_1 = require("essentials/network/paginated-results");
var ApiPaginatedResults = /** @class */ (function (_super) {
    tslib_1.__extends(ApiPaginatedResults, _super);
    function ApiPaginatedResults() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create a ApiPaginatedResults<T> instance from a raw response from the api.
     */
    ApiPaginatedResults.CreateFromServerResponse = function (response) {
        return paginated_results_1.PaginatedResults.CreateFromServerResponse(response);
    };
    return ApiPaginatedResults;
}(paginated_results_1.PaginatedResults));
exports.ApiPaginatedResults = ApiPaginatedResults;
